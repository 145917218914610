import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {AppRoutingModule} from '../app-routing.module';
import {HashkeyLoginComponent} from './hashkey-login/hashkey-login.component';
import {EnterPasswordComponent} from './enter-password/enter-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {VerifyClientComponent} from './verify-client/verify-client.component';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import {SecuritiesModule} from '../securities/securities.module';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent,
    HashkeyLoginComponent,
    EnterPasswordComponent,
    ResetPasswordComponent,
    VerifyClientComponent,
    ForceChangePasswordComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    SecuritiesModule
  ]
})
export class LoginModule { }
