import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WealthboxModule} from './wealthbox/wealthbox.module';
import {RouterModule} from '@angular/router';
import {INTEGRATIONS_ROUTES} from './integrations.routes';
import { IntegrationsListComponent } from './integrations-list/integrations-list.component';
import {SharedModule} from '../../shared/shared.module';
import { AtlasActivityComponent } from './atlas/atlas-activity/atlas-activity.component';
import {BenchmarkModule} from '../../benchmark/benchmark.module';
import {BillingModule} from '../../billing/billing.module';
import {PortformerModule} from './portformer/portformer.module';
import { AtlasRecommendationsComponent } from './atlas/atlas-activity/atlas-recommendations/atlas-recommendations.component';
import {JobsModule} from '../../jobs/jobs.module';
import { StratifiComponent } from './stratifi/stratifi.component';
import { OauthLandingComponent } from './oauth-landing/oauth-landing.component';

@NgModule({
  declarations: [
    IntegrationsListComponent,
    AtlasActivityComponent,
    AtlasRecommendationsComponent,
    StratifiComponent,
    OauthLandingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    WealthboxModule,
    RouterModule.forChild(INTEGRATIONS_ROUTES),
    BenchmarkModule,
    BillingModule,
    PortformerModule,
    JobsModule
  ],
  exports: [
    IntegrationsListComponent,
    AtlasActivityComponent,
    WealthboxModule
  ]
})
export class IntegrationsModule { }
