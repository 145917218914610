import {Component, Inject, OnInit} from '@angular/core';
import {ClientManagementService} from '../../clients/client-management.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Client} from '../../clients/clients.types';
import {Observable} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {catchError, filter, map, startWith, switchMap} from 'rxjs/operators';
import {ClientInboxService, InvoiceClientInboxInput, PdfClientInboxInput} from '../client-inbox.service';
import {NotificationService} from '../../shared/services/ui/notification.service';
import {Invoice} from '../../billing/invoices/invoice';

@Component({
  selector: 'app-send-client-email',
  templateUrl: './send-client-email.component.html',
  styleUrls: ['./send-client-email.component.scss']
})
export class SendClientEmailComponent implements OnInit {
  messageType: string;
  sendables: any;

  sending = false;

  emailForm: UntypedFormGroup;

  filteredClients$: Observable<Client[]>;

  constructor(
    private clientService: ClientManagementService,
    private clientInboxService: ClientInboxService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<SendClientEmailComponent>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService
  ) {
    this.messageType = data.messageType;
    this.sendables = data.sendables;

    this.emailForm = this.formBuilder.group({
      client: [null, Validators.required],
      verifyEmail: ['', Validators.required],
      message: '',
    }, {
      validator: this.validEmail
    });
  }

  ngOnInit(): void {
    this.filteredClients$ = this.emailForm.controls['client'].valueChanges.pipe(
      startWith(''),
      filter(search => {
        return typeof search === 'string';
      }),
      switchMap(search => {
        search = search.toLowerCase();
        return this.clientService.clients$.pipe(
          map(clients => {
            return clients.filter(client => {
              return client.first_name.toLowerCase().includes(search)
                || client.last_name.toLowerCase().includes(search)
                || client.email.toLowerCase().includes(search);
            });
          })
        );
      })
    );
  }

  displayClient(client: Client): string {
    return client ? `${client.first_name} ${client.last_name} (${client.email})` : '';
  }

  validEmail(control: AbstractControl) {
    const client = control.get('client').value;
    if (!client) {
      return;
    }
    const email = client.email;
    const verifyEmail = control.get('verifyEmail').value;

    if (email !== verifyEmail) {
      control.get('verifyEmail').setErrors({MatchEmail: true});
    } else {
      control.get('verifyEmail').setErrors(null);
    }
  }

  sendReport() {
    const client = this.emailForm.controls['client'].value;

    const input: PdfClientInboxInput = {
      client_id: client.id,
      message: this.emailForm.controls['message'].value.trim(),
      report_ids: this.sendables.map(s => s.id)
    };

    this.sending = true;

    this.clientInboxService.sendClientPdf(input).pipe(
      catchError(err => {
        this.notificationService.showErrorNotification('An error occurred while sending the client email');
        this.sending = false;
        return err;
      })
    ).subscribe(() => {
      let message = `${this.sendables.length} report(s) sent to ${client.first_name} ${client.last_name} at ${client.email}`;

      this.notificationService.showSuccessNotification(message);
      this.sending = false;
      this.dialogRef.close(true);
    });
  }

  sendInvoice() {
    const client = this.emailForm.controls['client'].value;

    const input: InvoiceClientInboxInput = {
      client_id: client.id,
      message: this.emailForm.controls['message'].value.trim(),
      invoice_ids: this.sendables.map(s => s.id)
    };

    this.sending = true;
    this.clientInboxService.sendClientInvoice(input).pipe(
      catchError(err => {
        this.notificationService.showErrorNotification('An error occurred while sending the client email');
        this.sending = false;
        return err;
      })
    ).subscribe(() => {
      let message = `${this.sendables.length} invoice(s) sent to ${client.first_name} ${client.last_name} at ${client.email}`;

      this.notificationService.showSuccessNotification(message);
      this.sending = false;
      this.dialogRef.close(true);
    });
  }

  getGroupName = (sendable) => {
    if (sendable.billing_group_serialized) {
      if (sendable.billing_group_serialized[0]) {
        return sendable.billing_group_serialized[0].name;
      } else {
        return '';
      }
    }
  }
}
