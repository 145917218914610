import { Component, OnInit } from '@angular/core';
import {Job} from '../../shared/type_classes/job';
import {ActivatedRoute} from '@angular/router';
import _reverse from 'lodash-es/reverse';
import _sortBy from 'lodash-es/sortBy';
import {JobsService} from '../../shared/services/jobs.service';
import {BRAND_COLORS} from '../../app-constants';
import {ColorsService} from '../../shared/services/colors.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  isHelpWindowVisible = false;
  firmBrandColors = [];

  constructor(private route: ActivatedRoute,
              private jobsService: JobsService,
              private colors: ColorsService) { }

  ngOnInit() {
    this.firmBrandColors = this.colors.brandColors;

    this.colors.firmUpdated
      .subscribe(firm => {
        if (!firm.brand_colors.length) {
          this.firmBrandColors = BRAND_COLORS;
        } else {
          this.firmBrandColors = firm.brand_colors;
        }
      });
  }

  get jobs() {
    return _reverse(_sortBy(this.jobsService.getJobs(), 'moment_created_at_utc'));
  }
  getCardColor(index: number) {
    if (this.firmBrandColors.length && this.firmBrandColors[index]) {
      return this.firmBrandColors[index];
    }
  }


  refreshJobs() {
    this.jobsService.fetchJobs();
  }

  nJobs(): number {
    return this.jobs.length;
  }

  nRunningJobs() {
    return this.jobs.filter((i) => i.is_running).length;
  }

  clear() {
    this.jobsService.clearNonRunningJobs();
  }

  getProgressBarStyle(job: Job) {
    return {
      width: this.getProgressBarPercentage(job) + '%'
    };
  }

  getProgressBarPercentage(job: Job) {
    if (!job.num_steps || job.num_steps === 0) {
      return 0;
    }

    return Math.round((job.current_step / job.num_steps) * 100);
  }


  getStatus(job: Job) {
    if (job.is_finished) {
      return 'Completed';
    }

    if (job.is_running) {
      return 'Running';
    }

    if (job.is_queued) {
      return 'Queued';
    }

    if (job.is_revoked) {
      return 'Revoked';
    }

    if (job.is_failed) {
      return 'Failed';
    }
  }

}
