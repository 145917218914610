import {Directive, OnInit} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

@Directive({
  selector: '[appHighlightRow]'
})
export class HighlightRowDirective implements OnInit {

  constructor( private host: DxDataGridComponent) { }


  ngOnInit() {

    setTimeout(() => {
      this.host.instance.option('onRowPrepared', (info) => {
        if (info.rowType === 'data' && info.data && info.data.error_message) {
          info.rowElement.classList.add('error');
        }
      });
    }, 100);
  }
}
