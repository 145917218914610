import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'effectiveRateGroupValue'
})
export class EffectiveRateGroupValuePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (0.0 <= input && input < 10 * 1e-4) {
      return '0 - 10bp';
    } else if (10 * 1e-4 <= input && input < 25 * 1e-4) {
      return '10bp - 25bp';
    } else if (25 * 1e-4 <= input && input < 50 * 1e-4) {
      return '25bp - 50bp';
    } else if (50 * 1e-4 <= input && input < 75 * 1e-4) {
      return '50bp - 75bp';
    } else if (75 * 1e-4 <= input && input < 1e-2) {
      return '75bp - 1%';
    } else if (1e-2 <= input && input < 1.5 * 1e-2) {
      return '1 - 1.5%';
    } else if (1.5e-2 <= input && input < 2e-2) {
      return '1.5 - 2%';
    } else if (2e-2 <= input && input < 3e-2) {
      return '2 - 3%';
    } else if (3e-2 <= input && input < 4e-2) {
      return '3 - 4%';
    } else if (4e-2 <= input && input < 5e-2) {
      return '4 - 5%';
    } else if (5e-2 <= input && input < 10e-2) {
      return '5 - 10%';
    } else if (10e-2 <= input) {
      return '10%+';
    }

    return '';
  }

}
