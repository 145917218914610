import { Injectable } from '@angular/core';
import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import { environment } from '../../../../environments/environment';
import { AuthProfile } from './auth-profile';
import {Observable, of, Subject} from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  cognitoUser: CognitoUser;
  authToken: string;
  authProfile: AuthProfile;

  // subject for the cognito user
  authenticatedSubject = new Subject<boolean>();
  authenticatedObservable = this.authenticatedSubject.asObservable();

  // login error observable
  loginErrorSubject = new Subject<string>();
  loginErrorObservable = this.loginErrorSubject.asObservable();

  // hashkey
  hashkeyCredentials: HashkeyResults;
  hashkeyLoginTimeSeconds: number;

  constructor() {
    this.authToken = '';
  }

  login(username: string, password: string, handleForceChangeCallback): void {
    const authenticationData = {
        Username: username,
        Password: password
    };

    const authDetails = new AuthenticationDetails(authenticationData);

    const authPoolInfo = {
        UserPoolId: environment.userPoolId,
        ClientId: environment.clientId
    };

    const userPool = new CognitoUserPool(authPoolInfo);

    const userData = {
        Username: username,
        Pool: userPool
    };

    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    this.cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          // Get the id token, place it in the auth service
          const token = result.getIdToken().getJwtToken();
          this.authToken = token;
          this.authProfile = JSON.parse(result.getIdToken().payload['bft:user']) as AuthProfile;

          this.authenticatedSubject.next(true);
          this.authenticatedSubject.complete();
        },
        onFailure: (err) => {
          // Display the error on the login screen
          this.loginErrorSubject.next(err.message);
          this.authenticatedSubject.next(false);
        },
        newPasswordRequired: handleForceChangeCallback
    });
  }

  setNewPassword(newPassword: string, onResetSuccess, onError) {
    this.cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: (session: any) => {
        if (session.isValid()) {
          this.setupCognito(session);

          // No need to handle the error at the moment. If there is an error, that means there is no s3 access.
          // That is handled in other services.
          this.authenticatedSubject.next(true);
          this.authenticatedSubject.complete();
          onResetSuccess();
        }
      },
      onFailure: onError

    });
  }

  // Constructs user from a username
  getCognitoUser(username: string) {
    const authPoolInfo = {
      UserPoolId: environment.userPoolId,
      ClientId: environment.clientId
    };

    const userPool = new CognitoUserPool(authPoolInfo);

    const userData = {
      Username: username,
      Pool: userPool
    };

    // save this user in the service to be used in reset/enter password flows
    this.cognitoUser = new CognitoUser(userData);

    return this.cognitoUser;
  }

  getCurrentUser(): CognitoUser {
    const poolInfo = {
        UserPoolId: environment.userPoolId,
        ClientId: environment.clientId
    };

    const userPool = new CognitoUserPool(poolInfo);

    return userPool.getCurrentUser();
  }

  refreshToken(): Observable<string> {
    return new Observable<string>((observer) => {
      this.getCurrentUser().getSession((err, session) => {
        if (err) {
          observer.next(null);
          observer.complete();
          return;
        }

        if (session.isValid()) {
          this.setupCognito(session);

          observer.next(this.authToken);
          observer.complete();
        }
      });
    });
  }

  setupCognito(session) {
    this.authToken = session.idToken.getJwtToken();

    this.authProfile = JSON.parse(session.idToken.payload['bft:user']) as AuthProfile;
  }

  isAuthenticated(): Observable<boolean> {
    if (!this.getCurrentUser()) {
      return of(false);
    }

    return new Observable((observer) => {
      this.getCurrentUser().getSession((err, session) => {
        if (err) {
          observer.next(false);
          this.authenticatedSubject.next(false);
          observer.complete();
          return;
        }

        if (session.isValid()) {
          this.setupCognito(session);

          this.authenticatedSubject.next(true);
          observer.next(true);
          observer.complete();
        } else {
          observer.next(false);
          this.authenticatedSubject.next(false);
        }
      });
    });
  }

  requestResetPassword(cognitoUser: CognitoUser, inputVerificationCode, onError) {
    // onError and inputVerificationCode are callbacks called from the ResetPasswordComponent
    cognitoUser.forgotPassword({
      onSuccess: () => {},
      onFailure: onError,
      inputVerificationCode
    });
  }

  confirmResetPassword(verificationCode: string, password: string, onResetSuccess, onError) {
    // onResetSuccess and onError are callbacks from EnterPasswordComponent
    this.cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: onResetSuccess,
      onFailure: onError
    });
  }

  logout(): void {
    this.getCurrentUser().signOut();
  }

  setHashkeyCredentials(hashkeyResults: HashkeyResults) {
    this.hashkeyCredentials = hashkeyResults;
    this.authToken = hashkeyResults.IdToken;

    // Keep track of the login time
    this.hashkeyLoginTimeSeconds = Date.now() / 1000;

    // decode for authProfile
    const decodedToken = this.decodeHashkeyToken(hashkeyResults.IdToken);
    this.authProfile = JSON.parse(decodedToken['bft:user']) as AuthProfile;
    this.authenticatedSubject.next(true);
    this.authenticatedSubject.complete();
  }

  // on hashkey login, we need to decode the token to get the payload for authprofile
  decodeHashkeyToken(token: string) {
    return jwt_decode(token);
  }

  isHashkeySessionValid() {
    const expiredTime = this.hashkeyLoginTimeSeconds + this.hashkeyCredentials.ExpiresIn;
    const now = Date.now() / 1000;

    const isValid = (now < expiredTime);

    return isValid;
  }
}

export interface HashkeyResults {
  ExpiresIn: number;
  TokenType: string;
  IdToken: string;
  RefreshToken: string;
}
