import { Pipe, PipeTransform } from '@angular/core';
import {Job} from '../type_classes/job';

@Pipe({
  name: 'jobStatus'
})
export class JobStatusPipe implements PipeTransform {

  transform(job: Job, args?: any): any {
    if (job.is_finished) {
      return 'Completed';
    }

    if (job.is_running) {
      return 'Running';
    }

    if (job.is_queued) {
      return 'Queued';
    }

    if (job.is_revoked) {
      return 'Revoked';
    }

    if (job.is_failed) {
      return 'Failed';
    }
  }

}
