export const dataGridOptions = {
  options: {
    height: 'auto',
    width: 'auto',
    allowColumnResizing: true,
    allowColumnReordering: true,
    columnAutoWidth: true,
    showBorders: false,
    columnResizingMode: 'widget',
    scrolling: {
      mode: 'none'
    },
    loadPanel: {
      enabled: true
    },
    sorting: {
      mode: 'single'
    },
    groupPanel: {
      visible: false
    },
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: false
    },
    selection: {
      mode: 'multiple',
      selectAllMode: 'allPages',
      showCheckBoxesMode: 'always',
      allowSelectAll: true
    },
    columnChooser: {
      mode: 'select',
      height: 500,
      title: 'Visible columns'
    },
    paging: {
      enabled: false
    },
    pager: {
      enabled: false
    },
    scroll: {
      mode: 'virtual'
    },
    stateStoring: {
      type: 'custom',
      customLoad() {
        // @ts-ignore
        const state = JSON.parse(localStorage.getItem(this.storageKey));
        if (state) {
          for (var i = 0; i < state.columns.length; i++) {
            state.columns[i].filterValue = null;
          }
          state.searchText = '';
          state.filterValue = null;
          state.selectedRowKeys = [];
        }

        return state;
      },
      customSave(state) {
        // set 'filterValue' and 'filterValues' properties as null
        const filteredColumnsState = state.columns.map(c => {
          return {...c, filterValue: null, filterValues: null};
        });
        // @ts-ignore
        localStorage.setItem(this.storageKey, JSON.stringify({...state, columns: filteredColumnsState}));
      }
    },
  },
};

export const popupOptions = {
  options: {
    shading: true,
    shadingColor: 'rgba(0, 0, 0, 0.5)',
    width: 600,
    height: 'auto',
    closeOnOutsideClick: true,
  }
};

export const popoverOptions = {
  options: {
    shadingColor: 'rgba(255, 0, 0, 0.5)',
  }
};

// export const devExtremePalette = {
//   simpleSet: [
//     '#aba9ff',
//     '#5A2ED1',
//     '#FF2C79',
//     '#FFBF48',
//     '#00BC97',
//     '#0084FF',
//     '#333333'
//   ]
// };

