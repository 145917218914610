import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {WealthboxService} from '../wealthbox.service';

@Injectable({
  providedIn: 'root'
})
export class WealthboxToolbarService {

  constructor(
    private wealthboxService: WealthboxService
  ) { }

  getContactsDxListDataSource() {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (props) => {
          const pageObj = {
            perPage: props.take,
            page: Math.trunc((props.take + props.skip) / props.take),
            name: props.searchValue || '',
          };

          return this.wealthboxService.getContacts(pageObj)
            .then((data: any) => data.contacts);
        }
      })
    });
  }

  getNotesDxListDataSource() {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (props) => {
          const pageObj =   {
            perPage: props.take,
            page: Math.trunc((props.take + props.skip) / props.take),
            name: props.searchValue || '',
          };

          return this.wealthboxService.getNotes(pageObj).toPromise()
            .then((data: any) => data.status_updates);
        },
        cacheRawData: false,
        insert: (values) => {
          return this.wealthboxService.createNote(values).toPromise().then((resp) => {
            return resp;
          });
        },
      }),
    });
  }

  getActivityDataSource(id?: number) {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (props) => {
          const pageObj = {
            perPage: props.take,
            page: Math.trunc((props.take + props.skip) / props.take),
            contact: id ? id : ''
          };

          return this.wealthboxService.getActivityStream(pageObj)
            .then((data: any) => data.stream_items);
        },
        cacheRawData: false,
      })
    });
  }

}
