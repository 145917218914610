import {createFeatureSelector, createSelector} from '@ngrx/store';
import {WealthboxContact} from '../../settings/integrations/wealthbox/type-classes/wealthbox-contact';

export type SidePanelSection = 'ATLAS_ACTIVITY' | 'WEALTHBOX';

export interface SidePanelState {
  opened: boolean;
  section: SidePanelSection;
  tab: string;
  mode: string;
  data?: {
    contact: number | WealthboxContact
  };
}

export interface LayoutState {
  sidePanel: SidePanelState;
}

const getLayoutState = createFeatureSelector<LayoutState>('layout');

export const getSidePanelState = createSelector(getLayoutState, state => state.sidePanel);
export const isSidePanelOpen = createSelector(getSidePanelState, state => state.opened);
export const getCurrentSection = createSelector(getSidePanelState, state => state.section);
export const getCurrentTab = createSelector(getSidePanelState, state => state.tab);
const getCurrentData = createSelector(getSidePanelState, state => state.data);
export const getCurrentContact = createSelector(getCurrentData, state => state && state.contact);
export const getCurrentMode = createSelector(getSidePanelState, state => state.mode);
