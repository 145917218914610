import {Injectable} from '@angular/core';
import {AppConstants} from '../../../../app-constants';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromFeeStructure from '../state';
import _keyBy from 'lodash-es/keyBy';
import {CALCULATION_TYPES, COLLECTION_TYPES} from '../../../../billing/billing.constants';
import {FeeStructuresService} from '../fee-structures.service';
import {AccountService} from '../../../../accounts/account.service';
import {DisplayCustodianPipe} from '../../../../shared/pipes/display-custodian.pipe';

@Injectable({
  providedIn: 'root'
})
export class FeeStructureAssignmentBulkUploadService {

  downloadFileType = AppConstants.FILE_TYPE.EXCEL;
  dataToExport: any[];

  account$: Subscription;
  feeStructure$: Subscription;

  feeStructureByID;
  accountByNumber;

  invalidAccounts = [];
  validationLoading = true;

  constructor(private feeStructuresService: FeeStructuresService,
              private accountsService: AccountService,
              private custodianPipe: DisplayCustodianPipe,
              private store: Store<fromFeeStructure.State>) {}

  getFeeStructureTemplate() {
    const feeStructureToExport = []

    this.feeStructure$ = this.store.pipe(select(fromFeeStructure.getFeeStructures)).subscribe(
      feeStructures => {
        this.feeStructureByID = _keyBy(feeStructures, 'id');

        feeStructures.forEach(f => {

          feeStructureToExport.push({
            Id: f.id,
            Name: f.name,
            Structure: COLLECTION_TYPES.find(s => s.id === f.collection_type).name,
            'Calculation Type': CALCULATION_TYPES.find(c => c.id === f.calculation_type).name
          });
        });
      }
    );

    return feeStructureToExport;
  }

  getAccountFeeStructureAssignmentTemplate() {
      const accountFeeAssignmentToExport = []

      this.account$ = this.accountsService.accounts$.subscribe(
      accounts => {

        this.accountByNumber = _keyBy(accounts, 'number');

        accounts.forEach(a => {

            accountFeeAssignmentToExport.push({
              Number: a.number,
              Name: a.name,
              Custodian: a.custodian ? this.custodianPipe.transform(a.custodian) : '',
              'Fee Structure ID': a.fee_structures_ids.length > 0 ? a.fee_structures_ids.toString() : null,
              'Fee Structure Name': a.fee_structures_ids.length > 0 ? this.getFeeStructureNames(a.fee_structures_ids) : null,
            });
        })
      });

      return accountFeeAssignmentToExport;
  }

  // the goal of this validation is to check if the uploaded template file has correct account numbers,
  // custodian, and fee structure ids. To check if the account numbers are correct, each account number form the file is
  // checked with a key-value pair (accountByNumber) obtained from the accountService. Similar check is done for the fee
  // structure ids.
  // Any accounts with incorrect account number, or custodian or fee structure ids will be added to a list `invalidAccounts`
  // to show on the dialog box so that the client know which accounts are faulty.
  validationCheck(uploadedDataStream): boolean {
    this.invalidAccounts= [];
    uploadedDataStream.forEach(assignment => {

      if (this.accountByNumber[assignment.Number]) {

        const custodianFromMap = this.accountByNumber[assignment.Number].custodian;
        const custodian = custodianFromMap ? this.custodianPipe.transform(custodianFromMap) : '';

        if (assignment.Custodian !== custodian) {
          this.invalidAccounts.push(`${assignment.Name} : ${assignment.Number}`);
        }

        const feeStructureIdString = assignment['Fee Structure ID'];

        if (!feeStructureIdString || feeStructureIdString === '') {
          return;
        }

        const feeStructureIds = this.createStringToNumericList(feeStructureIdString.toString());

        feeStructureIds.forEach(fId => {
          if (!this.feeStructureByID[fId]) {
            this.invalidAccounts.push(`${assignment.Name} : ${assignment.Number}`);
          }
        });
      } else {
        this.invalidAccounts.push(`${assignment.Name} : ${assignment.Number}`);
      }
    });

    return false;
  }

  isAssignmentValid (assignment): boolean {
    const feeStructureIdString = assignment['Fee Structure ID'];

    if (!feeStructureIdString || feeStructureIdString === '') {
      return false;
    }

    if (this.invalidAccounts.includes(assignment.Name)) {
      return false;
    }

    if (!this.accountByNumber[assignment.Number]) {
      return false;
    }

    if (this.accountByNumber[assignment.Number].fee_structures_ids.toString() === feeStructureIdString) {
      return false;
    }

    return true;
  }

  createStringToNumericList(fIdString) {
    return fIdString.split(',')
      .filter(element => {
        if (element.trim() === '') {
          return false;
        }

        return !isNaN(element);
      })
      .map(element => {
        return Number(element);
      });
  }

  getFeeStructureNames(fIds) {
    const feeStructureNames = []
    fIds.forEach(fId => {
      if (this.feeStructureByID[fId]) {
        feeStructureNames.push(this.feeStructureByID[fId].name);
      }
    });

    return feeStructureNames.toString();
  }
}
