import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'balanceGroupValue'
})
export class BalanceGroupValuePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (0.0 <= input && input < 1e3) {
      return '< $1K';
    } else if (1e3 <= input && input < 1e4) {
      return '$1K - $10K';
    } else if (1e4 <= input && input < 5e4) {
      return '$10K - $50K';
    } else if (5e4 <= input && input < 1e5) {
      return '$50K - $100K';
    } else if (100e3 <= input && input < 250e3) {
      return '$100K - $250K';
    } else if (250e3 <= input && input < 500e3) {
      return '$250K - $500K';
    } else if (500e3 <= input && input < 750e3) {
      return '$500K - $750K';
    } else if (750e3 <= input && input < 1e6) {
      return '$750K - $1M';
    } else if (1e6 <= input && input < 3e6) {
      return '$1M - $3M';
    } else if (3e6 <= input && input < 5e6) {
      return '$3M - $5M';
    } else if (5e6 <= input && input < 10e6) {
      return '$5M - $10M';
    } else if (10e6 <= input && input < 20e6) {
      return '$10M - $20M';
    } else if (20e6 <= input && input < 50e6) {
      return '$20M - $50M';
    } else if (75e6 <= input && input < 100e6) {
      return '$75M - $100M';
    } else if (input >= 100e6) {
      return '$100M+';
    }

    return '';
  }

}
