import 'reflect-metadata';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {environment} from './environments/environment';
import {enableProdMode} from '@angular/core';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.env,
  integrations: [
    new BrowserTracing({
      beforeNavigate: context => {
        return {
          ...context,
          name: location.pathname
            .replace(/\/[a-f0-9]{128}/g, '/<hash>')
            .replace(/\/\d+/g, '/<digits>'),
        };
      },
      tracingOrigins: [environment.apiV2Url],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

if (environment.production) {
  enableProdMode();
}
