<mat-stepper [linear]="false" #stepper>
  <mat-step>
    <ng-template matStepContent>
      <div style="display: flex; justify-content: center; flex-direction: column">
          <h2 mat-dialog-title>
            Upload Fee Structure
            <div #toolTipTemplate>
              <ol>
                <li>Download the Fee Structure template.</li>
                <br>
                <li>Populate the template in a format suggested by the sample rows.</li>
                <br>
                <li>It is fine to either leave the two sample rows as they are or remove them.</li>
                <br>
                <li>Once the Fee Structure Template is populated. Please upload it to the upload section.</li>
                <br>
                <h3> Note: This bulk upload does not support Tiers or Drop Through </h3>
              </ol>
            </div>

            <i class="far fa-info-circle info-symbol"
               [ngxTippy]="toolTipTemplate"
               tippyName="aumTooltip"></i>
          </h2>
        <br>
        <app-atlas-file-upload [dataToExportToExcel]="dataToExport"
                               [downloadFileType]= "downloadFileType"
                               (dataStream)="onFeeStructureUpload($event)">

        </app-atlas-file-upload>
      </div>
    </ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
      <div class="dialog-content" *ngIf="loading">
        <app-material-spinner></app-material-spinner>
      </div>
      <div *ngIf="!loading">
          <dx-data-grid #dataGrid
                        [dataSource]="feeStructureData"
                        [showBorders]="true"
                        [repaintChangesOnly]="true"
                        (onCellPrepared)="onCellPrepared($event)"
                        (onEditorPreparing) = "onEditorPrepared($event)"
                        (onContentReady) = "onContentReady($event)"
                        (onSaving)="onSaving($event)"
                        style="width: 90%; max-height: calc(100vh - 460px);">
            <dxo-editing
              mode="batch"
              [allowAdding]="true"
              [allowUpdating]="true"
              [allowDeleting]="true"
              [selectTextOnEditStart]="selectTextOnEditStart"
              [startEditAction]="startEditAction">
            </dxo-editing>

            <dxi-column
              caption="Name"
              dataType="string"
              dataField="name">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
            </dxi-column>
            <dxi-column
              caption="Collection Type"
              dataField="collection_type">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
              <dxo-lookup [dataSource]="collectionTypes" displayExpr="name" valueExpr="id">
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              caption="Structure"
              dataType="string"
              dataField="structure">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
              <dxo-lookup [dataSource]="structures" displayExpr="name" valueExpr="id">
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              caption="Valuation Type"
              dataType="string"
              dataField="valuation_type">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
              <dxo-lookup [dataSource]="valuationTypes" displayExpr="name" valueExpr="id">
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              caption="Billing Frequency"
              dataType="string"
              dataField="billing_frequency"
              [setCellValue]="setBillingFrequency">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
              <dxo-lookup [dataSource]="billingFrequencies" displayExpr="name" valueExpr="id">
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              caption="Cycle"
              dataType="string"
              dataField="cycle">
              <dxi-validation-rule type="required"> </dxi-validation-rule>
              <dxo-lookup [dataSource]="getFilteredCycles" displayExpr="name" valueExpr="id">
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              caption="Flat Rate"
              dataType="number"
              dataField="flat_rate">
            </dxi-column>
            <dxi-column
              caption="Fee Amount"
              dataType="number"
              dataField="fee_amount">
            </dxi-column>
            <dxo-toolbar>
              <dxi-item name="addRowButton" showText="always"></dxi-item>
              <dxi-item name="revertButton" showText="always"></dxi-item>
              <dxi-item name="saveButton" showText="always" [options]="saveButtonOptions"></dxi-item>
            </dxo-toolbar>

            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-selection mode="none"></dxo-selection>
          </dx-data-grid>

        <br>
        <div style="display:flex; flex-direction: row; align-items:center">
          <button mat-stroked-button
                  matStepperPrevious
                  style="justify-content:center"
                  color="primary">
            Back
          </button>
          <div class="actions-container">
            <button mat-button
                    color="primary"
                    [mat-dialog-close]="false">
              Cancel
            </button>

            <app-atlas-submit-button matTooltip="Save before Submitting"
                                     [disabled]="!saved"
                                     [onClick]="onSubmit">
              Submit
            </app-atlas-submit-button>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-step>
</mat-stepper>

