import { Injectable } from '@angular/core';
import {WealthboxContact} from '../../settings/integrations/wealthbox/type-classes/wealthbox-contact';
import {WealthboxSettingsService} from '../../settings/integrations/wealthbox/wealthbox-settings/wealthbox-settings.service';
import {WealthboxService} from '../../settings/integrations/wealthbox/wealthbox.service';
import {select, Store} from '@ngrx/store';
import {getCurrentSection, getCurrentTab, getCurrentContact, isSidePanelOpen, SidePanelState, getCurrentMode} from '../state';
import {changeSidePanelState, closeSidePanel, toggleSidePanel} from '../state/layout.actions';

@Injectable({
  providedIn: 'root'
})
export class SidePanelService {

  constructor(
    private wealthboxSettings: WealthboxSettingsService,
    private wealthboxService: WealthboxService,
    private store: Store<SidePanelState>,
    ) {}

  opened$ = this.store.pipe(select(isSidePanelOpen));
  currentSection$ = this.store.pipe(select(getCurrentSection));
  currentTab$ = this.store.pipe(select(getCurrentTab));
  currentContact$ = this.store.pipe(select(getCurrentContact));
  currentMode$ = this.store.pipe(select(getCurrentMode));

  view: 'tabs' | 'contactDetails' = 'tabs';
  activeTab: 'activity' | 'notes' | 'contacts' = 'notes';

  togglePanel(section) {
    this.store.dispatch(toggleSidePanel({section}));
  }

  closePanel() {
    this.store.dispatch(closeSidePanel());
  }

  openWealthboxContact(contact: number | WealthboxContact) {
    const state: SidePanelState = {
      opened: true,
      section: 'WEALTHBOX',
      tab: 'contactDetails',
      mode: 'full',
      data: {
        contact
      }
    };

    this.store.dispatch(changeSidePanelState({sidePanel: state}));
  }

  openTab(section, tab) {
    const state: SidePanelState = {
      opened: true,
      section,
      tab,
      mode: 'tabs'
    };

    this.store.dispatch(changeSidePanelState({sidePanel: state}));
  }
}
