import {createAction, props} from '@ngrx/store';
import {FeeStructure} from '../../../../billing/billing';

export const Load = createAction('[FeeStructures] Load fee structures');
export const LoadSuccess = createAction('[FeeStructures] Load fee structures success', props<{feeStructures: FeeStructure[]}>());
export const LoadFail = createAction('[FeeStructures] Load fee structures fail', props<{error: string}>());
export const UpdateFeeStructure = createAction('[FeeStructures] update fee structure', props<{feeStructure: FeeStructure}>());
export const UpdateFeeStructureSuccess = createAction(
  '[FeeStructures] update fee structure Success',
  props<{feeStructure: FeeStructure}>());
export const UpdateFeeStructureFail = createAction('[FeeStructures] update fee structure Fail', props<{error: string}>());

export const CreateFeeStructures = createAction('[FeeStructures] create fee structures', props<{feeStructures: FeeStructure[]}>());
export const CreateFeeStructuresSuccess = createAction(
  '[FeeStructures] create fee structures Success',
  props<{feeStructures: FeeStructure[]}>());
export const CreateFeeStructuresFail = createAction('[FeeStructures] create fee structures Fail', props<{error: string}>());

export const RemoveFeeStructure = createAction('[FeeStructures] remove fee structure', props<{feeStructure: FeeStructure}>());
export const RemoveFeeStructureSuccess = createAction(
  '[FeeStructures] remove fee structure Success',
  props<{feeStructure: FeeStructure}>());
export const RemoveFeeStructureFail = createAction('[FeeStructures] remove fee structure Fail', props<{error: string}>());
export const RemoveMultipleFeeStructure = createAction(
  '[FeeStructures] remove multiple fee structure', props<{feeStructures: FeeStructure[]}>());
export const RemoveMultipleFeeStructureSuccess = createAction(
  '[FeeStructures] remove multiple fee structure Success',
  props<{feeStructures: FeeStructure[]}>());
export const RemoveMultipleFeeStructureFail = createAction('[FeeStructures] remove multiple fee structure Fail', props<{error: string}>());
