<div class="content-wrap" style="padding-top: 0;">
  <mat-card>
    <div class="header-container">
      <div class="title-container">
        <mat-card-title>
          Setup Mapping
        </mat-card-title>
      </div>

      <div>
        <app-atlas-submit-button (click)="integrate()"
                                 [disabled]="householdControl.invalid || contactControl.invalid"
                                 [saving]="loadingAddContact">
          Integrate
        </app-atlas-submit-button>
      </div>
    </div>


    <mat-card-content>
      <div style="padding: 20px;">
        (Optional) You have the ability to map your Atlas Households to your contacts in wealthbox.
        This functionality allows you to automatically see contact details when viewing a Household's detail view.
      </div>

      <div style="padding: 20px;">
        <form [formGroup]="wealthboxForm">
          <div fxLayout="row"
               fxLayout.lt-sm="column"
               gdGap="20px"
               gdColumns.gt-sm="calc(50% - 10px) calc(50% - 10px)">
            <div>
              <label class="detail-label">Atlas Household</label>
              <mat-form-field appearance="outline" style="width: 100%;">
                <input type="text"
                       matInput
                       required
                       placeholder="Search for a Household"
                       formControlName="household"
                       [matAutocomplete]="households">
                <mat-autocomplete #households="matAutocomplete" [displayWith]="householdDisplay">
                  <mat-option *ngFor="let household of filteredHouseholds$ | async | slice:0:40" [value]="household">
                    {{ household.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error class="error-msg" *ngIf="wealthboxForm.controls.household.invalid">Household is required.</mat-error>
              </mat-form-field>
            </div>

            <div>
              <label class="detail-label">Wealthbox Contact</label>
              <mat-form-field appearance="outline" style="width: 100%;">
                <input type="text"
                       matInput
                       required
                       placeholder="Search for a Contact"
                       formControlName="contact"
                       [matAutocomplete]="contacts">
                <mat-autocomplete #contacts="matAutocomplete" [displayWith]="contactDisplay">
                  <mat-option *ngFor="let contact of filteredWealthboxContacts$ | async | slice:0:40" [value]="contact">
                    {{ contact.name }}
                  </mat-option>
                </mat-autocomplete>

                <mat-error class="error-msg" *ngIf="wealthboxForm.controls.contact.invalid">Contact is required.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>


<div class="content-wrap" style="padding-top: 0">
  <mat-card>
    <div class="header-container">
      <div class="title-container">
        <mat-card-title>Integrated Contacts</mat-card-title>
      </div>

      <div>
<!--        <app-atlas-submit-button (click)="removeRelations()"-->
<!--                                 [saving]="loadingRemoveContact">-->
<!--&lt;!&ndash;          <i *ngIf="!loadingRemoveContact" class="fa fa-trash"></i>&ndash;&gt;-->
<!--          <i *ngIf="!loadingRemoveContact" class="fas fa-trash prefix-icon"></i>-->
<!--          Delete-->
<!--        </app-atlas-submit-button>-->

        <button class="btn delete-btn" (click)="removeRelations()">
          <i *ngIf="!loadingRemoveContact" class="fas fa-trash prefix-icon"></i>
          Delete
        </button>
      </div>
    </div>

    <mat-card-content style="padding: 20px;">
      <dx-data-grid #dataGrid
                    [cacheEnabled]="true"
                    style="max-height: 400px;"
                    [dataSource]="relationsDataSource"
                    [(selectedRowKeys)]="selectedIntegrations"
                    [remoteOperations]="true">
        <dxi-column
          caption="Household Name"
          [allowSorting]="false"
          dataType="string"
          dataField="household.name">
        </dxi-column>
        <dxi-column
          caption="Wealthbox Contact Name"
          [allowSorting]="false"
          dataType="string"
          dataField="wealthboxContact.name">
        </dxi-column>
        <dxi-column
          dataType="string"
          cellTemplate="actionsTemplate">
        </dxi-column>

        <div *dxTemplate="let row of 'actionsTemplate'">
          <a mat-icon-button
             color="primary"
             (click)="removeRelation(row)">
            <i class="fas fa-trash prefix-icon"></i>
          </a>
        </div>

        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-header-filter [visible]="false"></dxo-header-filter>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
      </dx-data-grid>
    </mat-card-content>
  </mat-card>
</div>
