import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {UnsubscribeService} from '../unsubscribe.service';
import {AppLoadingService} from '../../layout/app-loading.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private unsubscribeService: UnsubscribeService,
              private appLoadingService: AppLoadingService) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        filter(params => {
          if (!params.token) {
            this.router.navigateByUrl('/dashboard');
          }
          return params.token;
        }),
        switchMap((params) => {
          return this.unsubscribeService.unsubscribe(params.token);
        }),
        catchError(err => this.router.navigateByUrl('/dashboard'))
      )
      .subscribe((resp) => {
        this.appLoadingService.setAppLoading(false);
      });
  }

}
