import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './fee-structure.reducer';
import {EffectsModule} from '@ngrx/effects';
import {FeeStructureEffects} from './fee-structure.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('feeStructures', reducer),
    EffectsModule.forFeature(
      [ FeeStructureEffects ]
    ),
  ]
})
export class FeeStructureStoreModule { }
