import {Inject, Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {AccountService} from './accounts/account.service';
import {BenchmarkService} from './benchmark/benchmark.service';
import {BillingGroupsService} from './billing/billing-groups.service';
import {FirmService} from './firm/firm.service';
import {AnalyticsService} from './analytics/analytics.service';
import {CurrentBalanceService} from './balances/current-balance.service';
import {SettingsService} from './shared/services/settings.service';
import {ProfileService} from './shared/services/profile.service';
import {RoleService} from './roles/role.service';
import {JobsService} from './shared/services/jobs.service';
import {DownloadPrintableReportsService} from './shared/services/download-printable-reports.service';
import {Observable} from 'rxjs';
import {Household} from './households/household';
import {Account} from './accounts/account';
import {SecuritiesService} from './securities/securities.service';
import {InvoiceService} from './billing/invoices/invoice.service';
import {Invoice} from './billing/invoices/invoice';
import {BillingReportService} from './billing/billing-report.service';
import {filter, first, switchMap, take, tap} from 'rxjs/operators';
import {Security} from './securities/security';
import {State, Store} from '@ngrx/store';

import {Load as LoadHouseholds} from './accounts/state/account.actions';
import {Load as LoadAccounts} from './households/state/household.actions';
import {CurrentBalance} from './balances/current-balance';

@Injectable()
export class HouseholdsResolver implements Resolve<any> {
  constructor(private accountService: AccountService,
              private store: Store<State<any>>) {}
  resolve(): Observable<Household[]> {
    return this.accountService.households$.pipe(
      tap(hhs => {
        if (!hhs.length) {
          this.store.dispatch(LoadHouseholds());
        }
      }),
      filter(hhs => {
        return !!hhs.length;
      }),
      first()
    );
  }
}

@Injectable()
export class AccountsResolver implements Resolve<any> {
  constructor(private accountService: AccountService,
              private store: Store<State<any>>) {}
  resolve(): Observable<Account[]> {
    return this.accountService.accounts$.pipe(
      tap(accounts => {
        if (!accounts.length) {
          this.store.dispatch(LoadAccounts());
        }
      }),
      filter(accounts => {
        return !!accounts.length;
      }),
      first()
    );
  }
}

@Injectable()
export class BenchmarksResolver implements Resolve<any> {
  constructor(private benchmarkService: BenchmarkService) {}
  resolve(): Promise<any> {
    return this.benchmarkService.getBenchmarks();
  }
}

@Injectable()
export class BillingGroupsResolver implements Resolve<any> {
  constructor(private billingGroupsService: BillingGroupsService) {}
  resolve(): Promise<any> {
    return this.billingGroupsService.getAccountGroups();
  }
}

@Injectable()
export class FirmResolver implements Resolve<any> {
  constructor(private firmService: FirmService) {}
  resolve(): Promise<any> {
    return this.firmService.fetchFirms();
  }
}

@Injectable()
export class AccountsHouseholdsResolver implements Resolve<any> {
  constructor(private accountService: AccountService) {}
  resolve(): Observable<Account[] | Household[]> {
    return this.accountService.initialize();
  }
}

@Injectable()
export class CurrentBalanceResolver implements Resolve<any> {
  constructor(private currentBalanceService: CurrentBalanceService) {}
  resolve(): Observable<CurrentBalance[]> {
    return this.currentBalanceService.balances$.pipe(
      take(1)
    );
  }
}

@Injectable()
export class SettingsResolver implements Resolve<any> {
  constructor(private settingsService: SettingsService) {}
  resolve(): Promise<any> {
    return this.settingsService.getSubReportDisplaySettings();
  }
}

@Injectable()
export class USDResolver implements Resolve<any> {
  constructor(private securitiesService: SecuritiesService) {}
  resolve(): Promise<any> {
    return this.securitiesService.getUSD();
  }
}

@Injectable()
export class CashResolver implements Resolve<any> {
  constructor(private securitiesService: SecuritiesService) {}
  resolve(): Promise<any> {
    return this.securitiesService.getCash();
  }
}

@Injectable()
export class ProfilesResolver implements Resolve<any> {
  constructor(private profileService: ProfileService) {}
  resolve(): Promise<any> {
    return this.profileService.getProfiles();
  }
}

@Injectable()
export class RolesResolver implements Resolve<any> {
  constructor(private roleService: RoleService) {}
  resolve(): Promise<any> {
    return this.roleService.getRoles();
  }
}

@Injectable()
export class JobsResolver implements Resolve<any> {
  constructor(private jobsService: JobsService) {}
  resolve(): Promise<any> {
    return this.jobsService.fetchJobs();
  }
}

@Injectable()
export class ReportsResolver implements Resolve<any> {
  constructor(private reportService: DownloadPrintableReportsService) {}
  resolve(): Promise<any> {
    return this.reportService.getReports();
  }
}

@Injectable()
export class LatestInvoicesResolver implements Resolve<Invoice[]> {
  constructor(private invoiceService: InvoiceService,
              private billingReportService: BillingReportService) {}

  resolve(): Observable<Invoice[]> {
    return this.invoiceService.getMostRecentInvoice().pipe(
      switchMap(invoice => {
        return this.invoiceService.getInvoicesFromBillingReport(invoice.billing_report_id);
      })
    );
  }
}

@Injectable()
export class SecuritiesResolver implements Resolve<Security[]> {
  constructor(private securitiesService: SecuritiesService) {}

  resolve(): Promise<Security[]> {
    return this.securitiesService.getSecuritiesPromise();
  }
}

export const appResolve = {
  households: HouseholdsResolver,
  accounts: AccountsResolver,
  benchmarks: BenchmarksResolver,
  accountGroups: BillingGroupsResolver,
  firms: FirmResolver
};
