import {Component, OnInit, ViewChild} from '@angular/core';
import {
  BALANCE_TYPES, BILLING_FREQUENCY_WITH_CYCLE,
  CALCULATION_TYPES,
  COLLECTION_TYPES,
  FREQUENCYS
} from '../../../../billing/billing.constants';
import {MatDialog} from '@angular/material/dialog';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {NotificationService} from '../../../../shared/services/ui/notification.service';
import {AppConstants} from '../../../../app-constants';
import {FeeStructureBulkUploadService} from './fee-structure-bulk-upload.service';
import {Store} from '@ngrx/store';
import * as fromFeeStructure from '../state';
import {CreateFeeStructures, CreateFeeStructuresFail, CreateFeeStructuresSuccess} from '../state/fee-structure.actions';
import {FeeStructure} from '../../../../billing/billing';
import {Actions, ofType} from '@ngrx/effects';

@Component({
  selector: 'app-fee-structure-bulk-upload',
  templateUrl: './fee-structure-bulk-upload.component.html',
  styleUrls: ['./fee-structure-bulk-upload.component.scss']
})
export class FeeStructureBulkUploadComponent implements OnInit {
  loading = true;
  saved = false;

  startEditAction = 'click';
  selectTextOnEditStart = true;
  saveButtonOptions: any;

  collectionTypes = COLLECTION_TYPES;
  structures = CALCULATION_TYPES;
  valuationTypes = BALANCE_TYPES;
  billingFrequencies = FREQUENCYS;
  billingFrequencyWithCycle = BILLING_FREQUENCY_WITH_CYCLE;

  uploadedDataStream = [];
  feeStructureData = [];

  downloadFileType = AppConstants.FILE_TYPE.EXCEL;
  dataToExport: any[];

  dispatchFeeStructures: FeeStructure[] = [];


  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('stepper') stepper;

  constructor(
    private notification: NotificationService,
    private dialog: MatDialog,
    private feeStructureBulkUploadService: FeeStructureBulkUploadService,
    private store: Store<fromFeeStructure.State>,
    private actions$: Actions
  ) {
    this.dataToExport = this.feeStructureBulkUploadService.dataToExport;
    this.getFilteredCycles = this.getFilteredCycles.bind(this);
  }

  ngOnInit(): void {}

  onFeeStructureUpload(data) {
    this.stepper.next()

    // enable the save button all the time
    this.saveButtonOptions = {
      onContentReady: (e: any) => {
        e.component.option('disabled', false);
      }
    };

    this.feeStructureData = this.feeStructureBulkUploadService.prepareDataGrid(data);

    this.loading = false;

  }

  onSubmit = () => {
    const dispatchFeeStructures: FeeStructure[] = []

    this.feeStructureData.forEach(f => {

      // @ts-ignore
      const feeStructure: FeeStructure = {
        balance_type: f.valuation_type,
        calculation_type: f.structure,
        collection_type: f.collection_type,
        flat_dollar_fee: this.setFeeAmount(f),
        flat_rate: this.setFlatRate(f),
        frequency: f.billing_frequency,
        name: f.name,
        quarter_cycle: this.findQuarterCycle(f),
        semi_annual_cycle: this.findSemiAnnualCycle(f),
        annual_cycle: this.findAnnualCycle(f),
        tiers: []
      };

      dispatchFeeStructures.push(feeStructure);
    });

    this.store.dispatch(CreateFeeStructures({feeStructures: dispatchFeeStructures}));


    this.actions$.pipe(
      ofType(CreateFeeStructuresSuccess
      ),
    ).subscribe(() => {
      this.notification.showSuccessNotification('Fee structures successfully created.');
    });

    this.actions$.pipe(
      ofType(CreateFeeStructuresFail
      ),
    ).subscribe(() => {
      this.notification.showSuccessNotification('Failed to create fee structures. Please contact support: support@bridgeft.com.');
    });

    this.dialog.closeAll();
  }

  onSaving(e) {
    this.saved = true;
  }

  onContentReady(e) {
    if (this.dataGrid.instance.hasEditData()) {
      this.saved = false;
    }
  }


  onCellPrepared(e) {

    const requiredCellColor = '#f6ceb9';
    const disableCellColor = '#e0e0e0';

    if (e.rowType === 'data') {
      if (e.column.dataField === 'structure') {
        if (!e.data.structure) {
          e.cellElement.style.background = requiredCellColor;
        }
      }

      if (e.column.dataField === 'collection_type') {
        if (!e.data.collection_type) {
          e.cellElement.style.background = requiredCellColor;
        }
      }
      if (e.column.dataField === 'valuation_type') {
        if (!e.data.valuation_type) {
          e.cellElement.style.background = requiredCellColor;
        }
      }
      if (e.column.dataField === 'billing_frequency') {
        if (!e.data.billing_frequency) {
          e.cellElement.style.background = requiredCellColor;
        }
      }
      if (e.column.dataField === 'cycle') {
        if (!e.data.cycle) {
          e.cellElement.style.background = requiredCellColor;
        }
        if (e.data.billing_frequency === 'M') {
          e.cellElement.style.background = disableCellColor;

        }
      }

      if (e.column.dataField === 'flat_rate') {
        if (e.data.structure === 'R' && !e.data.flat_rate) {
          e.cellElement.style.background = requiredCellColor;
        }
        if (e.data.structure !== 'R') {
          e.cellElement.style.color = disableCellColor;
          e.cellElement.style.background = disableCellColor;
        }
      }

      if (e.column.dataField === 'fee_amount') {
        if ((e.data.structure === 'A' || e.data.structure === 'G') && !e.data.fee_amount) {
          e.cellElement.style.background = requiredCellColor;
        }
        if (e.data.structure !== 'A' && e.data.structure !== 'G') {
          e.cellElement.style.color = disableCellColor;
          e.cellElement.style.background = disableCellColor;
        }
      }
    }
  }

  onEditorPrepared(e) {
    if (e.parentType === 'dataRow') {
      if (e.dataField === 'flat_rate' && e.row.data.structure !== 'R') {
        e.editorOptions.disabled = true;
      }

      if (e.dataField === 'fee_amount' && (e.row.data.structure !== 'A' && e.row.data.structure !== 'G')) {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === 'cycle' && e.row.data.billing_frequency === 'M') {
        e.editorOptions.disabled = true;
      }
    }

  }

  getFilteredCycles(options) {
    return {
      store: this.billingFrequencyWithCycle,
      filter: options.data ? ['frequency', '=', options.data.billing_frequency] : '',
    };
  }

  // Each of these billing frequency cycle has a number assigned.
  // For example, Quarterly Billing Frequency has three cycles: 'Jan - Apr - Jul - Oct', 'Feb - May - Aug - Nov', and 'Mar - Jun - Sep - Dec'.
  // Numbers assigned for the cycles are:
  // 'Jan - Apr - Jul - Oct' = 1
  // 'Feb - May - Aug - Nov' = 2
  // 'Mar - Jun - Sep - Dec' = 3
  // Similarly Semi-Annual cycles are: 'Jan -Jul' , 'Feb - Aug', Mar -Sep', etc and their numbers are:
  // 'Jan -Jul'  = 1,
  // 'Feb - Aug' = 2,
  // 'Mar -Sep' = 3,
  // ....
  // Based on the `BILLING_FREQUENCY_WITH_CYCLE` constants found in `billing.constants.ts`, the data grid is going to return
  // a number (that is different from the above example) that matches the billing frequency and the cycle.
  // For example if the selected billing frequency is Semi-Annual or just 'S' and the returned number is '6'
  // which corresponds to 'Mar - Sep' in `BILLING_FREQUENCY_WITH_CYCLE`.
  // In the file, Semi-Annual Cycle is 3 away from Quarterly Cycle, and Annual is 9 away. So for Semi annual
  // subtracting the 3 from the returned number (6) from the data grid should give 3 which then correspond to `Mar - Sep`
  // from the above example list.
  findQuarterCycle(f) {
    if (f.billing_frequency === 'Q') {
      return f.cycle;
    } else {
      return 1;
    }
  }

  findSemiAnnualCycle(f) {
    if (f.billing_frequency === 'S') {
      return Math.abs(f.cycle - 3);
    } else {
      return 1;
    }
  }

  findAnnualCycle(f) {
    if (f.billing_frequency === 'A') {
      return Math.abs(f.cycle - 9);
    } else {
      return 1;
    }
  }

  setBillingFrequency(rowData: any, value: any): void {
    rowData.cycle = null;
    (this as any).defaultSetCellValue(rowData, value);
  }

  setFlatRate(f) {
    if (f.structure !== 'R') {
      return null;
    }
    return parseFloat(f.flat_rate);
  }

  setFeeAmount(f) {
    if (f.structure !== 'A' && f.structure !== 'G') {
      return null;
    }
    return parseFloat(f.fee_amount);
  }

}
