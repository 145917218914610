import { MatDialogConfig } from '@angular/material/dialog';

export class AppConstants {
  public static CUSTODIAN_MAP = {
    TDA: 'TD Ameritrade',
    NFS: 'Fidelity',
    NFSA: 'Fidelity',
    SWB: 'Schwab',
    BLANK: 'Unknown',
    HDG: 'Hedge Fund',
    JFN: 'Jefferson National',
    MLT: 'Millenium Trust',
    TIA: 'TIAA-CREF',
    WHT: 'Wells Fargo',
    SCT: 'Scottrade',
    RJA: 'Raymond James',
    '': 'Unknown'
  };

  public static DOWNLOAD_TYPE = {
    DOWNLOAD_TYPE_REPORT: 0,
    DOWNLOAD_TYPE_INVOICE: 1
  };

  public static PERIOD_OPTIONS = [
    {value: 'S', period: 'Standard Period (Monthly/Quarterly)'},
    {value: 'C', period: 'Customized Period (Pick beginning/ending dates)'}
  ];

  public static BILLING_DATE_OPTIONS = [
    {value: 'S', period: 'Standard Period (End of Month or Quarter)'},
    // {value: 'exact', period: 'Exact Date'} TODO: Add back when billing fixes are in
  ];

  public static BILLING_DATE_OPTIONS_FOR_ONE_OFF = [
    {value: 'E', period: 'Exact Date'}
  ];

  public static ONE_OFF_BILLING_TYPE = {
    REBATE: 'R',
    COLLECT_FEE: 'C'
  };

  public static FILE_TYPE = {
    CSV: 'C',
    EXCEL: 'E'
  };

  public static MONTH_OPTIONS = [
    {value: 1, month: 'January'},
    {value: 2, month: 'February'},
    {value: 3, month: 'March'},
    {value: 4, month: 'April'},
    {value: 5, month: 'May'},
    {value: 6, month: 'June'},
    {value: 7, month: 'July'},
    {value: 8, month: 'August'},
    {value: 9, month: 'September'},
    {value: 10, month: 'October'},
    {value: 11, month: 'November'},
    {value: 12, month: 'December'},
  ];

  public static CLIENT_PORTAL_SUBREPORT_OPTIONS = [
    {
      id: 'account_summary',
      name: 'Account Summary'
    }, {
      id: 'consolidated_summary',
      name: 'Consolidated Summary'
    },
    {
      id: 'performance_summary',
      name: 'Performance Summary'
    },
    {
      id: 'performance_chart',
      name: 'Performance Chart'
    }, {
      id: 'net_investment_chart',
      name: 'Cumulative Net Investment'
    },
    {
      id: 'appraisals',
      name: 'Appraisals'
    },
    {
      id: 'asset_allocation_top_holdings',
      name: 'Asset allocation and Top Holdings'
    },
    {
      id: 'income',
      name: 'Income'
    },
    {
      id: 'appraisals_wo_cost_basis',
      name: 'Holdings'
    },
  ];

  public static SUB_REPORT_OPTIONS = [{
    id: 'account_summary',
    name: 'Account Summary'
  }, {
    id: 'consolidated_summary',
    name: 'Consolidated Summary'
  }, {
    id: 'performance_summary',
    name: 'Performance Summary'
  }, {
    id: 'benchmark_perf_summary',
    name: 'Benchmark Performance'
  }, {
    id: 'household_performance_attribution',
    name: 'Household Performance Attribution (PDF Only)'
  }, {
    id: 'portfolio_snapshot',
    name: 'Portfolio Snapshot (PDF Only)'
  }, {
    id: 'performance_chart',
    name: 'Performance Chart'
  }, {
    id: 'net_investment_chart',
    name: 'Cumulative Net Investment'
  }, {
    id: 'appraisals',
    name: 'Appraisals'
  }, {
    id: 'appraisals_wo_cost_basis',
    name: 'Holdings'
  }, {
    id: 'asset_allocation_top_holdings',
    name: 'Asset allocation and Top Holdings'
  }, {
    id: 'target_vs_actual_allocation',
    name: 'Target vs. Actual Allocation'
  }, {
    id: 'buy_sells',
    name: 'Buys and Sells'
  }, {
    id: 'deposits_withdrawals',
    name: 'Deposits and Withdrawals'
  }, {
    id: 'income',
    name: 'Income'
  }, {
    id: 'realized_gain_loss',
    name: 'Realized Gains and Losses'
  }, {
    id: 'management_fees',
    name: 'Fees'
  }, {
    id: 'security_performance',
    name: 'Security Performance'
  }, {
    id: 'security_exclusions',
    name: 'Security Exclusions',
  }, {
    id: 'risk_return_chart',
    name: 'Risk Return'
  }, {
    id: 'allocation_and_performance_summary',
    name: 'Allocation & Performance Summary (PDF Only)'
  }, {
    id: 'partition_performance_attribution',
    name: 'Partition Performance Attribution',
  }];

  public static HELDAWAY_RELATIONSHIP_TYPES = {
    1: 'Full discretionary account',
    2: 'Under advisement',
    3: 'Competing advisement',
    4: 'Self-managed account',
    5: 'Non-investment or miscellany'
  };

  public static APP_FOOTER_OFFSET = 52; // We use it to calculate height in FillHeightDirective
  public static APP_HEADER_OFFSET = 65;
  public static APP_MATERIAL_TABS_OFFSET = 49;

  public static HOUSEHOLD_ACCESSIBILITY_OPTIONS = [{
    id: 'limited',
    value: 'Subset of Accounts'
  }, {
    id: 'all',
    value: 'All Clients'
  }];

  public static INVOICE_DUE_DATE_OPTIONS = [{
    value: '',
    name: 'No Due Date'
  }, {
    value: '30',
    name: '30 Days from the Invoice Date'
  }, {
    value: 'E',
    name: 'End of the Following Month'
  }];
}

// Constants for Mat Dialog Modals
export const DEFAULT_DIALOG_OPTIONS: MatDialogConfig = {
  ...new MatDialogConfig(),
  panelClass: 'custom-dialog-container'
};

export const BILLING_PARTITION_OPTIONS = [
    {value: 'M', name: 'Monthly'},
    {value: 'Q', name: 'Quarterly'},
    {value: 'D', name: 'Daily'}
];

export const CASH_SECURITY_ID = 5217;

export const PRIMARY_COLOR = localStorage.getItem('primary-color') || '#5A2ED1';
export const ACCENT_COLOR = '#FFEA00';
export const BRAND_COLORS = [
  '#FF2C79',
  '#5A2ED1',
  '#00BC97',
  '#0084FF',
  '#333333'
];
