import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
  name: 'displayAmount'
})
export class DisplayAmountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!isNaN(parseInt(value, 10))) {
      return numeral(value).format('$(0,0.00)');
    }

    return '-';
  }

}
