<div class="content-wrap">
  <mat-card>
    <mat-card-title>Portformer API Key</mat-card-title>

    <mat-card-content style="padding: 20px;">
      <app-api-key-editor *ngIf="portformerConfig" [enabled]="portformerConfig.enabled"
                          [keyString]="portformerConfig.apiKey"
                          placeholder="Portformer API key"
                          (addKey)="onAddKey($event)"
                          (removeKey)="removeApiKey()"
                          (toggleEnabled)="onToggleEnabled($event)"></app-api-key-editor>
    </mat-card-content>
  </mat-card>
</div>
