import {Injectable} from '@angular/core';
import {Household} from '../household';
import {AccountService} from '../../accounts/account.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import * as householdActions from './household.actions';
import * as fromHousehold from '../state';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class HouseholdEffects {
  constructor(
    private accountService: AccountService,
    private actions$: Actions,
    private store: Store<fromHousehold.State>
  ) {}


  loadHouseholds$ = createEffect(() => this.actions$.pipe(
    ofType(householdActions.Load),
    withLatestFrom(this.store.pipe(select(fromHousehold.getHouseholds))),
    mergeMap(() => {
      return this.accountService.getHouseholds().pipe(
        map(households => (householdActions.LoadSuccess({households}))),
        catchError(error => of(householdActions.LoadFail({error})))
      );
    })
  ));


  updateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(householdActions.UpdateHousehold),
    map(action => action.household),
    mergeMap((household: Household) => {
      return this.accountService.saveHousehold(household).pipe(
        map((updatedHousehold: Household) => householdActions.UpdateHouseholdSuccess({household: updatedHousehold})),
        catchError((err) => {
          return of(householdActions.UpdateHouseholdFail({error: err.error}));
        })
      );
    })
  ));


  updateAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(householdActions.UpdateHouseholds),
    map(action => action.households),
    mergeMap((households: Household[]) => {
      return this.accountService.saveHouseholds(households).pipe(
        map((updatedHouseholds: Household[]) => householdActions.UpdateHouseholdsSuccess({households: updatedHouseholds})),
        catchError((err) => {
          return of(householdActions.UpdateHouseholdsFail({error: err.error}));
        })
      );
    })
  ));


  createHousehold$ = createEffect(() => this.actions$.pipe(
    ofType(householdActions.CreateHousehold),
    map(action => action.household),
    mergeMap((household: Household) => {
      return this.accountService.createHousehold(household).pipe(
        map((createdHousehold: Household) => householdActions.CreateHouseholdSuccess({household: createdHousehold})),
        catchError((err) => {
          return of(householdActions.CreateHouseholdFail({error: err.error}));
        })
      );
    })
  ));


  removeHousehold$ = createEffect(() => this.actions$.pipe(
    ofType(householdActions.RemoveHousehold),
    map(action => action.household),
    mergeMap((household: Household) => {
      return this.accountService.deleteHousehold(household)
        .pipe(
          map(() => (householdActions.RemoveHouseholdSuccess({household}))),
          catchError((err: any) => {
            return of(householdActions.RemoveHouseholdFail({error: err.error}));
          })
        );
    })
  ));


  removeMultipleHousehold$ = createEffect(() => this.actions$.pipe(
    ofType(householdActions.RemoveMultipleHouseholds),
    map(action => action.households),
    mergeMap((households: Household[]) => {
      return this.accountService.deleteHouseholds(households)
        .pipe(
          map(() => (householdActions.RemoveMultipleHouseholdsSuccess({households}))),
          catchError((err: any) => {
            return of(householdActions.RemoveMultipleHouseholdsFail({error: err.error}));
          })
        );
    })
  ));
}
