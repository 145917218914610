import { Pipe, PipeTransform } from '@angular/core';
import _repeat from 'lodash-es/repeat';

@Pipe({
  name: 'applyDataMask'
})
export class ApplyDataMaskPipe implements PipeTransform {

  transform(input: any, n?, char?, sep?): any {
    if (!input) {
      return '';
    }

    if (input === '0') {
      return '';
    }

    char = char || 'X';
    n = n || 3;
    sep = sep || ' ';

    return _repeat(char, input.length - n) + sep + input.substring(input.length - n, input.length);
  }

}
