<div style="width: 450px">
  <div mat-dialog-title>
    {{ editing ? 'Edit' : 'Create' }} Benchmark
  </div>

  <mat-dialog-content>
    <form [formGroup]="benchmarkForm">

      <div  fxLayout="column"
            gdGap="10px"
            gdColumns="calc(100% - 10px)">

        <label class="detail-label">
          Benchmark Name
        </label>

        <mat-form-field appearance="outline">
          <input matInput formControlName="name" placeholder="Enter a name" [(ngModel)]="benchmark.name">
        </mat-form-field>

        <label class="detail-label">
          Index
        </label>

        <mat-form-field appearance="outline">
          <input type="text"
                 placeholder="Select an Index to add"
                 aria-label="Number"
                 matInput
                 [formControl]="myIndexControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let index of filteredIndexOptions | async" [value]="index.name" (click)="selectedIndexChanged(index)">
              {{index.internal_name}}
            </mat-option>
          </mat-autocomplete>


        </mat-form-field>
      </div>
    </form>
    <br>

    <div style="overflow: auto; max-height: 300px; position: relative;">
      <table class="layout display"
             style="width: 450px;"
             *ngIf="benchmark.coefficients.length">
        <thead>
        <tr>
          <th class="text-col">Index</th>
          <th class="number-col">Weight (%)</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let coefficient of benchmark.coefficients">
          <td class="text-col">
            {{getIndex(coefficient).internal_name}}
          </td>

          <td class="number-col">
            <mat-form-field class="table-input" appearance="outline" style="width: 85px;">
              <input type="number" matInput [(ngModel)]="coefficient.weight">
            </mat-form-field>
          </td>

          <td>
            <div #removeIndexTemplate>
              Remove index
            </div>

            <button mat-icon-button
                    color="primary"
                    [ngxTippy]="removeIndexTemplate"
                    tippyName="removeIndexTooltip"
                    [tippyProps]="tooltipProps"
                    (click)="removeCoefficient(coefficient)">
              <i class="fas fa-trash datagrid-icon"></i>
            </button>
          </td>
        </tr>

        <tr class="total" style="margin-top: 10px">
          <td class="text-col">
            <strong>Total</strong>
          </td>

          <td class="number-col">
            <strong>{{totalWeight()}} %</strong>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <p class="help-block" *ngIf="benchmark.coefficients.length">
      <i class="far fa-info-circle prefix-icon"></i>
      Weights must add up to 100%
    </p>
  </mat-dialog-content>

  <div class="actions-container">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>

    <app-atlas-submit-button (click)="saveBenchmark()" [disabled]="!canSave()" [saving]="saving">Save</app-atlas-submit-button>
  </div>
</div>
