import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {InsightsParameter} from './parameter.type';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {State} from './state';
import {select, Store} from '@ngrx/store';
import {getParameters} from './state';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  parameters$ = this.store.pipe(select(getParameters));

  endpoint = `${environment.apiV2Url}/insights/parameters`;

  constructor(private http: HttpClient,
              private store: Store<State>) { }

  getParameters(): Observable<InsightsParameter[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: InsightsParameter[]}) => resp.data),
    );
  }

  getParameter(id: number): Observable<InsightsParameter> {
    const url = `${this.endpoint}/${id}`;

    return this.http.get<InsightsParameter>(url);
  }

  updateParameter(parameter: InsightsParameter): Observable<InsightsParameter> {
    const url = `${this.endpoint}/${parameter.id}`;

    return this.http.put<InsightsParameter>(url, parameter);
  }

  updateParameters(parameters: InsightsParameter[]): Observable<InsightsParameter[]> {
    return this.http.put<InsightsParameter[]>(this.endpoint, parameters);
  }
}
