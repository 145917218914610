import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FeeStructuresListComponent} from './fee-structures-list/fee-structures-list.component';
import {FeeStructuresCreateEditComponent} from './fee-structures-create-edit/fee-structures-create-edit.component';
import {FeeStructuresResolver} from './fee-structures.resolve';
import {SharedModule} from '../../../shared/shared.module';
import { FeeStructureBulkUploadComponent } from './fee-structure-bulk-upload/fee-structure-bulk-upload.component';
import {DisplayCustodianPipe} from '../../../shared/pipes/display-custodian.pipe';
import { FeeStructureAssignmentBulkUploadComponent } from './fee-structure-assignment-bulk-upload/fee-structure-assignment-bulk-upload.component';

@NgModule({
  declarations: [
    FeeStructuresListComponent,
    FeeStructuresCreateEditComponent,
    FeeStructureBulkUploadComponent,
    FeeStructureAssignmentBulkUploadComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    FeeStructuresResolver,
    DisplayCustodianPipe
  ],
  exports: [
    FeeStructuresListComponent
  ]
})
export class FeeStructuresModule { }
