import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {SharedModule} from '../shared/shared.module';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';

@NgModule({
  declarations: [UnauthorizedComponent, NoPermissionsComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ServicePagesModule { }
