<div style="width: 450px">
  <h2 mat-dialog-title>Select a Billing Group to Assign</h2>

  <mat-dialog-content>
    <mat-form-field appearance="outline" style="width: 100%">
      <input type="text"
             placeholder="Select a billing group"
             matInput
             [formControl]="billingGroupSearch"
             [matAutocomplete]="auto">

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelected" (optionSelected)="selectBillingGroup($event)">
        <mat-option *ngFor="let billingGroup of filteredBillingGroups$ | async | slice: 0: 20" [value]="billingGroup">
          {{billingGroup.name}}

        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <br>

    <div *ngIf="selectedGroup">
      <p>
        {{accounts.length}} accounts are being assigned to billing group <b>{{selectedGroup.name}}</b>
      </p>
    </div>

  </mat-dialog-content>

  <div class="actions-container">
    <button mat-button color="primary" [mat-dialog-close]>Cancel</button>

    <app-atlas-submit-button
      [disabled]="!this.selectedGroup || saving"
      [saving]="saving"
      (click)="save()">Assign</app-atlas-submit-button>
  </div>
</div>
