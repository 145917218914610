import {Component, OnInit} from '@angular/core';
import {AddSpacePipe} from '../add-space.pipe';
import {PortformerService, PortformerWithCurrentSecurityPosition} from '../portformer.service';
import {UserProfileService} from '../../../../shared/services/auth/user-profile.service';
import {of, Subject} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Component({
  selector: 'app-suggested-tickers',
  templateUrl: './suggested-tickers.component.html',
  providers: [AddSpacePipe],
  styleUrls: ['./suggested-tickers.component.scss']
})
export class SuggestedTickersComponent implements OnInit {
  private userId: number;
  constructor(
    private portformerService: PortformerService,
    private userProfileService: UserProfileService) { }


  displayedColumns: string[] = ['currentSymbol', 'altTickers', 'feeSavings', 'dollarSavings'];
  portformerLoadingError$ = new Subject<boolean>();
  loading = true;

  portformerRecommendations$ = this.portformerService.portformerRecommendations$.pipe(
    catchError(() => {
      this.portformerLoadingError$.next(true);
      this.loading = false;
      return of();
    }),
    tap(resp => {
      this.loading = false;
    })
  );

  ngOnInit(): void {
    this.userId = this.userProfileService.userProfile.id;
  }

  portformerIntegrationLink(obj: PortformerWithCurrentSecurityPosition) {
    return `https://www.portformer.com/bridgeft-portformer-integration?userid=${this.userId}&ticker=${obj.currentSecurity.symbol}`;
  }
}
