import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullFrequencyName'
})
export class FullFrequencyNamePipe implements PipeTransform {

  transform(value: string, args?: any): any {

    if (value.toUpperCase() === 'M') {
      return 'Monthly';
    } else if (value.toUpperCase() === 'Q') {
      return 'Quarterly';
    } else if (value.toUpperCase() === 'S') {
      return 'Semi-Annual';
    } else if (value.toUpperCase() === 'A') {
      return 'Annual';
    }

    return '';
  }

}
