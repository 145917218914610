import { Pipe, PipeTransform } from '@angular/core';
import _deepClone from 'lodash-es/cloneDeep';

@Pipe({
  name: 'copy'
})
export class CopyPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    return _deepClone(value);
  }
}
