import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {combineLatest} from 'rxjs';
import {select, State, Store} from '@ngrx/store';
import {getAuthSources, getLoading, getLoaded} from '../../auth-sources/state';
import {filter, first, map, take, tap} from 'rxjs/operators';
import {LoadAuthSources} from '../../auth-sources/state/auth-sources.actions';

@Injectable({
  providedIn: 'root'
})
export class WealthboxSettingsService {

  configuration$ = combineLatest([
    this.store.pipe(select(getLoaded)),
    this.store.pipe(select(getLoading)),
    this.store.pipe(select(getAuthSources))
  ]).pipe(
    tap(([loaded, loading, authSources]) => {
      if (!loading && !loaded) {
        this.store.dispatch(LoadAuthSources());
      }
    }),
    filter(([loaded, loading, authSources]) => {
      return loaded;
    }),
    map(([loaded, loading, authSources]) => {
      return authSources.find(a => a.source === 'Wealthbox');
    }),
  );

  constructor(private http: HttpClient,
              private store: Store<State<any>>) {
  }
}
