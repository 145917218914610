import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
  name: 'abbreviatedAmount'
})
export class AbbreviatedAmountPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (!isNaN(parseInt(input))) {

      if (input >= 1e9) {
        return numeral(input / 1e9).format('$(0,0)') + 'Bn';
      } else if (input >= 1e6) {
        return numeral(input / 1e6).format('$(0,0.00)') + ' M';
      } else if (input >= 1e3) {
        return numeral(input / 1e3).format('$(0,0)') + ' K';
      } else {
        return numeral(input).format('$(0,0.00)');
      }
    }

    return '-';
  }
}
