import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AccountService} from '../../accounts/account.service';
import {Actions} from '@ngrx/effects';
import {BillingGroup, BillingGroupAssignment} from '../billing';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter, map, startWith, switchMap} from 'rxjs/operators';
import _cloneDeep from 'lodash-es/cloneDeep';
import {Account} from '../../accounts/account';
import {BillingGroupsService} from '../billing-groups.service';
import {NotificationService} from '../../shared/services/ui/notification.service';

@Component({
  selector: 'app-assign-billing-groups',
  templateUrl: './assign-billing-groups.component.html',
  styleUrls: ['./assign-billing-groups.component.scss']
})
export class AssignBillingGroupsComponent implements OnInit {

  accounts: Account[];
  billingGroups: BillingGroup[];

  filteredBillingGroups$: Observable<BillingGroup[]>;
  billingGroupSearch = new UntypedFormControl();

  selectedGroup: BillingGroup;
  saving = false;

  constructor(
    private dialogRef: MatDialogRef<AssignBillingGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private billingGroupService: BillingGroupsService,
    private notificationService: NotificationService) {
    this.accounts = _cloneDeep(data.accounts);
    this.billingGroups = (data.billingGroups);
  }

  ngOnInit(): void {

    this.filteredBillingGroups$ = this.billingGroupSearch.valueChanges.pipe(
      startWith(''),
      filter(search => {
        return typeof search === 'string';
      }),
      map(search => {
        search = search.toLowerCase();
        return this.billingGroups.filter(bg => bg.name.toLowerCase().includes(search));
      })
    );
  }

  displaySelected(billingGroup: BillingGroup) {
    if (billingGroup) {
      return billingGroup.name;
    }
  }

  selectBillingGroup(e) {
    this.selectedGroup = e.option.value;
  }

  save() {
    this.saving = true;

    this.accounts.forEach(a => {
      if (this.selectedGroup && this.selectedGroup.assignments) {
        const assignment = this.selectedGroup.assignments.find(as => as.account_id === a.id);

        // If there isn't already an assignment for that account in the group, don't add a new one
        if (!assignment) {
          // Check for previous assignment
          const oldGroup = this.billingGroupService.accountGroupByAccountID[a.id];
          const groupID = oldGroup ? oldGroup.id : null;

          // Setting the old group tells the backend that it needs to delete the old assignment
          this.selectedGroup.assignments.push(new BillingGroupAssignment(a.id, groupID));
        }
      }
    });

    if (this.selectedGroup) {
      this.billingGroupService.saveAccountGroup(this.selectedGroup)
        .then(() => {
          this.notificationService.showSuccessNotification(`Added ${this.accounts.length} accounts to billing group ${this.selectedGroup.name}`);
          this.dialogRef.close(true);
        })
        .catch(() => {
          this.notificationService.showErrorNotification('An error occurred while adding accounts to the selected billing group');
        })
        .finally(() => this.saving = false);
    }
  }
}
