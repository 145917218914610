import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingDisplayBalanceType'
})
export class BillingDisplayBalanceTypePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'E') {
      return 'Ending Period Balance';
    } else if (input === 'A') {
      return 'Average Daily Balance';
    } else if (input === 'P') {
      return 'Ending Period Balance w/ Adjustment for Flows';
    } else if (input === 'C') {
      return 'Ending Period Balance w/ Adjustment for Flows, Less Cash';
    }

    return '';
  }

}
