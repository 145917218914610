import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-spinner',
  template: `    
    <div class="modal-header">
      <h4 class="modal-title">Please Wait</h4>
    </div>

    <mat-dialog-content>
      <div class="modal-body">

        <div class="row mt-50 mb-50">
          <div class="text-center p-30">
            <div class="three-quarters-loader"></div>
            <br/>
            {{ message }}
          </div>
        </div>

      </div>
    </mat-dialog-content>
  `
})
export class SpinnerComponent implements OnInit {
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    this.message = this.data.message;
  }

}
