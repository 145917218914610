<div style="width: 450px;">
  <h2 mat-dialog-title>Select Fee Structure to Assign</h2>

  <mat-dialog-content>
    <div>
      <div>

        <form>
          <div style="margin: 20px;">
            <label style="color: #737373; font-weight: bold;">Fee Structure</label>
            <div>
              <app-atlas-multiselect
                [data]="feeStructures"
                [(value)]="selected"
                placeholder="Select fee structures"
                displayField="name"
                valueField="id">
              </app-atlas-multiselect>
            </div>
          </div>

          <div class="form-group" *ngIf="!isSameFields('collection_type')">
            <label class="col-sm-3 control-label">
            <span class="red-text text-darken-3">
                Error
            </span>
            </label>

            <div class="col-sm-8">
              <b>Mixed collection types</b>
              <p>
                The selected fee structures have different collection types.
                <br/>
                All fee structures in a fee-assignment group must have the same collection type.
              </p>
            </div>
          </div>

          <div class="form-group" *ngIf="!isSameFields('frequency')">
            <label class="col-sm-3 control-label">
            <span class="red-text text-darken-3">
                Error
            </span>
            </label>

            <div class="col-sm-8">
              <b>Mixed billing frequency</b>
              <p>
                The selected fee structures have different billing frequencies.
                <br/>
                All fee structures in a fee-assignment group must have the same billing frequency.
              </p>
            </div>
          </div>

          <div class="form-group" *ngIf="!isSameFields('quarter_cycle')">
            <label class="col-sm-3 control-label">
            <span class="red-text text-darken-3">
                Error
            </span>
            </label>

            <div class="col-sm-8">
              <b>Mixed quarter cycles</b>
              <p>
                The selected fee structures have different quarter cycles.
                <br/>
                All fee structures in a fee-assignment group must have the same quarter cycles.
              </p>
            </div>
          </div>

          <div class="form-group" *ngIf="heterogeneousFeeStructures">
            <label class="col-sm-3 control-label">
            <span class="orange-text text-darken-3">
                Warning
            </span>
            </label>

            <div class="col-sm-8">
              <b>Mixed fee structures</b>
              <p>
                These accounts currently have different fee structures assigned.
                The initial selection is a union of all fee structures across the selected accounts.
              </p>
            </div>

          </div>

          <div class="notes">
            <h3 class="notes-label">Notes</h3>

            You are assigning <b>{{ numAccounts | number }} {{ numAccounts === 1 ? 'account' : 'accounts' }}</b> to the selected fee structure.
            <br>
            <br>
            Existing assignments within this set of accounts will be overwritten.
          </div>
        </form>

      </div>
    </div>

  </mat-dialog-content>

  <div class="actions-container">
    <button mat-button color="primary" [mat-dialog-close]>Cancel</button>

    <app-atlas-submit-button
      [disabled]="isSaveDisabled()"
      [saving]="status.assigning"
      (click)="save()">Assign</app-atlas-submit-button>
  </div>


</div>
