import {StoreModule} from '@ngrx/store';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {reducer} from './securities.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SecuritiesEffects} from './securities.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('securities', reducer),
    EffectsModule.forFeature(
      [SecuritiesEffects]
    ),
  ]
})
export class SecuritiesStoreModule { }
