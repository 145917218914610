import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-atlas-submit-button',
  templateUrl: './atlas-submit-button.component.html',
  styleUrls: ['./atlas-submit-button.component.scss']
})
export class AtlasSubmitButtonComponent implements OnInit {
  @Input() saving: boolean;
  @Input() disabled: boolean;

  @Input() onClick = () => {};

  constructor() { }

  ngOnInit() {
  }

}
