import { Inject, Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {AccountService} from '../../../../accounts/account.service';
import {ContactIntegrationRelationship, WealthboxContact} from '../type-classes/wealthbox-contact';
import {WealthboxService} from '../wealthbox.service';

@Injectable()
export class WealthboxContactsMappingService {
  private wealthboxContactCache: WealthboxContact[] = [];
  private readonly householdsStore: CustomStore;

  constructor(
    @Inject(AccountService) private accountService,
    @Inject(WealthboxService) private wealthboxService) {
    this.householdsStore = new CustomStore({
      key: 'id',
      load: () => {
        return this.accountService.filterHouseholds();
      },
      loadMode: 'raw',
    });
  }

  getHouseholdsDataSource(): DataSource {
    return new DataSource({
      store: this.householdsStore,
      map: (item) => {
        item.disabled = this.wealthboxService.integrations.find(r => r.householdId === item.id);
        return item;
      },
    });
  }

  getClientsDataSource(): DataSource {
    return new DataSource({
      store: new CustomStore(this.wealthboxService.contactsLookupStoreConfig),
      group: 'type',
      map: (item) => {
        item.disabled = this.wealthboxService.integrations.find(r => r.wealthboxContactId === item.id);
        return item;
      }
    });
  }

  getRelationsDataSource(households, contacts) {
    return {
      store: new CustomStore({
        key: 'id',
        load: (loadOptions) => {
          const start = loadOptions.skip || 0;
          const finish = (loadOptions.skip + loadOptions.take) || 25;

          let page;
          return this.wealthboxService.getIntegrations()
            .then(() => {
              page = this.wealthboxService.integrations.slice(start, finish);

              const items = page.map((i: ContactIntegrationRelationship) => {
                return {
                  id: i.id,
                  household: households.find(h => h.id === i.householdId),
                  wealthboxContact: contacts.find(c => c.id === i.wealthboxContactId)
                };
              });

              return {
                data: items,
                totalCount: items.length
              };
            })
            .catch(() => {
              return Promise.reject(`We are not able to load integrations. Please ensure you have a valid API token entered.
              Feel free to contact support@bridgeft.com if you continue to have errors`);
            });
        },
        cacheRawData: true,
      }),
    };
  }


  private _getWealthboxContacts(integrations: ContactIntegrationRelationship[]): Promise<WealthboxContact[]> {
    const contactsToLoad = integrations.filter((i) => {
      return !this.wealthboxContactCache.find(c => c.id === i.wealthboxContactId);
    });

    return Promise.all(contactsToLoad.map(i => this.wealthboxService.getContact(i.wealthboxContactId)))
      .then((data: WealthboxContact[]) => {
        this.wealthboxContactCache.push(...data);

        return this.wealthboxContactCache;
      });

  }
}
