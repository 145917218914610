import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayTransactionCode'
})
export class DisplayTransactionCodePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    switch (input) {
      case 'BTO':
        return 'Buy to open';
      case 'BTC':
        return 'Buy to close';
      case 'STO':
        return 'Sell to open';
      case 'STC':
        return 'Sell to close';
      case 'DEP':
        return 'Deposit';
      case 'WITH':
        return 'Withdrawal';
      case 'INTACC':
        return 'Accrued interest';
      case 'DIV':
        return 'Dividend earned';
      case 'INT':
        return 'Interest earned';
      case 'INC':
        return 'Income';
      case 'EXP':
        return 'Management fee';
      case 'SLO':
        return 'Split to open long';
      case 'SLC':
        return 'Split to close long';
      case 'SSO':
        return 'Split to open short';
      case 'SSC':
        return 'Split to close short';
      case 'ILO':
        return 'Initialize long to open';
      case 'ISO':
        return 'Initialize short to open';
      case 'ICC':
        return 'Initialize cash currency';
      case 'IML':
        return 'Initialize manual ledger';
      case 'TLO':
        return 'Security transfer to open long';
      case 'TLC':
        return 'Security transfer to close long';
      case 'TSO':
        return 'Security transfer to open short';
      case 'TSC':
        return 'Security transfer to close short';
      default:
        return input;
    }
  }

}
