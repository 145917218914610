import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as firmActions from './firm.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as fromFirm from '../state';
import {Firm} from '../../firm';
import {FirmService} from '../../firm.service';

@Injectable()
export class FirmEffects {

  constructor(
    private firmService: FirmService,
    private actions$: Actions,
    private store: Store<fromFirm.State>
    ) {
  }


  loadFirms$ = createEffect(() => this.actions$.pipe(
    ofType(firmActions.Load),
    withLatestFrom(this.store.pipe(select(fromFirm.getFirms))),
    mergeMap(() => {
      return this.firmService.getFirms()
        .pipe(
          map(firms => (firmActions.LoadSuccess({Firms: firms}))),
          catchError(error => of(firmActions.LoadFail({error})))
        );
    })
  ));


  updateFirm$ = createEffect(() => this.actions$.pipe(
    ofType(firmActions.UpdateFirm),
    map(action => action.Firm),
    mergeMap((firm: Firm) => {
      return this.firmService.updateFirm(firm)
        .pipe(
          map((updatedFirm: Firm) => (firmActions.UpdateFirmSuccess({Firm: updatedFirm}))),
          catchError((err: any) => {
            return of(firmActions.UpdateFirmFail({error: err.error.Message}));
          })
        );
    })
  ));
}
