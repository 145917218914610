import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(private http: HttpClient) { }

  unsubscribe(token: string): Observable<any> {
    const url = `${environment.insightsApiUrl}notifications/unsubscribe/${token}?system=insights`;

    return this.http.post(url, {});

  }
}
