import {AuthSourceState, selectAllAuthSources} from './auth-sources.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: AuthSourceState;
}

const getAuthSourceFeatureState = createFeatureSelector<AuthSourceState>('authSources');

export const getAuthSources = createSelector(
  getAuthSourceFeatureState,
  selectAllAuthSources
);

export const getLoading = createSelector(
  getAuthSourceFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getAuthSourceFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getAuthSourceFeatureState,
  (state) => state.callState.error
);
