import {Component, Input, OnInit} from '@angular/core';
import {WealthboxContact} from '../type-classes/wealthbox-contact';
import {WealthboxService} from '../wealthbox.service';
import {SidePanelService} from '../../../../layout/side-panel/side-panel.service';

@Component({
  selector: 'app-wealthbox-contact-card',
  templateUrl: './wealthbox-contact-card.component.html',
  styleUrls: ['./wealthbox-contact-card.component.scss']
})
export class WealthboxContactCardComponent implements OnInit {

  @Input() contact: WealthboxContact;

  constructor(
    private wealthboxService: WealthboxService,
    private sidePanelState: SidePanelService
    ) { }

  ngOnInit() {
  }

  get phoneNumber(): string {
    let phone = null;

    if (this.contact.phone_numbers) {
      this.contact.phone_numbers.forEach((phoneObject) => {
        if (phoneObject.principal) {
          phone = phoneObject.address;
        }
      });

      // Default to the first number
      if (!phone && this.contact.phone_numbers[0]) {
        phone = this.contact.phone_numbers[0].address;
      }
    }

    if (!phone) {
      return 'n/a';
    }

    return phone;
  }

  get email(): string {
    let address = null;

    if (this.contact.email_addresses) {
      this.contact.email_addresses.forEach(email => {
        if (email.principal) {
          address = email.address;
        }
      });

      if (!address && this.contact.email_addresses[0]) {
        address = this.contact.email_addresses[0].address;
      }
    }

    if (!address) {
      address = 'n/a';
    }

    return address;
  }

  openDetail() {
    this.sidePanelState.openWealthboxContact(this.contact);
  }

}
