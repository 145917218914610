import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetAdjustmentsResolver, GroupResolver, MinimumsResolver, SplitResolver } from './billing.resolve';
import { DevExtremeModule} from '../devextreme/dev-extreme.module';
import {SharedModule} from '../shared/shared.module';
import {AccountsResolver, BillingGroupsResolver, CurrentBalanceResolver} from '../app.resolve';
import {InvoicesModule} from './invoices/invoices.module';
import {FeeStructuresModule} from '../settings/billing/fee-structures/fee-structures.module';
import {BillableAndNonBillableComponent} from '../dashboard/billable-and-non-billable/billable-and-non-billable.component';
import {ChartsModule} from 'ng2-charts';
import { AssignBillingGroupsComponent } from './assign-billing-groups/assign-billing-groups.component';
import { BillingPaymentSourceDialogComponent } from './billing-payment-source-dialog/billing-payment-source-dialog.component';

@NgModule({
    declarations: [
        BillableAndNonBillableComponent,
        AssignBillingGroupsComponent,
        BillingPaymentSourceDialogComponent
    ],
    imports: [
        CommonModule,
        DevExtremeModule,
        SharedModule,
        InvoicesModule,
        FeeStructuresModule,
        ChartsModule,
    ],
    providers: [
        SplitResolver,
        GroupResolver,
        MinimumsResolver,
        AccountsResolver,
        CurrentBalanceResolver,
        BillingGroupsResolver,
        AssetAdjustmentsResolver
    ],
    exports: [
        BillableAndNonBillableComponent,
        AssignBillingGroupsComponent,
    ]
})
export class BillingModule {}
