<div *ngFor="let job of jobs">
  <div class="recent-activity-card"
       [ngClass]="job.job_type === 'pdfrp' ? 'pdf' : ''"
       [ngStyle]="job.job_type === 'pdfrp' ? {'border-left-color': getCardColor(1)} : {'border-left-color': getCardColor(0)}">

    <i *ngIf="job.job_type === 'pdfrp' else billing"
       class="far fa-file-pdf"
       [ngStyle]="{'color': getCardColor(1)}"></i>

    <ng-template #billing>
      <i class="far fa-file-invoice-dollar"
         [ngStyle]="{'color': getCardColor(0)}"></i>
    </ng-template>

    <div class="content-container">
      <div class="content-header">
        <div *ngIf="job.job_type === 'b'">
          Billing Report
        </div>

        <div *ngIf="job.job_type === 'pdfrp'">
          PDF Reports
        </div>

        <div class="date">
          {{ job.created_at_utc | date:'mediumDate' }}
        </div>
      </div>

      <div style="display: flex; flex-direction: column;">
        <div class="content">
          <span *ngIf="getStatus(job) === 'Running' else status"> {{getProgressBarPercentage(job)}}% </span>
          <ng-template #status>{{getStatus(job)}}</ng-template>

          <span *ngIf="getStatus(job) === 'Running'" class="right">
            Step {{job.current_step}} of {{job.num_steps}}
          </span>

          <mat-progress-bar [ngClass]="job.job_type === 'pdfrp' ? 'pdf' : 'billing'"
                            style="margin-top: 5px;"
                            [value]="(job.current_step / job.num_steps) * 100"></mat-progress-bar>

        </div>


      </div>
    </div>
  </div>
</div>



<!--<div class="page-title">-->

<!--  <div class="row">-->
<!--    <div class="col s12">-->

<!--      <app-breadcrumbs [links]="[{name: 'Background Jobs'}]"></app-breadcrumbs>-->

<!--      <div class="pull-right">-->

<!--        <ul>-->
<!--          <li>-->
<!--            <a [routerLink]="" (click)="isHelpWindowVisible = true">-->
<!--              Help with Background Jobs-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->

<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

<!--</div>-->

<!--<div class="row">-->
<!--  <div class="col s12">-->
<!--    <div class="card">-->
<!--      <div class="title">-->
<!--        <div class="pull-left">-->
<!--          <h4>Background Jobs</h4>-->
<!--          <p>-->
<!--            Background jobs are running processes that may take awhile to run.<br/>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="pull-right">-->
<!--          <button class="btn btn-default"><i class="fa fa-refresh" (click)="refreshJobs()"></i></button>-->
<!--          <a [routerLink]="" (click)="clear()" class="btn btn-default">-->
<!--            Clear-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="content">-->

<!--        <div class="row">-->

<!--          <p *ngIf="nRunningJobs() === 0 && nJobs() === 0" class="text-center">-->
<!--            No background jobs found-->
<!--            <br/>-->
<!--            <br/>-->
<!--            <br/>-->
<!--            <br/>-->
<!--          </p>-->


<!--          <table class="table" *ngIf="nJobs() > 0">-->
<!--            <thead>-->
<!--            <th>Task</th>-->
<!--            <th>Created</th>-->
<!--            <th>Status</th>-->
<!--            <th style="width: 50%">Progress</th>-->
<!--            </thead>-->

<!--            <tbody>-->
<!--            <tr *ngFor="let job of jobs">-->
<!--              <td>-->
<!--                <span *ngIf="job.job_type === 'b'">-->
<!--                    Billing Report-->
<!--                </span>-->

<!--                <span *ngIf="job.job_type === 'pdfrp'">-->
<!--                    Generate Reports-->
<!--                </span>-->

<!--                <span *ngIf="job.job_type === 'bd'">-->
<!--                    Bulk Download-->
<!--                </span>-->

<!--              </td>-->

<!--              <td>-->
<!--                {{ job.moment_created_at_utc | date:"MMM d, yyyy":"UTC" }}-->
<!--                <br/>-->
<!--                <small>-->
<!--                  <span am-time-ago="job.moment_created_at_utc | amLocal"></span>-->
<!--                </small>-->
<!--              </td>-->

<!--              <td>-->
<!--                {{ getStatus(job) }}-->
<!--              </td>-->

<!--              <td style="width: 40%">-->

<!--                <span *ngIf="job.is_failed === false">-->
<!--                    <div class="progress" style="margin-right:50px;">-->
<!--                        <div class="blue lighten-2 black-text" [ngStyle]="getProgressBarStyle(job)">-->
<!--                            {{ getProgressBarPercentage(job) }}%-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <span *ngIf="job.num_steps > 0 && job.current_step > 0" [ngPlural]="job.num_steps">-->
<!--                        Completed {{ job.current_step }} of {{ job.num_steps }}-->
<!--                      <ng-template ngPluralCase="0"> steps</ng-template>-->
<!--                      <ng-template ngPluralCase="1"> step</ng-template>-->
<!--                      <ng-template ngPluralCase="other"> steps</ng-template>-->

<!--                    </span>-->
<!--                </span>-->

<!--                <span *ngIf="job.is_failed === true">-->
<!--                    <div class="alert">-->
<!--                        <strong>Failed</strong> This job failed-->
<!--                    </div>-->

<!--                    <br/>-->
<!--                    This job encountered an error during processing; the error has been captured by our monitoring-->
<!--                    systems and our software development team has been notified. We may follow with you via email if necessary;-->
<!--                    please reach out to support@bridgeft.com if you have any questions.-->

<!--                </span>-->

<!--              </td>-->

<!--            </tr>-->
<!--            </tbody>-->

<!--          </table>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<app-help-popup [(show)]="isHelpWindowVisible" header="Background Jobs">-->
<!--  <ng-container ngProjectAs="popup-body">-->
<!--    <p>-->
<!--      Background jobs are long-running tasks.-->
<!--    </p>-->

<!--    <p>-->
<!--      They include things like generating reports and running billing. You can check back on this screen-->
<!--      periodically to see the progress of jobs.-->
<!--    </p>-->

<!--    <p>-->
<!--      Job statuses are also available by clicking the tasks icon in the upper right corner of the dashboard.-->
<!--    </p>-->
<!--  </ng-container>-->
<!--</app-help-popup>-->
