import {Component, Inject, OnInit} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-notification',
  template: `
    <span><i class="fa fa-check text-success"></i> &nbsp; {{message}}</span>
  `
})
export class SuccessNotificationComponent implements OnInit {
  message: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: any) {
    this.message = data.message;
  }

  ngOnInit() {
  }

}
