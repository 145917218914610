import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FeeStructureStoreModule} from '../settings/billing/fee-structures/state/fee-structure-store.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SecuritiesStoreModule} from '../securities/state/securities-store.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';
import {AccountStoreModule} from '../accounts/state/account-store.module';
import {HouseholdStoreModule} from '../households/state/household-store.module';
import {ClientStoreModule} from '../clients/state/client-store.module';
import {ReportPackagesStoreModule} from '../settings/reporting/report-packages/state/report-packages-store.module';
import {SecurityExclusionsStoreModule} from '../settings/reporting/security-exclusions/state/security-exclusions-store.module';
import {AlertConditionsStoreModule} from '../settings/insights/alert-conditions/state/alert-conditions-store.module';
import {ParametersStoreModule} from '../settings/insights/parameters/state/parameters-store.module';
import {AlertSubscriptionsStoreModule} from '../settings/insights/alert-subscriptions/state/alert-subscriptions-store.module';
import {AuthSourcesStoreModule} from '../settings/integrations/auth-sources/state/auth-sources-store.module';
import {FirmStoreModule} from '../firm/state/state/firm-store.module';
import {BalancesStoreModule} from '../balances/state/balances-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BalancesStoreModule,
    FeeStructureStoreModule,
    SecuritiesStoreModule,
    AccountStoreModule,
    HouseholdStoreModule,
    ClientStoreModule,
    ReportPackagesStoreModule,
    SecurityExclusionsStoreModule,
    AlertConditionsStoreModule,
    ParametersStoreModule,
    AlertSubscriptionsStoreModule,
    AuthSourcesStoreModule,
    FirmStoreModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'APM Demo App DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
  ]
})
export class RootStateModule { }
