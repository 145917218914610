import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountStatusDisplay'
})
export class AccountStatusDisplayPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value === 'funded') {
      return 'Funded';
    } else if (value === 'stale') {
      return 'Stale';
    } else if (value === 'papered') {
      return 'Papered';
    } else if (value === 'closed') {
      return 'Closed';
    }

    return 'Unknown';
  }

}
