import {ClientState, selectAllClients} from './client.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: ClientState;
}

const getClientFeatureState = createFeatureSelector<ClientState>('clients');

export const getClients = createSelector(
  getClientFeatureState,
  selectAllClients
);

export const getLoading = createSelector(
  getClientFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getClientFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getClientFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getClientFeatureState,
  state => state.updateCallState.loading
);
