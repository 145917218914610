import {Component, OnInit} from '@angular/core';
import {WealthboxService} from '../wealthbox.service';
import {WealthboxToolbarService} from './wealthbox-toolbar.service';
import DataSource from 'devextreme/data/data_source';
import NoteEditor from '../../../../../scripts/note-editor';
import {SidePanelService} from '../../../../layout/side-panel/side-panel.service';
import {mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

enum tabs {
  Activity,
  Notes,
  Contacts
}

@Component({
  selector: 'app-wealthbox-toolbar',
  templateUrl: './wealthbox-toolbar.component.html',
  styleUrls: ['./wealthbox-toolbar.component.scss']
})
export class WealthboxToolbarComponent implements OnInit {
  contactsDxListDataSource: DataSource;
  activityDatasource: DataSource;
  editor = NoteEditor;
  tabs = tabs;
  activeContact$ = this.sidePanelState.currentContact$.pipe(
    mergeMap(contact => {
      if (typeof contact === 'number') {
        return this.wealthboxService.getContactObservable(contact);
      } else {
        return of(contact);
      }
    })
  );

  constructor(
    private wealthboxService: WealthboxService,
    private wealthboxToolbarService: WealthboxToolbarService,
    public sidePanelState: SidePanelService,
  ) {
    this.contactsDxListDataSource = wealthboxToolbarService.getContactsDxListDataSource();
    this.activityDatasource = wealthboxToolbarService.getActivityDataSource();
  }

  ngOnInit() {
  }

  resetContactsListDxStorage() {
    this.contactsDxListDataSource = this.wealthboxToolbarService.getContactsDxListDataSource();
  }

  resetActivityListStorage() {
    this.activityDatasource = this.wealthboxToolbarService.getActivityDataSource();
  }

  changeTabsState(index) {
    this.sidePanelState.openTab('WEALTHBOX', tabs[index]);
  }

  close() {
    this.sidePanelState.closePanel();
  }
}
