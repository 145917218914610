import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {first, tap} from 'rxjs/operators';

export interface PdfClientInboxInput {
  client_id: number;
  message: string;
  report_ids: number[];
}

export interface InvoiceClientInboxInput {
  client_id: number;
  message: string;
  invoice_ids: number[];
}

@Injectable({
  providedIn: 'root'
})
export class ClientInboxService {

  printableEndpoint = `${environment.apiV2Url}/reporting/printable-reports/send`;
  invoiceEndpoint = `${environment.apiV2Url}/billing/invoices/send`;

  constructor(private http: HttpClient) { }

  sendClientPdf(input: PdfClientInboxInput): Observable<any> {
    return this.http.post(this.printableEndpoint, input);
  }

  sendClientInvoice(input: InvoiceClientInboxInput) {
    return this.http.post(this.invoiceEndpoint, input);
  }

  downloadAttachments(key: string) {
    const url = `${environment.apiV2Url}/client-inbox/download?access_key=${key}`;

    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/octet-stream');
    header = header.append('Accept', 'application/octet-stream');


    return this.http.get(url, {responseType: 'arraybuffer', headers: header}).pipe(
      tap((data: any) => {
        const blob = new Blob([new Uint8Array(data)], {type: 'application/zip'});
        const pdf = window.URL.createObjectURL(blob);

        // Trigger a browser download
        const a: any = document.createElement('a');
        a.style = 'display: none';
        a.href = pdf;

        a.download = 'client-attachments';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(pdf);
      })
    );

  }
}
