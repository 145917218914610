import { Injectable } from '@angular/core';
import DataGrid from 'devextreme/ui/data_grid';
import {dataGridOptions, popoverOptions, popupOptions} from '../../global-devextram-options';
import Popup from 'devextreme/ui/popup';
import Popover from 'devextreme/ui/popover';
import {Chart} from 'chart.js';
import tippy, {roundArrow} from 'tippy.js';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  constructor(
  ) { }

  /***
   *
   */
  init() {
    this.initializeDevExtreme();
    this.initializeChartjs();
    this.initializeTippy();
  }

  private initializeDevExtreme(): void {
    // Default DevExtreme Options
    DataGrid.defaultOptions(dataGridOptions);
    Popup.defaultOptions(popupOptions);
    Popover.defaultOptions(popoverOptions);
  }

  private initializeChartjs() {
    // Default Chart.js options
    Chart.defaults.global.tooltips.xPadding = 10;
    Chart.defaults.global.tooltips.yPadding = 10;
    Chart.defaults.global.tooltips.titleAlign = 'center';
    Chart.defaults.global.tooltips.bodyAlign = 'center';
    Chart.defaults.global.tooltips.backgroundColor = '#ffffff';
    Chart.defaults.global.tooltips.titleFontColor = '#474747';
    Chart.defaults.global.tooltips.bodyFontColor = '#636363';
    Chart.defaults.global.tooltips.bodyFontSize = 16;
    Chart.defaults.global.defaultFontFamily = 'Fira Sans, "Helvetica Neue", sans-serif;';

    Chart.defaults.LineWithLine = Chart.defaults.line;
    Chart.controllers.LineWithLine = Chart.controllers.line.extend({
      draw: function(ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);
        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          const activePoint = this.chart.tooltip._active[0];
          const ctx = this.chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = this.chart.scales['y-axis-0'].top;
          const bottomY = this.chart.scales['y-axis-0'].bottom;

          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#07C';
          ctx.stroke();
          ctx.restore();
        }
      }
    });
  }

  /***
   * Tippy is used for our tooltips.
   * TODO: See if we can remove references in sub components for tippy setup
   * @private
   */
  private initializeTippy() {
    tippy.setDefaultProps({
      allowHTML: true,
      content: 'Content',
      theme: 'material',
      animation: 'shift-away',
      placement: 'right',
      arrow: roundArrow
    });
  }




}
