import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === true) {
      return 'yes';
    } else if (input === false) {
      return 'no';
    } else {
      return '';
    }
  }
}
