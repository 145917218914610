import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './household.reducer';
import {EffectsModule} from '@ngrx/effects';
import {HouseholdEffects} from './household.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('households', reducer),
    EffectsModule.forFeature(
      [ HouseholdEffects ]
    ),
  ]
})
export class HouseholdStoreModule { }

