import {Component, Input, OnInit} from '@angular/core';
import {UserProfileService} from '../../services/auth/user-profile.service';

export interface BreadcrumbLink {
  url?: string;
  name: string;
}

@Component({
  selector: 'app-breadcrumbs',
  template: `<ul class="breadcrumbs">
    <li *ngIf="isFirmUser()">
      <a [routerLink]="['/dashboard']">Home</a> /
    </li>

    <li *ngIf="isEndClient()">
      <a [routerLink]="['/main']">Home</a> /
    </li>
    
    <li *ngFor="let link of links; let last = last">
      <a *ngIf="link.url" [routerLink]="[link.url]">{{link.name}}</a>
      <span *ngIf="!link.url">{{link.name}}</span>
      <span *ngIf="!last">/</span>
    </li>
      
    <li *ngIf="current"> / {{current}}</li>
  </ul>`,
  styles: [`span {margin: 0 6px;}`],
})
export class BreadcrumbsComponent implements OnInit {
  isFirmUser: () => boolean;
  isEndClient: () => boolean;

  @Input() links: BreadcrumbLink[];
  @Input() current: string; // needed to bind entity name and reflect it changes

  constructor(private userProfile: UserProfileService) {
    this.isFirmUser = userProfile.isFirmUser.bind(userProfile);
    this.isEndClient = userProfile.isEndClientUser.bind(userProfile);
  }

  ngOnInit() {
  }

}
