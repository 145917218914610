import {RoleGuardService} from '../../shared/services/auth/role-guard.service';
import {WealthboxSettingsComponent} from './wealthbox/wealthbox-settings/wealthbox-settings.component';
import {Route} from '@angular/router';
import {IntegrationsListComponent} from './integrations-list/integrations-list.component';
import {PortformerSettingsComponent} from './portformer/portformer-settings/portformer-settings.component';
import {StratifiComponent} from './stratifi/stratifi.component';
import {OauthLandingComponent} from './oauth-landing/oauth-landing.component';

export const INTEGRATIONS_ROUTES: Route[] = [
  {
    path: '',
    component: IntegrationsListComponent,
    canActivate: [RoleGuardService],
    data: {
      firmUserRequired: true
    }
  },
  {
    path: 'stratifi',
    component: StratifiComponent,
    canActivate: [RoleGuardService],
    data: {
      firmUserRequired: true
    }
  },
  {
    path: 'oauth',
    component: OauthLandingComponent,
    canActivate: [RoleGuardService],
    data: {
      firmUserRequired: true
    }
  },
  {
    path: 'wealthbox',
    component: WealthboxSettingsComponent,
    canActivate: [RoleGuardService],
    data: {
      firmUserRequired: true
    }
  },
  {
    path: 'portformer',
    component: PortformerSettingsComponent,
    canActivate: [RoleGuardService],
  }
];
