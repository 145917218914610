import {Action, createReducer, on} from '@ngrx/store';
import * as securityActions from './securities.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {CallState} from '../../state/types';
import {InitialCallState} from '../../state/helpings/initial-call-state';
import {Security, TopHoldings} from '../security';

export interface SecuritiesState extends EntityState<Security> {
  callState: CallState;
  fetchByIdCallState: CallState;
  managedIds: number[];
  fetchManagedCallState: CallState;
}

const adapter: EntityAdapter<Security> = createEntityAdapter<Security>();

const initialState: SecuritiesState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  fetchByIdCallState: {...new InitialCallState()},
  managedIds: [],
  fetchManagedCallState: {...new InitialCallState()},
});


const securitiesReducer = createReducer(
  initialState,
  on(securityActions.Load, state => {
    const callState = {...state.callState, loading: true};
    return {...state, callState};
  }),
  on(securityActions.LoadSuccess, (state, {securities}) => {
    const callState = {loading: false, loaded: true, error: null};
    return {...adapter.setAll(securities, state), callState};
  }),
  on(securityActions.FetchById, state => {
    const fetchByIdCallState = {...state.fetchByIdCallState, loading: true};
    return {...state, fetchByIdCallState};
  }),
  on(securityActions.FetchByIdSuccess, (state, {securities}) => {
    const fetchByIdCallState = {loading: false, loaded: true, error: null};
    return {...adapter.setAll(securities, state), fetchByIdCallState};
  }),
  on(securityActions.LoadManagedIds, state => {
    const fetchManagedCallState = {...state.fetchManagedCallState, loading: true};
    return {...state, fetchManagedCallState};
  }),
  on(securityActions.LoadManagedIdsSuccess, (state, {securityIds}) => {
    const fetchManagedCallState = {loading: false, loaded: true, error: null};
    return {...state, managedIds: securityIds, fetchManagedCallState};
  })
);

export function reducer(state: SecuritiesState | undefined, action: Action) {
  return securitiesReducer(state, action);
}

const {
  selectAll,
  selectEntities
} = adapter.getSelectors();

export const selectAllSecurities = selectAll;
export const selectSecurities = selectEntities;
