<div class="row" *ngIf="!currentIntegration">
  <div class="col s12">
    <div class="card">
      <div class="title">
        <div class="pull-left">
          <h4>
            Wealthbox Integration
          </h4>
        </div>
        <div class="pull-right" *ngIf="wealthBoxContact">
          <div class="btn-group">
            <button class="btn btn-block btn-default"
                    (click)="removeIntegration()"
                    matTooltip="Remove Integration"
                    type="button">
              <i *ngIf="!loadingRemoveContact" class="fa fa-trash"></i>
              <dx-load-indicator class='button-indicator'
                                 [visible]="loadingRemoveContact">
              </dx-load-indicator>
            </button>
          </div>
        </div>
      </div>
      <div class="content" id="wealthboxContactDetails">
        <div *ngIf="!currentIntegration">
          <p>Select Wealthbox contact to be associated with "{{household.name}}" household</p>
          <form [formGroup]="wealthboxForm" (ngSubmit)="integrate()">
            <dx-lookup
              class="mb-20"
              height="100%"
              name="contact"
              [dataSource]="contactsDataSource"
              [grouped]="true"
              [showPopupTitle]="false"
              [closeOnOutsideClick]="true"
              formControlName="contact"
              displayExpr="name">
            </dx-lookup>

            <div class="text-center">
              <button [disabled]="wealthboxForm.status === 'INVALID'" class="btn btn-primary" type="submit">
                <dx-load-indicator class='button-indicator'
                                   [visible]="loadingAddContact">
                </dx-load-indicator>
                Integrate
              </button>
            </div>
          </form>
        </div>

        <div *ngIf="wealthBoxContact">
          <ul>
            <li><b>Name: </b> {{wealthBoxContact.name}}</li>
            <li><b>Type: </b> {{wealthBoxContact.type}}</li>
            <li><b>Created Date: </b> {{wealthBoxContact.created_at}}</li>
          </ul>
        </div>

        <div class="alert orange white-text" *ngIf="wealthBoxContactError">
          We are not able to link these households. Please ensure you have a valid API token entered in the <a
          routerLink="/settings/integrations/wealthbox">Settings page</a>. Feel free to contact <a href="mailto:support@bridgeft.com">support@bridgeft.com</a> if you continue to have errors
        </div>

      </div>
    </div>
  </div>
</div>



<dx-load-panel
    [position]="{ of: '#sidePanelContainer' }"
    [(visible)]="loadingWealthboxContact"
    [showIndicator]="true"
    [showPane]="true"
    [closeOnOutsideClick]="false">
</dx-load-panel>
