import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Client} from './clients.types';
import {combineLatest, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {getClients, getLoaded, getLoading, State} from './state';
import {select, Store} from '@ngrx/store';
import {LoadClients} from './state/client.actions';

@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {

  endpoint = `${environment.apiV2Url}/org/clients`;

  constructor(private http: HttpClient,
              private store: Store<State>) { }

  clients$ = combineLatest([
    this.store.pipe(select(getClients)),
    this.store.pipe(select(getLoading)),
    this.store.pipe(select(getLoaded)),
  ]).pipe(
    map(([clients, loading, loaded]) => {
      if (!loaded && !loading) {
        this.store.dispatch(LoadClients());
      }

      return clients;
    })
  );

  getClients(): Observable<Client[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: Client[]}) => resp.data)
    );
  }

  updateClient(client: Client): Observable<Client> {
    const url = `${this.endpoint}/${client.id}`;

    return this.http.put<Client>(url, client);
  }

  updateClients(clients: Client[]): Observable<Client[]> {
    return this.http.put<Client[]>(this.endpoint, clients);
  }

  createClient(client: Client): Observable<Client> {
    return this.http.post<Client>(this.endpoint, client);
  }

  removeClient(client: Client): Observable<any> {
    const url = `${this.endpoint}/${client.id}`;

    return this.http.delete(url);
  }

  removeClients(clients: Client[]): Observable<any> {
    const ids = clients.map(c => c.id);

    const data = {
      ids
    };

    const options = { body: data };

    // @ts-ignore
    return this.http.delete(this.endpoint, options);
  }
}
