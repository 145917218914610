<div style=" height: 100%; width: 100%; background-color: #f9f9f9">
  <mat-card>
      <mat-card-title-group>
        <mat-card-title>
          {{ contact.name }}
        </mat-card-title>
        <mat-card-subtitle>
          <div *ngIf="contact.job_title && contact.company_name">
            {{ contact.job_title}} at {{contact.company_name}}
          </div>
        </mat-card-subtitle>
        <div class="right">
          <button mat-icon-button matTooltip="Close" (click)="sidePanelState.openTab('WEALTHBOX', 'Contacts')"><mat-icon>close</mat-icon></button>
        </div>
      </mat-card-title-group>



    <mat-card-content>
      <mat-divider></mat-divider>  <br />
      <p>
        <i class="fa fa-phone"></i> &nbsp; {{phoneNumber}}
      </p>

      <p>
        <i class="fa fa-envelope"></i> &nbsp; <a [href]="'mailto:'+email">{{email}}</a>
      </p>

      <p></p>
    </mat-card-content>
  </mat-card>
  <br />

  <dx-list
    class="toolbar-dx-full-list dx-disable-selection"
    [dataSource]="activityDatasource"
    [viewportOffset]="0"
    appFillHeight
    minHeight="0"
    [activeStateEnabled]="false"
    [focusStateEnabled]="false"
    [hoverStateEnabled]="false"
    selectionMode="none"
    [searchEnabled]="false">
  >
    <div *dxTemplate="let data of 'item'">
      <app-atlas-activity-card>
        <app-atlas-activity-card-header>
          <img mat-card-avatar [src]="data.icon" style="height: calc(100% - 2px); width: auto">
        </app-atlas-activity-card-header>
        <app-atlas-activity-card-content *ngIf="data.body">

          <div [innerHTML]="data.header"></div>
          <app-atlas-activity-card-details>
            <div [innerHTML]="data.body"></div>
          </app-atlas-activity-card-details>
        </app-atlas-activity-card-content>
      </app-atlas-activity-card>
    </div>
  </dx-list>

</div>
