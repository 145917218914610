import {Component, Input, OnInit} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import {Household} from '../../../../households/household';
import {ContactIntegrationRelationship, WealthboxContact} from '../type-classes/wealthbox-contact';
import {WealthboxService} from '../wealthbox.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../../shared/services/ui/notification.service';

@Component({
  selector: 'app-wealthbox-household-details',
  templateUrl: './wealthbox-contact-details.component.html',
})
export class WealthboxContactDetailsComponent implements OnInit {
  contactsDataSource: { store: CustomStore };

  @Input() household: Household;
  wealthBoxContact: WealthboxContact;
  currentIntegration: ContactIntegrationRelationship;
  loadingWealthboxContact = false;
  wealthboxForm: UntypedFormGroup;
  contactControl: AbstractControl;
  wealthboxFormSubmitAttempt = false;
  loadingAddContact = false;
  loadingRemoveContact = false;
  wealthBoxContactError = false;

  constructor(
    private wealthboxService: WealthboxService,
    private notification: NotificationService
  ) {
    this.contactsDataSource = {
      store: new CustomStore(this.wealthboxService.contactsLookupStoreConfig),
    };

    this.wealthboxForm = new UntypedFormGroup({
      contact: new UntypedFormControl(null, Validators.required)
    });

    this.contactControl = this.wealthboxForm.controls.contact;
  }

  ngOnInit() {
    this.currentIntegration = this.wealthboxService.integrations.find(i => i.householdId === this.household.id);
  }

  integrate() {
    this.wealthboxFormSubmitAttempt = true;

    if (this.wealthboxForm.status === 'VALID') {
      this.loadingAddContact = true;
      this.wealthboxService.addIntegration(this.household, this.contactControl.value)
        .then((data) => {
          // this.currentIntegration = data;
          this.wealthBoxContact = this.contactControl.value;
          this.notification.showSuccessNotification('Contact was successfully integrated');
        })
        .catch(() => {
          this.notification.showErrorNotification('There is a problem occurs while removing your integrated contact.');
        })
        .finally(() => {
          this.loadingAddContact = false;
        });
    }
  }

  removeIntegration() {
    this.notification.displayConfirm(`You are deleting ${this.wealthBoxContact.name} contact`).toPromise()
      .then(data => {
        if (data) {
          this.loadingRemoveContact = true;
          this.wealthboxService.removeIntegrations(this.currentIntegration.id)
            .then(() => {
              this.currentIntegration = null;
              this.wealthBoxContact = null;
              this.wealthboxForm.reset();
            })
            .catch(() => {
              this.notification.showErrorNotification('There is a problem occurs while removing your integrated contact.');
            })
            .finally(() => {
              this.loadingRemoveContact = false;
            });
        }
      });
  }
}
