import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../accounts/account.service';
import {BenchmarkService} from '../../benchmark/benchmark.service';
import {FeeStructuresService} from '../../settings/billing/fee-structures/fee-structures.service';
import {ProfileService} from '../../shared/services/profile.service';
import {Account} from '../../accounts/account';
import {Household} from '../../households/household';
import {Benchmark} from '../../benchmark/benchmark';
import {FeeStructure} from '../../billing/billing';
import {UserProfile} from '../../shared/services/auth/user-profile';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
declare const $: any;
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromFeeStructure from '../../settings/billing/fee-structures/state';
import {FeeStructuresResolver} from '../../settings/billing/fee-structures/fee-structures.resolve';
import {SearchService} from './search.service';
import {MatDialog} from '@angular/material/dialog';
import {FeeStructuresCreateEditComponent} from '../../settings/billing/fee-structures/fee-structures-create-edit/fee-structures-create-edit.component';
import {BenchmarksCreateEditComponent} from '../../settings/reporting/benchmarks-create-edit/benchmarks-create-edit.component';
import {IdcService} from '../../idc/index.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {

  accounts$: Observable<Account[]>;
  households$: Observable<Household[]>;
  benchmarks$: Observable<Benchmark[]>;
  feeStructuresFiltered$: Observable<FeeStructure[]>;
  profiles$: Observable<UserProfile[]>;

  searchTerms = new Subject<string>();

  loadingEntities: boolean;
  feeStructuresLoading$: Observable<boolean>;
  hasFeeStructures$: Observable<boolean>;
  navigationStarting = false;

  constructor(private accountService: AccountService,
              private benchmarkService: BenchmarkService,
              private feeStructureService: FeeStructuresService,
              private profileService: ProfileService,
              private router: Router,
              private searchService: SearchService,
              private feeStructuresResolver: FeeStructuresResolver,
              private store: Store<fromFeeStructure.State>,
              private indexService: IdcService,
              private dialog: MatDialog
              ) {
    this.loadingEntities = true;

  }

  exitSearch() {
    this.searchService.closeSearch();
  }

  ngOnInit() {
    this.feeStructuresFiltered$ = combineLatest([
      this.feeStructureService.feeStructures$,
      this.searchTerms,
    ])
      .pipe(
        delay(0), // temporary solution until get rid of AfterViewInit
        map(([feeStructures, searchTerm]) => {
          return feeStructures && feeStructures.filter(
            (feeStructure: FeeStructure) => feeStructure.name.toLowerCase().includes(searchTerm)
          );
        }),
      );

    this.feeStructuresLoading$ = this.store.pipe(select(fromFeeStructure.getUpdateLoading));
    this.hasFeeStructures$ = this.feeStructuresFiltered$.pipe(
      map(f => f.length > 0)
    );


    this.accounts$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchAccounts(term))
    );

    this.households$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchHouseholds(term))
    );

    this.benchmarks$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchBenchmarks(term))
    );

    this.profiles$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchProfiles(term))
    );
  }

  ngAfterViewInit() {
    // Wait for households and accounts
    const promises = [
      this.accountService.initialize()
    ];

    Promise.all(promises).then(() => {
      this.searchTerms.next(' ');
    }).finally(() => {
      this.loadingEntities = false;
    });

    this.searchTerms.next('');
  }

  closeSearch() {
    // @ts-ignore
    $('.search-bar:eq(0)').MDLayer('hide');
  }

  search(searchTerm: string) {
    this.searchTerms.next(searchTerm);
  }

  navigateTo(path: string, id: number) {
    this.exitSearch();
    this.router.navigate([path, id]);
    this.searchTerms.next('');
  }

  searchAccounts(searchTerm): Observable<Account[]> {
    searchTerm = searchTerm.trim().toLowerCase();

    return this.accountService.accounts$
      .pipe(
        map(accounts => {
          return accounts.filter((account: Account) => {
            return account.display_name.toLowerCase().includes(searchTerm) || account.number.includes(searchTerm);
          });
        })
      );
  }

  searchHouseholds(searchTerm: string): Observable<Household[]> {
    searchTerm = searchTerm.trim().toLowerCase();

    return this.accountService.households$
      .pipe(
        map(households => {
          return households.filter((household: Household) => household.name.toLowerCase().includes(searchTerm));
        })
      );
  }

  searchBenchmarks(searchTerm: string) {
    searchTerm = searchTerm.trim().toLowerCase();

    if (searchTerm === '') {
      return of(this.benchmarkService.benchmarks);
    }

    const searchResults = this.benchmarkService.benchmarks
      .filter((benchmark: Benchmark) => benchmark.name.toLowerCase().includes(searchTerm));

    return of(searchResults);
  }

  searchProfiles(searchTerm: string) {
    searchTerm = searchTerm.trim().toLowerCase();

    if (searchTerm === '') {
      return of(this.profileService.profiles);
    }

    const searchResults = this.profileService.profiles
      .filter((profile: UserProfile) => profile.full_name.toLowerCase().includes(searchTerm));

    return of(searchResults);
  }

  navigateToFeeStructure(id: number) {
    this.exitSearch();
    this.router.navigateByUrl('/settings/billing/fee-structures');
    this.dialog.open(FeeStructuresCreateEditComponent, {data: {id}});
    this.searchTerms.next('');
  }

  navigateToBenchmark(benchmark: Benchmark) {
    this.navigationStarting = true;
    this.indexService.getIndexes()
      .then(() => {
        this.exitSearch();
        this.router.navigateByUrl('/settings/reporting/benchmarks');
        this.dialog.open(BenchmarksCreateEditComponent, {data: {benchmark}});
        this.searchTerms.next('');
      });
  }
}
