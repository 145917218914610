import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobsComponent } from './jobs/jobs.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {JOBS_ROUTES} from './jobs.routes';
import {JobsResolver} from '../app.resolve';

@NgModule({
  declarations: [JobsComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(JOBS_ROUTES)
  ],
  exports: [
    JobsComponent
  ],
  providers: [
    JobsResolver
  ]
})
export class JobsModule { }
