import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SidePanelService} from './side-panel.service';
import {WealthboxService} from '../../settings/integrations/wealthbox/wealthbox.service';
import {CognitoService} from '../../shared/services/auth/cognito.service';
import {SidePanelSection} from '../state';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit {

  constructor(private router: Router,
              public sidePanel: SidePanelService,
              private wealthboxService: WealthboxService,
              private cognitoService: CognitoService) { }

  isFirmUser: boolean;

  ngOnInit() {
    this.isFirmUser = this.cognitoService.authProfile.is_firm_user;
  }
}
