import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'financialStandard'
})
export class FinancialStandardPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value !== 0 && !value) {
      return null;
    }

    if (args[0] === 'percent') {
      const formatted = (Math.abs(value) * 100).toFixed(2);
      return value < 0 ? `(${formatted}%)` : `${formatted}%`;
    } else if (args[0] === 'currency') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return value < 0 ? `(${formatter.format(Math.abs(value))})` : `${formatter.format(value)}`;
    } else if (args[0] === 'fixedPoint') {
      return value < 0 ? `(${value.toFixed(2)})` : `${value.toFixed(2)}`;
    } else if (args[0] === 'altPercent') {
      const formatted = Math.abs(value).toFixed(2);
      return value < 0 ? `(${formatted}%)` : `${formatted}%`;
    }
  }
}
