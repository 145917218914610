import {Component, OnInit, ViewChild} from '@angular/core';
import {WealthboxContact} from '../type-classes/wealthbox-contact';
import {WealthboxService} from '../wealthbox.service';
import NoteEditor from '../../../../../scripts/note-editor';
import {ContactLink, Note} from '../type-classes/wealthbox-note';
import {Subject} from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import {WealthboxToolbarService} from '../wealthbox-toolbar/wealthbox-toolbar.service';

@Component({
  selector: 'app-wealthbox-notes-list',
  templateUrl: './wealthbox-notes-list.component.html',
  styleUrls: ['./wealthbox-notes-list.component.scss']
})
export class WealthboxNotesListComponent implements OnInit {
  editor = NoteEditor;
  newNote = new Note();
  creating = false;
  recalculateListHeightSubject$: Subject<void> = new Subject<void>();
  notesDxListDataSource: DataSource;

  editorConfig = this.wealthboxService.noteEditorConfig;

  constructor(
    private wealthboxService: WealthboxService,
    private wealthboxToolbarService: WealthboxToolbarService) { }

  ngOnInit() {
    this.recalculateListHeightSubject$.next();
    this.notesDxListDataSource = this.wealthboxToolbarService.getNotesDxListDataSource();
  }

  addNote() {
    // Add associations to the note
    this.addContactAssociations();

    this.notesDxListDataSource.store()
      .insert(this.newNote)
      .then(() => {
        this.newNote = new Note();
        this.notesDxListDataSource.load();
      });
  }

  toggleAddNoteForm(state) {
    this.creating = state;
    this.recalculateListHeightSubject$.next();
  }

  // Parse note.content to get the id of any associations
  addContactAssociations() {
    const noteContent = this.newNote.content;

    const mentionRegex = /contactmentionid="\d*-[A-Za-z\s]*/g; // Get substrings that match contactmentionid="[0-9]*-[A-Z]*"
    const matchRegex = /\d*-[A-Za-z\s]*/;  // Strip just the "[0-9]*-[A-Z]*"
    const contactMentions = noteContent.match(mentionRegex);

    if (!contactMentions) {
      return;
    }

    contactMentions.forEach(mention => {
      const association = mention.match(matchRegex)[0].split('-'); // strip the id and the contact name

      const newAssociation = {
        id: parseInt(association[0]),
        name: association[1],
        type: 'Contact'
      }; // create new association

      this.newNote.linked_to.push(newAssociation);
    });
  }
}
