import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingDisplayPaymentSource'
})
export class BillingDisplayPaymentSourcePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'C') {
      return 'Custodian billed';
    } else if (input === 'D') {
      return 'Directly billed';
    }

    return '';
  }

}
