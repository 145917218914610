import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable, of, Subject} from 'rxjs';
import {PortformerConfig} from '../portformer';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {PORTFORMER_CONFIG, PORTFORMER_DIR_NAME} from '../portformer.constants';
import {CognitoService} from '../../../../shared/services/auth/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class PortformerSettingsService {

  private defaultConfig: PortformerConfig = {
    enabled: true,
    apiKey: ''
  };

  private configSubject$: Subject<PortformerConfig> = new BehaviorSubject(null);
  config$: Observable<PortformerConfig> = this.configSubject$.pipe(
    tap(config => {
      if (!config) {
        this.loadPortformerConfig();
      }
    }),
    filter(config => !!config)
  );

  constructor(private cognitoService: CognitoService) {}

  updatePortformerConfig(config: PortformerConfig) {
    // return from(this.s3ObjectService.upload(config, PORTFORMER_DIR_NAME, PORTFORMER_CONFIG))
    //   .subscribe(() => this.configSubject$.next(config));
  }

  private getPortformerConfig() {
    // return this.s3ObjectService.getObject(PORTFORMER_DIR_NAME, PORTFORMER_CONFIG);
  }

  private loadPortformerConfig() {
    // If hashkey login, just return the default portformer config
    if (this.cognitoService.hashkeyCredentials) {
      this.configSubject$.next(this.defaultConfig);
      return of(this.defaultConfig);
    }

    return of(this.defaultConfig);
  }
}
