import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {CognitoService} from './auth/cognito.service';
import {WebSocket} from '../type_classes/websocket';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  websocketUrl: string;
  websocket$: any;

  constructor(private cognitoService: CognitoService) {
    this.websocketUrl = environment.websocketUrl;
  }

  // Opens websocket connection
  openWebSocketConnection(handleJobProcess: any) {
    this.websocket$ = webSocket( this.websocketUrl + `?queryauth=${this.cognitoService.authToken}`);

    this.websocket$.subscribe(
      (msg: WebSocket) => handleJobProcess(msg),
      (err) => {},
      () => {
        // Reconnect, current api gateway automatically closes after a certain period of time, when this happens, reconnect.
        this.openWebSocketConnection(handleJobProcess);
      }
    );
  }
}
