<div class="content-wrap" *ngIf="!loading">
  <mat-card>
    <mat-card-title>Wealthbox API Key</mat-card-title>

    <mat-card-content>
      <div style="padding: 20px;">
        <div style="padding: 20px 0;">
          Enter your Wealthbox CRM API access token here. This is used to verify your identity and enable the data flow. You can get this token by going to the "Personal Settings" page in Wealthbox. See
          <a href="https://help.wealthbox.com/en/articles/1670054-how-do-i-create-an-api-access-token" target="_blank">this link</a> for help.
        </div>

        <div fxLayout="row"
             fxLayout.lt-sm="column"
             gdGap="30px"
             gdColumns.gt-xs="calc(80% - 10px) calc(20% - 10px)"
             [formGroup]="wealthboxConfigForm">
          <div>
            <mat-form-field appearance="outline" style="width: 100%">
              <input matInput placeholder="Enter your API Key" formControlName="apiKey">
            </mat-form-field>
          </div>

          <div>
            <app-atlas-submit-button *ngIf="!this.creating"
                                     [onClick]="remove"
                                     [disabled]="this.saving"
            >
              Remove
            </app-atlas-submit-button>
          </div>

        </div>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <app-atlas-submit-button [saving]="saving"
                               [disabled]="saving || !wealthboxConfigForm.valid"
                               [onClick]="save">
        Save
      </app-atlas-submit-button>
    </mat-card-actions>
  </mat-card>
</div>

<!--<app-wealthbox-contacts-mapping *ngIf="hasWealthboxApiKey"></app-wealthbox-contacts-mapping>-->

<!--<div class="alert orange white-text" *ngIf="wealthboxService.fetchContactsError">-->
<!--  We are not able to load integrations. Please ensure you have a valid API token entered.-->
<!--  Feel free to contact <a href="mailto:support@bridgeft.com"></a> if you continue to have errors-->
<!--</div>-->
