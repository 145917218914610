import {createAction, props} from '@ngrx/store';
import {Security} from '../security';

export const Load = createAction('[Securities] Load securities');
export const LoadSuccess = createAction('[Securities] Load securities success', props<{securities: Security[]}>());
export const LoadFail = createAction('[Securities] Load securities fail', props<{error: string}>());
export const FetchById = createAction('[Securities] fetch by ids securities', props<{securityIds: number[]}>());
export const FetchByIdSuccess = createAction('[Securities] fetch by ids securities success', props<{securities: Security[]}>());
export const LoadManagedIds = createAction('[Securities] Load managed ids');
export const LoadManagedIdsSuccess = createAction('[Securities] Load managed ids success', props<{securityIds: number[]}>());
export const LoadManagedIdsFail = createAction('[Securities] Load managed ids success', props<{error: string}>());
