export const COLLECTION_TYPES = [
  {
    id: 'R',
    name: 'Arrears',
  },
  {
    id: 'D',
    name: 'Advance',
  },
  {
    id: 'P',
    name: 'Advance with Proration',
  }
];

export const CALCULATION_TYPES = [
  {
    id: 'R',
    name: 'Flat Rate',
  },
  {
    id: 'A',
    name: 'Flat Account Fee',
  },
  {
    id: 'G',
    name: 'Flat Group Fee',
  },
  {
    id: 'D',
    name: 'Drop Through',
  },
  {
    id: 'T',
    name: 'Tiered',
  },
  {
    id: 'F',
    name: 'Favor',
  }
];

export const BALANCE_TYPES = [
  {
    id: 'E',
    name: 'Ending Period Balance'
  },
  {
    id: 'A',
    name: 'Average Daily Balance'
  },
  {
    id: 'P',
    name: 'Ending Period Balance w/ Adjustment for Flows'
  },
  {
    id: 'C',
    name: 'Ending Period Balance w/ Adjustment for Flows, Less Cash'
  }
];

export const FREQUENCYS = [
  {
    id: 'M',
    name: 'Monthly'
  },
  {
    id: 'Q',
    name: 'Quarterly'
  },
  {
    id: 'S',
    name: 'Semi-Annual'
  },
  {
    id: 'A',
    name: 'Annual'
  },
];

export const QUARTER_CYCLES = [
  {
    id: 1,
    name: 'Jan - Apr - Jul - Oct'
  },
  {
    id: 2,
    name: 'Feb - May - Aug - Nov'
  },
  {
    id: 3,
    name: 'Mar - Jun - Sep - Dec'
  }
];

export const SEMI_ANNUAL_CYCLES = [
  {
    id: 1,
    name: 'Jan - Jul'
  },
  {
    id: 2,
    name: 'Feb - Aug'
  },
  {
    id: 3,
    name: 'Mar - Sep'
  },
  {
    id: 4,
    name: 'Apr - Oct'
  },
  {
    id: 5,
    name: 'May - Nov'
  },
  {
    id: 6,
    name: 'June - Dec'
  }
];

export const ANNUAL_CYCLES = [
  {
    id: 1,
    name: 'Jan'
  },
  {
    id: 2,
    name: 'Feb'
  },
  {
    id: 3,
    name: 'Mar'
  },
  {
    id: 4,
    name: 'Apr'
  },
  {
    id: 5,
    name: 'May'
  },
  {
    id: 6,
    name: 'June'
  },
  {
    id: 7,
    name: 'Jul'
  },
  {
    id: 8,
    name: 'Aug'
  },
  {
    id: 9,
    name: 'Sep'
  },
  {
    id: 10,
    name: 'Oct'
  },
  {
    id: 11,
    name: 'Nov'
  },
  {
    id: 12,
    name: 'Dec'
  }
];

export const ADJUSTMENT_LEVELS = [
  {
    id: 'f',
    name: 'Firm'
  },
  {
    id: 'a',
    name: 'Account'
  }
];

export const EXCLUDE_INCLUDE = [
  {
    id: 'i',
    name: 'Inclusion',
  },
  {
    id: 'e',
    name: 'Exclusion',
  }
];

export const BILLING_FREQUENCY_WITH_CYCLE = [
  {
    id: 1,
    name: 'Jan - Apr - Jul - Oct',
    frequency: 'Q'
  },
  {
    id: 2,
    name: 'Feb - May - Aug - Nov',
    frequency: 'Q'
  },
  {
    id: 3,
    name: 'Mar - Jun - Sep - Dec',
    frequency: 'Q'
  },
  {
    id: 4,
    name: 'Jan - Jul',
    frequency: 'S'
  },
  {
    id: 5,
    name: 'Feb - Aug',
    frequency: 'S'
  },
  {
    id: 6,
    name: 'Mar - Sep',
    frequency: 'S'
  },
  {
    id: 7,
    name: 'Apr - Oct',
    frequency: 'S'
  },
  {
    id: 8,
    name: 'May - Nov',
    frequency: 'S'
  },
  {
    id: 9,
    name: 'June - Dec',
    frequency: 'S'
  },
  {
    id: 10,
    name: 'Jan',
    frequency: 'A'
  },
  {
    id: 11,
    name: 'Feb',
    frequency: 'A'
  },
  {
    id: 12,
    name: 'Mar',
    frequency: 'A'
  },
  {
    id: 13,
    name: 'Apr',
    frequency: 'A'
  },
  {
    id: 14,
    name: 'May',
    frequency: 'A'
  },
  {
    id: 15,
    name: 'June',
    frequency: 'A'
  },
  {
    id: 16,
    name: 'Jul',
    frequency: 'A'
  },
  {
    id: 17,
    name: 'Aug',
    frequency: 'A'
  },
  {
    id: 18,
    name: 'Sep',
    frequency: 'A'
  },
  {
    id: 19,
    name: 'Oct',
    frequency: 'A'
  },
  {
    id: 20,
    name: 'Nov',
    frequency: 'A'
  },
  {
    id: 21,
    name: 'Dec',
    frequency: 'A'
  }
];
