import { Injectable } from '@angular/core';
import {BillingReport} from '../shared/type_classes/billing-report';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import _keyBy from 'lodash-es/keyBy';
import _has from 'lodash-es/has';
import {UtilsService} from '../shared/services/utils.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChurnZeroService} from '../shared/services/churn-zero.service';

@Injectable({
  providedIn: 'root'
})
export class BillingReportService {
  endpoint: string;
  reports: BillingReport[];
  fetchedAll: boolean;
  private _detailedReportsByID: any;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private churnZeroService: ChurnZeroService,
  ) {
    this.endpoint = `${environment.apiV2Url}/billing/reports`;
    this.fetchedAll = false;
    this._detailedReportsByID = {};
  }

  get reportsByID() {
    return _keyBy(this.reports, 'id');
  }

  getAllReports() {
    const pageSize = 1000;
    // if (this.reports && this.reports.length > 0) {
    //   const page = Math.trunc(this.reports.length / pageSize) + 1;
    //   return this.utilsService.stepByStepLoading(this.endpoint, pageSize, page).then((resp: any) => {
    //     this.reports.push(...resp);
    //     return this.reports;
    //   });
    // }

    return this.utilsService.stepByStepLoading(this.endpoint, pageSize).then((resp) => {
      this.reports = resp as BillingReport[];
      this.fetchedAll = true;
      return this.reports;
    });
  }

  getReportDetails(id) {
    id = id.toString();

    if (_has(this._detailedReportsByID, id)) {
      return Promise.resolve(this._detailedReportsByID[id]);
    } else {
      const url = `${this.endpoint}/${id}`;

      return this.http.get(url).toPromise().then((resp: BillingReport) => {
        this._detailedReportsByID[id] = resp;
        return resp;
      });
    }
  }

  deleteReport(report: BillingReport) {
    if (!report.id) {
      return;
    }

    const url = `${this.endpoint}/${report.id}`;

    return this.http.delete(url).toPromise().then(() => {
      if (this.reports) {
        this.reports = this.reports.filter((r) => r.id !== report.id);
      }
    });
  }

  downloadBillingReport(report: BillingReport) {
    const url = `${environment.apiV2Url}/billing/fee-upload-files/download/${report.fee_upload_file_id}`;

    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/octet-stream');
    header = header.append('Accept', 'application/octet-stream');

    return this.http.post(url, {}, {responseType: 'arraybuffer', headers:  header}).subscribe((data: any) => {
      this.downloadFile(data, report);
      this.churnZeroService.trackEvent('Fee Upload File', 'Fee upload file downloaded');
    });
  }

  private downloadFile(data: any, report: BillingReport) {
    const blob = new Blob([data], {type: 'application/zip'});
    const file = window.URL.createObjectURL(blob);

    // Trigger a browser download
    const a: any = document.createElement('a');
    a.style = 'display: none';
    a.href = file;
    a.download = `Fee-Upload-File-${report.billing_date}.zip`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(file);
  }

  getMostRecentBillingReport(): Observable<BillingReport> {
    const url = `${environment.apiV2Url}/billing/reports?pager.limit=1`;

    return this.http.get(url).pipe(
      map((resp: {data: BillingReport[]}) => {
        return resp.data[0];
      })
    );
  }
}
