import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor() { }

  clearAccountsSelectedRowKeys() {
    const layoutData = JSON.parse(localStorage.accounts);
    delete layoutData.selectedRowKeys;
    localStorage.setItem('accounts', JSON.stringify(layoutData));
  }

  clearSearchText(localStorageKey: string): void {
    if (localStorage[localStorageKey]) {
      const layoutData = JSON.parse(localStorage[localStorageKey]);
      delete layoutData.searchText;
      localStorage.setItem(localStorageKey, JSON.stringify(layoutData));
    }
  }
}
