<div class="activity-container" *ngIf="!loading && !error">

<!--  <ng-container *ngIf="portformerEnabled$ | async">-->
<!--    <app-atlas-recommendations [etfRecommendations]="etfRecommendations$ | async"-->
<!--                               [showError]="portformerLoadingError$ | async"></app-atlas-recommendations>-->
<!--  </ng-container>-->

  <!-- TODO: Add Jobs, PDF reports, Billing -->


  <app-jobs *ngIf="!loading"></app-jobs>



  <div *ngFor="let arr of data; index as index">
    <div *ngIf="arr.length < 3">
      <div *ngFor="let item of arr">
        <div class="billing-alerts" *ngIf="item.object === 'billing.report'">

          <div class="recent-activity-card"
               [ngStyle]="{'border-left-color': getCardColor(0)}">
            <i class="far fa-file-invoice-dollar"
               [ngStyle]="{'color': getCardColor(0)}"></i>

            <div class="content-container">
              <div class="content-header">
                <div>
                  Billing Report
                </div>

                <div class="date">
                  {{item.sort_date | date}}
                </div>
              </div>

              <div style="display: flex; flex-direction: column;">
                <div class="content">
                  <a [ngStyle]="{'color': getCardColor(0)}"
                     [routerLink]="['/revenue/billing/reports/detail', item.id]">
                    Billing report
                  </a>
                  has been generated.
                </div>

                <div class="activity-details">
                  <div class="activity-details__button--download left"
                       [ngStyle]="{'border-color': getCardColor(0), 'color': getCardColor(0)}"
                       (click)="downloadInvoices(item.invoice_ids)">
                    Download Invoices
                  </div>

                  <div class="activity-details__button--download right"
                       [ngStyle]="{'border-color': getCardColor(0), 'color': getCardColor(0)}"
                       (click)="downloadFeeUploadFiles(item)">
                    Download Fee Upload
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div *ngIf="item.object === 'reporting.printable'">
          <div class="recent-activity-card"
               [ngStyle]="{'border-left-color': getCardColor(1)}">
            <i class="far fa-file-pdf"
               [ngStyle]="{'color': getCardColor(1)}"></i>

            <div class="content-container">
              <div class="content-header">
                <div>
                  PDF Report
                </div>

                <div class="date">
                  {{ item.sort_date | date }}
                </div>
              </div>

              <div style="display: flex; flex-direction: column;">
                <div class="content">
                  A <span *ngIf="item.frequency === 'Q' else monthly">quarterly</span> <ng-template #monthly>monthly</ng-template> report for
                  <a [ngStyle]="{'color': getCardColor(1)}"
                     *ngIf="item.account_id else householdReport"
                     [routerLink]="['/clients/account', item.account_id]">
                    {{ item.account_name }}
                  </a>

                  <ng-template #householdReport>
                    <a [ngStyle]="{'color': getCardColor(1)}"
                       [routerLink]="['/clients/household', item.household_id]">
                      {{ item.household_name }}
                    </a>
                  </ng-template>
                  was generated.
                </div>

                <div class="activity-details">
                  <button class="activity-details__button--download pdf"
                          [ngStyle]="{'border-color': getCardColor(1), 'color': getCardColor(1)}"
                          (click)="downloadPDF(item)">
                    Download Report
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div *ngIf="item.object === 'account_management.account' && item.is_luca_migrated">

          <div class="recent-activity-card"
               [ngStyle]="{'border-left-color': getCardColor(2)}">
            <i class="far fa-user"
               [ngStyle]="{'color': getCardColor(2)}"></i>

            <div class="content-container">
              <div class="content-header">
                <div>
                  New Account
                </div>

                <div class="date">
                  {{ item.sort_date | date }}
                </div>
              </div>

              <div style="display: flex; flex-direction: column;">
                <div class="content">
                  Congratulations!
                  <a *ngIf="item.display_name"
                     [ngStyle]="{'color': getCardColor(2)}"
                     [routerLink]="['/clients/account', item.id]">{{ item.display_name }}</a>

                  <span *ngIf="!item.display_name">
                    New account <a [ngStyle]="{'border-left-color': getCardColor(2)}"
                                   [routerLink]="['/clients/account', item.id]">{{ item.display_number }}</a>
                  </span>

                  has successfully onboarded.
                </div>
              </div>
            </div>
          </div>
        </div>




        <div *ngIf="item.object === 'data.luca.transfer'">
          <div class="recent-activity-card"
               [ngStyle]="{'border-left-color': getCardColor(3)}">
            <i class="far fa-money-bill-wave"
               [ngStyle]="{'color': getCardColor(3)}"
               *ngIf="item.security_id === cashSecurityID"></i>

            <i class="far fa-university"
               [ngStyle]="{'color': getCardColor(3)}"
               *ngIf="item.security_id !== cashSecurityID"></i>

            <div class="content-container">
              <div class="content-header">
                <div>
                  {{ item.security_id === cashSecurityID ? 'Cash Flow' : 'Security Transfer' }}
                </div>

                <div class="date">
                  {{ item.sort_date | date }}
                </div>
              </div>

              <div style="display: flex; flex-direction: column;">
                <div class="content">
                  <div *ngIf="item.type === 'DEP'">

                    <span *ngIf="item.security_id === cashSecurityID else securityDepositDescription">
                      <a [ngStyle]="{'color': getCardColor(3)}"
                         [routerLink]="['/clients/account', item.account_id]">
                        {{ item.account_name ? item.account_name : 'Unnamed account' }}
                      </a> deposited

                      <span style="color: #000000; font-weight: bold;">
                        {{item.amount | currency }}
                      </span>
                    </span>

                    <ng-template #securityDepositDescription>
                      <a [ngStyle]="{'color': getCardColor(3)}"
                         [routerLink]="['/clients/account',item.account_id]">{{ item.account_name ? item.account_name : 'Unnamed account' }}</a> deposited
                      <span style="color: #000000; font-weight: bold;">
                        {{ item.amount | currency }}
                      </span> worth of securities.
                    </ng-template>
                  </div>

                  <div *ngIf="item.type ==='WITH'">

                    <!-- TODO: Remove hardcoded value -->
                    <span *ngIf="item.security_id === cashSecurityID else securityWithdrawalDescription">
                      <a [ngStyle]="{'color': getCardColor(3)}"
                         [routerLink]="['/clients/account', item.account_id]">{{ item.account_name ? item.account_name : 'Unnamed account'}}</a> withdrew

                      <span style="color: #000000; font-weight: bold;">
                        {{item.amount | currency }}
                      </span>
                    </span>

                    <ng-template #securityWithdrawalDescription>
                      <a [ngStyle]="{'color': getCardColor(3)}"
                         [routerLink]="['/clients/account',item.account_id]">{{ item.account_name ? item.account_name : 'Unnamed account'}}</a> withdrew
                      <span style="color: #000000; font-weight: bold;">
                        {{ item.amount | currency }}
                      </span> worth of securities.
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div *ngIf="arr.length >= 3">
      <div class="billing-alerts" *ngIf="arr[0].object === 'billing.report'">

        <div class="recent-activity-card"
             [ngStyle]="{'border-left-color': getCardColor(0)}">
          <i class="far fa-file-invoice-dollar"
             [ngStyle]="{'color': getCardColor(0)}"></i>

          <div class="content-container">
            <div class="content-header">
              <div>
                Billing Reports
              </div>

              <div class="date">
                {{arr[0].sort_date | date}}
              </div>
            </div>

            <div style="display: flex; flex-direction: column;">
              <div class="content">
                <a [ngStyle]="{'color': getCardColor(0)}"
                   [routerLink]="['/revenue/billing/reports']"> {{ arr.length }} billing reports</a>
                have been generated.
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="arr[0].object === 'reporting.printable'">

        <div class="recent-activity-card"
             [ngStyle]="{'border-left-color': getCardColor(1)}">
          <i class="far fa-file-pdf"
             [ngStyle]="{'color': getCardColor(1)}"></i>

          <div class="content-container">
            <div class="content-header">
              <div>
                PDF Reports
              </div>

              <div class="date">
                {{ arr[0].sort_date | date }}
              </div>
            </div>

            <div style="display: flex; flex-direction: column;">
              <div class="content">
                PDF reports have been generated for

                <span *ngFor="let acc of arr; let i = index">
                  <span *ngIf="i < 2">
                    <a [ngStyle]="{'color': getCardColor(1)}"
                       *ngIf="acc.account_id else householdReport" [routerLink]="['/clients/account', acc.account_id]">
                      {{ acc.account_name }}
                    </a>

                    <ng-template #householdReport>
                      <a [ngStyle]="{'color': getCardColor(1)}"
                         [routerLink]="['/clients/household', acc.household_id]">
                        {{ acc.household_name }}
                      </a>
                    </ng-template>,
                  </span>
                </span>
                and <span style="color: #000000; font-weight: bold;">{{ arr.length - 2 }} {{ arr.length - 2 === 1 ? 'other' : 'others' }}</span>.
              </div>

              <div class="activity-details">
                <button class="activity-details__button--download"
                        [ngStyle]="{'border-color': getCardColor(1), 'color': getCardColor(1)}"
                        [routerLink]="['/reporting/printable-reports']">
                  View All Reports
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="arr[0].object === 'account_management.account' && arr[0].is_luca_migrated">

        <div class="recent-activity-card account"
             [ngStyle]="{'border-left-color': getCardColor(2)}">
          <i class="far fa-users"
             [ngStyle]="{'color': getCardColor(2)}"></i>

          <div class="content-container">
            <div class="content-header">
              <div>
                New Accounts
              </div>

              <div class="date">
                {{ arr[0].sort_date | date }}
              </div>
            </div>

            <div style="display: flex; flex-direction: column;">
              <div class="content">
                Congratulations!

                <span *ngFor="let acc of arr; index as i">
                  <span *ngIf="i < 2">
                    <a [ngStyle]="{'color': getCardColor(2)}"
                       [routerLink]="['/clients/account', acc.id]">{{ acc.display_name ? acc.display_name : acc.display_number }}</a>,
                  </span>
                </span>
                and <span style="color: #000000; font-weight: bold;">{{ arr.length - 2 }} {{ arr.length - 2 === 1 ? 'other' : 'others' }}</span> have successfully onboarded.


                <div class="accordion-container">
                  <label class="label" for="{{ 'accordion-' + index }}">
                    View All New Accounts
                  </label>
                  <input class="input" id="{{ 'accordion-' + index }}" name="{{ 'accordion-' + index }}" type="checkbox">
                  <div class="accordion-content">
                    <div *ngFor="let acc of arr" style="margin: 10px 0;">
                      <a [ngStyle]="{'color': getCardColor(2)}"
                         [routerLink]="['/clients/account',acc.id]">{{ acc.display_name ? acc.display_name : 'Unnamed account' }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="arr[0].object === 'data.luca.transfer'">
        <div class="recent-activity-card"
             [ngStyle]="{'border-left-color': getCardColor(3)}">
          <i class="far fa-sack-dollar"
             [ngStyle]="{'color': getCardColor(3)}"
             *ngIf="arr[0].security_id === cashSecurityID"></i>

          <i class="far fa-university"
             [ngStyle]="{'color': getCardColor(3)}"
             *ngIf="arr[0].security_id !== cashSecurityID"></i>

          <div class="content-container">
            <div class="content-header">
              <div>
                {{ arr[0].security_id === cashSecurityID ? 'Cash Flows' : 'Security Transfers' }}
              </div>

              <div class="date">
                {{ arr[0].sort_date | date }}
              </div>
            </div>

            <div style="display: flex; flex-direction: column;">
              <div class="content">
                  <span *ngFor="let transaction of arr; index as i">
                    <span *ngIf="i < 2">
                      <a [ngStyle]="{'color': getCardColor(3)}"
                         [routerLink]="['/clients/account',transaction.account_id]">{{ transaction.account_name ? transaction.account_name : 'Unnamed account' }}</a>,
                    </span>
                  </span> and <span style="color: #000000; font-weight: bold;">{{ arr.length - 2 }} {{ arr.length - 2 === 1 ? 'other' : 'others' }}</span> have
                  deposited or withdrawn {{ arr[0].security_id === cashSecurityID ? 'cash' : 'securities'}}.


                <div class="accordion-container">
                  <label class="label" for="{{ 'accordion-' + index }}">
                    View All Activities
                  </label>
                  <input class="input" id="{{ 'accordion-' + index }}" name="{{ 'accordion-' + index }}" type="checkbox">
                  <div class="accordion-content">
                    <div *ngFor="let transfer of arr" style="margin: 10px 0;">
                      <a [ngStyle]="{'color': getCardColor(3)}"
                         [routerLink]="['/clients/account',transfer.account_id]">{{ transfer.account_name }}</a> {{ transfer.type === 'DEP' ? 'deposited' : 'withdrew' }} <span style="color: #000000; font-weight: bold;">{{ transfer.amount | currency }}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && error" class="loader error">
  An error seems to have occurred. Please refresh or try again later.
</div>

<div *ngIf="loading" class="loader">
  Analyzing
  <div style="margin-bottom: 10px"></div>
  <app-material-spinner></app-material-spinner>
</div>
