import { Component, OnInit } from '@angular/core';
import {CognitoService} from '../../shared/services/auth/cognito.service';
import {Router} from '@angular/router';
import {LoginService} from '../../shared/services/auth/login.service';
import {AppLoadingService} from '../../layout/app-loading.service';
import { LoginViewStates } from '../../shared/services/auth/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  user = {
    username: ''
  };

  errorMessage = false;
  message: string;

  requesting = false;

  constructor(private cognitoService: CognitoService,
              private router: Router,
              private loginService: LoginService,
              private appLoadingService: AppLoadingService) { }

  ngOnInit() {
    // If logged in redirect to dashboard
    this.appLoadingService.setAppLoading(false);

    if (this.loginService.isLoggedIn()) {
      this.router.navigateByUrl('/dashboard');
      return;
    }
  }

  requestReset() {
    if (!this.user.username) {
      this.errorMessage = true;
      this.message = 'Please enter a valid email.';
      return;
    }

    this.requesting = true;
    const cognitoUser = this.cognitoService.getCognitoUser(this.user.username.toLowerCase().trim());

    this.cognitoService.requestResetPassword(cognitoUser, this.handleVerificationCode, this.onRequestError);
  }

  // callback to handle error from cognito service
  onRequestError = (err) => {
    this.requesting = false;
    if (err.message.includes('cannot be reset')) {

      this.loginService.checkForTemporaryPassword(this.user.username).subscribe(() => {
        // this.router.navigateByUrl('/login');
        // this.loginService.loginDisplayMessage = 'A temporary password has been sent to your email. You will be prompted to set a new password on initial login.';

        const loginPageMessage = 'A temporary password has been sent to your email. You will be prompted to set a new password on initial login.';
        this.loginService.setLoginDisplayMessage(loginPageMessage);
        this.loginService.modeObservable.next(LoginViewStates.LOGIN);

        return;
      }, (error) => {
        this.displayError(error.message);

        return;
      });
    } else if (err.message.toLowerCase().includes('no user found')) {
      this.displayError('No user found.');
    } else {
      this.displayError(err.message);

      return;
    }
  }

  handleVerificationCode = () => {
    // Request was successful and the user will enter their verification code and new password
    this.loginService.modeObservable.next(LoginViewStates.ENTER_PASSWORD);
  }

  displayError(message) {
    this.errorMessage = true;
    this.message = message;
  }

  closeMessage() {
    this.errorMessage = false;
    this.message = null;
  }
}
