import {Component, Input, OnInit} from '@angular/core';
import {Note} from '../type-classes/wealthbox-note';
import {WealthboxService} from '../wealthbox.service';
import * as NoteEditor from '../../../../../scripts/note-editor';

@Component({
  selector: 'app-wealthbox-note',
  templateUrl: './wealthbox-note.component.html',
  styleUrls: ['./wealthbox-note.component.scss']
})
export class WealthboxNoteComponent implements OnInit {

  @Input() note: Note;
  initialNote: Note;
  edit = false;
  editor = NoteEditor;

  editorConfig = this.wealthboxService.noteEditorConfig;

  constructor(private wealthboxService: WealthboxService) {
    this.initialNote = this.note;
  }

  ngOnInit() {
  }

  saveNote(note: Note) {
    this.addContactAssociations();
    this.wealthboxService.saveNote(note).subscribe();
    this.edit = false;
  }

  cancel() {
    this.edit = false;
    this.note = this.initialNote;
  }

  addContactAssociations() {
    const noteContent = this.note.content;

    const mentionRegex = /contactmentionid="\d*-[A-Za-z\s]*/g; // Get substrings that match contactmentionid="[0-9]*-[A-Z]*"
    const matchRegex = /\d*-[A-Za-z\s]*/;  // Strip just the "[0-9]*-[A-Z]*"
    const contactMentions = noteContent.match(mentionRegex);

    if (!contactMentions) {
      return;
    }

    const currentLinkIds = this.note.linked_to.map(contact => contact.id);
    // Wealthbox api will clone associations in a put, so clear this out after you check what's in it.
    this.note.linked_to = [];

    contactMentions.forEach(mention => {
      const association = mention.match(matchRegex)[0].split('-'); // strip the id and the contact name

      const newAssociation = {
        id: parseInt(association[0]),
        name: association[1],
        type: 'Contact'
      }; // create new association

      if (!currentLinkIds.includes(newAssociation.id)) {
        this.note.linked_to.push(newAssociation);
      }
    });
  }

}
