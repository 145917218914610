import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {BillingAssetAdjustment} from './billing';
import {HttpClient} from '@angular/common/http';
import {FirmService} from '../firm/firm.service';

@Injectable({
  providedIn: 'root'
})
export class BillingAssetAdjustmentService {
  private endpoint = `${environment.apiV2Url}/billing/asset-adjustments`;
  public assetAdjustments: BillingAssetAdjustment[] = [];
  constructor(private http: HttpClient, private firmService: FirmService) { }

  constructAssetAdjustment() {
    return new BillingAssetAdjustment(this.firmService.firm.id);
  }

  getAssetAdjustments() {
    return this.http.get(this.endpoint).toPromise().then((resp: {data: BillingAssetAdjustment[]}) => {
      this.assetAdjustments = resp.data;

      return this.assetAdjustments;
    });
  }

  saveAssetAdjustment(assetAdjustment: BillingAssetAdjustment): Promise<BillingAssetAdjustment> {
    const url = ('id' in assetAdjustment) ? `${this.endpoint}/${assetAdjustment.id}` : this.endpoint;
    const method = ('id' in assetAdjustment) ? 'put' : 'post';

    return this.http[method](url, assetAdjustment).toPromise()
      .then((resp: BillingAssetAdjustment) => {
        this.assetAdjustments = ('id' in assetAdjustment) ?
          this.assetAdjustments.map(a => (a.id === resp.id) ? resp : a) :
          [...this.assetAdjustments, resp];

        return resp;
      });
  }

  deleteAssetAdjustment(assetAdjustment: BillingAssetAdjustment) {
    const url = `${this.endpoint}/${assetAdjustment.id}`;

    return this.http.delete(url).toPromise()
      .then(() => {
        this.assetAdjustments = this.assetAdjustments.filter(a => a.id !== assetAdjustment.id);
      });
  }

  deleteAssetAdjustments(adjustments: BillingAssetAdjustment[]) {
    const url = this.endpoint;

    const data = {
      ids: adjustments.map(a => a.id)
    };

    const options = {
      body: data
    };

    // Body on delete request to work with ALB API
    // @ts-ignore
    return this.http.delete(url, options).toPromise().then(() => {

      this.assetAdjustments = this.assetAdjustments.filter((aj) => !data.ids.includes(aj.id));
    });
  }
}
