import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAccounts from './account.reducer';

export interface State  {
  products: fromAccounts.AccountState;
}

const getAccountFeatureState = createFeatureSelector<fromAccounts.AccountState>('accounts');

export const getAccounts = createSelector(
  getAccountFeatureState,
  fromAccounts.selectAllAccounts
);

export const getLoading = createSelector(
  getAccountFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getAccountFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getAccountFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getAccountFeatureState,
  state => state.updateCallState.loading
);
