import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExcelService} from '../excel.service';
import * as XLSX from 'xlsx';
import {AppConstants} from '../../app-constants';

@Component({
  selector: 'app-atlas-file-upload',
  templateUrl: './atlas-file-upload.component.html',
  styleUrls: ['./atlas-file-upload.component.scss']
})
export class AtlasFileUploadComponent implements OnInit {

  @Input() title;
  @Input() fileName = 'Select a file';
  @Input() dataToExportToExcel: any[];
  @Input() downloadFileType: string;
  @Input() templateFileName: string = null;

  @Output() dataStream = new EventEmitter<any>();

  uploadedDataStream;
  disable = true;

  constructor(public excelService: ExcelService) {}

  ngOnInit(): void {
    if(!this.templateFileName) {
      this.templateFileName = 'Template-'
    }
  }

  exportTemplateFile(): void {
    if (this.downloadFileType === AppConstants.FILE_TYPE.CSV) {
      this.excelService.exportAsCsvFile(this.dataToExportToExcel, this.templateFileName);
    } else if (this.downloadFileType === AppConstants.FILE_TYPE.EXCEL) {
      this.excelService.exportAsExcelFile(this.dataToExportToExcel, this.templateFileName);
    }
  }


  uploadFile(ev) {
    let workBook = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {type: 'binary'});
      this.uploadedDataStream = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        return XLSX.utils.sheet_to_json(sheet);
      }, {});

      this.disable = false;
    };

    if (file) {
      this.fileName = file.name;
    } else {
      this.fileName = 'Select File';
    }
    reader.readAsBinaryString(file);

  }

  onClick = () => {
    this.dataStream.emit(this.uploadedDataStream);
  }

}
