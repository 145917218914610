<div class="login-margin">
  <div class="background-layer">
  </div>

  <div class="login-page">
    <div class="section login">

      <div class="login-container">

        <div *ngIf="verificationData" class="section-item logo-container">
          <img [src]="verificationData.logo_url" [alt]="verificationData.firm_name + ' logo'" class="img-thumbnail img-rounded firm-logo">
        </div>

        <div *ngIf="verificationData">
          <div class="section-item welcome-header">
            Last step: we need to verify your identity.
            <br>
            <br>

            Enter the <b>full account number</b> of your account ending with {{ verificationData.account_hint}}.
          </div>

          <div [ngClass]="errorMessage ? 'msg-container error' : 'msg-container success'"
               *ngIf="message"
               (click)="closeMessage()">
            <div class="icon-container">
              <i *ngIf="errorMessage" class="fas fa-times-circle error-msg-close"></i>

              <i *ngIf="!errorMessage" class="fas fa-check-circle success-msg-close"></i>
            </div>

            {{message}}
          </div>

          <div class="section-item">
            <form>
              <mat-form-field appearance="outline" style="width: 100%;">
                <input type="text"
                       matInput
                       class="form-control"
                       autocomplete="off"
                       placeholder="Full account number excluding dashes"
                       value=""
                       name="challenge"
                       [(ngModel)]="challenge">
              </mat-form-field>

              <div class="help">
                <!--              if challenge_account.has_custodian_name-->
                <p *ngIf="verificationData.custodian">
                  Hint: This account is held at <b>{{ verificationData.custodian }}</b>.
                </p>

                Account numbers can usually be found on statements or by logging into your custodian account.
              </div>
            </form>
          </div>

          <div class="section-item reset-btn-container">
            <app-atlas-submit-button (click)="verifyClient(verificationData)">
              Verify and Login  &nbsp; <i class="fa fa-arrow-right"></i>
            </app-atlas-submit-button>
          </div>
        </div>
      </div>



      <div class="footer">
        Powered by
        <div class="footer-img-container">
          <img src="../../../assets/images/Bridge_Black.jpg" alt="Bridge Financial Technology Logo" class="firm-logo">
        </div>
      </div>
    </div>


    <div class="section-two"></div>
  </div>
</div>

