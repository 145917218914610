import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {forkJoin, Observable} from 'rxjs';
import {NotificationService} from './ui/notification.service';

interface AppVersion {
  version: string;
}

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  frontendURL = `${environment.hostUrl}/version.json`;

  // Frontend version is passed in from post-build script
  frontendVersion = '{{POST_BUILD_VERSION}}';

  constructor(private http: HttpClient,
              private notificationService: NotificationService) {}

  public initVersionCheck(): void {
      this.setVersionCheckInterval();
  }

  private setVersionCheckInterval(): void {
    // If not production or the post build script didn't run or the apiVersion was not found don't try to check
    if (this.frontendVersion.includes('POST_BUILD_VERSION')) {
      return;
    }
    // DO initial version check ping every 10 minutes
    setInterval(() => {
      this.checkVersion();
    }, 1000 * 60 * 10);
  }

  // Call the cloudfront cache to check for a new version
  private getFrontendVersion(): Observable<AppVersion> {
    return this.http.get<AppVersion>(this.frontendURL);
  }

  private checkVersion(): void {
    // Call api version
    this.getFrontendVersion().subscribe((results: any) => {
      const frontendVersion = results.version;

      this.checkNewVersion(frontendVersion);
    });
  }

  private checkNewVersion(frontendVersion: string): void {
    if (this.frontendVersion !== frontendVersion) {
      this.notificationService.showAppReloadNotification();
    }
  }
}
