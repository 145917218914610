import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CognitoService, HashkeyResults} from '../../shared/services/auth/cognito.service';
import {LoginService} from '../../shared/services/auth/login.service';
import {AppLoadingService} from '../../layout/app-loading.service';
import { LoginViewStates } from '../../shared/services/auth/login.service';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // Data for branded login
  brandedLogin: any;

  defaultLogin = true;

  message: string;
  errorMessage = false;

  user = {
    username: '',
    password: ''
  };

  loggingIn = false;


  resetMode = false;
  enterPasswordMode = false;
  forceChangePasswordMode = false;

  constructor(private route: ActivatedRoute,
              private cognitoService: CognitoService,
              private router: Router,
              private loginService: LoginService,
              private appLoadingService: AppLoadingService) {
  }

  ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      this.loggingIn = true;
      this.router.navigateByUrl('/dashboard');
      return;
    }

    this.loginService.modeObservable.asObservable()
      .subscribe(resp => {
        if (resp === LoginViewStates.LOGIN) {
          if (this.loginService.loginDisplayMessage !== '') {
            this.message = this.loginService.loginDisplayMessage;
          }
          this.resetMode = false;
          this.enterPasswordMode = false;
          this.forceChangePasswordMode = false;

        } else if (resp === LoginViewStates.RESET_PASSWORD) {
          this.resetMode = true;
          this.enterPasswordMode = false;
          this.forceChangePasswordMode = false;

        } else if (resp === LoginViewStates.ENTER_PASSWORD) {
          this.resetMode = false;
          this.enterPasswordMode = true;
          this.forceChangePasswordMode = false;

        } else if (resp === LoginViewStates.FORCE_CHANGE_PASSWORD) {
          this.resetMode = false;
          this.enterPasswordMode = false;
          this.forceChangePasswordMode = true;
        }
      });

    // Check branded login
    this.appLoadingService.setAppFinishedLoading();

    if (this.route.snapshot.params.id) {
      const shortName = this.route.snapshot.params.id;
      this.loginService.fetchBrandedLogin(shortName)
        .pipe(
          catchError((err) => {
            this.defaultLogin = true;
            return err;
          })
        )
        .subscribe((brandedLogin) => {
          this.defaultLogin = false;
          this.brandedLogin = brandedLogin;
      });
    } else {
      this.defaultLogin = true;
    }

    if (this.loginService.loginDisplayMessage !== '') {
      this.message = this.loginService.loginDisplayMessage;
    }

    // Listen for errors coming from cognito
    this.cognitoService.loginErrorObservable.subscribe((message) => {
      // Check for temporary password and resend invitation
      if (message.toLowerCase().includes('temporary password has expired')) {
        this.loginService.checkForTemporaryPassword(this.user.username).subscribe(() => {
          this.loggingIn = false;
          this.displayError('Your temporary password has expired, a new temporary password has been sent to your email.');
        }, (err) => {
          console.log(err);
        });
      } else if (message.toLowerCase().includes('incorrect username or password')) {
        // If user had an incorrect password, check if there was a temporary password send if so, resend and navigate to custom view
        // If not, just display the error message incorrect username or password
        this.loginService.isInTemporaryPasswordState(this.user.username).subscribe((response: any) => {
          if (response.result) {
            this.loggingIn = false;
            // this.turnOnSecurityUpdateMode();
            // this.router.navigateByUrl('/security-update');
          } else {
            this.loggingIn = false;
            this.displayError(message);
          }
        }, (err) => {
          this.loggingIn = false;
          this.displayError(message);
        });
      } else {
        this.loggingIn = false;
        this.displayError(message);
      }
    });
  }

  login() {
    // Listen for authentication
    this.loggingIn = true;

    this.cognitoService.authenticatedObservable.subscribe(authenticated => {
      if (authenticated) {
        if (this.cognitoService.authProfile.is_client_user) {
          this.router.navigateByUrl('/cp/main');
        } else {
          this.router.navigateByUrl('/dashboard');
        }
      }
    });

    this.cognitoService.login(this.user.username.toLowerCase().trim(), this.user.password, this.handleForceChangePassword);
  }

  handleForceChangePassword = () => {
    this.loginService.modeObservable.next(LoginViewStates.FORCE_CHANGE_PASSWORD);
    // this.router.navigateByUrl('change-password');
  }

  closeMessage() {
    this.errorMessage = false;
    this.loginService.setLoginDisplayMessage('');
    this.message = null;
  }

  displayError(message) {
    this.errorMessage = true;
    this.message = message;
  }

  turnResetModeOn() {
    this.loginService.modeObservable.next(LoginViewStates.RESET_PASSWORD);
  }

  backToLogin() {
    this.loginService.modeObservable.next(LoginViewStates.LOGIN);
  }
}
