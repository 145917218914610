import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'advisorCodes'
})
export class AdvisorCodesPipe implements PipeTransform {

  transform(advisorCodes: string[], ...args: unknown[]): unknown {
    return advisorCodes.join(', ');
  }
}
