import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { FirmService } from '../firm/firm.service';
import { UtilsService } from '../shared/services/utils.service';
import { HttpClient } from '@angular/common/http';
import _keyBy from 'lodash-es/keyBy';
import {Benchmark, Coefficient} from './benchmark';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {

  constructor(
    private firmService: FirmService,
    private util: UtilsService,
    private http: HttpClient) {

    this.endpoint = `${environment.apiV2Url}/reporting/benchmarks`;
  }

  benchmarks: any[] = [];
  private endpoint: string;

  static constructCoefficient(benchmarkID: number, indexID: number) {
    return new Coefficient(benchmarkID, indexID);
  }

  get benchmarksByID() {
    return _keyBy(this.benchmarks, 'id');
  }

  constructBenchmark() {
    return new Benchmark(this.firmService.firm.id);
  }

  constructCoefficient(benchmarkID: number, indexID: number) {
    return {
        benchmark_id: benchmarkID,
        index_id: indexID,
        weight: 5.00,
        object: 'reporting.benchmark_coefficient'
    };
  }


  saveBenchmark(benchmark) {
    const url = benchmark.id ? `${this.endpoint}/${benchmark.id}` : this.endpoint;
    const method = benchmark.id ? 'put' : 'post';

    delete benchmark.$$hashKey; // TODO - need to be removed after migration
    return this.http[method](url, benchmark).pipe(
      tap((resp: any) => {
        if (benchmark.id) {
          this.benchmarks = this.benchmarks.map((a) => resp.id === a.id ? resp : a);
        } else {
          this.benchmarks.push(resp);
        }

        return resp;
      })
    );
  }


/**
 * Gets a list of benchmarks owned by the firm of the logged in user
 */
  getBenchmarks(): Promise<Benchmark[]> {

    if (this.benchmarks.length > 0) {
      return Promise.resolve(this.benchmarks);
    }

    return this.http.get(this.endpoint).toPromise()
      .then((resp: {data: Benchmark[]}) => {
        this.benchmarks = resp.data;

        return this.benchmarks;
    });
  }


  deleteBenchmark(benchmark) {
    const url = `${this.endpoint}/${benchmark.id}`;

    return this.http.delete(url).toPromise().then(() => {
        this.util.removeItem(this.benchmarks, benchmark);
    });
  }

  deleteBenchmarks(benchmarks: Benchmark[]): Observable<Benchmark[]> {
    const url = this.endpoint;
    const ids = benchmarks.map(b => b.id);

    const data = {
      ids
    };

    const options = {
      body: data
    };

    // @ts-ignore
    return this.http.delete(url, options).pipe(
      map((resp) => {
        this.benchmarks = this.benchmarks.filter(b => !ids.includes(b.id));

        return this.benchmarks;
      })
    );
  }
}
