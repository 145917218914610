import {createAction, props} from '@ngrx/store';
import {ReportPackage} from '../report-package';

export const LoadReportPackages = createAction('[ReportPackages] Load report packages');
export const LoadReportPackagesSuccess = createAction('[ReportPackages] Load clients success', props<{reportPackages: ReportPackage[]}>());
export const LoadReportPackagesFail = createAction('[ReportPackages] Load clients fail', props<{error: any}>());

export const CreateReportPackage = createAction('[ReportPackages] Create clients', props<{reportPackage: ReportPackage}>());
export const CreateReportPackageSuccess = createAction('[ReportPackages] Create clients success', props<{reportPackage: ReportPackage}>());
export const CreateReportPackageFail = createAction('[ReportPackages] Create clients fail', props<{error: any}>());

export const UpdateReportPackage = createAction('[ReportPackages] Update client', props<{reportPackage: ReportPackage}>());
export const UpdateReportPackageSuccess = createAction('[ReportPackages] Update client success', props<{reportPackage: ReportPackage}>());
export const UpdateReportPackageFail = createAction('[ReportPackages] Update reportPackage fail', props<{error: any}>());

export const UpdateReportPackages = createAction('[ReportPackages] Update reportPackages', props<{reportPackages: ReportPackage[]}>());
export const UpdateReportPackagesSuccess = createAction('[ReportPackages] Update reportPackages success', props<{reportPackages: ReportPackage[]}>());
export const UpdateReportPackagesFail = createAction('[ReportPackages] Update reportPackages fail', props<{error: any}>());

export const RemoveReportPackage = createAction('[ReportPackages] Remove reportPackage', props<{reportPackage: ReportPackage}>());
export const RemoveReportPackageSuccess = createAction('[ReportPackages] Remove reportPackage success', props<{reportPackage: ReportPackage}>());
export const RemoveReportPackageFail = createAction('[ReportPackages] Remove reportPackage fail', props<{error: any}>());

export const RemoveReportPackages = createAction('[ReportPackages] Remove reportPackages', props<{reportPackages: ReportPackage[]}>());
export const RemoveReportPackagesSuccess = createAction('[ReportPackages] Remove reportPackages success', props<{reportPackages: ReportPackage[]}>());
export const RemoveReportPackagesFail = createAction('[ReportPackages] Remove reportPackages fail', props<{error: any}>());
