import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {getAlertSubscriptions, State} from './state';
import {select, Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AlertSubscription} from './alert-subscription.type';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertSubscriptionsService {
  endpoint = `${environment.apiV2Url}/insights/alert-subscriptions`;

  alertSubscriptions$ = this.store.pipe(select(getAlertSubscriptions));

  constructor(private http: HttpClient,
              private store: Store<State>) { }

  getAlertSubscriptions(): Observable<AlertSubscription[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: AlertSubscription[]}) => {
        return resp.data;
      })
    );
  }

  getAlertSubscription(id: number): Observable<AlertSubscription> {
    const url = `${this.endpoint}/${id}`;

    return this.http.get<AlertSubscription>(url);
  }

  createAlertSubscription(alertSubscription: AlertSubscription): Observable<AlertSubscription> {
    return this.http.post<AlertSubscription>(this.endpoint, alertSubscription);
  }

  removeAlertSubscription(alertSubscription: AlertSubscription): Observable<any> {
    const url = `${this.endpoint}/${alertSubscription.id}`;

    return this.http.delete(url);
  }
}
