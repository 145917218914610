import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appSelectOnClick]'
})
export class SelectOnClickDirective {

  constructor(
    element: ElementRef,
  ) {
    element.nativeElement.addEventListener('click', function() {
      if (!window.getSelection().toString()) {
        // Required for mobile Safari
        this.setSelectionRange(0, this.value.length);
      }
    });
  }

}
