import {Injectable} from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../shared/services/utils.service';
import {Firm} from './firm';
import _keyBy from 'lodash-es/keyBy';
import _get from 'lodash-es/get';
import _filter from 'lodash-es/filter';
import {UserProfileService} from '../shared/services/auth/user-profile.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ColorsService} from '../shared/services/colors.service';
import {getFirms, getLoaded, State} from './state/state';
import {select, Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class FirmService {
  endpoint: string;
  ancestorEndpoint: string;
  firm: any;
  subFirms: Firm[];
  allFirms: Firm[];
  ancestorFirms: Firm[];
  ancestorFirmsFetched: boolean;
  status: any;

  private logoCacheParam: number;

  firmsLoaded$ = this.store.pipe(select(getLoaded));
  firms$ = this.store.pipe(select(getFirms));

  constructor(private http: HttpClient,
              private utils: UtilsService,
              private userProfileService: UserProfileService,
              private colorService: ColorsService,
              private store: Store<State>) {
    this.endpoint = `${environment.apiV2Url}/org/firms`;
    this.ancestorEndpoint = `${environment.apiV2Url}/org/firms/ancestors`;

    this.logoCacheParam = Math.round(Math.random() * 999999);
    this.status = {
      loaded: false
    };

    this.allFirms = [];
    this.subFirms = [];
    this.ancestorFirms = [];
    this.ancestorFirmsFetched = false;
  }

  get firmsByID() {
    return _keyBy(this.allFirms, 'id');
  }

  get ancestorFirmsByID() {
    return _keyBy(this.ancestorFirms, 'id');
  }

  fetchFirmByID(firmID: number): Observable<Firm> {
    return this.http.get<Firm>(`${this.endpoint}/${firmID}`).pipe(
      tap((firm) => {
        // Sets the theme of the application
        this.colorService.setFirmColors(firm);
      })
    );
  }

  isSuperFirm(): boolean {
    return this.firm.parent_firm_id === null && this.subFirms.length > 0;
  }

  changeLogoCacheParam(): void {
    this.logoCacheParam = Math.round(Math.random() * 999999);
  }

  getLogoUrl(firm?: any): string {
    firm = firm || this.firm;

    if (firm && firm.logo_url) {
      return firm.logo_url + '?r=' + this.logoCacheParam;
    }
  }

  getFirm(id: number): any {
    return _get(this.firmsByID, id, {});
  }

  getAncestorFirm(id: number): any {
    return _get(this.ancestorFirmsByID, id, {});
  }

  firmBrandedLoginUrl(firm: any): string {
    firm = firm || this.firm;

    return `${environment.hostUrl}/firm/${firm.short_name}`;
  }

  private populateCurrentAndSubFirms(): void {
    this.utils.clearObject(this.firm);
    this.utils.clearArray(this.subFirms);

    // find the current firm
    const firms = _filter(this.allFirms, (i: any) => {
      return i.parent_firm_id === null || this.userProfileService.userProfile.firm_id === i.id;
    });

    if (firms.length === 1) {
      this.firm = firms[0];
    }

    const subfirms = _filter(this.allFirms, (i) => {
      return i.parent_firm_id !== null && i.id !== this.firm.id;
    });

    this.utils.updateDataByPath(this.subFirms, subfirms);
  }

  getFirms(): Observable<Firm[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: Firm[]}) => {
        return resp.data;
      })
    );
  }

  /***
   * @deprecated this has been refactored for use with ngrx, this should no longer be used and will be removed.
   * TODO: Remove
   */
  fetchFirms(): Promise<any> {
    // cache data
    if (this.allFirms.length > 0 && !this.utils.isEmptyObject(this.firm)) {
      return Promise.resolve(this.allFirms);
    }

    return this.http.get(this.endpoint).toPromise().then((resp: any) => {
      if (resp.data.length === 0) {
        throw new Error();
      }

      this.allFirms = resp.data as Firm[];
      this.populateCurrentAndSubFirms();
      return this.allFirms;
    }).finally(() => {
      this.status.loaded = true;
    });
  }

  fetchAncestorFirms(): Promise<any> {
    // cache data
    if (this.ancestorFirmsFetched === true) {
      return Promise.resolve(this.ancestorFirms);
    }

    return this.http.get(this.ancestorEndpoint).toPromise().then((resp: any) => {
      if (resp.data.length === 0) {
        return this.ancestorFirms;
      }

      this.ancestorFirms = resp.data as Firm[];
      return this.ancestorFirms;
    }).finally(() => {
      this.ancestorFirmsFetched = true;
    });
  }

  updateFirm(firm: Firm): Observable<Firm> {
    const url = `${this.endpoint}/${firm.id}`;

    return this.http.put<Firm>(url, firm);
  }

  updateFirms(firms: Firm[]): Observable<Firm[]> {
    const url = `${this.endpoint}`;

    return this.http.put<Firm[]>(url, firms);
  }

  saveFirm(firm: any): Promise<any> {
    const url = `${this.endpoint}/${firm.id}`;

    const promises: Promise<any>[] = [
      this.http.put(url, firm).toPromise(),
      this.fetchFirms()
    ];

    return Promise.all(promises);
  }

  clientHasPermission(url: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.fetchFirms().then(() => {
        let hasPermission = false;

        // Check permissions
        if (!this.isClientPortalEnabled()) {
          observer.next(false);
          observer.complete();
        }


        if (url === '/cp/shared-files') {
          hasPermission = this.clientCanAccessSharedFiles();
        } else if (url === ('/reporting/printable-reports')) {
          hasPermission =  this.clientCanAccessPrintableReports();
        } else if (url === '/reporting/entity-summary/') {
          hasPermission =  this.clientCanAccessWebReports();
        } else if (url.includes('/invoices/details')) {
          hasPermission = this.clientCanAccessInvoices();
        } else if (url === '/invoices/list') {
          hasPermission =  this.clientCanAccessInvoices();
        } else if (url === '/held-away/list') {
          hasPermission = this.clientCanAccessHeldaways();
        } else {
          hasPermission = false;
        }

        observer.next(hasPermission);
        observer.complete();
      });
    });
  }

  clientCanAccessWebReports(): boolean {
    return this.firm.cp_web_reports_enabled;
  }

  clientCanAccessPrintableReports(): boolean {
    return this.firm.cp_printable_reports_enabled;
  }

  clientCanAccessInvoices(): boolean {
    return this.firm.cp_invoices_enabled;
  }

  clientCanAccessSharedFiles(): boolean {
    return this.firm.cp_shared_files_enabled;
  }

  clientCanAccessHeldaways(): boolean {
    return this.firm.cp_heldaways_enabled;
  }

  isClientPortalEnabled(): boolean {
    return this.firm.cp_enabled;
  }
}
