import {
  AfterViewInit,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {AppConstants} from '../../app-constants';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {DxListComponent} from 'devextreme-angular/ui/list';
import {Observable, Subscription} from 'rxjs';

@Directive({
  selector: '[appFillHeight]'
})
export class FillHeightDirective implements AfterViewInit, OnDestroy, OnInit {
  private host: DxDataGridComponent | DxListComponent;

  // total top and bottom offset from the viewport, needed to proper calculation element height
  @Input() viewportOffset: number = AppConstants.APP_FOOTER_OFFSET;

  @Input() minHeight = 500;
  @Input() recalculateEvent: Observable<void>;
  private eventsSubscription: Subscription;
  constructor(
    private el: ElementRef,
    @Host() @Self() @Optional() private dataGridHost: DxDataGridComponent,
    @Host() @Self() @Optional() private listHost: DxListComponent) {
    this.host = dataGridHost || listHost;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateAndSetElementHeight();
    });
  }

  ngOnInit(): void {
    if (this.recalculateEvent) {
      this.eventsSubscription = this.recalculateEvent.subscribe(() => {
        setTimeout(() => {
          this.calculateAndSetElementHeight();
        });
      });
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateAndSetElementHeight();
  }

  calculateAndSetElementHeight() {
    // this.el.nativeElement.style.overflow = 'auto';
    const windowHeight = window.innerHeight;
    const elementOffsetTop = this.getElementOffsetTop() || 0;

    const height = windowHeight - elementOffsetTop - this.viewportOffset;

    if (height > this.minHeight) {
      // @ts-ignore
      this.host.instance.option('height', height);
      this.restoreDataGridOptions(height);
    } else {
      this.setSmallScreenMode();
    }
  }

  private getElementOffsetTop() {
    return this.el.nativeElement.getBoundingClientRect().top;
  }

  private setSmallScreenMode() {
    // @ts-ignore
    this.host.instance.option('height', 500);
  }

  private restoreDataGridOptions(height) {
    // @ts-ignore
    this.host.instance.option('height', height);
  }
}
