<mat-card>
  <div class="card-header">
    <div class="card-header__title">
      <h2>Billable Assets</h2>

      <div #billableAssetsTemplate>
        <p>
          See the total value of AUM or number of accounts not billed. Monitor this to make sure you are not giving too many discounts.
      </div>

      <i class="far fa-info-circle info-symbol"
         [ngxTippy]="billableAssetsTemplate"
         tippyName="billableAssetsTooltip"></i>
    </div>

    <div class="card-options">
      <div class="card-header__toggle-container">
        <div class="option" [class.active]="selected === 'aum'" (click)="toggle()">AUM</div>

        <div class="option second" [class.active]="selected === 'accounts'" (click)="toggle()">Accounts</div>
      </div>

      <div class="handle" *ngIf="isActiveRoute('/dashboard')">
        <i class="far fa-arrows"></i>
      </div>
    </div>
  </div>

  <mat-card-content>
    <div *ngIf="loading">
      <app-material-spinner></app-material-spinner>
    </div>
    <div *ngIf="!loading"
      class="chart-container" [ngClass]="doughnutChartData.length && (!doughnutChartData[0] && !doughnutChartData[1]) ? 'hidden' : null">
      <canvas baseChart
              #billableAssetsChart
              [data]="doughnutChartData"
              [colors]="chartColors"
              [plugins]="plugins"
              [labels]="doughnutChartLabels"
              [options]="options"
              [chartType]="doughnutChartType">
      </canvas>
    </div>

    <div class="error-container" *ngIf="!loading && doughnutChartData.length && (!doughnutChartData[0] && !doughnutChartData[1])">
      No data
    </div>
  </mat-card-content>
</mat-card>
