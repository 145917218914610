import {ReportPackageState, selectAllReportPackages} from './report-packages.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: ReportPackageState;
}

const getReportPackageFeatureState = createFeatureSelector<ReportPackageState>('reportPackages');

export const getReportPackages = createSelector(
  getReportPackageFeatureState,
  selectAllReportPackages
);

export const getLoading = createSelector(
  getReportPackageFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getReportPackageFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getReportPackageFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getReportPackageFeatureState,
  state => state.updateCallState.loading
);
