import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-tab-container',
  templateUrl: './tab-container.component.html',
  styleUrls: [
    './tab-container.component.scss'
    // '../../assets/atlas-material/_theming.scss'
  ]
})
export class TabContainerComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    const tabContainer = document.querySelector('.tab-container');
    const firstTab = tabContainer.querySelector('.tab');

    firstTab.classList.add('active');

    const selector = document.querySelector('.selector');
    const activeItem = document.querySelector('.active');

    const waitForDOM = () => {
      setTimeout(() => {
        const activeWidth = window.getComputedStyle(activeItem).width;

        if (activeWidth !== 'auto') {
          const activeItemLeftPosition = window.getComputedStyle(activeItem).left;

          this.renderer.setStyle(selector, 'left', activeItemLeftPosition);
          this.renderer.setStyle(selector, 'width', activeWidth);
        } else {
          waitForDOM();
        }
      }, 10);
    };

    waitForDOM();
  }
}
