import { Injectable } from '@angular/core';
import {Deferred} from 'ts-deferred';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { isObject } from 'rxjs/internal-compatibility';
import _map from 'lodash-es/map';
import _get from 'lodash-es/get';
import _keys from 'lodash-es/keys';
import _forEach from 'lodash-es/forEach';
import _values from 'lodash-es/values';
import _range from 'lodash-es/range';
import _remove from 'lodash-es/remove';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpClient
  ) { }


  getDetailUrl(endpoint: string, id: number | string): string {
    let fmt = '';

    if (endpoint[endpoint.length - 1] === '/') {
      fmt = `${endpoint}${id}/`;
    } else {
      fmt = `${endpoint}/${id}/`;
    }

    return fmt;
  }

  isUndefinedOrNull(value: any): boolean {
    return value === undefined || value == null;
  }

  updateDatumByPath(dataContainer: any, obj: any, path?: any): void {
    path = path || 'id';

    if (Array.isArray(dataContainer)) {
      const index = _map(dataContainer, path).indexOf(_get(obj, path));

      if (index >= 0) {
        // replace item
        dataContainer[index] = obj;
      } else {
        // add item
        dataContainer.push(obj);
      }
    } else if (isObject(dataContainer)) {
      // always replace item
      dataContainer[_get(obj, path)] = obj;
    }
  }

  updateDataByPath(dataContainer: any, items: any, path?: any): void {
    if (Array.isArray(items)) {
      _forEach(items, (item) => {
        this.updateDatumByPath(dataContainer, item, path);
      });
    } else if (isObject(items)) {
      this.updateDatumByPath(dataContainer, items, path);
    }
  }

  public isDefinedAndNotNull(value: any): any {
    return !this.isUndefinedOrNull(value);
  }

  public clearObject(object: any): void {
    const keys = _keys(object);

    _forEach(keys, (key) => {
      delete object[key];
    });

    return object;
  }

  public clearArray(arr: any): void {
    arr.length = 0;
  }

  public isEmptyObject(object: any): boolean {
    return isObject(object) && _keys(object).length === 0;
  }

  /**
   * Removes an item from a data structure
   * @param dataContainer the containing data structure (list or object)
   * @param obj the object to remove
   * @param path the path to identify the matching key. defaults to 'id'
   */
  removeItem(dataContainer, obj, path?) {
    path = path || 'id';

    if (Array.isArray(dataContainer)) {
      const value = _get(obj, path);

      _remove(dataContainer, function(item) {
        return _get(item, path) === value;
      });
    } else if (isObject(dataContainer)) {
      const key = _get(obj, path);
      delete dataContainer[key];
    }
  }

  /**
   * Data is missing content if it's either undefined, null or an empty string, if it is a string, or empty array if it's an array,
   * or an empty object it's an object
   * The *Content functions are useful for determing whether a value can/should be used in filtering
   */
  noContent(data) {
    return this.isUndefinedOrNull(data) ||
      (typeof data === 'string' && data.length === 0) ||
      (Array.isArray(data) && data.length === 0) ||
      (data && data && Object.keys(data).length === 0);
  }

  /**
   * Data has content when it's (1) defined, (2) non-null, (3) not an empty string, if it is a string
   * (4) a non-empty array, if it's an array
   * The *Content functions are useful for determing whether a value can/should be used in filtering
   */
  hasContent(data) {
    return !this.noContent(data);
  }

  precisionRound(n, precision, allowNan?) {
    if (this.isUndefinedOrNull(n)) {
      return null;
    }

    allowNan = allowNan || false;

    const factor = Math.pow(10, precision);
    const val = Math.round(n * factor) / factor;
    if (isNaN(val) && !allowNan) {
      return null;
    }

    return val;
  }

  /**
   * Transforms column-oriented data into row-oriented data
   * Implicitly uses the "id" of the property of the column to serve as a naming / uniqueness specifier
   */
  toRowOriented(columnData) {
    const objects = [];

    const keys = _keys(columnData);
    const ids = _map(_keys(columnData[keys[0]]), Number);

    ids.forEach((id) =>  {
      const obj = {
        id
      };
      keys.forEach((key) => {
        obj[key] = columnData[key][id];
      });

      objects.push(obj);
    });

    return objects;
  }

  /**
   * Transforms column-oriente data to row-oriented data without an id
   * Instead, it uses a provided keyname (defaults to "keyName" if unspecified) to serve
   * as the identifier of the object
   * Also attaches a numerical "index" property to the object for numerical identification
   * @param columnData input data
   * @param keyName name of the key to store the property name
   * @param indexName name of the key to store the 0-based auto-incrementing numerical index
   */
  toRowOrientedWithoutId(columnData, keyName, indexName?) {
    keyName = keyName || 'keyName';
    indexName = indexName || 'index';

    const keys = _keys(columnData);

    // holds objects populated by the index
    const objectsByIndex = {};

    // initialize this data structure
    const n = _keys(columnData[keys[0]]).length;
    const indexes = _range(n);
    _forEach(indexes, (idx) => {
      objectsByIndex[idx] = {};
    });

    let index = 0;

    _forEach(keys, (key) => {
      index = 0;

      _forEach(columnData[key], (value, propName) => {
        objectsByIndex[index][key] = value;
        objectsByIndex[index][keyName] = propName;
        objectsByIndex[index][indexName] = index;
        index += 1;
      });

    });

    return _values(objectsByIndex);
  }

  stepByStepLoading(url: string, limit: number, page: number = 1): Promise<any> {
    const content = [];
    const deferred = new Deferred();

    const makeCall = () => {
      const params = new HttpParams({fromObject: {'pager.limit': limit.toString(), 'pager.page': page.toString()} });
      this.http.get(url, {params}).toPromise()
        .then((response: any) => {
          content.push(...response.data);
          if (response.has_next) {
            page += 1;
            makeCall();
          } else {
            deferred.resolve(content);
          }
        });
    };

    makeCall();

    return deferred.promise;
  }

  // Resolves every page from an API Endpoint from /filter api endpoints
  fetchAllFilteredPages(url: string, body: any, limit: number, page: number = 1) {
    const content = [];

    return new Observable<any>(observer => {

      const makeCall = () => {
        const params = new HttpParams({fromObject: {'pager.limit': limit.toString(), 'pager.page': page.toString()} });

        this.http.post(url, body, {params}).subscribe((response: any) => {
          content.push(...response.data);

          if (response.has_next) {
            page += 1;
            makeCall();
          } else {
            observer.next(content);
            observer.complete();
          }
        });
      };

      makeCall();
    });
  }
}
