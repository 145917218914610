import {createAction, props} from '@ngrx/store';
import {AlertSubscription} from '../alert-subscription.type';

export const LoadAlertSubscriptions = createAction('[AlertSubscriptions] Load alert subscriptions');
export const LoadAlertSubscriptionsSuccess = createAction('[AlertSubscriptions] Load alertSubscriptions success', props<{alertSubscriptions: AlertSubscription[]}>());
export const LoadAlertSubscriptionsFail = createAction('[AlertSubscriptions] Load alertSubscriptions fail', props<{error: any}>());

export const CreateAlertSubscription = createAction('[AlertSubscriptions] Create alert subscription', props<{alertSubscription: AlertSubscription}>());
export const CreateAlertSubscriptionSuccess = createAction('[AlertSubscriptions] Create alert subscription success', props<{alertSubscription: AlertSubscription}>());
export const CreateAlertSubscriptionFail = createAction('[AlertSubscriptions] Create alert subscription fail', props<{error: any}>());

export const RemoveAlertSubscription = createAction('[AlertSubscriptions] Remove alert subscription', props<{alertSubscription: AlertSubscription}>());
export const RemoveAlertSubscriptionSuccess = createAction('[AlertSubscriptions] Remove alert subscription success', props<{alertSubscription: AlertSubscription}>());
export const RemoveAlertSubscriptionFail = createAction('[AlertSubscriptions] Remove alert subscription fail', props<{error: any}>());
