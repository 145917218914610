import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './alert-subscriptions.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AlertSubscriptionsEffects} from './alert-subscriptions.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('alertSubscriptions', reducer),
    EffectsModule.forFeature(
      [AlertSubscriptionsEffects]
    ),
  ]
})
export class AlertSubscriptionsStoreModule {}
