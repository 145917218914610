import {createAction, props} from '@ngrx/store';
import {AlertConditionSet} from '../alert-condition.type';

export const LoadAlertConditions = createAction('[AlertConditions] Load security exclusions');
export const LoadAlertConditionsSuccess = createAction('[AlertConditions] Load alertConditions success', props<{alertConditions: AlertConditionSet[]}>());
export const LoadAlertConditionsFail = createAction('[AlertConditions] Load alertConditions fail', props<{error: any}>());

export const CreateAlertCondition = createAction('[AlertConditions] Create clients', props<{alertCondition: AlertConditionSet}>());
export const CreateAlertConditionSuccess = createAction('[AlertConditions] Create clients success', props<{alertCondition: AlertConditionSet}>());
export const CreateAlertConditionFail = createAction('[AlertConditions] Create clients fail', props<{error: any}>());

export const UpdateAlertCondition = createAction('[AlertConditions] Update securityExclusion', props<{alertCondition: AlertConditionSet}>());
export const UpdateAlertConditionSuccess = createAction('[AlertConditions] Update securityExclusion success', props<{alertCondition: AlertConditionSet}>());
export const UpdateAlertConditionFail = createAction('[AlertConditions] Update securityExclusion fail', props<{error: any}>());

export const UpdateAlertConditions = createAction('[AlertConditions] Update alertConditions', props<{alertConditions: AlertConditionSet[]}>());
export const UpdateAlertConditionsSuccess = createAction('[AlertConditions] Update alertConditions success', props<{alertConditions: AlertConditionSet[]}>());
export const UpdateAlertConditionsFail = createAction('[AlertConditions] Update alertConditions fail', props<{error: any}>());

export const RemoveAlertCondition = createAction('[AlertConditions] Remove securityExclusion', props<{alertCondition: AlertConditionSet}>());
export const RemoveAlertConditionSuccess = createAction('[AlertConditions] Remove securityExclusion success', props<{alertCondition: AlertConditionSet}>());
export const RemoveAlertConditionFail = createAction('[AlertConditions] Remove securityExclusion fail', props<{error: any}>());

export const RemoveAlertConditions = createAction('[AlertConditions] Remove alertConditions', props<{alertConditions: AlertConditionSet[]}>());
export const RemoveAlertConditionsSuccess = createAction('[AlertConditions] Remove alertConditions success', props<{alertConditions: AlertConditionSet[]}>());
export const RemoveAlertConditionsFail = createAction('[AlertConditions] Remove alertConditions fail', props<{error: any}>());
