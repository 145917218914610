import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {UtilsService} from '../shared/services/utils.service';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../shared/services/ui/notification.service';
import {FirmService} from '../firm/firm.service';
import {Split} from './billing';
import _keyBy from 'lodash-es/keyBy';

@Injectable({
  providedIn: 'root'
})
export class BillingSplitsService {
  private endpoint: string;
  public splits: any[] = [];

  constructor(
    private utils: UtilsService,
    private http: HttpClient,
    private notifications: NotificationService,
    private firmService: FirmService
  ) {
    this.endpoint = `${environment.apiV2Url}/billing/splits`;
  }

  constructSplit(): Split {
    return new Split(this.firmService.firm.id);
  }

  get splitsByID() {
    return _keyBy(this.splits, 'id');
  }


  save(split) {
    if (!split.name) {
      split.name = `${split.splitter_name}: ${parseFloat(split.percentage).toFixed(1)}`;
    }

    const url = split.id ? `${this.endpoint}/${split.id}` : this.endpoint;
    const method = split.id ? 'put' : 'post';

    return this.http[method](url, split).toPromise()
      .then((resp: any) => {
        if (split.id) {
          this.splits = this.splits.map((c) => (c.id === resp.id) ? resp : c);
        } else {
          this.splits.push(resp);
        }
        return this.splits;
      })
      .catch((err) => {
        // TODO - need to correct message headers;
        const message = (err.status === 403) ? err.data.message : 'There was a problem saving this billing split. ' +
          'Please contact support: support@bridgeft.com.';

        this.notifications.showErrorNotification(message);

        return Promise.reject(err);
      });
  }


  removeSplit(split) {
    if (!split.id) {
      return;
    }

    const url = `${this.endpoint}/${split.id}`;

    return this.http.delete(url).toPromise()
      .then(() => {
        this.splits = this.splits.filter((s) => s.id !== split.id);
    });
  }

  removeSplits(splits) {
    const data = {
      ids: splits.map((s) => s.id),
    };

    const url = this.endpoint;

    const options = {
      body: data
    };

    // Body on delete request to work with ALB API
    // @ts-ignore
    return this.http.delete(url, options).toPromise()
      .then(() => {
        this.splits = this.splits.filter((split) => !data.ids.includes(split.id));
      });
  }


  fetchAll() {
    if (this.splits.length > 0) {
      return Promise.resolve(this.splits);
    }
    const url = this.endpoint;

    return this.http.get(url).toPromise()
      .then((resp: {data: any}) => {
        this.utils.clearArray(this.splits);
        this.splits = resp.data;
        return this.splits;
      });
  }
}
