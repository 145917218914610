import {ParameterState, selectAllParameters} from './parameters.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: ParameterState;
}

const getParameterFeatureState = createFeatureSelector<ParameterState>('parameters');

export const getParameters = createSelector(
  getParameterFeatureState,
  selectAllParameters
);

export const getLoading = createSelector(
  getParameterFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getParameterFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getParameterFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getParameterFeatureState,
  state => state.updateCallState.loading
);
