import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Index} from './index';
import {HttpClient} from '@angular/common/http';
import _keyBy from 'lodash-es/keyBy';

@Injectable({
  providedIn: 'root'
})
export class IdcService {
  readonly endpoint = `${environment.apiV2Url}/data/idc/indexes`;
  public indexes: Index[] = [];
  constructor(private http: HttpClient) { }

  get indexesByID() {
    return _keyBy(this.indexes, 'id');
  }

  getIndexes() {
    return this.http.get(this.endpoint).toPromise()
      .then((resp: {data: Index[]}) => {
        this.indexes = resp.data;

        return this.indexes;
    });
  }
}
