import {Component, Input, OnInit} from '@angular/core';
import {FirmService} from '../../../firm/firm.service';
import {UserProfileService} from '../../services/auth/user-profile.service';
import _includes from 'lodash-es/includes';
import _forEach from 'lodash-es/forEach';
import _set from 'lodash-es/set';
import {PermissionsObject} from '../../type_classes/permissions-object';
import {ProfileService} from '../../services/profile.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  @Input() permissionsObject: any;
  @Input() checkPermissions: any;
  @Input() seedPermissions: any;

  checkFirmPermissions: any;
  seedPermissionsAttribute: any;

  currentFirm: any;

  permissions = new PermissionsObject();

  canReadInvitations = false;

  // Help Window flags
  isPermissionHelpVisible = false;
  isFeeStructuresHelpVisible = false;
  isBillingGroupsHelpVisible = false;
  isMinimumsHelpVisible = false;
  isSplitsHelpVisible = false;
  isAssetAdjustmentsHelpVisible = false;
  isBillingReportsHelpVisible = false;
  isInvoicesHelpVisible = false;
  isReportSettingsHelpVisible = false;
  isBenchmarksHelpVisible = false;
  isAssetClassificationsHelpVisible = false;
  isWebReportsHelpVisible = false;
  isPrintableReportsHelpVisible = false;
  isAccountsHelpVisible = false;
  isHouseholdsHelpVisible = false;
  isHeldawaysHelpVisible = false;
  isFileSharingHelpVisible = false;
  isSharedLinksHelpVisible = false;
  isInvitationsHelpVisible = false;
  isFirmUsersHelpVisible = false;
  isRolesHelpVisible = false;
  isFirmSettingsHelpVisible = false;
  isAllPermissionsHelpVisible = false;

  constructor(private firmService: FirmService,
              private userProfileService: UserProfileService,
              private profileService: ProfileService) { }

  ngOnInit() {
    this.currentFirm = this.firmService.firm;

    this.checkFirmPermissions = this.checkPermissions || true;
    this.seedPermissionsAttribute = this.seedPermissions || 'granted_permissions';

    this.updatePermissionsObject();

    // If * permissions, check All();
    if (this.hasAllPermissions()) {
      this.checkAll();
    }
  }

  allPermissionsEnabled() {
    // If permissions object is a role or firm, return true by default
    // this can be indicated by the seedPermissionObject not being 'granted_permissions' which is the field on the userprofile type
    if (this.seedPermissionsAttribute !== 'granted_permissions') {
      return true;
    }
    return this.userProfileService.userProfile.is_owner && this.permissionsObject.id !== this.userProfileService.userProfile.id;
  }

  permissionsEnabled(permission: string): boolean {
    if (this.userProfileService.userProfile.is_owner !== true) {
      return false;
    }

    if (this.checkFirmPermissions) {
      // permission cannot be modified if it's not part of the firm permission,
      // and firm permissions are checked in the widget
      if (_includes(this.currentFirm.permissions, '*')) {
        return true;
      }

      return _includes(this.currentFirm.permissions, permission);
    }

    return true;
  }

  hasAllPermissions(): boolean {
    // If user has * permissions return true
    return this.permissions['*'];
  }

  toggleAllPermissions() {
    if (this.permissions['*']) {
      delete this.permissions['*'];
    } else {
      this.checkAll();
      this.permissions['*'] = true;
    }

    this.updatePermissionsList();
  }

  checkAll() {
    this.bulkChangeAccountManagementPermissions(true);
    this.bulkChangeBillingPermissions(true);
    this.bulkChangeFileSharingPermissions(true);
    this.bulkChangeReportingPermissions(true);
    this.bulkChangeSettingsPermissions(true);
    this.bulkChangeUserManagementPermissions(true);
  }

  permissionDisabled(permission: string): boolean {
    if (this.hasAllPermissions()) {
      return true;
    }

    return !this.permissionsEnabled(permission);
  }

  // TODO: permissions object should be seeded by a different list, provided to the permissions widget
  updatePermissionsList() {
    const ls = [];
    let code = '';
    _forEach(this.permissions, (data1, prefix1) => {
      if (prefix1 === '*') {
        ls.push(prefix1);
      }
      // no overload matches this call warning suppressed
      // @ts-ignore
      _forEach(data1, (data2, prefix2) => {
        // @ts-ignore
        _forEach(data2, (value, key) => {
          if (value === true) {
            code = [prefix1, prefix2, key].join('.');
            ls.push(code);
          } else {
            // New V2 Data Custodian permissions were breaking here, instead of three properties, they have four
            // @ts-ignore
            _forEach(value, (val, key2) => {
              if (val) {
                code = [prefix1, prefix2, key, key2].join('.');
                ls.push(code);
              }
            });
          }
        });
      });
    });

    this.permissionsObject.permissions = ls;
  }

  updatePermissionsObject() {
    this.permissions = new PermissionsObject();
    _forEach(this.permissionsObject[this.seedPermissionsAttribute], (code) => {
      _set(this.permissions, code, true);
    });
  }

  bulkChangeBillingPermissions(value) {
    const ls = [
      'permissions.billing.fee_structure.read',
      'permissions.billing.fee_structure.create',
      'permissions.billing.fee_structure.update',
      'permissions.billing.fee_structure.delete',
      'permissions.billing.fee_structure.assign',

      'permissions.billing.group.read',
      'permissions.billing.group.create',
      'permissions.billing.group.update',
      'permissions.billing.group.delete',

      'permissions.billing.minimum.read',
      'permissions.billing.minimum.create',
      'permissions.billing.minimum.update',
      'permissions.billing.minimum.delete',

      'permissions.billing.split.read',
      'permissions.billing.split.create',
      'permissions.billing.split.update',
      'permissions.billing.split.delete',

      'permissions.billing.asset_adjustment.read',
      'permissions.billing.asset_adjustment.create',
      'permissions.billing.asset_adjustment.update',
      'permissions.billing.asset_adjustment.delete',

      'permissions.billing.report.read',
      'permissions.billing.report.create',
      'permissions.billing.report.download',
      'permissions.billing.report.delete',

      'permissions.billing.invoice.read',
    ];

    _forEach(ls, (path) => {
      if (!this.permissionDisabled(path)) {
        _set(this, path, value);
      }
    });

    this.updatePermissionsList();
  }

  bulkChangeReportingPermissions(value) {
    const ls = [
      'permissions.reporting.settings.read',
      'permissions.reporting.settings.update',

      'permissions.reporting.benchmark.read',
      'permissions.reporting.benchmark.create',
      'permissions.reporting.benchmark.update',
      'permissions.reporting.benchmark.delete',
      'permissions.reporting.benchmark.assign',

      'permissions.reporting.asset_classification.read',
      'permissions.reporting.asset_classification.create',
      'permissions.reporting.asset_classification.update',
      'permissions.reporting.asset_classification.delete',
      'permissions.reporting.web.create',

      'permissions.reporting.printable.read',
      'permissions.reporting.printable.create',
      'permissions.reporting.printable.download',
      'permissions.reporting.printable.delete',
    ];

    _forEach(ls, (path) => {
      if (!this.permissionDisabled(path)) {
        _set(this, path, value);
      }
    });

    this.updatePermissionsList();
  }

  bulkChangeAccountManagementPermissions(value) {
    const ls = [
      'permissions.account_management.account.read',
      'permissions.account_management.account.update',

      'permissions.account_management.household.read',
      'permissions.account_management.household.update',

      'permissions.account_management.heldaway.read',
      'permissions.account_management.heldaway.create',
      'permissions.account_management.heldaway.update',
      'permissions.account_management.heldaway.delete',
    ];

    _forEach(ls, (path) => {
      if (!this.permissionDisabled(path)) {
        _set(this, path, value);
      }
    });

    this.updatePermissionsList();
  }

  bulkChangeFileSharingPermissions(value) {
    const ls = [
      'permissions.file_sharing.file.read',
      'permissions.file_sharing.file.create',
      'permissions.file_sharing.file.delete',

      'permissions.file_sharing.link.read',
      'permissions.file_sharing.link.create',
      'permissions.file_sharing.link.update',
      'permissions.file_sharing.link.delete',
    ];

    _forEach(ls, (path) => {
      if (!this.permissionDisabled(path)) {
        _set(this, path, value);
      }
    });

    this.updatePermissionsList();
  }

  bulkChangeUserManagementPermissions(value) {
    const ls = [
      'permissions.user_management.client_user.read',
      'permissions.user_management.firm_user.read',
      'permissions.user_management.client_user.create',
      'permissions.user_management.firm_user.create',
      'permissions.user_management.client_user.update',
      'permissions.user_management.firm_user.update',
      'permissions.user_management.client_user.delete',
      'permissions.user_management.firm_user.delete',
      'permissions.user_management.role.read',
      'permissions.user_management.role.create',
      'permissions.user_management.role.update',
      'permissions.user_management.role.delete',
    ];

    _forEach(ls, (path) => {
      if (!this.permissionDisabled(path)) {
        _set(this, path, value);
      }
    });

    this.updatePermissionsList();
  }

  bulkChangeSettingsPermissions(value) {
    const ls = [
      'permissions.firm.settings.read',
      'permissions.firm.settings.update',
    ];

    _forEach(ls, (path) => {
      if (!this.permissionDisabled(path)) {
        _set(this, path, value);
      }
    });

    this.updatePermissionsList();
  }
}
