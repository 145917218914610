import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromBalances from './balances.reducer';

export interface State  {
  products: fromBalances.BalanceState;
}

const getBalanceFeatureState = createFeatureSelector<fromBalances.BalanceState>('balances');

export const getBalances = createSelector(
  getBalanceFeatureState,
  fromBalances.selectAllBalances
);

export const getLoading = createSelector(
  getBalanceFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getBalanceFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getBalanceFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getBalanceFeatureState,
  state => state.updateCallState.loading
);
