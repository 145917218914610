import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSeriesName'
})
export class TimeSeriesNamePipe implements PipeTransform {

  transform(value: string): string {
    const nameParts = value.split('.');

    return nameParts[nameParts.length - 1].split('_').join(' ');
  }
}
