import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import _groupBy from 'lodash-es/groupBy';
import _map from 'lodash-es/map';
import {ContactIntegrationRelationship, WealthboxContact} from './type-classes/wealthbox-contact';
import {Household} from '../../../households/household';
import {NotificationService} from '../../../shared/services/ui/notification.service';
import {Note} from './type-classes/wealthbox-note';
import {Observable, of} from 'rxjs';
import {first, map, take, tap} from 'rxjs/operators';
import {CognitoService} from '../../../shared/services/auth/cognito.service';
import { Deferred } from 'ts-deferred';
import {WealthboxSettingsService} from './wealthbox-settings/wealthbox-settings.service';

@Injectable({
  providedIn: 'root'
})
export class WealthboxService {
  integrations: ContactIntegrationRelationship[] = [];
  notes: Note[] = [];

  notesUrl: string;
  fetchContactsError: any;

  constructor(
    private http: HttpClient,
    private notification: NotificationService,
    private cognitoService: CognitoService,
    private wealthboxSettingsService: WealthboxSettingsService,
  ) {
    this.notesUrl = `${environment.apiWealthbox}/notes`;
  }

  contactsLookupStoreConfig = {
    key: 'id',
    load: (options) => {
      const params = {
        name: options.searchValue || ''
      };
      return this.getContacts(params)
        .then((data: any) => {
          return _map(_groupBy(data.contacts, 'type'), (v, k) => {
            return {key: k, items: v};
          });
        })
        .catch(() => {
          this.notification.showErrorNotification(`We are not able to load Wealthbox contacts. Please ensure you have a valid API
          token entered. Feel free to contact support@bridgeft.com if you continue to have errors`);

          return Promise.reject();
        });
    },
    byKey(item) {
      return item;
    }
  };

  getNotes(options = {}): Observable<any> {
    const url = `${environment.apiWealthbox}/notes`;
    const params = new HttpParams({fromObject: options});

    return this.http.get(url, {params}).pipe(
      tap((resp) => {
        this.notes = resp.status_updates;
      })
    );
  }

  createNote(note: Note) {
    return this.http.post(this.notesUrl, note).pipe(
      tap((resp: Note) => {
        this.notes.unshift(resp);
      }),
      take(1)
    );
  }

  saveNote(note: Note): Observable<any> {
    const url = `${this.notesUrl}/${note.id}`;

    return this.http.put(url, note).pipe(
      tap((resp) => {
        const index = this.notes.findIndex((n) => note.id === n.id);
        this.notes[index] = resp;
      })
    );
  }

  searchContactsByName(options): Observable<WealthboxContact[]> {
    const url = `${environment.apiWealthbox}/contacts`;
    const params = new HttpParams({fromObject: options});

    return this.http.get<WealthboxContact[]>(url, {params}).pipe(
      map((resp: any) => resp.contacts)
    );
  }

  // TODO: Only used by the aging assets widget, which is currently diabled
  getAllContacts(): Promise<WealthboxContact[]> {
    // arbitrary number to expand contacts per page from default of 25 due to wealthbox api pagination
    const contactsPerPage = 500;
    let page = 1;
    const url = `${environment.apiWealthbox}/contacts`;
    const content = [];
    const deferred = new Deferred<WealthboxContact[]>();

    const makeCall = () => {
      const params = new HttpParams({fromObject: {perPage: contactsPerPage.toString(), page: page.toString()} });
      this.http.get(url, { params }).toPromise()
        .then((response: any) => {
          content.push(...response.contacts);
          if (response.meta.page < response.meta.total_pages) {
            page += 1;
            makeCall();
          } else {
            deferred.resolve(content);
          }
        });
    };

    makeCall();
    return deferred.promise;
  }

  getContacts(options = {}) {
    const url = `${environment.apiWealthbox}/contacts`;
    const params = new HttpParams({fromObject: options});

    return this.http.get(url, {params}).toPromise();
  }

  searchContacts(options = {}): Observable<WealthboxContact[]> {
    const url = `${environment.apiWealthbox}/contacts`;
    const params = new HttpParams({fromObject: options});

    return this.http.get<WealthboxContact[]>(url, {params});
  }


  getContact(id, options = {}): Promise<WealthboxContact> {
    const url = `${environment.apiWealthbox}/contacts/${id}`;
    const params = new HttpParams({fromObject: options});

    return this.http.get<WealthboxContact>(url, {params}).toPromise();
  }

  getContactObservable(id, options = {}): Observable<WealthboxContact> {
    const url = `${environment.apiWealthbox}/contacts/${id}`;
    const params = new HttpParams({fromObject: options});

    return this.http.get<WealthboxContact>(url, {params});
  }

  getActivityStream(options = {}) {
    const url = `${environment.apiWealthbox}/activity`;
    const params = new HttpParams({fromObject: options});

    return this.http.get(url, {params}).toPromise();
  }

  getWealthboxIntegrationConfig() {
    return this.wealthboxSettingsService.configuration$.pipe(
      map((config) => {
        if (!config) {
          return null;
        }

        return config;
      }),
      first()
    );
  }

  // TODO: See about removing this
  getIntegrations(useCache = true) {
    return Promise.resolve([]);
  }

  // TODO: See about removing this
  addIntegration(
    household: Household,
    wealthboxContact: WealthboxContact) {
    return Promise.resolve({});
  }

  // TODO: See about removing this
  removeIntegrations(id: string | string[]) {
    return Promise.resolve([]);
  }

  get noteEditorConfig() {
    return {
      extraPlugins: [this.MentionCustomizePlugin],
      placeholder: 'Enter a note...',
      mention: {
        feeds: [
          {
            marker: '@',
            minimumCharacters: 1,
            feed: (queryString: string) => {
              return new Promise(resolve => {
                const options = {
                  name: queryString,
                  per_page: 5
                };
                this.searchContactsByName(options).subscribe((contacts: WealthboxContact[]) => {
                  resolve(contacts.map((i: any) => {
                    i.userId = i.id;
                    i.id = '@' + i.name;

                    return i;
                  }));
                });
              });
            },
            itemRenderer: (item) => {
              const itemElement = document.createElement( 'span' );

              itemElement.classList.add( 'custom-item' );
              itemElement.id = `mention-list-item-id-${ item.userId }`;
              itemElement.textContent = `${ item.name } `;

              return itemElement;
            }
          }
        ]
      }
    };
  }

  MentionCustomizePlugin = (editor) => {
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'b',
        key: 'data-mention',
        classes: 'mention',
        attributes: {
          name: true,
          contactMentionId: true,
          id: true
        }
      },
      model: {
        key: 'mention',
        value: viewItem => {
          const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
            name: viewItem.getAttribute('name'),
            userId: viewItem.getAttribute('id')
          });

          return mentionAttribute;
        }
      },
      converterPriority: 'high'
    });

    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue, viewWriter) => {

        if (!modelAttributeValue) {
          return;
        }

        return viewWriter.createAttributeElement('b', {
          class: 'test',
          contactMentionId: `${modelAttributeValue.userId}-${modelAttributeValue.name}`
        });
      },
      converterPriority: 'high'
    });
  }
}
