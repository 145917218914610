<div class="loading">

</div>

<div class="login-margin">
  <div class="background-layer">
  </div>

  <div class="login-page">
    <div class="section login">

      <div class="back-btn" *ngIf="resetMode" (click)="backToLogin()">
        <mat-icon>keyboard_backspace</mat-icon>
        Back to login
      </div>


      <div class="login-container">

        <div  class="section-item logo-container">
          <img *ngIf="brandedLogin && !defaultLogin" [src]="brandedLogin.logo_url" class="firm-logo" alt="Logo">

          <img *ngIf="defaultLogin" src="../../../assets/images/BridgeFT_LOGO_BLACK.png" alt="Bridge Financial Technology Logo" class="firm-logo">
        </div>

        <div *ngIf="!resetMode && !enterPasswordMode && !forceChangePasswordMode">
          <div class="section-item welcome-header">
            Welcome to Atlas!
          </div>

          <div [ngClass]="errorMessage ? 'msg-container error' : 'msg-container success'"
               *ngIf="message"
               (click)="closeMessage()">
            <div class="icon-container">
              <i *ngIf="errorMessage" class="fas fa-times-circle error-msg-close"></i>

              <i *ngIf="!errorMessage" class="fas fa-check-circle success-msg-close"></i>
            </div>

            {{message}}
          </div>


          <div class="section-item">
            <form>
              <mat-form-field appearance="outline" style="width: 100%;">
                <input type="text"
                       matInput
                       autofocus
                       autocomplete="off"
                       placeholder="Email or Username"
                       (keydown.enter)="login()"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="user.username">
                <mat-icon matPrefix class="login-icon">person</mat-icon>
              </mat-form-field>
              <br>

              <mat-form-field appearance="outline" style="width: 100%;">
                <input type="password"
                       matInput
                       autocomplete="off"
                       placeholder="Password"
                       (keydown.enter)="login()"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="user.password">
                <mat-icon matPrefix class="login-icon">lock</mat-icon>
              </mat-form-field>

              <div class="section-item" style="margin-top: 3.5px; justify-content: flex-start;">
                <app-atlas-submit-button class="sign-in-btn"
                                         [disabled]="user.password === '' || user.username === ''"
                                         [saving]="loggingIn"
                                         (click)="login()">
                  Sign In
                </app-atlas-submit-button>

                <a class="btn forgot-password" (click)="turnResetModeOn()">Forgot your password?</a>
              </div>
            </form>
          </div>
        </div>

        <div class="section-item" style="padding-top: 20px; text-align: justify" *ngIf="brandedLogin">
          Need credentials? Please reach out to your contact or representative at {{ brandedLogin.name }} for login credentials.
        </div>

        <div class="section-item" style="padding-top: 20px; text-align: justify" *ngIf="!brandedLogin && defaultLogin">
          Need credentials? Please reach out to your contact or representative at BridgeFT for login credentials.
        </div>

        <app-reset-password *ngIf="resetMode"></app-reset-password>

        <app-enter-password *ngIf="enterPasswordMode"></app-enter-password>

        <app-force-change-password *ngIf="forceChangePasswordMode"></app-force-change-password>
      </div>



      <div class="footer">
        Powered by
        <div class="footer-img-container">
          <img src="../../../assets/images/BridgeFT_LOGO_BLACK.png" alt="Bridge Financial Technology Logo" class="firm-logo">
        </div>
      </div>
    </div>


    <div class="section-two"></div>
  </div>
</div>
