import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromFeeStructures from './fee-structure.reducer';

export interface State  {
  products: fromFeeStructures.FeeStructureState;
}

const getFeeStructureFeatureState = createFeatureSelector<fromFeeStructures.FeeStructureState>('feeStructures');

export const getFeeStructures = createSelector(
  getFeeStructureFeatureState,
  fromFeeStructures.selectAllFeeStructures
);

export const getLoading = createSelector(
  getFeeStructureFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getFeeStructureFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getFeeStructureFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getFeeStructureFeatureState,
  state => state.updateCallState.loading
);
