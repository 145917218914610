import {Injectable} from '@angular/core';
import {AppConstants} from '../../../../app-constants';

@Injectable({
  providedIn: 'root'
})
export class FeeStructureBulkUploadService {

  downloadFileType = AppConstants.FILE_TYPE.EXCEL;
  dataToExport: any[];

  constructor() {
    // This json payload will be used while downloading the Excel template file.
    // These two items will be ignored when processing the upload.
    this.dataToExport = [
      {
        Name: 'Test-1',
        'Collection Type': 'Arrears',
        Structure: 'Flat Rate',
        'Valuation Type': 'Ending Period Balance',
        'Billing Frequency': 'Quarterly',
        Cycle: 'Jan - Apr - Jul - Oct',
        'Flat Rate': '2',
        'Fee Amount': ''
      },
      {
        Name: 'Test-2',
        'Collection Type': 'Advance',
        Structure: 'Flat Account Fee',
        'Valuation Type': 'Average Daily Balance',
        'Billing Frequency': 'Semi-Annual',
        Cycle: 'June - Dec',
        'Flat Rate': '',
        'Fee Amount': '5000.00'
      }
    ];
  }

  prepareDataGrid(uploadedDataStream) {
    const feeStructureData = [];

    uploadedDataStream.forEach(f => {
      if (f.Name === 'Test-1' || f.Name === 'Test-2') {
        return;
      }

      feeStructureData.push(
        {
          name: f.Name,
          collection_type: this.prepareCollectionType(f['Collection Type']),
          structure: this.prepareStructure(f.Structure),
          valuation_type: this.prepareValuationType(f['Valuation Type']),
          billing_frequency: this.prepareBillingFrequency(f['Billing Frequency']),
          cycle: this.prepareCycle(f),
          flat_rate: f['Flat Rate'],
          fee_amount: f['Fee Amount']
        }
      );
    });

    return feeStructureData
  }

  // upon uploading the template file, the prepare methods uses RegEx to return Billing Constants.

  // prepareCollectionType matches collection type: Advance, Arrears, Advance with Proration.
  // Here:
  // 'P' represents  Advance with Proration.
  // 'D' represents Advance
  // 'R' represents Arrears
  prepareCollectionType(c) {
    if (c === null || c === '' || c === undefined) {
      return null;
    }

    const advance = new RegExp(/ad/i);
    const arrears = new RegExp(/ar/i);
    const proration = new RegExp(/ad.* p/i);
    if (proration.test(c)) {
      return 'P';
    }
    if (advance.test(c)) {
      return 'D';
    }
    if (arrears.test(c)) {
      return 'R';
    }

    return null;
  }

  // prepareStructure matches calculation types: Flat Rate, Flat Amount, Tiered, Drop Through, etc.
  // Here:
  // 'R' represents  Flat Rate.
  // 'A' represents Account Flat Amount
  // 'G' represents Group Flat Amount
  // 'D' represents Drop Through
  // 'T' represents Tiered
  // 'F' represents Favored
  prepareStructure(s) {
    if (s === null || s === '' || s === undefined) {
      return null;
    }

    const flatRate = new RegExp(/fl.* rate/i);
    const flatAmount = new RegExp(/acc/i);
    const flatGroup = new RegExp(/group/i);
    const dropThrough = new RegExp(/drop/i);
    const tiered = new RegExp(/ti/i);
    const favor = new RegExp(/fav/i);

    if (flatRate.test(s)) {
      return 'R';
    }
    if (flatAmount.test(s)) {
      return 'A';
    }
    if (flatGroup.test(s)) {
      return 'G';
    }
    if (dropThrough.test(s)) {
      return 'D';
    }
    if (tiered.test(s)) {
      return 'T';
    }
    if (favor.test(s)) {
      return 'F';
    }
    return null;
  }

  // prepareValuationType matches balance or valuation types: Ending Period Balance, Average Daily Balance, Ending with Adjustment.
  // Here:
  // 'P' represents Ending Balance with Adjustment.
  // 'E' represents Ending Period Balance
  // 'A' represents Average Daily Balance
  prepareValuationType(v) {
    if (v === null || v === '' || v === undefined) {
      return null;
    }

    const ending = new RegExp(/end/i);
    const daily = new RegExp(/av/i);
    const adjustment = new RegExp(/end.* adj/i);
    if (adjustment.test(v)) {
      return 'P';
    }
    if (ending.test(v)) {
      return 'E';
    }
    if (daily.test(v)) {
      return 'A';
    }

    return null;
  }

  // prepareBillingFrequency matches balance or valuation types: Monthly, Quarterly, Semi-Annual, Annual.
  // Here:
  // 'M' represents Monthly
  // 'Q' represents Quarterly
  // 'S' represents Semi-Annual
  // 'A' represents Annual
  prepareBillingFrequency(f) {
    if (f === null || f === '' || f === undefined) {
      return null;
    }

    const monthly = new RegExp(/month/i);
    const quarterly = new RegExp(/quarter/i);
    const semiAnnual = new RegExp(/s.*a/i);
    const annual = new RegExp(/annual/i);

    if (monthly.test(f)) {
      return 'M';
    }
    if (quarterly.test(f)) {
      return 'Q';
    }
    if (semiAnnual.test(f)) {
      return 'S';
    }
    if (annual.test(f)) {
      return 'A';
    }
    return null;
  }

  // prepareCycle matches the billing cycle for the billing frequency. For example, Quarterly Billing Frequency has
  // three cycles: 'Jan - Apr - Jul - Oct', 'Feb - May - Aug - Nov', and 'Mar - Jun - Sep - Dec'. Similar Semi-Annual frequency has six cycles.
  // And each of these cycles has a number assigned. 1-3 for Quarterly, 4-9 for Semi-Annual, and 9-21 for Annual.
  // These assigned numbers can be found in billing.constants.ts file under `BILLING_FREQUENCY_WITH_CYCLE` object.
  // Now, if the selected billing frequency is Semi-Annual or just 'S' and the selected cycle is 'May - Nov', this method
  // will return number '8' which correspond to 'May - Nov' cycle of Semi annual billing frequency in `BILLING_FREQUENCY_WITH_CYCLE`
  prepareCycle(feeStructure) {
    const c = feeStructure.Cycle;
    const f = this.prepareBillingFrequency(feeStructure['Billing Frequency']);

    if (c === null || c === '' || c === undefined) {
      return null;
    }

    const jan = new RegExp(/jan/i);
    const feb = new RegExp(/feb/i);
    const mar = new RegExp(/mar/i);
    const apr = new RegExp(/apr/i);
    const may = new RegExp(/may/i);
    const jun = new RegExp(/jun/i);
    const jul = new RegExp(/jul/i);
    const aug = new RegExp(/aug/i);
    const sep = new RegExp(/sep/i);
    const oct = new RegExp(/oct/i);
    const nov = new RegExp(/nov/i);
    const dec = new RegExp(/dec/i);

    if (jan.test(c)) {
      if (f === 'Q') {
        return 1;
      } else if (f === 'S') {
        return 4;
      } else if (f === 'A') {
        return 10;
      }
    }
    if (feb.test(c)) {
      if (f === 'Q') {
        return 2;
      } else if (f === 'S') {
        return 5;
      } else if (f === 'A') {
        return 11;
      }
    }
    if (mar.test(c)) {
      if (f === 'Q') {
        return 3;
      } else if (f === 'S') {
        return 6;
      } else if (f === 'A') {
        return 12;
      }
    }
    if (apr.test(c)) {
      if (f === 'S') {
        return 7;
      } else if (f === 'A') {
        return 13;
      }
    }
    if (may.test(c)) {
      if (f === 'S') {
        return 8;
      } else if (f === 'A') {
        return 14;
      }
    }
    if (jun.test(c)) {
      if (f === 'S') {
        return 9;
      } else if (f === 'A') {
        return 15;
      }
    }
    if (jul.test(c)) {
      if (f === 'A') {
        return 16;
      }
    }
    if (aug.test(c)) {
      if (f === 'A') {
        return 17;
      }
    }
    if (sep.test(c)) {
      if (f === 'A') {
        return 18;
      }
    }
    if (oct.test(c)) {
      if (f === 'A') {
        return 19;
      }
    }
    if (nov.test(c)) {
      if (f === 'A') {
        return 20;
      }
    }
    if (dec.test(c)) {
      if (f === 'A') {
        return 21;
      }
    }
    return null;
  }


}
