import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivityService} from '../activity.service';
import {Transaction} from '../../../../shared/type_classes/luca/transaction';
import {Account} from '../../../../accounts/account';
import {BillingGroupsService} from '../../../../billing/billing-groups.service';
import {FeeStructuresService} from '../../../billing/fee-structures/fee-structures.service';
import {BenchmarkService} from '../../../../benchmark/benchmark.service';
import {BillingReport} from '../../../../shared/type_classes/billing-report';
import {JobsService} from '../../../../shared/services/jobs.service';
import {AppConstants, BRAND_COLORS, CASH_SECURITY_ID} from '../../../../app-constants';
import {DownloadPrintableReportsService} from '../../../../shared/services/download-printable-reports.service';
import { MatDialog } from '@angular/material/dialog';
import {BillingSelectFeeStructuresForAssignmentModalComponent} from '../../../../billing/billing-select-fee-structures-for-assignment-modal/billing-select-fee-structures-for-assignment-modal.component';
import {BillingReportService} from '../../../../billing/billing-report.service';
import {PortformerService, PortformerWithCurrentSecurityPosition} from '../../portformer/portformer.service';
import {interval, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, map, startWith} from 'rxjs/operators';
import {PortformerSettingsService} from '../../portformer/portformer-settings/portformer-settings.service';
import {SidePanelService} from '../../../../layout/side-panel/side-panel.service';
import {FirmService} from '../../../../firm/firm.service';
import {ColorsService} from '../../../../shared/services/colors.service';

@Component({
  selector: 'app-atlas-activity',
  templateUrl: './atlas-activity.component.html',
  styleUrls: ['./atlas-activity.component.scss']
})
export class AtlasActivityComponent implements OnInit, OnDestroy {
  transactions: Transaction[];
  newAccounts: Account[];
  lastBillingReport: BillingReport;
  latestPDFReports: any;

  loadingAccounts = true;
  loadingTransactions = true;
  loadingBilling = true;
  loadingReports = true;

  accountInfoExpanded = false;
  transactionInfoExpanded = false;
  reportsExpanded = false;

  benchmarks: any;
  showAssignBenchmarkModal = false;
  accountToEdit: Account;
  etfRecommendations$: Observable<PortformerWithCurrentSecurityPosition[]>;

  cashSecurityID: number;
  private portformerLoadingError$ = new Subject<boolean>();
  portformerEnabled$: Observable<boolean>;

  loading = true;
  data = [];
  dataSubscription$: Subscription;
  error = false;

  firmBrandColors = [];

  constructor(private activityService: ActivityService,
              private billingGroupService: BillingGroupsService,
              private feeStructureService: FeeStructuresService,
              private benchmarkService: BenchmarkService,
              private jobsService: JobsService,
              private pdfReportService: DownloadPrintableReportsService,
              private billingReportService: BillingReportService,
              private dialog: MatDialog,
              private portformerService: PortformerService,
              private colors: ColorsService,
              private firmService: FirmService,
              portformerSettingsService: PortformerSettingsService,
              private sidePanelService: SidePanelService) {
    this.portformerEnabled$ = portformerSettingsService.config$.pipe(map(config => config.enabled));
  }

  ngOnInit() {
    this.firmBrandColors = this.colors.brandColors;

    this.colors.firmUpdated
      .subscribe(firm => {
        if (!firm.brand_colors.length) {
          // this.firmBrandColors = this.colors.getDefaultColors();
          this.firmBrandColors = BRAND_COLORS;
        } else {
          this.firmBrandColors = firm.brand_colors;
        }
      });

    this.cashSecurityID = CASH_SECURITY_ID;
    this.benchmarks = this.benchmarkService.benchmarks;

    this.dataSubscription$ = this.activityService.getActivityFeedData()
      .pipe(
        catchError((err) => {
          this.error = true;
          this.loading = false;
          return of(null);
        })
      )
      .subscribe(resp => {
        this.loading = true;
        this.data = resp;
        this.loading = false;
      });

    // this.etfRecommendations$ = this.portformerService.portformerRecommendations$.pipe(
    //   catchError(() => {
    //     this.portformerLoadingError$.next(true);
    //     return of(null);
    //   })
    // );
  }

  getCardColor(index: number) {
    if (this.firmBrandColors.length && this.firmBrandColors[index]) {
      return this.firmBrandColors[index];
    }
  }

  getBillingGroup(accountID: number) {
    return this.billingGroupService.accountGroupByAccountID[accountID];
  }

  getFeeStructure(feeStructureID: number) {
    return this.feeStructureService.chargeUnitsByID[feeStructureID];
  }

  getBenchmark(benchmarkID: number) {
    return this.benchmarkService.benchmarksByID[benchmarkID];
  }

  downloadInvoices(invoiceIDs: number[]) {
    this.jobsService.startBulkDownload(AppConstants.DOWNLOAD_TYPE.DOWNLOAD_TYPE_INVOICE, invoiceIDs);
  }

  downloadFeeUploadFiles(report: BillingReport) {
    this.billingReportService.downloadBillingReport(report);
  }

  downloadPDF(report) {
    report.name = `${report.account_name ? report.account_name : report.household_name} ${report.start_date} to ${report.end_date}`;

    this.pdfReportService.downloadPDFReport(report).subscribe(() => {}, (err) => {});
  }

  assignBenchmarks(account: Account) {
    this.accountToEdit = account;
    this.showAssignBenchmarkModal = true;
  }

  assignFeeStructures(account: Account) {
    this.dialog.open(BillingSelectFeeStructuresForAssignmentModalComponent, {data: {
        accounts: [account]
      }}).afterClosed()
      .subscribe(() => {

      });
  }

  close() {
    this.sidePanelService.closePanel();
  }

  ngOnDestroy(): void {
    this.dataSubscription$.unsubscribe();
  }
}
