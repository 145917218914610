<div class="content-wrap">
  <button mat-button routerLink="/settings/billing"><mat-icon>arrow_back</mat-icon> Back to Setup</button>

  <mat-card style="margin-top: 10px;">

<!--    <mat-card-title-group>-->
<!--      <mat-card-title>Fee Structures</mat-card-title>-->
<!--      <mat-card-subtitle>Click the name to edit</mat-card-subtitle>-->
<!--    </mat-card-title-group>-->

    <div class="card-header">
      <div class="card-header__title">
        <h2>Fee Structures</h2>
      </div>

      <div class="card-options">
        <div class="search-bar">
          <i matPrefix class="fas fa-search searchbar-icon"></i>
          <input placeholder="Search" (input)="dataGrid.instance.searchByText($event.target.value)">
        </div>

        <button mat-stroked-button
                color="primary"
                (click)="dataGrid.instance.showColumnChooser()">
          <i class="fas fa-columns prefix-icon"></i>
          Columns
        </button>

<!--        <div class="right" style="margin-bottom: 5px">-->
          <button mat-stroked-button color="primary"
                  (click)="openCreateModal()"
                  matTooltip="Create new fee structure">
            <i class="fas fa-plus prefix-icon"></i>
            Create
          </button>

          <button mat-stroked-button
                  color="primary"
                  [matMenuTriggerFor]="bulkMenu">
            Bulk Upload
            <i class="fas fa-caret-down suffix-icon"></i>
          </button>

          <mat-menu #bulkMenu="matMenu">
            <button mat-menu-item
                    (click)="openFeeStructureBulkUpload()">
              Fee Structure Upload
            </button>
            <button mat-menu-item
                    (click)="openFeeAssignmentBulkUpload()">
              Fee Assignment Upload
            </button>
          </mat-menu>


          <button class="btn delete-btn" (click)="removedSelectedFeeStructures()"
                  matTooltip="Delete selected"
          >
<!--            <mat-icon svgIcon="atlas-delete"></mat-icon>-->
            <i class="fas fa-trash prefix-icon"></i>
            Delete
          </button>
<!--        </div>-->
      </div>
    </div>

    <mat-card-content>

<!--      <mat-form-field appearance="outline" floatLabel="Search">-->
<!--        <input id="search-box" matInput placeholder="Search" (input)="dataGrid.instance.searchByText($event.target.value)">-->
<!--        <mat-icon matSuffix>search</mat-icon>-->
<!--      </mat-form-field>-->

<!--      <div class="search-bar">-->
<!--        <i matPrefix class="fas fa-search searchbar-icon"></i>-->
<!--        <input placeholder="Search" (input)="dataGrid.instance.searchByText($event.target.value)">-->
<!--      </div>-->

<!--      <div class="right" style="margin-bottom: 5px">-->
<!--        <button mat-raised-button color="primary"-->
<!--           (click)="openCreateModal()"-->
<!--           matTooltip="Create new fee structure">-->
<!--          Create-->
<!--        </button>-->

<!--        <button mat-raised-button color="primary" (click)="removedSelectedFeeStructures()"-->
<!--          matTooltip="Delete selected"-->
<!--        >-->
<!--          <mat-icon svgIcon="atlas-delete"></mat-icon>-->
<!--        </button>-->
<!--      </div>-->

      <dx-data-grid #dataGrid
                    appFillHeight
                    [dataSource]="feeStructures$ | async"
                    (onSelectionChanged)="onSelection($event)"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true">
        <dxi-column
          caption="Name"
          dataType="string"
          dataField="name"
          cellTemplate="nameTemplate">
        </dxi-column>
        <dxi-column
          caption="Frequency"
          dataType="string"
          dataField="frequency"
          [calculateCellValue]="displayFrequencyValue">
        </dxi-column>
        <dxi-column
          caption="Collection"
          dataType="string"
          dataField="collection_type"
          [calculateCellValue]="displayCollectionValue">
        </dxi-column>
        <dxi-column
          caption="Structure"
          dataType="string"
          dataField="calculation_type"
          [calculateCellValue]="displayCalculationValue">
        </dxi-column>
        <dxi-column
          caption="Valuation Method"
          dataType="string"
          dataField="balance_type"
          [calculateCellValue]="displayBalanceType">
        </dxi-column>
        <dxi-column
          caption="Assigned Accounts"
          dataType="number"
          [allowSorting]="true"
          [calculateCellValue]="countAssignedAccounts"
          [calculateSortValue]="countAssignedAccounts"
          [calculateDisplayValue]="countAssignedAccounts"
        >
        </dxi-column>

        <dxi-column
          caption="Firm"
          dataType="string"
          dataField="firm"
          [calculateCellValue]="displayFirm">
        </dxi-column>

        <dxi-column
          cellTemplate="actionTemplate"
        >

        </dxi-column>

        <dxo-load-panel [enabled]="true" [showPane]="true" [showIndicator]="true"></dxo-load-panel>
        <dxo-export fileName="Fee_Structures_list"></dxo-export>
        <dxo-state-storing [enabled]="true" storageKey="fee_structures"></dxo-state-storing>

        <div *dxTemplate="let row of 'nameTemplate'">
          <button mat-button color="primary" class="mat-button-xs" (click)="openEditModal(row.data.id)">{{row.data.name}}</button>
        </div>

        <div *dxTemplate="let row of 'actionTemplate'">

          <button mat-icon-button
                  color="primary"
                  (click)="openEditModal(row.data.id)"
                  matTooltip="Edit Fee Structure">
            <i class="fas fa-pencil datagrid-icon"></i>
          </button>
          &nbsp;
          <button mat-icon-button
                  color="primary"
                  matTooltip="Delete"
                  (click)="removeFeeStructure(row.data)">
            <i class="fas fa-trash datagrid-icon"></i>
          </button>

        </div>
      </dx-data-grid>

    </mat-card-content>

  </mat-card>
</div>
