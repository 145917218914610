import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingDisplayCalculationType'
})
export class BillingDisplayCalculationTypePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'R') {
      return 'Flat Rate';
    } else if (input === 'A') {
      return 'Flat Fee';
    } else if (input === 'G') {
      return 'Flat Group Fee';
    } else if (input === 'D') {
      return 'Drop Through';
    } else if (input === 'T') {
      return 'Tiered';
    } else if (input === 'F') {
      return 'Favor';
    }

    return '';
  }

}
