import {createAction, props} from '@ngrx/store';
import {Household} from '../household';
import {FeeStructure} from '../../billing/billing';

export const Load = createAction('[Households] Load Households');
export const LoadSuccess = createAction(
  '[Households] Load Households success',
  props<{households: Household[]}>());
export const LoadFail = createAction('[Households] Load Households fail', props<{error: string}>());

export const CreateHousehold = createAction('[Households] create household', props<{household: Household}>());
export const CreateHouseholdSuccess = createAction('[Households] create household Success', props<{household: Household}>());
export const CreateHouseholdFail = createAction('[Households] create household Fail', props<{error: string}>());

export const UpdateHousehold = createAction(
  '[Households] update household',
  props<{household: Household}>());
export const UpdateHouseholdSuccess = createAction(
  '[Household] update household Success',
  props<{household: Household}>());
export const UpdateHouseholdFail = createAction(
  '[Households] update household Fail',
  props<{error: string}>());
export const UpdateHouseholds = createAction(
  '[Households] update Households',
  props<{households: Household[]}>());
export const UpdateHouseholdsSuccess = createAction(
  '[Households] update Households success', props<{households: Household[]}>());
export const UpdateHouseholdsFail = createAction(
  '[Households] update Households failed', props<{error: string}>());

export const RemoveHousehold = createAction('[Households] delete household', props<{household: Household}>());
export const RemoveHouseholdSuccess = createAction(
  '[Households] delete household Success',
  props<{household: Household}>());
export const RemoveHouseholdFail = createAction('[Households] delete household Fail', props<{error: string}>());

export const RemoveMultipleHouseholds = createAction(
  '[Households] delete multiple households', props<{households: Household[]}>());
export const RemoveMultipleHouseholdsSuccess = createAction(
  '[Households] delete multiple households Success',
  props<{households: Household[]}>());
export const RemoveMultipleHouseholdsFail = createAction('[Households] delete multiple households Fail', props<{error: string}>());
