import { Injectable } from '@angular/core';
import {act, Actions, createEffect, ofType} from '@ngrx/effects';
import * as securitiesActions from './securities.actions';
import {catchError, map, mergeMap, take} from 'rxjs/operators';
import {SecuritiesService} from '../securities.service';
import {of} from 'rxjs';


@Injectable()
export class SecuritiesEffects {

  constructor(
    private actions$: Actions,
    private securitiesService: SecuritiesService
  ) { }

  
  loadSecurities$ = createEffect(() => this.actions$.pipe(
    ofType(securitiesActions.Load),
    mergeMap(() => {
      return this.securitiesService.getSecurities().pipe(
        map((securities) => {
          return securitiesActions.LoadSuccess({securities});
        }),
        catchError(error => of(securitiesActions.LoadFail({error})))
      );
    }),
    take(1)
  ));

  
  fetchByIdSecurities$ = createEffect(() => this.actions$.pipe(
    ofType(securitiesActions.FetchById),
    mergeMap((action) => {
      return this.securitiesService.fetchSecurities(action.securityIds).pipe(
        map((securities) => {
          return securitiesActions.FetchByIdSuccess({securities});
        }),
        catchError(error => of(securitiesActions.LoadFail({error})))
      );
    })
  ));

  
  loadManagedSecurityIds$ = createEffect(() => this.actions$.pipe(
    ofType(securitiesActions.LoadManagedIds),
    mergeMap(() => {
      return this.securitiesService.fetchManagedSecurityIDs().pipe(
        map((securityIds) => {
          return securitiesActions.LoadManagedIdsSuccess({securityIds});
        }),
        catchError(error => of(securitiesActions.LoadManagedIdsFail({error})))
      );
    })
  ));
}
