<div class="content-wrap">
  <div *ngIf="firmInfo else defaultLogo" style="display: flex; justify-content: center">
    <a routerLink="/cp/main">
      <img [src]="firmInfo.logo_url" class="firm-logo" alt="Logo" style="height: 100px">
    </a>
  </div>

  <ng-template #defaultLogo>
    <div style="display: flex; justify-content: center">
      <img src="../../../assets/images/Bridge_Black.jpg" alt="Bridge Financial Technology Logo" style="max-height: 100px; ">
    </div>
  </ng-template>

  <div *ngIf="hasError" style="width: 40%; text-align: center; margin: auto;">
    <p *ngIf="errorCode === 404">
      No attachments found.

      <br>

      Please contact your <span *ngIf="firmInfo else defaultFirmName">{{firmInfo.name}} advisor</span> <ng-template #defaultFirmName>advisor</ng-template> to retrieve your documents.
    </p>

    <p *ngIf="errorCode === 401">
      Access to your documents has expired.

      <br>

      Please contact your <span *ngIf="firmInfo else defaultFirmName">{{firmInfo.name}} advisor</span> <ng-template #defaultFirmName>advisor</ng-template> to retrieve your documents.

      If you have client portal access, you can <a routerLink="/cp/main'">login</a> to see if your documents are available in your client portal.
    </p>

    <p *ngIf="errorCode !== 404 && errorCode !== 401">
      An error occurred while retrieving your documents.

      <br>

      Please contact your <span *ngIf="firmInfo else defaultFirmName">{{firmInfo.name}} advisor</span> <ng-template #defaultFirmName>advisor</ng-template> to retrieve your documents.
    </p>
  </div>

  <div style="width: 40%; text-align: center; margin: auto;" *ngIf="!hasError">
    <p>
      New documents are available from your <span *ngIf="firmInfo else defaultFirmName">{{firmInfo.name}} advisor</span> <ng-template #defaultFirmName>advisor</ng-template>.
    </p>

    <p>
      If your documents do not download automatically, please <a routerLink="/client-inbox" (click)="downloadAttachments()">click here</a>.
    </p>
  </div>

</div>

