<div style="padding: 20px">
  <h3>Stage History</h3>

  <dx-data-grid
    [dataSource]="stages$ | async"
  >
    <dxi-column
      caption="Stage"
      dataField="stage"
      dataType="string"
      cellTemplate="stageDescription"
    >
    </dxi-column>

    <div *dxTemplate="let row of 'stageDescription'">
      <div [ngSwitch]="row.value">
        <ng-template ngSwitchCase="LaunchingPreprocess">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Raw data from the custodian has been received. Preparing the data to be normalized."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Launching Preprocess
          </span>
        </ng-template>
        <ng-template ngSwitchCase="Loading">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Stores raw files provided by the custodian."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Loading Raw Data
          </span>
        </ng-template>
        <ng-template ngSwitchCase="DiscoverAccounts">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Stores new accounts provided by the custodian."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Discovering Accounts
          </span>
        </ng-template>
        <ng-template ngSwitchCase="DiscoverSecurities">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Stores previously untracked securities provided by the custodian."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Discovering Securities
          </span>
        </ng-template>
        <ng-template ngSwitchCase="DiscoverPrices">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Stores previously untracked security prices provided by the custodian."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Discovering Prices
          </span>
        </ng-template>
        <ng-template ngSwitchCase="DiscoverAdvisorCodes">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Store new advisor codes that are being provided by the custodian."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Discovering Advisor Codes
          </span>
        </ng-template>
        <ng-template ngSwitchCase="UpdateAccounts">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Storing account metadata (firm information, advisor codes, descriptions, etc.)"
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Persisting Account Data
          </span>
        </ng-template>
        <ng-template ngSwitchCase="Normalize">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Converts raw transactions from the custodian into normalized data types."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Normalize Raw Data
          </span>
        </ng-template>
        <ng-template ngSwitchCase="LaunchingLedgerization">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Preparing normalized data to be ledgerized."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Launching Ledgerization
          </span>
        </ng-template>
        <ng-template ngSwitchCase="Ledgerization">
          <span>
            <i class="far fa-info-circle info-symbol"
               matTooltip="Creating lots, fills, and the cash ledger and aggregating them for reporting purposes."
               matTooltipPosition="above"
            ></i>
            &nbsp;
            Ledgerization
          </span>
        </ng-template>
      </div>
    </div>

    <dxi-column
      caption="Start Time"
      dataField="start_time"
      dataType="date"
      cellTemplate="stageTime"
      sortOrder="asc"
    >
    </dxi-column>

    <dxi-column
      caption="End Time"
      dataField="end_time"
      cellTemplate="stageTime"
    >
    </dxi-column>

    <dxi-column
      caption="Duration"
      dataField="duration"
      dataType="string"
    >
    </dxi-column>

    <dxi-column
      caption="Status"
      dataField="status"
      dataType="string"
    >
    </dxi-column>

    <div *dxTemplate="let row of 'stageTime'">
      {{ row.value | date:'short'}}
    </div>

    <dxo-selection mode="none"></dxo-selection>
  </dx-data-grid>
</div>
