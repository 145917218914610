
<mat-stepper [linear]="false" #stepper>
  <mat-step>
    <ng-template matStepContent>
      <div>
        <h2 mat-dialog-title>
          Fee Structure Assignment
          <div #toolTipTemplate>
            <ol>
              <li>Download the Fee Structure file and the Fee Assignment template.</li>
              <br>
              <li>Populate the Fee Structure ID and Fee Structure Name from the fee structure file to the desired row in fee assignment template file.</li>
              <br>
              <li>Do not change the Account Number, Name, and Custodian.</li>
              <br>
              <li>If there are multiple fee structures for an account,
                use comma seperated list to populate the Fee Structure IDs and Fee Structure Names.
                For example: 1001, 1002, 1003 and Fee1, Fee2, Fee3. </li>
              <br>
              <li>Once the Fee Assignment Template is populated. Please upload it to the upload section.</li>
              <br>
              <li>If there are incorrect Account Numbers or Fee Structure IDs. The system will list those accounts. </li>
            </ol>
          </div>

          <i class="far fa-info-circle info-symbol"
             [ngxTippy]="toolTipTemplate"
             tippyName="aumTooltip"></i>
        </h2>
        <div class="dialog-content" *ngIf="loading">
          <app-material-spinner></app-material-spinner>
        </div>
        <div class="dialog-content" *ngIf="!loading">

          <div style="display: flex; justify-content:flex-end">
            <button mat-button
                    color="primary"
                    style="justify-content:flex-end; width: 200px"
                    (click)="exportFeeStructures()">
              Download Fee Structure
              <i class="fas fa-download prefix-icon"></i>
            </button>
          </div>

          <br>

          <div style="display: flex; justify-content:center">
            <button mat-stroked-button
                    color="primary"
                    style="justify-content:center; width: 280px"
                    (click)="exportFeeAssignment()">
              <i class="fas fa-file-excel prefix-icon"></i>
              Download Fee Assignment Template
            </button>
          </div>


          <br>

          <h4> Upload File </h4>


          <mat-form-field>

            <mat-toolbar>

              <!-- Readonly Input to show File names -->
              <input matInput style="font-size: 16px" [(ngModel)]="fileName" readonly />

              <!-- Browse Button -->

              <div class="parent" style="position: relative;">
                <button mat-flat-button
                        color="primary"
                        style="display: flex; justify-content: center; width: 70px">
                  Browse
                </button>
                <div class="child">
                  <input type="file"
                         id="fileUpload"
                         (change)="uploadTemplateFile($event)"
                         (click)="$event.target.value=null"
                         name="fileUpload"/>
                </div>
              </div>

            </mat-toolbar>

          </mat-form-field>

          <div class="dialog-content" *ngIf="validationLoading">
            <app-material-spinner></app-material-spinner>
          </div>

          <div *ngIf="!validationLoading && invalidAccounts.length > 0">
            <div class="warning-msg">
              <i class="fal fa-exclamation-triangle"></i>
              Please check the account number, custodian or the fee structure ids for the following accounts. Either
              the account number is incorrect, or the account number does not match the custodian,
              or the fee structure ids are incorrect.
            </div>
            <mat-list style="overflow: auto; max-height: 200px">
              <mat-list-item *ngFor="let name of invalidAccounts" style="font-size: 14px; color: #660000">
                {{ name }}
              </mat-list-item>
            </mat-list>
          </div>


          <div class="actions-container">
            <button mat-button
                    color="primary"
                    [mat-dialog-close]="false">
              Cancel
            </button>

            <app-atlas-submit-button [disabled]="disable"
                                     [onClick]="onSubmit">
              Submit
            </app-atlas-submit-button>
          </div>

        </div>
      </div>
    </ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
    <div class="dialog-content" *ngIf="updatedAccountNames.length == 0">
      <app-material-spinner></app-material-spinner>
    </div>

    <div *ngIf="updatedAccountNames.length > 0">
      <div class="success-msg">
        Accounts that are successfully updated
      </div>
      <mat-list style="overflow: auto; max-height: 200px">
        <mat-list-item *ngFor="let name of updatedAccountNames" style="font-size: 14px; color: #660000">
          {{ name }}
        </mat-list-item>
      </mat-list>
    </div>


    <div class="actions-container">
      <button mat-flat-button
              color="primary"
              [mat-dialog-close]="false"
              (click)="closeDialog()">
        Close
      </button>

    </div>
    </ng-template>
  </mat-step>
</mat-stepper>
