import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Account} from '../../../../accounts/account';
import {ExcelService} from '../../../../atlas-ui/excel.service';
import {FeeStructuresService} from '../fee-structures.service';
import {AccountService} from '../../../../accounts/account.service';
import {DisplayCustodianPipe} from '../../../../shared/pipes/display-custodian.pipe';
import {Store} from '@ngrx/store';
import * as fromFeeStructure from '../state';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../../shared/services/ui/notification.service';
import * as XLSX from 'xlsx';
import {FeeStructureAssignmentBulkUploadService} from './fee-structure-assignment-bulk-upload.service';
import {Actions, ofType} from '@ngrx/effects';
import {CreateFeeStructuresFail, CreateFeeStructuresSuccess} from '../state/fee-structure.actions';

@Component({
  selector: 'app-fee-structure-assignment-bulk-upload',
  templateUrl: './fee-structure-assignment-bulk-upload.component.html',
  styleUrls: ['./fee-structure-assignment-bulk-upload.component.scss']
})
export class FeeStructureAssignmentBulkUploadComponent implements OnInit, OnDestroy {
  loading = true;
  validationLoading: boolean;

  fileName = 'Select a file';

  uploadedDataStream = [];
  disable = true;

  feeStructureToExport =  [];
  accountFeeAssignmentToExport = [];

  invalidAccounts = [];
  accountsToDispatch: Account[] = [];
  updatedAccountNames: string[] = [];

  @ViewChild('stepper') stepper;

  constructor(private excelService: ExcelService,
              private feeStructuresService: FeeStructuresService,
              private accountsService: AccountService,
              private custodianPipe: DisplayCustodianPipe,
              private store: Store<fromFeeStructure.State>,
              private dialog: MatDialog,
              private actions$: Actions,
              private notificationService: NotificationService,
              private feeAssignmentService: FeeStructureAssignmentBulkUploadService) {}

  ngOnInit(): void {

    this.feeStructureToExport = this.feeAssignmentService.getFeeStructureTemplate()
    this.accountFeeAssignmentToExport = this.feeAssignmentService.getAccountFeeStructureAssignmentTemplate()
    this.loading = false;

  }

  ngOnDestroy() {
    this.feeAssignmentService.account$.unsubscribe();
    this.feeAssignmentService.feeStructure$.unsubscribe();
  }

  exportFeeStructures(): void {
    this.excelService.exportAsExcelFile(this.feeStructureToExport, 'Fee Structures -');
  }

  exportFeeAssignment(): void {
    this.excelService.exportAsExcelFile(this.accountFeeAssignmentToExport, 'Fee Assignment-');
  }

  // creating a custom functionality for upload dialog box. This dialog box includes two different downloads.
  uploadTemplateFile(ev) {
    let workBook = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {type: 'binary'});
      this.uploadedDataStream = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        return XLSX.utils.sheet_to_json(sheet);
      }, {});

      this.validationLoading = this.feeAssignmentService.validationCheck(this.uploadedDataStream);
      this.invalidAccounts = this.feeAssignmentService.invalidAccounts;

      if (this.invalidAccounts.length === 0) {
        this.disable = false;
      }
    };

    if (file) {
      this.fileName = file.name;
    } else {
      this.fileName = 'Select File';
    }
    reader.readAsBinaryString(file);

  }

  onSubmit = () => {

    this.uploadedDataStream.forEach(assignment => {

      if (!this.feeAssignmentService.isAssignmentValid(assignment))  {
        return;
      }

      const account: Account = this.feeAssignmentService.accountByNumber[assignment.Number];

      account.fee_structures_ids = this.feeAssignmentService.createStringToNumericList(assignment['Fee Structure ID'].toString());

      this.accountsToDispatch.push(account);

      this.updatedAccountNames.push(`${account.name} : ${account.number}`);
    });

    this.accountsService.dispatchAccounts(this.accountsToDispatch);

    this.actions$.pipe(
      ofType(CreateFeeStructuresSuccess
      ),
    ).subscribe(() => {
      this.notificationService.showSuccessNotification('Account Fee Structure Assignments have been successfully created.');
    });

    this.actions$.pipe(
      ofType(CreateFeeStructuresFail
      ),
    ).subscribe(() => {
      this.notificationService.showSuccessNotification('Failed to create Account Fee Structure Assignments. Please contact support: support@bridgeft.com.');
    });

    this.stepper.next();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
