import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {WealthboxService} from './wealthbox.service';
import {Observable} from 'rxjs';
import {WealthboxSettingsService} from './wealthbox-settings/wealthbox-settings.service';
import {first} from 'rxjs/operators';

@Injectable()
export class WealthboxIntegrationConfigResolver {
  constructor(private wealthboxSettingsService: WealthboxSettingsService) {}
  resolve(): Observable<any> {
    return this.wealthboxSettingsService.configuration$.pipe(
      first(),
    );
  }
}
