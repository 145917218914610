import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayCustodian'
})
export class DisplayCustodianPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'NFSA' || input === 'NFS') {
      return 'Fidelity';
    } else if (input === 'TDA') {
      return 'TD Ameritrade';
    } else if (input === 'SWB') {
      return 'Schwab';
    } else if (input === 'HDG') {
      return 'Manual Accounts';
    } else if (input === 'JFN') {
      return 'Jefferson National';
    } else if (input === 'MLT') {
      return 'Millennium Trust';
    } else if (input === 'TIA') {
      return 'TIAA-CREF';
    } else if (input === 'WHT') {
      return 'Wells Fargo';
    } else if (input === 'SCT') {
      return 'Scottrade';
    } else if (input === 'RJA') {
      return 'Raymond James';
    } else if (input === 'APX') {
      return 'Apex';
    } else if (input === 'EGB') {
      return 'Eaglebrook';
    } else if (input === 'PER') {
      return 'Pershing';
    } else if (input === 'IBK') {
      return 'Interactive Brokers';
    }
    else if (input === 'BLANK' || input === '') {
      return 'Unknown';
    }

    return input;
  }

}
