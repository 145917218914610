import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPipelineSyncState'
})
export class DisplayPipelineSyncStatePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'quovo.pipeline.started') {
      return 'Started';
    } else if (input === 'quovo.connections.fetching') {
      return 'Pulling account information';
    } else if (input === 'accounts.fetching') {
      return 'Pulling account data';
    } else if (input === 'quovo.transactions.fetching') {
      return 'Pulling transactions';
    } else if (input === 'quovo.holdings.fetching') {
      return 'Pulling holdings';
    } else if (input === 'quovo.data.aggregating') {
      return 'Aggregating data';
    } else if (input === 'quovo.pipeline.finished') {
      return 'Finished';
    }

    return '';
  }

}
