import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { CognitoService } from './cognito.service';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {UserProfileService} from './user-profile.service';
import {UserProfile} from './user-profile';
import {AppLoadingService} from '../../../layout/app-loading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  authenticated: boolean;

  constructor(private cognitoService: CognitoService,
              private router: Router,
              private userProfileService: UserProfileService,
              private appLoadingService: AppLoadingService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    // if hashkey login check the session
    if (this.cognitoService.hashkeyCredentials) {
      const isHashkeySessionValid = this.cognitoService.isHashkeySessionValid();

      if (!isHashkeySessionValid) {
        return this.router.navigateByUrl('/hashkey-login');
      }

      return isHashkeySessionValid;
    }

    // get cognito user
    const user = this.cognitoService.getCurrentUser();

    if (!user) {
      // route to login
      return this.router.navigateByUrl('/login');
    }

    return this.cognitoService.isAuthenticated().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return this.userProfileService.fetchUserProfile();
        }

        return null;
      }),
      map((userProfile) => {
        if (!userProfile) {
          return false;
        }

        if (!userProfile.is_verified) {
          this.appLoadingService.setAppFinishedLoading();
          this.router.navigateByUrl('/verify-client');
          return false;
        }

        // if client is trying to go to /dashboard reroute to /cp/main
        if (state.url.includes('dashboard') && userProfile.is_client_user) {
          this.router.navigateByUrl('/cp/main');
          return false;
        }

        return true;
      })
    );
  }
}

