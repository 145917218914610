import { Injectable } from '@angular/core';
import {LayoutService} from '../../layout/layout.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {

  constructor(
    private navigationService: LayoutService,
    private router: Router
  ) { }

  doAction(event: KeyboardEvent) {
    if (event.key === 's') {
      this.navigationService.toggleSearchLayer(true);
    }

    if (event.key === 'Escape') {
      this.navigationService.toggleSearchLayer(false);
    }

    if (event.key === 'a') {
      this.router.navigate(['/accounts/list']);
    }

    if (event.key === 'h') {
      this.router.navigate(['/households/list']);
    }

    if (event.key === 'p') {
      this.router.navigate(['/reporting/printable-reports']);
    }
  }
}
