<div style="margin-bottom: 16px;">
  <label class="detail-label">
    Recommendations
  </label>

  <div class="recent-activity-card" *ngIf="!showError else error">
    <i class="fas fa-search-dollar"></i>

    <div class="content-container">
      <div class="content-header">
        Potential Savings
      </div>

      <div class="align-items-center">
        <div class="content">
          <b>{{totalSavings | currency}}</b> - the amount you could save your clients in expense ratio fees over the next 10 years.
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #error>
  <div class="sidebar-card">
    <div class="activity-content">
      An error occurred while attempting to load data
    </div>
  </div>
</ng-template>
