import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './alert-conditions.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AlertConditionsEffects} from './alert-conditions.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('alertConditions', reducer),
    EffectsModule.forFeature(
      [AlertConditionsEffects]
    ),
  ]
})
export class AlertConditionsStoreModule {}
