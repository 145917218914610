import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {AlertSubscription} from '../alert-subscription.type';
import {CallState} from '../../../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CreateAlertSubscription,
  CreateAlertSubscriptionFail,
  CreateAlertSubscriptionSuccess,
  LoadAlertSubscriptions,
  LoadAlertSubscriptionsFail,
  LoadAlertSubscriptionsSuccess,
  RemoveAlertSubscription,
  RemoveAlertSubscriptionFail,
  RemoveAlertSubscriptionSuccess,
} from './alert-subscriptions.actions';

export interface AlertSubscriptionState extends EntityState<AlertSubscription> {
  callState: CallState;
  createCallState: CallState;
  removeCallState: CallState;
}

export const adapter: EntityAdapter<AlertSubscription> = createEntityAdapter<AlertSubscription>();

const initialState: AlertSubscriptionState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
});

export function reducer(state: AlertSubscriptionState | undefined, action: Action) {
  const reportPackageReducer = createReducer(
    initialState,
    on(LoadAlertSubscriptions, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadAlertSubscriptionsSuccess, (s, {alertSubscriptions}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(alertSubscriptions, s), callState};
    }),

    on(LoadAlertSubscriptionsFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(CreateAlertSubscription, (s) => {
      const createCallState = {...s.createCallState, loading: true};
      return {...s, createCallState};
    }),

    on(CreateAlertSubscriptionSuccess, (s, {alertSubscription}) => {
      const createCallState = {loading: false};
      return {...adapter.addOne(alertSubscription, s), ...createCallState};
    }),

    on(CreateAlertSubscriptionFail, (s) => {
      const createCallState = {loading: false};
      return {...s, ...createCallState};
    }),

    on(RemoveAlertSubscription, (s) => {
      const removeCallState = {...s.removeCallState, loading: true};
      return {...s, removeCallState};
    }),

    on(RemoveAlertSubscriptionSuccess, (s, {alertSubscription}) => {
      const removeCallState = {loading: false};
      return {...adapter.removeOne(alertSubscription.id, s), ...removeCallState};
    }),

    on(RemoveAlertSubscriptionFail, (s) => {
      const removeCallState = {loading: false};
      return {...s, ...removeCallState};
    }),
  );

  return reportPackageReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of alert subscriptions
export const selectAllAlertSubscriptions = selectAll;
