import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PortformerAnalyzedPosition, PortformerAnalyzedPositionWithAlts, PortformerAnalyzeRequest, PortformerPosition} from './portformer';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SecuritiesService} from '../../../securities/securities.service';
import {Security} from '../../../securities/security';
import _sumBy from 'lodash-es/sumBy';
import {TopHoldingsService} from '../../../shared/services/top-holdings.service';

export interface PortformerWithCurrentSecurityPosition {
  currentSecurity: Security;
  currentValue: number;
  portformerData: (PortformerAnalyzedPosition | PortformerAnalyzedPositionWithAlts);
}

@Injectable({
  providedIn: 'root'
})
export class PortformerService {
  constructor(
    private http: HttpClient,
    private securitiesService: SecuritiesService,
    private topHoldingsService: TopHoldingsService
    ) { }
  private endpoint = `${environment.apiPortformer}/integration/bridgeft`;

  portformerRecommendations$: Observable<PortformerWithCurrentSecurityPosition[]> = this.topHoldingsService.top10HoldingsEtf$.pipe(
    mergeMap(this.preparePortformerRequest.bind(this)),
    mergeMap(this.prepareTopEtfsData.bind(this)),
    map(recommendations => {
      return recommendations.filter(r => !!r.portformerData.est_savings);
    }),
  );

  calculateTotalSavings(etfs): number {
    return _sumBy(etfs, (etf: any) =>
      etf.portformerData.est_savings > 0 ? etf.portformerData.est_savings : 0
    );
  }

  private analyze(etfs: PortformerAnalyzeRequest[]): Observable<PortformerPosition[]> {
    const url = `${this.endpoint}/analyze`;
    return this.http.post(url, {positions: etfs}).pipe(
      map((response: any) => {
        if (response.data) {
          return response.data.positions;
        } else {
          throw response.err;
        }
      }),
    );
  }

  private preparePortformerRequest(topEtfs): Observable<Partial<PortformerWithCurrentSecurityPosition>[]> {
    const ids = topEtfs.map(e => e.security_id);

    return this.securitiesService.getSecuritiesByIds(ids).pipe(
      filter(s => s.length === topEtfs.length),
      map(securities => {
        return securities.map(s => {
          return {
            currentSecurity: s,
            currentValue: +topEtfs.find(e => e.security_id === s.id).sum_market_value,
          };
        });
      })
    );
  }

  private prepareTopEtfsData(currentSecurities): Observable<PortformerWithCurrentSecurityPosition[]> {
    const analyzeRequest: PortformerAnalyzeRequest[] = currentSecurities.map(s => {
      return {
        ticker: s.currentSecurity.symbol,
        value: s.currentValue
      };
    });

    return this.analyze(analyzeRequest).pipe(
      map(portformerResponse => {
        return currentSecurities.map(s => {
          return {
            ...s,
            portformerData: portformerResponse.find(p => p.ticker === s.currentSecurity.symbol),
          };
        });
      })
    );
  }
}
