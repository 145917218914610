import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  isSearchLayerVisible = false;

  constructor() { }

  toggleSearchLayer(state = false) {
    this.isSearchLayerVisible = state;

    const action = state ? 'show' : 'hide';
    $('.search-bar:eq(0)').MDLayer(action);
  }
}
