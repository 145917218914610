import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {FirmService} from '../../../firm/firm.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {HttpClient} from '@angular/common/http';
import _keyBy from 'lodash-es/keyBy';
import {UserProfileService} from '../../../shared/services/auth/user-profile.service';
import {FeeStructure, FeeStructureTier} from '../../../billing/billing';
import {combineLatest, Observable} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import * as fromFeeStructure from './state';
import { Store, select } from '@ngrx/store';
import {load} from '@tinymce/tinymce-angular/utils/ScriptLoader';
import * as fromActions from './state/fee-structure.actions';

@Injectable({
  providedIn: 'root'
})
export class FeeStructuresService {

  constructor(
    private firmService: FirmService,
    private utils: UtilsService,
    private http: HttpClient,
    private userProfile: UserProfileService,
    private store: Store<fromFeeStructure.State>
  ) {
    this.endpoint = `${environment.apiV2Url}/billing/fee-structures`;
  }
  private endpoint: string;
  state: {fetched: boolean, fetching: boolean, notPermitted: boolean} = {
    fetched: false,
    fetching: false,
    notPermitted: false
  };

  /**
   * @deprecated use feeStructures property for NgRx state management instead
   */
  chargeUnits: any[] = [];

  feeStructures$ = combineLatest([
    this.store.pipe(select(fromFeeStructure.getFeeStructures)),
    this.store.pipe(select(fromFeeStructure.getLoaded)),
    this.store.pipe(select(fromFeeStructure.getLoading)),
  ]).pipe(
    map(([feeStructures, loaded, loading]) => {
      if (!loaded && !loading) {
        this.store.dispatch(fromActions.Load());
      }
      return feeStructures;
    })
  );

  constructTier(chargeUnitID) {
    return new FeeStructureTier(chargeUnitID);
  }

  constructChargeUnit() {
    return new FeeStructure(this.firmService.firm.id);
  }


  removeChargeUnit(chargeUnit) {
    const url = `${this.endpoint}/${chargeUnit.id}`;
    return this.http.delete(url).pipe(
      tap(() => {
        this.chargeUnits = this.chargeUnits.filter(c => c.id !== chargeUnit.id);
      })
    );
  }


  deleteFeeStructures(feeStructures) {
    const url = this.endpoint;

    const ids = feeStructures.map((s) => s.id);

    const data = {
      ids
    };

    const options = {
      body: data
    };

    // Body on delete request to work with ALB API
    // @ts-ignore

    return this.http.delete(url, options).pipe(
      tap(() => {
        this.chargeUnits = this.chargeUnits.filter((fs) => !ids.includes(fs.id));
      })
    );
  }


  getFeeStructures(): Observable<{data: FeeStructure[]}> {
    return this.http.get<{data: FeeStructure[]}>(this.endpoint)
      .pipe(
        map((response: {data: FeeStructure[]}) => {
          response.data.forEach(fs => {
            if (!fs.tiers) {
              fs.tiers = [];
            }
          });

          this.chargeUnits = response.data;

          return response;
        })
      );
  }


  /**
   * Saves the charge unit to the backend, issuing a post / put based on whether the id exists
   */
  saveChargeUnit(chargeUnitData) {
    const method = chargeUnitData.id ? 'put' : 'post';
    const url = chargeUnitData.id ? `${this.endpoint}/${chargeUnitData.id}` : this.endpoint;

    const chargeUnit = Object.assign({}, chargeUnitData, {
      created_by_id: this.userProfile.userProfile.id,
      object: 'billing.fee_structure',
    });

    return this.http[method](url, chargeUnit)
      .pipe(
        tap((resp: FeeStructure) => {
          if (chargeUnit.id) {
            this.chargeUnits = this.chargeUnits.map(c => (c.id === resp.id) ? resp : c);
          } else {
            this.chargeUnits.push(resp);
          }
        })
      );
  }

  get chargeUnitsByID() {
    return _keyBy(this.chargeUnits, 'id');
  }

  createFeeStructures(feeStructures: FeeStructure[]): Observable<FeeStructure[]> {
    const url = `${this.endpoint}/create-many`;
    return this.http.post<FeeStructure[]>(url, feeStructures);
  }

}
