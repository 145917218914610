import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ManualHolding} from './manual-holding';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {Security} from '../securities/security';
import {catchError, filter, map, switchMap, take} from 'rxjs/operators';
import {AccountService} from '../accounts/account.service';
import {Account} from '../accounts/account';
import {SecuritiesService} from '../securities/securities.service';
import {CurrentBalanceService} from '../balances/current-balance.service';
import {NotificationService} from "../shared/services/ui/notification.service";

@Injectable({
  providedIn: 'root'
})
export class ManualHoldingsService {

  constructor(private http: HttpClient,
              private accountService: AccountService,
              private securitiesService: SecuritiesService,
              private currentBalanceService: CurrentBalanceService,
              private notificationService: NotificationService) {
  }

  getManualAccounts(): Observable<Account[]> {
    const endpoint = `${environment.apiV2Url}/account-management/accounts?custodian=HDG`

    return this.http.get(endpoint).pipe(
      switchMap((resp: {data: Account[]}) => {
        const accounts = resp.data;

        return this.getLatestManualAccountBalances(accounts.map((a: Account) => a.id)).pipe(
          map((balances: any[]) => {
            accounts.forEach(a => {
              const balance = balances.find(b => b.account_id === a.id)

              if (balance) {
                balance.abs_value = balance.cash_value + balance.security_holdings_value;
              }

              a.balance = balance;
            })

            return accounts;
          })
        )
      }),
    );
  }

  getManualSecurities(): Observable<Security[]> {
    const idsEndpoint = `${environment.apiV2Url}/data/luca/manual-securities`;

    return this.http.get(idsEndpoint).pipe(
      switchMap((ids: number[]) => {

        return of([]);
      })
    );
  }

  getLatestManualAccountBalances(accountIDs: number[]) {
    return this.http.get(`${environment.apiV2Url}/data/luca/balances/current`)
      .pipe(
        map((balances: any[]) => {
          return balances.filter((b) => accountIDs.includes(b.account_id))
        })
      )
  }

  createManualHolding(holdings: ManualHolding[]): Observable<any> {
    const endpoint = `${environment.apiV2Url}/data/luca/manual-holdings/create-many`;

    return this.http.post(endpoint, holdings);
  }

  getAccountSecurity(account: Account): Promise<Security[]> {
    return this.currentBalanceService.lastDate$.pipe(
      switchMap((date: string) => {

        return this.http.get(`${environment.apiV2Url}/data/luca/holding?account_id=${account.id}&as_of_date=${date}`);
      }),
      switchMap((resp: {data: any}) => {
        const securityIDs = [];

        resp.data.forEach(h => securityIDs.push(h.security_id));

        return this.securitiesService.fetchSecurities(securityIDs);
      }),
      take(1),
    ).toPromise();
  }

  deleteManualHoldings(accounts: Account[]) {
    const endpoint = `${environment.apiV2Url}/data/luca/manual-holdings/delete-many`;

    const accountIds = accounts.map(a => a.id)

    return this.http.post(endpoint, {ids: accountIds}).pipe(
      catchError(err => {
        this.notificationService.showErrorNotification('There was an error deleting manual holdings');

        return throwError(err);
      })
    );
  }

  preparingMHToDispatch(manualHoldings, creatingMH: boolean){

    const holdingsToCreate = [];

    manualHoldings.forEach(mh => {

      // if account number is a number it will create an issue when c.binding on API
        mh.account_number = mh.account_number.toString()

      // do some math
        const endingValue = parseFloat(mh.ending_balance);
        const transactionType = mh.transaction_type;
        const transactionAmount = mh.transaction_amount ? Math.abs(parseFloat(mh.transaction_amount)) : mh.transaction_amount;
        const transactionDate = mh.transaction_date;

        const includeDepositWithdrawal = mh.include_deposit_withdrawal
        const accountStartingValue = mh.account_starting_value;

        if (includeDepositWithdrawal) {
          let delta = 0;

          if (transactionType === 'contribution') {
            delta = endingValue - accountStartingValue - transactionAmount;
          } else {
            delta = endingValue - accountStartingValue + transactionAmount;
          }

          // add the contribution/withdrawal
          const depositWithdrawal = {
            symbol: mh.security_symbol,
            account_number: mh.account_number,
            transaction_date: transactionDate,
          };

          depositWithdrawal[transactionType] = transactionAmount;

          holdingsToCreate.push(depositWithdrawal);

          // if delta is less than 0 add a dividend expense, else add a dividend income with the value of delta amount
          if (delta < 0) {
            const dividendExpense = {
              symbol: mh.security_symbol,
              account_number: mh.account_number,
              transaction_date: transactionDate,
              dividend_expense: Math.abs(delta),
            };

            holdingsToCreate.push(dividendExpense);
          } else if (delta > 0) {
            const dividendIncome = {
              symbol: mh.security_symbol,
              account_number: mh.account_number,
              transaction_date: transactionDate,
              dividend_income: delta,
            };

            holdingsToCreate.push(dividendIncome);
          }
        } else {
          const amount = endingValue - accountStartingValue;

          // If an account is being created add a contribution initially
          if (creatingMH) {
            const contribution = {
              symbol: mh.security_symbol,
              account_number: mh.account_number,
              transaction_date: transactionDate,
              contribution: amount,
            };

            holdingsToCreate.push(contribution);
          } else {
            if (amount < 0) {
              const dividendExpense = {
                symbol: mh.security_symbol,
                account_number: mh.account_number,
                transaction_date: transactionDate,
                dividend_expense: Math.abs(amount),
              };

              holdingsToCreate.push(dividendExpense);
            } else {
              const dividendIncome = {
                symbol: mh.security_symbol,
                account_number: mh.account_number,
                transaction_date: transactionDate,
                dividend_income: amount,
              };

              holdingsToCreate.push(dividendIncome);
            }
          }
        }
      }
    )

    return holdingsToCreate;

  }
}
