import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, JsonPipe, KeyValuePipe} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {DevExtremeModule} from '../../devextreme/dev-extreme.module';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {RouterLinkWithHref} from '@angular/router';
import {IngestionStageHistory, IngestionStatus, StatusService} from '../status.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-ingestion-stage',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    DevExtremeModule,
  ],
  templateUrl: './ingestion-stage.component.html',
  styleUrls: ['./ingestion-stage.component.scss']
})
export class IngestionStageComponent implements OnInit {

  @Input() ingestion: IngestionStatus;

  stages$: Observable<IngestionStageHistory[]>;

  constructor(private statusService: StatusService) {

  }

  ngOnInit(): void {
    this.stages$ = this.statusService.getIngestionStageHistory(this.ingestion.id);
  }
}
