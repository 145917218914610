import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, flatMap, map} from 'rxjs/operators';
import {UserProfileService} from './user-profile.service';
import {CognitoService} from './cognito.service';

export enum LoginViewStates {
  LOGIN,
  RESET_PASSWORD,
  ENTER_PASSWORD,
  FORCE_CHANGE_PASSWORD,
  VERIFY_CLIENT
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  hashkeyEndpoint: string;

  loginDisplayMessage: string;

  modeObservable = new BehaviorSubject(0);

  constructor(private http: HttpClient,
              private userProfileService: UserProfileService,
              private cognitoService: CognitoService) {
    this.hashkeyEndpoint = `${environment.apiV2Url}/oauth2/token`;
  }

  isInTemporaryPasswordState(username: string) {
    const url = `${environment.apiV2Url}/cognito-onboarding/is-temporary`;

    const data = {
      email: username
    };

    return this.http.post(url, data);
  }

  checkForTemporaryPassword(username: string) {
    const url = `${environment.apiV2Url}/cognito-onboarding/is-temporary`;

    const data = {
      email: username
    };

    return this.http.post(url, data).pipe(
      flatMap(() => this.resetTemporaryPassword(data)),
      catchError((err) => throwError(err))
    );
  }

  resetTemporaryPassword(data) {
    const url = `${environment.apiV2Url}/cognito-onboarding/resend-temporary-password`;

    return this.http.post(url, data);
  }

  loginWithHashkey(hashkey: string) {
    const body = `hashkey=${hashkey}&grant_type=hashkey`;
    const unencodedHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this.http.post(this.hashkeyEndpoint, body, {headers: unencodedHeaders});
  }

  fetchBrandedLogin(firmShortName: string): Observable<string> {
    const url = `${environment.apiV2Url}/org/firms/branded-login/${firmShortName}`;

    return this.http.get<string>(url);
  }

  // This will allow a message to be passed from other routes to login page
  setLoginDisplayMessage(message: string) {
    this.loginDisplayMessage = message;
  }

  getVerificationInfo(): Observable<any> {
    const url = `${environment.apiV2Url}/verify-end-client-user`;

    return this.http.get(url);
  }

  verifyClient(verificationData) {
    const url = `${environment.apiV2Url}/verify-end-client-user`;

    return this.http.post(url, verificationData).pipe(
      map((result: any) => {
        // if successful, fetch new verified userprofile
        this.userProfileService.userProfile.is_verified = result.verified;

        return result;
      })
    );
  }

  isLoggedIn(): boolean {
    // If there is a cognito user,
    if (this.cognitoService.getCurrentUser()) {
      return true;
    }

    return false;
  }
}
