<app-atlas-activity-card (click)="openDetail()">
  <app-atlas-activity-card-header>
    {{contact.name}}
  </app-atlas-activity-card-header>

  <app-atlas-activity-card-content>
    <p><strong>Phone: </strong> {{phoneNumber}}</p>
    <p><strong>Email: </strong> <a [href]="'mailto:' + email">{{email}}</a></p>
  </app-atlas-activity-card-content>
</app-atlas-activity-card>
