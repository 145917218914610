import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {SecurityExclusion} from './security-exclusion.type';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {State} from './state';
import {getSecurityExclusions} from './state';

@Injectable({
  providedIn: 'root'
})
export class SecurityExclusionsService {

  endpoint = `${environment.apiV2Url}/reporting/security-exclusions`;

  securityExclusions$ = this.store.pipe(select(getSecurityExclusions));

  constructor(private http: HttpClient,
              private store: Store<State>) { }

  getSecurityExclusions(): Observable<SecurityExclusion[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: SecurityExclusion[]}) => {
        return resp.data;
      })
    );
  }

  getSecurityExclusion(id: number): Observable<SecurityExclusion> {
    const url = `${this.endpoint}/${id}`;

    return this.http.get<SecurityExclusion>(url);
  }

  updateSecurityExclusions(securityExclusions: SecurityExclusion[]): Observable<SecurityExclusion[]> {
    return this.http.post<SecurityExclusion[]>(this.endpoint, securityExclusions);
  }

  updateSecurityExclusion(securityExclusion: SecurityExclusion): Observable<SecurityExclusion> {
    const url = `${this.endpoint}/${securityExclusion.id}`;

    return this.http.put<SecurityExclusion>(url, securityExclusion);
  }

  createSecurityExclusion(securityExclusion: SecurityExclusion): Observable<SecurityExclusion> {
    return this.http.post<SecurityExclusion>(this.endpoint, securityExclusion);
  }

  removeSecurityExclusion(securityExclusion: SecurityExclusion): Observable<any> {
    const url = `${this.endpoint}/${securityExclusion.id}`;

    return this.http.delete(url);
  }

  removeSecurityExclusions(securityExclusions: SecurityExclusion[]): Observable<any> {
    const ids = securityExclusions.map(s => s.id);

    const data = {
      ids
    };

    const options = { body: data };

    // @ts-ignore
    return this.http.delete(this.endpoint, options);
  }
}
