<div>
  <div class="section-item welcome-header">
    Enter your email to reset your password.
  </div>

  <div [ngClass]="errorMessage ? 'msg-container error' : 'msg-container success'"
       *ngIf="message"
       (click)="closeMessage()">
    <div class="icon-container">
      <i *ngIf="errorMessage" class="fas fa-times-circle error-msg-close"></i>

      <i *ngIf="!errorMessage" class="fas fa-check-circle success-msg-close"></i>
    </div>

    {{message}}
  </div>

  <div class="section-item">
    <form>
      <mat-form-field appearance="outline" style="width: 100%;">
        <input type="text"
               matInput
               autofocus
               autocomplete="off"
               placeholder="Email"
               (keydown.enter)="requestReset()"
               [ngModelOptions]="{standalone: true}"
               [(ngModel)]="user.username">
        <mat-icon matPrefix class="login-icon">person</mat-icon>
      </mat-form-field>
    </form>
  </div>

  <div class="section-item reset-btn-container">
    <app-atlas-submit-button class="reset-btn"
                             [disabled]="user.username === ''"
                             [saving]="requesting"
                             (click)="requestReset()">Reset Password</app-atlas-submit-button>
  </div>
</div>
