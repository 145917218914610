<mat-card class="dashboard-tile">
  <div class="card-header">
    <div class="card-header__title">
      <h2>Fund Analysis</h2>

      <div #fundAnalysisTemplate>
        <p>
          Portformer's machine-learning enabled algorithm scans the ETF/mutual fund universe and searches for similar investment options that might have lower fees or risk. See their website to learn more.
        </p>
      </div>

      <i class="far fa-info-circle info-symbol"
         [ngxTippy]="fundAnalysisTemplate"
         tippyName="fundAnalysisTooltip"></i>
    </div>

    <div class="card-options">
      <div class="handle">
        <i class="far fa-arrows"></i>
      </div>
    </div>
  </div>

  <mat-card-content>

    <dx-data-grid #dataGrid
                  [wordWrapEnabled]="true"
                  [sorting]="false"
                  [headerFilter]="false"
                  [dataSource]="portformerRecommendations$ | async">
      <dxi-column caption="Current Holding"
                  dataType="string"
                  dataField="currentSecurity.symbol">
      </dxi-column>

      <dxi-column caption="Alternative"
                  dataType="string"
                  dataField="portformerData.alt_tickers"
                  cellTemplate="alternativeTemplate">
      </dxi-column>

      <dxi-column caption="Fee Savings"
                  dataType="number"
                  dataField="portformerData.net_fee_diff"
                  cellTemplate="feeSavingsTemplate">
        <dxo-format type="percent"
                    precision="2"></dxo-format>
      </dxi-column>

      <dxi-column caption="Dollar Savings"
                  dataType="number"
                  dataField="portformerData.est_savings"
                  cellTemplate="dollarSavingsTemplate">
        <dxo-format type="currency"
                    precision="2"></dxo-format>
      </dxi-column>

      <div *dxTemplate="let row of 'alternativeTemplate'">
        <a mat-button color="primary" *ngIf="row.data.portformerData.alt_tickers" [href]="row.data.portformerData.analyze_url" target="_blank">{{row.data.portformerData.alt_tickers | addSpace}}</a>

        <a mat-button color="primary" *ngIf="!row.data.portformerData.alt_tickers" [href]="portformerIntegrationLink(row.data)" target="_blank" class="blurred-out">{{row.data.currentSecurity.symbol}}</a>
      </div>

      <div *dxTemplate="let row of 'feeSavingsTemplate'">
        {{ (row.data.portformerData.net_fee_diff > 0 ? row.data.portformerData.net_fee_diff : 0) | percent:'1.0-2' }}
      </div>

      <div *dxTemplate="let row of 'dollarSavingsTemplate'">
        {{ (row.data.portformerData.est_savings > 0?  row.data.portformerData.est_savings : 0) | currency }}
      </div>

      <dxo-selection mode="none"></dxo-selection>
    </dx-data-grid>
  </mat-card-content>
</mat-card>

