import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {AuthSource} from '../auth-source.type';
import {CallState} from '../../../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CreateAuthSource,
  CreateAuthSourceFail,
  CreateAuthSourceSuccess,
  LoadAuthSources,
  LoadAuthSourcesFail,
  LoadAuthSourcesSuccess,
  RemoveAuthSource,
  RemoveAuthSourceFail,
  RemoveAuthSourceSuccess,
} from './auth-sources.actions';

export interface AuthSourceState extends EntityState<AuthSource> {
  callState: CallState;
  createCallState: CallState;
  removeCallState: CallState;
}

export const adapter: EntityAdapter<AuthSource> = createEntityAdapter<AuthSource>();

const initialState: AuthSourceState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
});

export function reducer(state: AuthSourceState | undefined, action: Action) {
  const authSourcesReducer = createReducer(
    initialState,
    on(LoadAuthSources, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadAuthSourcesSuccess, (s, {authSources}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(authSources, s), callState};
    }),

    on(LoadAuthSourcesFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(CreateAuthSource, (s) => {
      const createCallState = {...s.createCallState, loading: true};
      return {...s, createCallState};
    }),

    on(CreateAuthSourceSuccess, (s, {authSource}) => {
      const createCallState = {loading: false};
      return {...adapter.addOne(authSource, s), ...createCallState};
    }),

    on(CreateAuthSourceFail, (s) => {
      const createCallState = {loading: false};
      return {...s, ...createCallState};
    }),

    on(RemoveAuthSource, (s) => {
      const removeCallState = {...s.removeCallState, loading: true};
      return {...s, removeCallState};
    }),

    on(RemoveAuthSourceSuccess, (s, {authSource}) => {
      const removeCallState = {loading: false};
      return {...adapter.removeOne(authSource.id, s), ...removeCallState};
    }),

    on(RemoveAuthSourceFail, (s) => {
      const removeCallState = {loading: false};
      return {...s, ...removeCallState};
    }),
  );

  return authSourcesReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of auth sources
export const selectAllAuthSources = selectAll;
