import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './client.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ClientEffects} from './client.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('clients', reducer),
    EffectsModule.forFeature(
      [ClientEffects]
    ),
  ]
})
export class ClientStoreModule {}
