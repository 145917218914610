import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import { SetActiveDirective } from './set-active-directive/set-active.directive';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabContainerComponent } from './tab-container/tab-container.component';
import { TabComponent } from './tab/tab.component';
import { ContentContainerComponent } from './content-container/content-container.component';
import { ContentComponent } from './content/content.component';

@NgModule({
  declarations: [SetActiveDirective, TabGroupComponent, TabContainerComponent, TabComponent, ContentContainerComponent, ContentComponent],
  exports: [
    TabGroupComponent,
    TabContainerComponent,
    TabComponent,
    ContentContainerComponent,
    ContentComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule
  ]
})
export class AtlasTabsModule { }
