import {createAction, props} from '@ngrx/store';
import {InsightsParameter} from '../parameter.type';

export const LoadParameters = createAction('[Parameters] Load parameters');
export const LoadParametersSuccess = createAction('[Parameters] Load parameters success', props<{parameters: InsightsParameter[]}>());
export const LoadParametersFail = createAction('[Parameters] Load parameters fail', props<{error: any}>());

export const UpdateParameter = createAction('[Parameters] Update parameter', props<{parameter: InsightsParameter}>());
export const UpdateParameterSuccess = createAction('[Parameters] Update parameter success', props<{parameter: InsightsParameter}>());
export const UpdateParameterFail = createAction('[Parameters] Update parameter fail', props<{error: any}>());

export const UpdateParameters = createAction('[Parameters] Update parameters', props<{parameters: InsightsParameter[]}>());
export const UpdateParametersSuccess = createAction('[Parameters] Update parameters success', props<{parameters: InsightsParameter[]}>());
export const UpdateParametersFail = createAction('[Parameters] Update parameters fail', props<{error: any}>());
