import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FirmService} from "../../firm/firm.service";

export interface IngestionProgressAlert {
  show_banner: boolean;
  message: string;
}


@Injectable({
  providedIn: 'root'
})
// We want to show the default ingestion progress alert banner on the UI at a certain time frame. For example: Weekdays between 1 and 8:30 AM EST.
// IngestionProgressAlertService looks for the current time and decides it falls on different time frame depending on the day of a week.
// If we want to show custom message before or after 8:30 AM, we have an endpoint that fetches ingestion progress message from our db.
// Note: In the below setup, custom message from the Database takes precedent over default message
export class IngestionProgressAlertService {
  isCollapsed: boolean = false;
  message: string = "Please be advised that BridgeFT is still processing custodial data which could result in the appearance of incomplete data. This notice will be removed once all custodial data has processed and is available.";

  private endpoint = `${environment.apiV2Url}/status/ingestion-progress-atlas-banner`;

  constructor(private http: HttpClient,
              private firmService: FirmService) {
    this.setBannerMsg();
  }

  getBannerMsg(): Observable<IngestionProgressAlert> {
    return this.http.get<IngestionProgressAlert>(this.endpoint);
  }

  setBannerMsg() {
    const now = new Date();

    // this should always give an easter time in a string format despite the day-light saving.
    const easternTime = now.toLocaleTimeString('en-US', {
      timeZone: 'America/New_York',
      hour12: false,
    });

    const currentHour = parseInt(easternTime.split(':')[0], 10);
    const currentMinute = parseInt(easternTime.split(':')[1], 10);

    if (now.getDay() == 6) {
      // Check if the current time is between 1 AM and 4:30 PM on Saturday
      this.isCollapsed = !(currentHour >= 1 && (currentHour < 16 || (currentHour === 16 && currentMinute <= 30)));
    } else if (now.getDay() == 7) {
      // hide the banner for sunday
      this.isCollapsed = true
    } else {
      // Check if the current time is between 1 AM and 8:30 AM for weekdays
      this.isCollapsed = !(currentHour >= 1 && (currentHour < 8 || (currentHour === 8 && currentMinute <= 30)));
    }

    // custom message from the Database should take precedent over default message
    this.getBannerMsg().toPromise().then((resp) => {
      if (resp.message != "" || resp.message != undefined) {
        if (resp.show_banner) {
          this.message = resp.message;
          this.isCollapsed = false;
        }
      }
    });
  }

  // this event is triggered when the user closes the alert banner. It will not show the entire session once closed.
  setCollapse(v) {
    this.isCollapsed = v;
  }

}
