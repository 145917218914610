import {Routes} from '@angular/router';
import {AuthGuardService} from '../shared/services/auth/auth-guard.service';
import {RoleGuardService} from '../shared/services/auth/role-guard.service';
import {JobsComponent} from './jobs/jobs.component';
import {JobsResolver} from '../app.resolve';

export const JOBS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'list',
        component: JobsComponent,
        canActivate: [RoleGuardService],
        data: {
          firmUserRequired: true
        },
        resolve: {
          jobs: JobsResolver
        }
      }
    ]
  }
];
