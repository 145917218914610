export interface WebSocketData {
  entity_completed: string;
  job_id: number;
  job_type: string;
  pdf_download: string;
  status_msg: string;
}

export interface WebSocket {
  job_id: number;
  job_type: JobType;
  object_id: number;
  remaining: number;
  total: number;
  state: string;
  status: string;
  download_link: string;
}

export enum JobType {
  PDF_REPORT = 'pdfrp',
  WEB_REPORT = 'webrp',
  BILLING_REPORT = 'b',
  QUOVO = 'qu',
  BULK_DOWNLOAD = 'bd'
}
