import { Injectable } from '@angular/core';
import { environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {WebsocketService} from './websocket.service';
import _groupBy from 'lodash-es/groupBy';
import {Account} from '../../accounts/account';
import {map, tap} from 'rxjs/operators';
import {UtilsService} from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadPrintableReportsService {

  endpoint: string;
  reports: any;
  reportsById: any;
  reportsByAccountId: any;
  reportsByHouseholdId: any;

  constructor(
    private http: HttpClient,
    private websocket: WebsocketService,
    private utilsService: UtilsService
  ) {
    this.endpoint = `${environment.apiV2Url}/reporting/printable-reports`;
    this.reports = [];
    this.reportsById = {};
    this.reportsByAccountId = {};
    this.reportsByHouseholdId = {};
  }

  getPaginatedReportsByOptions(options) {
    const params = new HttpParams({fromObject: options});

    return this.http.get(this.endpoint, {params});
  }

  getReports(): Promise<any> {
    const pageSize = 5000;

    return this.utilsService.stepByStepLoading(this.endpoint, pageSize).then(
      (resp) => {
        this.setupReportData(resp);
        return resp;
      }
    );
  }

  setupReportData(reports: any) {
    this.reports = [];

    if (this.reports.length === 0) {
      this.reports = reports;
    } else {
      this.reports.push(...reports);
    }

    reports.forEach((report) => {
     this.reportsById[report.id] = report;

     if (report.account_id) {
       if (this.reportsByAccountId[report.account_id]) {
         this.reportsByAccountId[report.account_id].push(report);
       } else {
         this.reportsByAccountId[report.account_id] = [report];
       }
     } else if (report.household_id) {
       if (this.reportsByHouseholdId[report.household_id]) {
         this.reportsByHouseholdId[report.household_id].push(report);
       } else {
         this.reportsByHouseholdId[report.household_id] = [report];
       }
     }
    });
  }

  removeReports(selectedIds: number[]): Promise<any> {
    const url = this.endpoint;

    const data = {
      ids: selectedIds
    };

    const options = {
      body: data
    };
    // @ts-ignore
    return this.http.delete(url, options).toPromise<any>();
  }

  downloadPDFReport(report, entity?: any) {

    const url = `${this.endpoint}/download/${report.id}`;

    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/octet-stream');
    header = header.append('Accept', 'application/octet-stream');


    return this.http.post(url, {}, {responseType: 'arraybuffer', headers:  header}).pipe(
      tap((data) => {
        if (entity) {
          this.downloadPDF(report, data, entity);
        } else {
          this.downloadPDF(report, data);
        }
      })
    );
  }

  private downloadPDF(report: any, data: any, entity?: any) {
    const blob = new Blob([new Uint8Array(data)], {type: 'application/pdf'});
    const pdf = window.URL.createObjectURL(blob);

    // Trigger a browser download
    const a: any = document.createElement('a');
    a.style = 'display: none';
    a.href = pdf;

    a.download = (entity) ? `${entity.name.replace('.', '')} ${report.start_date} to ${report.end_date}` : report.name.replace('.', '');
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(pdf);
  }

  get reportsByAccountID() {
    return _groupBy(this.reports, 'account_id');
  }
}
