<app-atlas-activity-card>
  <app-atlas-activity-card-header>
    {{ note.created_at | date}}

    <span class="right">
      <a aria-label="Edit Note" matTooltip="Edit" (click)="edit = !edit"><mat-icon color="primary" class="fa fa-edit"></mat-icon></a>
    </span>
  </app-atlas-activity-card-header>

  <app-atlas-activity-card-content>
    <div *ngIf="!edit" [innerHTML]="note.content"></div>
    <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="note.content" [ngModelOptions]="{standalone: true}" *ngIf="edit"></ckeditor>

    <app-atlas-activity-card-details *ngIf="note.linked_to.length > 0 || edit">
      <span *ngIf="note.linked_to.length > 0">
        Note for:
      </span>

        <span *ngFor="let association of note.linked_to; let last = last">
        <b>{{association.name}}</b><span *ngIf="!last">, </span>
      </span>

      <div *ngIf="edit">
        <br>
        <button mat-button class="mat-button-xs" color="primary" (click)="saveNote(note)">Save</button>
        <button mat-button class="mat-button-xs" (click)="edit = false;">Cancel</button>
      </div>


    </app-atlas-activity-card-details>

  </app-atlas-activity-card-content>
</app-atlas-activity-card>


<!--<mat-card>-->

<!--  <mat-card-title-group>-->
<!--    <mat-card-subtitle>-->
<!--      <b>{{ note.created_at | date}}</b>-->
<!--    </mat-card-subtitle>-->


<!--  </mat-card-title-group>-->

<!--  <mat-card-content>-->
<!--    <div *ngIf="!edit" [innerHTML]="note.content"></div>-->
<!--    <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="note.content" [ngModelOptions]="{standalone: true}" *ngIf="edit"></ckeditor>-->
<!--  </mat-card-content>-->


<!--  <mat-card-actions align="end"  *ngIf="edit">-->
<!--    <button mat-button (click)="edit = false;">Cancel</button>-->
<!--    <button mat-button color="primary" (click)="saveNote(note)">Save</button>-->
<!--  </mat-card-actions>-->
<!--</mat-card>-->
