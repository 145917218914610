<div class="warning-dialog">
  <i class="fas fa-trash" *ngIf="data.type === 'delete'"></i>

  <i class="fal fa-exclamation-triangle" style="color: #FF7C53;" *ngIf="data.type !== 'delete'"></i>

  <h1 class="warning-dialog__title">{{ data.title }}</h1>

  <div class="content-container" mat-dialog-content>
    <div [innerHtml]="data.message"></div>
  </div>
  <br>

  <div class="btn-container">
    <button class="btn secondary-btn"
            [mat-dialog-close]="false">
      Cancel
    </button>

    <button class="btn primary-btn"
            [mat-dialog-close]="true">
      {{ data.type === 'delete' ? 'Delete' : 'Confirm' }}
    </button>
  </div>
</div>
