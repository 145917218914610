import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbLink} from '../../../../shared/components/breadcrumbs/breadcrumbs.component';
import {PortformerSettingsService} from './portformer-settings.service';
import {Observable, Subscription} from 'rxjs';
import {PortformerConfig} from '../portformer';

@Component({
  selector: 'app-portformer-settings',
  templateUrl: './portformer-settings.component.html',
})
export class PortformerSettingsComponent implements OnDestroy {
  isHelpWindowVisible: boolean;
  breadcrumbs: BreadcrumbLink[];
  portformerConfig: PortformerConfig;
  private portformerConfigSubscription: Subscription;

  constructor(private portformerSettingsService: PortformerSettingsService) {
    this.breadcrumbs = [
      {
        name: 'Integrations',
        url: '/integrations'
      },
      {
        name: 'Portformer'
      }
    ];

    this.portformerConfigSubscription = portformerSettingsService.config$.subscribe(config => {
      this.portformerConfig = config;
    });
  }

  ngOnDestroy(): void {
    this.portformerConfigSubscription.unsubscribe();
  }

  openHelpModal() {
    this.isHelpWindowVisible = true;
  }

  onAddKey(apiKey) {
    const newState = {...this.portformerConfig, apiKey, enabled: true};

    this.portformerSettingsService.updatePortformerConfig(newState);
  }

  removeApiKey() {
    const newState = {...this.portformerConfig, apiKey: ''};

    this.portformerSettingsService.updatePortformerConfig(newState);
  }

  onToggleEnabled(enabled) {
    const newState = {...this.portformerConfig, enabled};

    this.portformerSettingsService.updatePortformerConfig(newState);
  }

}
