import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {UtilsService} from './utils.service';
import {AppConstants} from '../../app-constants';
import _forEach from 'lodash-es/forEach';
import _map from 'lodash-es/map';
import _filter from 'lodash-es/filter';
import _includes from 'lodash-es/includes';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  v2Endpoint: string;
  subReportSettingsByComponent: any = {};

  printableComponent: string;
  advisorDefaultsComponent: string;
  clientDefaultsComponent: string;
  subReportOptions: any;

  constructor(private http: HttpClient, private utils: UtilsService) {
    this.v2Endpoint = `${environment.apiV2Url}/reporting/report-settings`;
    this.printableComponent = 'printable';
    this.advisorDefaultsComponent = 'advisor_defaults';
    this.clientDefaultsComponent = 'client_defaults';

    this.subReportOptions = AppConstants.SUB_REPORT_OPTIONS;
  }

  getSelectedSubReports(component: string): any {
    const object = this.subReportSettingsByComponent[component];

    if (!object) {
      return null;
    }

    const mapping = {...this.subReportOptions};

    return _filter(mapping, (item) => {
      return object[item.id] === true;
    });
  }

  getSubReportDisplaySettings(): Promise<any> {

    return this.http.get(this.v2Endpoint).toPromise<any>()
      .then((resp) => {
        this.utils.updateDataByPath(this.subReportSettingsByComponent, resp.data, 'component');
      });
  }

  private saveSettingsObj(settingsObject: any): Promise<any> {
    let url: string;

    if (settingsObject.id) {
      url = `${this.v2Endpoint}/${settingsObject.id}`;
    } else {
      url = this.v2Endpoint;
    }

    if (url === this.v2Endpoint) {
      return this.http.post(url, settingsObject).toPromise<any>().then((data) => {
        this.subReportSettingsByComponent[data.component] = data;
      });
    } else {
      return this.http.put(url, settingsObject).toPromise<any>().then((data) => {
        this.subReportSettingsByComponent[data.component] = data;
      });
    }
  }

  private updateSettingsBooleanFromList(settingsObject: any, optionsList: any, type: string): any {
    const enabled = _map(optionsList, 'id');

    if (type === this.printableComponent) {
      _forEach(this.subReportOptions, (item) => {
        settingsObject[item.id] = _includes(enabled, item.id);
      });
    } else if (type === this.advisorDefaultsComponent) {
      _forEach(this.subReportOptions, (item) => {
        settingsObject[item.id] = _includes(enabled, item.id);
      });
    } else if (type === this.clientDefaultsComponent) {
      _forEach(this.subReportOptions, (item) => {
        settingsObject[item.id] = _includes(enabled, item.id);
      });
    }



    return settingsObject;
  }

  saveReportSettings(printableSubReports: any, advisorSubReports: any, clientSubReports: any): Promise<any> {
    let promises: Promise<any>[] = [];

    _forEach (this.subReportSettingsByComponent, (settingsObject, component) => {
      let ls;

      if (component === 'printable') {
        ls = printableSubReports;
      } else if (component === 'advisor_defaults') {
        ls = advisorSubReports;
      } else if (component === 'client_defaults') {
        ls = clientSubReports;
      }

      const object = this.updateSettingsBooleanFromList(settingsObject, ls, component);
      promises.push(this.saveSettingsObj(object));
    });

    return Promise.all(promises);
  }

}
