import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromFeeStructure from './state';
import {filter, map, take} from 'rxjs/operators';
import {combineLatest, Observable, throwError} from 'rxjs';
import {FeeStructure} from '../../../billing/billing';
import {FeeStructuresService} from './fee-structures.service';

@Injectable()
export class FeeStructuresResolver implements Resolve<any> {
  constructor(
    private store: Store<fromFeeStructure.State>,
    private feeStructuresService: FeeStructuresService
  ) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<FeeStructure[]> {
    return combineLatest([
      this.feeStructuresService.feeStructures$,
      this.store.pipe(select(fromFeeStructure.getLoading)),
      this.store.pipe(select(fromFeeStructure.getError))
    ]).pipe(
      filter(([f, loading]) => !loading),
      map(([feeStructures, loaded, error]) => {
        if (error) {
          throwError(error);
        }
        return feeStructures;
      } ),
      take(1)
    );
  }
}
