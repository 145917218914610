import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Role} from './role';
import _keyBy from 'lodash-es/keyBy';
import {HttpClient} from '@angular/common/http';
import {FirmService} from '../firm/firm.service';
import {UserProfileService} from '../shared/services/auth/user-profile.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private endpoint = `${environment.apiV2Url}/user-management/roles`;
  public roles: Role[] = [];
  constructor(private http: HttpClient, private firmService: FirmService, private userProfile: UserProfileService) { }

  get rolesByID() {
    return _keyBy(this.roles, 'id');
  }

  /**
   * Fetches all roles and updates RoleService.roles
   */
  getRoles(): Promise<Role[]> {
    if (this.roles.length > 0) {
      return Promise.resolve(this.roles);
    }

    return this.http.get(this.endpoint).toPromise()
      .then((resp: {data: Role[]}) => {
        this.roles = resp.data;
        return this.roles;
    });
  }

  removeRoles(roles: Role[]): Observable<Role[]> {
    const ids = roles.map(role => role.id);
    const url = this.endpoint;

    const data = {
      ids
    };

    const options = {
      body: data
    };

    // @ts-ignore
    return this.http.delete(url, options).pipe(
      map(() => {
        this.roles = this.roles.filter(role => !ids.includes(role.id));

        return this.roles;
      })
    );
  }

  removeRole(role: Role): Promise<Role[]> {
    if (!role.id) {
      return;
    }

    const url = `${this.endpoint}/${role.id}`;

    return this.http.delete(url).toPromise()
      .then(() => {
        this.roles = this.roles.filter(r => r !== role);

        return this.roles;
    });
  }

  saveRole(roleData): Promise<Role[]> {
    const role: Role = Object.assign({}, roleData,
      {
        firm_id: this.firmService.firm.id,
        created_by_user_id: this.userProfile.userProfile.user_id
      });
    const isCreate = !role.id;
    const url = isCreate ? this.endpoint : `${this.endpoint}/${role.id}`;
    const method = isCreate ? 'post' : 'put';

    return this.http[method](url, role).toPromise()
      .then((resp: Role) => {
        this.roles = isCreate ? [...this.roles, resp] : this.roles.map(r => (r.id === role.id) ? role : r);

        return this.roles;
    });
  }
}
