import { Pipe, PipeTransform } from '@angular/core';
import _includes from 'lodash-es/includes';

@Pipe({
  name: 'fileSharingDisplayLinkPermissions'
})
export class FileSharingDisplayLinkPermissionsPipe implements PipeTransform {

  transform(link: any, args?: any): any {
    const ls = [];

    if (_includes(link.sharing_permissions, 'file_sharing.link.view')) {
      ls.push('view');
    }
    if (_includes(link.sharing_permissions, 'file_sharing.link.download')) {
      ls.push('download');
    }
    if (_includes(link.sharing_permissions, 'file_sharing.link.upload')) {
      ls.push('upload');
    }
    if (_includes(link.sharing_permissions, 'file_sharing.link.delete')) {
      ls.push('delete');
    }

    return ls.join(', ');
  }

}
