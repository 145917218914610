import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tsPayloadUnit'
})
export class TsPayloadUnitPipe implements PipeTransform {

  transform(payloadUnit: string): string {

    return payloadUnit.split('_').join(' ');
  }
}
