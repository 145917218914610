import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feeGroupValue'
})
export class FeeGroupValuePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (0.0 <= input && input < 250.0) {
      return '$0 - $250';
    } else if (250.0 <= input && input < 500.0) {
      return '$250 - $500';
    } else if (500.0 <= input && input < 750.0) {
      return '$500 - $750';
    } else if (750.0 <= input && input < 1e3) {
      return '$750 - $1,000';
    } else if (1e3 <= input && input < 2.5e3) {
      return '$1,000 - $2,500';
    } else if (2.5e3 <= input && input < 5e3) {
      return '$2,500 - $5,000';
    } else if (5e3 <= input && input < 10e3) {
      return '$5K - $10K';
    } else if (10e3 <= input && input < 15e3) {
      return '$10K - $15K';
    } else if (15e3 <= input && input < 20e3) {
      return '$15K - $20K';
    } else if (20e3 <= input && input < 25e3) {
      return '$20K - $25K';
    } else if (25e3 <= input && input < 50e3) {
      return '$25K - $50K';
    } else if (50e3 <= input && input < 75e3) {
      return '$50K - $75K';
    } else if (75e3 <= input && input < 1e5) {
      return '$75K - $100K';
    } else if (input >= 1e5) {
      return '$100K+';
    }

    return '';
  }

}
