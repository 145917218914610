import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Benchmark} from '../benchmark';
import {AccountService} from '../../accounts/account.service';
import {NotificationService} from '../../shared/services/ui/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {BenchmarkService} from '../benchmark.service';
import {Actions, ofType} from '@ngrx/effects';
import * as accountActions from '../../accounts/state/account.actions';
import * as householdActions from '../../households/state/household.actions';
import {merge} from 'rxjs';
import {Household} from '../../households/household';
import {Account} from '../../accounts/account';

class Status {
  saving = false;
}

@Component({
  selector: 'app-benchmark-select-assignments-modal',
  templateUrl: './benchmark-select-assignments-modal.component.html',
})
export class BenchmarkSelectAssignmentsModalComponent implements OnInit{
  public selectedBenchmarks: number[] = [];
  public propagateHouseholdAssignments = false;

  benchmarks: Benchmark[];
  entities: Account[] | Household[];
  private accountIds: number[] = [];
  householdIds: number[] = [];
  status: any;

  constructor(
    private accountService: AccountService,
    private notification: NotificationService,
    private dialogRef: MatDialogRef<BenchmarkSelectAssignmentsModalComponent>,
    private benchmarkService: BenchmarkService,
    @Inject(MAT_DIALOG_DATA) data,
    private actions$: Actions,
  ) {
    this.entities = data.entities;
    this.status = new Status();
  }

  ngOnInit(): void {
    this.benchmarks = this.benchmarkService.benchmarks;

    this.accountIds = this.entities.filter(e => !!e.is_account).map(e => e.id);
    this.householdIds = this.entities.filter(e => !!e.is_household).map(e => e.id);

    merge(
      this.actions$.pipe(ofType(accountActions.UpdateAccountsSuccess)),
      this.actions$.pipe(ofType(householdActions.UpdateHouseholdsSuccess))
    ).subscribe((resp) => {
      this.status.saving = false;
      this.dialogRef.close(true);
    });

    merge(
      this.actions$.pipe(ofType(accountActions.UpdateAccountsFail)),
      this.actions$.pipe(ofType(householdActions.UpdateHouseholdsFail))
    ).subscribe((resp: any) => {
        this.status.saving = false;
        this.notification.showErrorNotification(`There was an error while updating accounts: ${resp.error.message}`);
      });
  }

  numEntities() {
    return this.entities.length;
  }

  close() {
    // this.show = false;
  }

  save() {
    // const benchmarkIds = this.selectedBenchmarks.map(b => b.id);
    this.status.saving = true;

    this.accountService.assignBenchmarks(this.entities, this.selectedBenchmarks, this.propagateHouseholdAssignments);
  }

}
