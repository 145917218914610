<h2 mat-dialog-title >Are you sure?</h2>

<div mat-dialog-content style="min-height: 50px; min-width: 300px; align-items: center; max-width: 600px; white-space: pre-line">
  {{message}}
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="confirm()">Confirm</button>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>
