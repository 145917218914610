import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientInboxService} from '../client-inbox.service';
import {LoginService} from '../../shared/services/auth/login.service';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-client-inbox-landing',
  templateUrl: './client-inbox-landing.component.html',
  styleUrls: ['./client-inbox-landing.component.scss']
})
export class ClientInboxLandingComponent implements OnInit {

  errorCode: number;
  hasError = false;

  firmInfo: any;
  accessKey: string;

  constructor(private route: ActivatedRoute,
              private clientInboxService: ClientInboxService,
              private loginService: LoginService,
              private router: Router) { }

  ngOnInit(): void {

    const firmShortName = this.route.snapshot.queryParamMap.get('firm');

    if (firmShortName && firmShortName !== '') {
      this.loginService.fetchBrandedLogin(firmShortName).toPromise()
        .then((firmInfo) => {
          this.firmInfo = firmInfo;
        });
    }

    this.accessKey = this.route.snapshot.queryParamMap.get('key');

    if (!this.accessKey) {
      this.router.navigateByUrl('/login');
      this.hasError = true;
    } else {
      this.downloadAttachments();
    }
  }

  downloadAttachments() {
    this.clientInboxService.downloadAttachments(this.accessKey).pipe(
      catchError((err) => {
        this.errorCode = err.status;
        this.hasError = true;

        return err;
      })
    ).toPromise().then(() => {});
  }
}
