import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../shared/services/auth/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-verify-client',
  templateUrl: './verify-client.component.html',
  styleUrls: ['./verify-client.component.scss']
})
export class VerifyClientComponent implements OnInit {
  verificationData: any;
  challenge = '';

  errorMessage = false;
  message: string;
  loggingIn = false;

  constructor(private loginService: LoginService,
              private router: Router) { }

  ngOnInit() {
    this.loginService.getVerificationInfo().subscribe((verificationData) => {
      this.verificationData = verificationData;
    });
  }

  verifyClient(verificationData) {
    this.loggingIn = true;

    verificationData.challenge_answer = this.challenge;

    this.loginService.verifyClient(verificationData).subscribe(
      () => {
        this.router.navigateByUrl('/cp/main');
      },
      (err) => {
        this.displayError(err.error.Message.split(':')[1]);
      }
    );
  }

  closeMessage() {
    this.errorMessage = false;
    this.loginService.setLoginDisplayMessage('');
    this.message = null;
  }

  displayError(message) {
    this.errorMessage = true;
    this.message = message;
  }
}
