import {Component, Input, OnInit} from '@angular/core';
import {PortformerService, PortformerWithCurrentSecurityPosition} from '../../../portformer/portformer.service';

@Component({
  selector: 'app-atlas-recommendations',
  templateUrl: './atlas-recommendations.component.html',
  // TODO - need to move this styles to common file
  styleUrls: ['../atlas-activity.component.scss']
})
export class AtlasRecommendationsComponent implements OnInit {
  totalSavings: number;

  constructor(private portformerService: PortformerService) { }

  @Input('etfRecommendations')
  set etfRecommendations(value: PortformerWithCurrentSecurityPosition[]) {
    this.totalSavings = this.portformerService.calculateTotalSavings(value);
  }
  @Input() showError: boolean;


  ngOnInit() {

  }

}
