import { Component, OnInit } from '@angular/core';
import {BreadcrumbLink} from '../../shared/components/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-unauthorized',
  template: `<div class="page-title">
      <div class="row">
          <div class="col s12 m9 l10">
              <app-breadcrumbs [links]="breadcrumbs"></app-breadcrumbs>
          </div>
      </div>
  </div>



  <div class="row">
      <div class="col s12">
          <div class="card">
              <div class="title">
                  <h4>
                      Unauthorized
                  </h4>
              </div>

              <div class="content">

                  <p class="text-center">
                      You are not authorized to view this page or perform the attempted action.
                  </p>

                  <p class="text-center">
                      Please contact your system administrator or support@bridgeft.com if you believe this to be a misconfiguration.
                  </p>

              </div>
          </div>
      </div>
  </div>
  `,
})
export class UnauthorizedComponent {
  breadcrumbs: BreadcrumbLink[];

  constructor() {
    this.breadcrumbs = [
      {
        name: 'Unauthorized'
      }
    ];
  }

}
