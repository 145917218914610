// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `hmrEnvironment.ts` with `hmrEnvironment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  hostUrl: 'http://localhost:9000',

  env: 'dev',

  searchCache: false,
  searchCacheTTL: 0,

  churnZeroAppKey: '',

  pendoApiKey: '',

  sentryDSN: '',

  region: 'us-east-1',
  identityPoolId: 'us-east-1:25dd81f9-85b8-4f76-868e-3a0c3a175ca0',
  userPoolId: 'us-east-1_A8kjzkCxE',
  clientId: '374aq6gm2addpi6a3ispv39tou',

  apiV2Url: 'https://dev.api.bridgeft.com/v2',
  websocketUrl: 'wss://bxhu95zvgc.execute-api.us-east-1.amazonaws.com/dev/',

  apiWealthbox: 'https://api.crmworkspace.com/v1',
  wealthboxApiKey: 'c202bcec4497f39ddbf68223e91d452a',

  apiPortformer: 'https://app.portformer.com/api/v2',
  portformerAppKey: 'z+Aj8EKo7UfxEp3zGhHJTNfFCqtGINKGxonS8b3egss=',

  intercomKey: '',

  plaidServiceUrl: 'https://dev.api.bridgeft.com/v2/integrations/plaid',

  insightsApiUrl: 'https://dev.insights.api.bridgeft.com/',

  portformerExternalUrl: 'https://www.portformer.com/bridgeft-portformer-integration',

  stratifiExternalUrl: 'https://sandbox.stratifi.com/o/authorize',
  stratifiClientId: 'wESUw6y1aXFXrgtmiKEK13HHcfYJejzO2N0oQUCi'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
