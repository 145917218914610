import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './parameters.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ParametersEffects} from './parameters.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('parameters', reducer),
    EffectsModule.forFeature(
      [ParametersEffects]
    ),
  ]
})
export class ParametersStoreModule {}
