import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SuccessNotificationComponent} from '../../../ui/success-notification/success-notification.component';
import {ErrorNotificationComponent} from '../../../ui/error-notification/error-notification.component';
import {Router} from '@angular/router';
import {JobType} from '../../type_classes/websocket';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationComponent} from '../../components/notification/confirmation/confirmation.component';
import {Observable} from 'rxjs';
import {WarningDialogComponent} from '../../../ui/warning-dialog/warning-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackbar: MatSnackBar,
              private router: Router,
              private dialog: MatDialog) { }

  displayConfirmationNotification(titleText, msg, type = null) {
    return this.dialog.open(WarningDialogComponent, {
      data: {
        title: titleText,
        message: msg,
        type
      }
    });
  }

  // Returns true or false
  displayConfirm(message: string): Observable<boolean> {
    return this.dialog.open(ConfirmationComponent, {data: {message}}).afterClosed();
  }

  showSuccessNotification(msg: string) {
    this.snackbar.openFromComponent(SuccessNotificationComponent, {
      data: {message: msg},
      duration: 3000});
  }

  showErrorNotification(msg: string, duration = 5000) {
    this.snackbar.openFromComponent(ErrorNotificationComponent, {
      data: {message: msg},
      duration,
    });
  }

  showInfoNotification(msg: string) {
    this.snackbar.open(msg, '', {
      duration: 3000
    });
  }

  showJobCompleteNotification(type: string, id: number = null): void {
    if (type === JobType.PDF_REPORT) {

      const snackbar = this.snackbar.open('PDF Reports have been generated', 'View Reports', {duration: 10000});
      snackbar.onAction().subscribe(() => {
        this.router.navigateByUrl('/reporting/printable-reports');
        snackbar.dismiss();
      });
    } else if (type === JobType.BILLING_REPORT) {

      const snackbar = this.snackbar.open('Billing has completed', 'View Billing', {duration: 10000});

      snackbar.onAction().subscribe(() => {
        this.router.navigateByUrl(`/revenue/billing/reports/detail/${id}`);
        snackbar.dismiss();
      });
    }
  }

  showAppReloadNotification(): void {
    const snackbar = this.snackbar.open(
      'Atlas has received important updates. Please reload the application.',
      'Reload',
      {duration: 1000 * 60 * 2});

    snackbar.onAction().subscribe(() => {
      window.location.reload();
    });
  }
}
