import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getParameters, State} from './index';
import {
  LoadParameters,
  LoadParametersFail,
  LoadParametersSuccess, UpdateParameter, UpdateParameterFail,
  UpdateParameters, UpdateParametersFail, UpdateParametersSuccess,
  UpdateParameterSuccess
} from './parameters.actions';
import {ParametersService} from '../parameters.service';
import {InsightsParameter} from '../parameter.type';
@Injectable()
export class ParametersEffects {
  constructor(
    private parametersService: ParametersService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  
  loadParameters$ = createEffect(() => this.actions$.pipe(
    ofType(LoadParameters),
    withLatestFrom(this.store.pipe(select(getParameters))),
    mergeMap(() => this.parametersService.getParameters().pipe(
      map((resp) => LoadParametersSuccess({parameters: resp})),
      catchError((err) => of(LoadParametersFail({error: err.error})))
    )),
    take(1),
  ));

  
  updateParameter$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateParameter),
    map(action => action.parameter),
    mergeMap((parameter: InsightsParameter) => {
      return this.parametersService.updateParameter(parameter).pipe(
        map((updatedParameter: InsightsParameter) => UpdateParameterSuccess({parameter: updatedParameter})),
        catchError((err) => {
          return of(UpdateParameterFail({error: err.error}));
        })
      );
    })
  ));

  
  updateParameters$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateParameters),
    map(action => action.parameters),
    mergeMap((parameters: InsightsParameter[]) => {
      return this.parametersService.updateParameters(parameters).pipe(
        map((updatedParameters: InsightsParameter[]) => UpdateParametersSuccess({parameters: updatedParameters})),
        catchError((err) => {
          return of (UpdateParametersFail({error: err.error}));
        })
      );
    })
  ));
}
