import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenchmarksListComponent } from './benchmarks-list/benchmarks-list.component';
import {RouterModule} from '@angular/router';
import {DevExtremeModule} from '../devextreme/dev-extreme.module';
import {SharedModule} from '../shared/shared.module';
import {IndexModule} from '../idc/index.module';
import {
  BenchmarkSelectAssignmentsModalComponent
} from './benchmark-select-assignments-modal/benchmark-select-assignments-modal.component';

@NgModule({
    declarations: [
        BenchmarksListComponent,
        BenchmarkSelectAssignmentsModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DevExtremeModule,
        SharedModule,
        IndexModule
    ],
    exports: [
        BenchmarkSelectAssignmentsModalComponent,
        BenchmarksListComponent
    ]
})
export class BenchmarkModule { }
