<div class="search-overlay">
  <div class="search-container">
    <div class="close-button" (click)="exitSearch()">
      <mat-icon>close</mat-icon>
    </div>

    <div class="search-bar-container">
      <mat-form-field appearance="outline" style="width: 100%;">
        <input #searchBox name="searchbox" type="text"
               matInput
               (input)="search(searchBox.value)"
               placeholder="Search"
               autofocus>
        <mat-icon matPrefix style="color: #7C7C7C; margin: 5px 10px 5px 5px;">search</mat-icon>
      </mat-form-field>
    </div>

    <mat-tab-group [color]="'primary'">
      <mat-tab label="Accounts">
        <div class="results-container">
          <div>
            <p *ngIf="loadingEntities">
              <app-material-spinner></app-material-spinner>
            </p>
            <p *ngIf="!loadingEntities && (accounts$ | async)?.length === 0">No accounts found.</p>
          </div>

          <div (click)="navigateTo('/clients/account/', account.id)"
               class="search-item account"
               *ngFor="let account of accounts$ | async | slice:0:10">

            <div class="circle-icon search-account">{{ account.name | slice:0:3 | uppercase }}</div>

            <div>
              {{ account.display_name }}
              <br>
              Number: {{ account.number }}
              <br>
              Opened: {{ account.first_funded_date }}
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Households">
        <div class="results-container">
          <div>
            <p class="text-lead" *ngIf="loadingEntities">Loading households...</p>
            <p class="text-lead" *ngIf="!loadingEntities && (households$ | async)?.length === 0">No households found.</p>
          </div>

          <div (click)="navigateTo('/clients/household/', household.id)"
               class="search-item household"
               *ngFor="let household of households$ | async | slice:0:10">

            <div class="circle-icon search-household">{{ household.name | slice:0:3 | uppercase }}</div>
            <div>
              {{ household.name }}
              <br>
              Opened: {{ household.first_funded_date }}
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Benchmarks">
        <div class="results-container">
          <div>
            <p *ngIf="(benchmarks$ | async)?.length === 0">No benchmarks found.</p>
            <div *ngIf="navigationStarting" style="padding: 10px;">
              Loading...
              <div style="margin: 10px;"></div>
              <app-material-spinner></app-material-spinner>
            </div>
          </div>

          <div *ngIf="!navigationStarting">
            <div (click)="navigateToBenchmark(benchmark)"
                 class="search-item benchmark"
                 *ngFor="let benchmark of benchmarks$  | async | slice:0:10">

              <div class="circle-icon search-benchmark">
                {{ benchmark.name | slice:0:3 | uppercase }}
              </div>

              <div>
                {{ benchmark.name }}
                <br>
                <div [ngPlural]="benchmark.coefficients.length">
                  <ng-template ngPluralCase="0">Composed of {{ benchmark.coefficients.length }} indexes</ng-template>
                  <ng-template ngPluralCase="1">Composed of {{ benchmark.coefficients.length }} index</ng-template>
                  <ng-template ngPluralCase="other">Composed of {{ benchmark.coefficients.length }} indexes</ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Fee Structures">
        <div class="results-container">
          <ng-container *ngIf="!(feeStructuresLoading$ | async) else loading">
            <div>
              <p *ngIf="!(hasFeeStructures$ | async)">No fee structures found.</p>
            </div>

            <div (click)="navigateToFeeStructure(feeStructure.id)"
                 class="search-item fee"
                 *ngFor="let feeStructure of feeStructuresFiltered$ | async | slice:0:10">

              <div class="circle-icon search-fee">
                {{ feeStructure.name | slice:0:3 | uppercase }}
              </div>

              <div>
                {{ feeStructure.name }}
                <br>
                {{ feeStructure.calculation_type | billingDisplayCalculationType }}, {{ feeStructure.frequency | fullFrequencyName }}
              </div>

            </div>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab label="Firm Users">
        <div class="results-container">
          <div>
            <p *ngIf="(profiles$ | async)?.length === 0">No users found.</p>
          </div>

          <div (click)="navigateTo('/settings/users/details', profile.id)"
               class="search-item user"
               *ngFor="let profile of profiles$ | async | slice:0:10">

            <div class="circle-icon search-user">
              {{ profile.full_name | slice:0:3 | uppercase }}
            </div>

            <div>
              {{ profile.full_name }}
              <br>
              {{ profile.email }}
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>


<ng-template #loading>
  <div>
    Loading...
    <div style="margin: 10px;"></div>
    <app-material-spinner></app-material-spinner>
  </div>
</ng-template>

