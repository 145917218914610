import {createAction, props} from '@ngrx/store';
import {AuthSource} from '../auth-source.type';

export const LoadAuthSources = createAction('[AuthSources] Load auth source');
export const LoadAuthSourcesSuccess = createAction('[AuthSources] Load auth source success', props<{authSources: AuthSource[]}>());
export const LoadAuthSourcesFail = createAction('[AuthSources] Load auth source fail', props<{error: any}>());

export const CreateAuthSource = createAction('[AuthSources] Create auth source', props<{authSource: AuthSource}>());
export const CreateAuthSourceSuccess = createAction('[AuthSources] Create auth source success', props<{authSource: AuthSource}>());
export const CreateAuthSourceFail = createAction('[AuthSources] Create auth source fail', props<{error: any}>());

export const UpdateAuthSource = createAction('[AuthSources] Update auth source', props<{authSource: AuthSource}>());
export const UpdateAuthSourceSuccess = createAction('[AuthSources] Update auth source success', props<{authSource: AuthSource}>());
export const UpdateAuthSourceFail = createAction('[AuthSources] Update auth source fail', props<{error: any}>());

export const RemoveAuthSource = createAction('[AuthSources] Remove auth source', props<{authSource: AuthSource}>());
export const RemoveAuthSourceSuccess = createAction('[AuthSources] Remove auth source success', props<{authSource: AuthSource}>());
export const RemoveAuthSourceFail = createAction('[AuthSources] Remove auth source fail', props<{error: any}>());
