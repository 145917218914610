import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IndexResolver} from './index.resolve';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [IndexResolver],
})
export class IndexModule { }
