<!--<div *ngIf="isFirmUser" class="vertical-break" style="float: left; width: 56px">-->
<!--  <ul>-->
<!--    <li class="sidebar-button">-->
<!--      <button mat-button mat-icon-button matTooltip="Atlas Activity" (click)="togglePanel('ATLAS_ACTIVITY')">-->
<!--        <img matBadge="!" matBadgeSize="small" matBadgeColor="warn" src="../../../images/favicon/apple-icon.png" style="height: auto; width: 70%">-->
<!--      </button>-->
<!--    </li>-->

<!--    <li>-->
<!--      <div class="sidebar-button">-->
<!--        <button mat-button mat-icon-button matTooltip="Wealthbox" (click)="togglePanel('WEALTHBOX')">-->
<!--          <img src="../../../images/wealthbox-icon&#45;&#45;blue.png" style="height: auto; width: 70%">-->
<!--        </button>-->
<!--      </div>-->
<!--    </li>-->
<!--  </ul>-->
<!--</div>-->

<!--*ngIf="sidePanel.opened$ | async"-->

<div *ngIf="isFirmUser && sidePanel.opened$ | async" style="width: 400px;">

  <app-atlas-activity *ngIf="(sidePanel.currentSection$ | async) === 'ATLAS_ACTIVITY'"></app-atlas-activity>

  <app-wealthbox-toolbar *ngIf="(sidePanel.currentSection$ | async) === 'WEALTHBOX'"></app-wealthbox-toolbar>
</div>
