import {createAction, props} from '@ngrx/store';
import {Firm} from '../../firm';

export const Load = createAction('[FeeStructures] Load firms');
export const LoadSuccess = createAction('[Firms] Load firms success', props<{Firms: Firm[]}>());
export const LoadFail = createAction('[Firms] Load firms fail', props<{error: string}>());
export const UpdateFirm = createAction('[Firms] update firm', props<{Firm: Firm}>());
export const UpdateFirmSuccess = createAction(
  '[Firms] update firm Success',
  props<{Firm: Firm}>());
export const UpdateFirmFail = createAction('[Firms] update firm Fail', props<{error: string}>());
// export const LoadLoggedInFirm = createAction('[Firms] Load logged in firm');
// export const LoadLoggedInFirmSuccess = createAction(
//   '[Firms] load logged in firm Success',
//   props<{Firm: Firm}>());
// export const LoadLoggedInFirmFail = createAction('[Firms] load logged in firm Fail', props<{error: string}>());
