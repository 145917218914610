import {Component, Inject, OnInit} from '@angular/core';
import {FeeStructuresService} from '../../settings/billing/fee-structures/fee-structures.service';
import {FeeStructure} from '../billing';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Account} from '../../accounts/account';
import _uniq from 'lodash-es/uniq';
import _every from 'lodash-es/every';
import _difference from 'lodash-es/difference';
import _flatten from 'lodash-es/flatten';
import {AccountService} from '../../accounts/account.service';
import {NotificationService} from '../../shared/services/ui/notification.service';
import {Subscription} from 'rxjs';
import {Actions, ofType} from '@ngrx/effects';
import * as accountActions from '../../accounts/state/account.actions';

class Status {
  assigning = false;
}

@Component({
  selector: 'app-billing-select-fee-structures-for-assignment-modal',
  templateUrl: './billing-select-fee-structures-for-assignment-modal.component.html',
})
export class BillingSelectFeeStructuresForAssignmentModalComponent implements OnInit {
  status: Status;
  feeStructures: FeeStructure[];
  private accounts: Account[];
  public selected: number[] = [];
  heterogeneousFeeStructures: boolean;
  numAccounts: number;

  successSubscription: Subscription;
  failureSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    private notification: NotificationService,
    private billingFeeStructuresService: FeeStructuresService,
    private dialogRef: MatDialogRef<BillingSelectFeeStructuresForAssignmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private actions$: Actions,
  ) {
    this.status = new Status();

    this.accounts = data.accounts;
    this.numAccounts = this.accounts.length;

    const selectedIds = _uniq(_flatten(this.accounts.map(a => a.fee_structures_ids)));

    /*
        True if not all accounts have all the selected fee structures
         */
    this.heterogeneousFeeStructures = !_every(this.accounts, (acct: Account) => {
      // it's ok if the account's fee structures are fully represented by the selected fee structures
      return _difference(selectedIds, acct.fee_structures_ids).length === 0;
    });
  }

  ngOnInit() {
    this.billingFeeStructuresService.feeStructures$
      .subscribe(resp => {
        this.feeStructures = resp;
      });

    this.successSubscription = this.actions$.pipe(
      ofType(accountActions.UpdateAccountsSuccess),
    ).subscribe(() => {
      this.status.assigning = false;
      this.notification.showSuccessNotification(`Updated ${this.accounts.length} accounts`);
      this.dialogRef.close(true);
    });

    this.failureSubscription = this.actions$.pipe(
      ofType(accountActions.UpdateAccountsFail),
    ).subscribe((err) => {
      this.status.assigning = false;
      this.notification.showErrorNotification(`Failed to update accounts.`);
    });
  }

  save() {
    this.status.assigning = true;

    const feeStructureIds = this.selected;

    this.accounts.forEach(account => account.fee_structures_ids = feeStructureIds);

    this.accountService.dispatchAccounts(this.accounts);
  }

  isSameFields = (field) => {
    if (!this.selected || this.selected.length === 0) {
      return true;
    }

    const uniq =  _uniq(this.selected.map(s => s[field]));

    return uniq.length <= 1;
  }

  isSaveDisabled() {
    return !this.isSameFields('collection_type') || !this.isSameFields('frequency')
      || !this.isSameFields('quarter_cycle') || !this.selected || this.selected.length === 0;
  }

}
