import {Injectable} from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CognitoService} from '../shared/services/auth/cognito.service';
import {UtilsService} from '../shared/services/utils.service';
import _keyBy from 'lodash-es/keyBy';
import { Account } from './account';
import {FeeStructuresService} from '../settings/billing/fee-structures/fee-structures.service';
import {BenchmarkService} from '../benchmark/benchmark.service';
import {BillingSplitsService} from '../billing/billing-splits.service';
import {Household} from '../households/household';
import {combineLatest, Observable, of} from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromAccount from './state';
import * as fromHousehold from '../households/state';
import * as accountActions from './state/account.actions';
import * as householdActions from '../households/state/household.actions';
import {ChurnZeroService} from '../shared/services/churn-zero.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  accountsEndpoint: string;
  householdsEndpoint: string;

  accountsLoaded$ = this.store.pipe(select(fromAccount.getLoaded));
  householdsLoaded$ = this.store.pipe(select(fromHousehold.getLoaded));

  entitiesLoaded$ = combineLatest([
    this.accountsLoaded$,
    this.householdsLoaded$,
  ]).pipe(
    map(([accountsLoaded, householdsLoaded]) => {
      return accountsLoaded && householdsLoaded;
    })
  );

  /*
    Source of truth on accounts, this fetches the accounts from the state, if they don't exist
    then they are fetched from the server.
   */
  accounts$ = this.store.pipe(
    select(fromAccount.getAccounts),
    map((accounts: Account[]) => {
      return accounts.filter(a => a.status !== 'closed');
    }),
  );

  households$ = this.store.pipe(select(fromHousehold.getHouseholds));

  /**
   * @deprecated use accounts$ observable instead
   */
  accounts: Account[] = [];
  /**
   * @deprecated use households$ observable instead
   */
  households: any = [];

  /**
   * @deprecated need to crete getAccountsByHouseholdId method which will use accounts$ observable to find needed account
   */
  accountsByHouseholdID: any;
  /**
   * @deprecated
   */
  accountsByNumber: any;

  householdsByHouseholdId: any;

  accountsByAccountId: any;

  entities: any = [];

  lucaMigratedAccountIDs: number[];
  lucaMigratedHouseholdIDs: number[];

  constructor(private http: HttpClient,
              private cognitoService: CognitoService,
              private util: UtilsService,
              private feeStructureService: FeeStructuresService,
              private benchmarkService: BenchmarkService,
              private splitsService: BillingSplitsService,
              private utils: UtilsService,
              private store: Store<fromAccount.State>,
              private churnZeroService: ChurnZeroService,) {
    this.accountsEndpoint = `${environment.apiV2Url}/account-management/accounts`;
    this.householdsEndpoint = `${environment.apiV2Url}/account-management/households`;

    this.accounts = [];
    this.households = [];
    this.householdsByHouseholdId = {};
    this.accountsByHouseholdID = {};
  }

  getAccounts(): Observable<Account[]> {
    if (this.accounts.length > 0) {
      return of(this.accounts);
    }

    const url = `${this.accountsEndpoint}?pager.limit=20000`;

    return this.http.get(url).pipe(
      map((resp: {data: Account[]}) => {
        this.accounts = resp.data;
        this.setupAccountData();

        if (!this.lucaMigratedAccountIDs) {
          this.lucaMigratedAccountIDs = [];
          this.lucaMigratedHouseholdIDs = [];

          resp.data.forEach(account => {
              this.lucaMigratedAccountIDs.push(account.id);
              this.lucaMigratedHouseholdIDs.push(account.household_id);
          });
        }

        for (const account of resp.data) {
          if (account.custodian === 'SWB') {
            for (let i = 0; i < account.advisor_codes.length; i++) {
              const trimmedCode = parseInt(account.advisor_codes[i], 10)

              if (!isNaN(trimmedCode)) {
                account.advisor_codes[i] = `${trimmedCode}`
              }
            }

          }
        }

        this.accounts = resp.data;
        this.setupAccountData();

        return resp.data;
      }),
      shareReplay(1),
    );
  }

  getClosedAccounts(): Observable<Account[]> {
    const url = `${this.accountsEndpoint}?status=closed`;
    return this.http.get(url).pipe(
      map((resp: {data: Account[]}) => {
        return resp.data;
      }),
      shareReplay(1)
    );
  }

  getHouseholds(): Observable<Household[]> {
    if (this.households.length > 0) {
      return of(this.households);
    }

    return this.http.get(this.householdsEndpoint).pipe(
      map((resp: {data: Household[]}) => {
        this.households = resp.data;
        this.setupHouseholdData();
        return resp.data;
      }),
      shareReplay(1),
    );
  }

  getHouseholdsPaginated(pageObj) {
    const params = new HttpParams({fromObject: pageObj});

    return this.http.get(this.householdsEndpoint, {params});
  }

  getHousehold(id: number): Observable<Household> {
    const url = `${this.householdsEndpoint}/${id}`;

    return this.http.get<Household>(url);
  }

  getAccount(id: number): Observable<Account> {
    const url = `${this.accountsEndpoint}/${id}`;

    return this.http.get<Account>(url);
  }


  filterHouseholds(options = {}, filters = {}): Promise<Household[]> {
    const url =   `${this.householdsEndpoint}/filter`;
    const params = new HttpParams({fromObject: options});

    return this.http.post(url, filters, {params}).toPromise<any>()
      .then((resp) => {
        return resp.data;
      });
  }

  /**
   * @deprecated need to create method based on accounts$ observable
   */
  setupAccountData(): void {
    this.accountsByAccountId = {};
    this.accountsByHouseholdID = {};


    this.accountsByAccountId = _keyBy(this.accounts, 'id');

    // Setup accountsByHousehold
    this.accounts.forEach((account: any) => {
      if (!this.accountsByHouseholdID[account.household_id]) {
        this.accountsByHouseholdID[account.household_id] = [account];
      } else {
        this.accountsByHouseholdID[account.household_id].push(account);
      }
    });
  }

  /**
   * @deprecated need to create method based on households$ observable
   */
  setupHouseholdData(): void {
    this.householdsByHouseholdId = {};

    this.households.forEach((household: any) => {
      this.householdsByHouseholdId[household.id] = household;
    });
  }

  initialize(): Observable<Account[] | Household[]> {
    return combineLatest([this.accounts$, this.households$]).pipe(
      map(([accounts, households]) => households.concat(accounts))
    );
  }

  // State management: if (fee structures, benchmarks, billingsplits assigned no longer exist, filter them)
  validateData(account) {
    account.fee_structures_ids =
      account.fee_structures_ids.filter((id) => id in this.feeStructureService.chargeUnitsByID);

    account.benchmarks_ids =
      account.benchmarks_ids.filter((id) => id in this.benchmarkService.benchmarksByID);

    account.billing_splits_ids =
      account.billing_splits_ids.filter((id) => id in this.splitsService.splitsByID);
  }

  createHousehold(household: Household) {
    return this.http.post<Household>(this.householdsEndpoint, household).pipe(
      tap(() => {
        this.churnZeroService.trackEvent('Create Households', `User created a new household`);
      })
    );
  }

  remapAccountsToHousehold(accounts: Account[], household: Household) {
    const url = `${this.householdsEndpoint}/remap`;

    const remapObj = {};

    accounts.forEach(a => {
      remapObj[`${a.id}`] = household.id;
    });

    return this.http.post<Household>(url, remapObj);
  }

  deleteHousehold(household: Household) {
    return this.http.delete(`${this.householdsEndpoint}/${household.id}`);
  }

  deleteHouseholds(households: Household[]) {
    const householdIDs = households.map(h => h.id);

    const options = {
      body: {
        ids: householdIDs
      }
    };

    return this.http.request('delete', this.householdsEndpoint, options);
  }

  saveHousehold(household: Household): Observable<Household> {
    const url = `${this.householdsEndpoint}/${household.id}`;

    return this.http.put<Household>(url, household);
  }

  saveHouseholds(households: Household[]): Observable<Household[]> {
    return this.http.put<Household[]>(this.householdsEndpoint, households);
  }

  // Linked to the state actions
  saveAccount(account: Account): Observable<Account> {
    const url = `${this.accountsEndpoint}/${account.id}`;

    return this.http.put<Account>(url, account);
  }

  saveAccounts(accounts: Account[]): Observable<Account[]> {
    return this.http.put<Account[]>(this.accountsEndpoint, accounts);
  }

  assignFeeStructuresHelper(ids: number[], feeStructureIds: number[]) {
    const accountsToUpdate = this.accounts.filter((account: any) => ids.includes(account.id));

    accountsToUpdate.forEach((account: any) => {
      account.fee_structures_ids = feeStructureIds;
      this.validateData(account);
    });

    return this.http.put(this.accountsEndpoint, accountsToUpdate).toPromise().then((resp: any) => {
      this.util.updateDataByPath(this.accounts, resp);
      this.util.updateDataByPath(this.accountsByNumber, this.accounts, 'number');
      return resp;
    });
  }

  assignFeeStructures(ids: number[], feeStructureIds: number[]) {
    if (!this.accounts.length) {
      return this.getAccounts().toPromise()
        .then(() => {
          return this.assignFeeStructuresHelper(ids, feeStructureIds);
        });
    } else {
      return this.assignFeeStructuresHelper(ids, feeStructureIds);
    }

  }

  assignSplits(accounts: Account[], splitIDs: number[]) {
    if (accounts.length) {
      accounts.forEach(acc => {
        acc.billing_splits_ids = splitIDs;
      });

      this.dispatchAccounts(accounts);
    }
  }

  assignBenchmarks(entities: Account[] | Household[], benchmarkIds: number[], propagateHouseholds: boolean) {

    entities.forEach(e => e.benchmarks_ids = benchmarkIds);

    const householdsToUpdate: Household[] = [];
    const accountsToUpdate: Account[] = [];

    entities.forEach((e) => {
      if (e.is_account) {
        accountsToUpdate.push(e);
      } else {
        householdsToUpdate.push(e);
      }
    });

    if (householdsToUpdate.length > 0 && propagateHouseholds) {
      const householdIDs = householdsToUpdate.map(h => h.id);

      this.accounts.forEach(a => {
        if (householdIDs.includes(a.household_id)) {
          a.benchmarks_ids = benchmarkIds;
          accountsToUpdate.push(a);
        }
      });
    }

    if (accountsToUpdate.length > 0) {
      this.dispatchAccounts(accountsToUpdate);
    }

    if (householdsToUpdate.length > 0) {
      this.dispatchHouseholds(householdsToUpdate);
    }
  }

  get accountsByID() {
    return _keyBy(this.accounts, 'id');
  }

  get householdsByID() {
    return _keyBy(this.households, 'id');
  }

  getAccountsByHousehold(householdId: number): Observable<Account[]> {
    const url = `${this.accountsEndpoint}?household_id=${householdId}`;
    return this.http.get(url).pipe(
      map((resp: {data: Account[]}) => resp.data as Account[])
    );
  }

  dispatchAccount(account: Account) {
    this.store.dispatch(accountActions.UpdateAccount({account}));
  }

  dispatchAccounts(accounts: Account[]) {
    this.store.dispatch(accountActions.UpdateAccounts({accounts}));
  }

  dispatchHousehold(household: Household) {
    this.store.dispatch(householdActions.UpdateHousehold({household}));
  }

  dispatchHouseholds(households: Household[]) {
    this.store.dispatch(householdActions.UpdateHouseholds({households}));
  }
}
