import { NgModule, Injectable } from '@angular/core';
import {RouterModule, Routes, UrlHandlingStrategy, UrlTree} from '@angular/router';
import {AuthGuardService} from './shared/services/auth/auth-guard.service';
import {MainComponent} from './main/main.component';
import {
  BenchmarksResolver,
  FirmResolver,
  JobsResolver, ProfilesResolver
} from './app.resolve';
import {LoginComponent} from './login/login/login.component';
import {VerifyClientComponent} from './login/verify-client/verify-client.component';
import {UnauthorizedComponent} from './service-pages/unauthorized/unauthorized.component';
import {NoPermissionsComponent} from './service-pages/no-permissions/no-permissions.component';
import {HashkeyLoginComponent} from './login/hashkey-login/hashkey-login.component';
import {WealthboxIntegrationConfigResolver} from './settings/integrations/wealthbox/wealthbox.resolve';
import {ProductboardComponent} from './productboard/productboard.component';
import {ClientInboxLandingComponent} from './client-inbox/client-inbox-landing/client-inbox-landing.component';
import {StatusComponent} from './status/status.component';
import {MaintenanceModeComponent} from './maintenance-mode/maintenance-mode.component';

// Custom URL handler, telling whether e an Angular Route or legacy angularjs route
// During migration, new routes will need to be added here, so that Angular knows to use its router.
@Injectable()
export class UrlHandler implements UrlHandlingStrategy {

    shouldProcessUrl(url: UrlTree): boolean {
        // Example: url.toString().startsith('/test');
        // Set to false for now, since there are no new angular routes yet.
      return true;
    }

    extract(url: UrlTree): UrlTree {
        return url;
    }

    merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree {
        return newUrlPart;
    }
}

// Routes for New Angular Components should be declared here
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'firm/:id',
    component: LoginComponent,
  },
  {
    path: 'hashkey-login',
    redirectTo: 'hashkey-login/',
    pathMatch: 'full',
  },
  {
    path: 'hashkey-login/:id',
    component: HashkeyLoginComponent,
  },
  {
    path: 'verify-client',
    component: VerifyClientComponent
  },
  {
    path: 'client-inbox',
    component: ClientInboxLandingComponent,
  },
  {
    path: 'status',
    component: StatusComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'maintenance-mode',
    component: MaintenanceModeComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
      }, {
        path: 'reporting',
        loadChildren: () => import('./reporting/reporting.module').then(mod => mod.ReportingModule),
      }, {
        path: 'clients',
        loadChildren: () => import ('./clients/clients.module').then(mod => mod.ClientsModule),
      }, {
        path: 'revenue',
        loadChildren: () => import('./revenue/revenue.module').then(mod => mod.RevenueModule),
      },
      {
        path: 'investment-management',
        loadChildren: () => import('./investment-management/investment-management.module').then(mod => mod.InvestmentManagementModule),
      },
      {
        path: 'data-management',
        loadChildren: () => import('./data-management/data-management.module').then(mod => mod.DataManagementModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(mod => mod.SettingsModule)
      },
      {
        path: 'insights',
        loadChildren: () => import('./insights/insights.module').then(mod => mod.InsightsModule),
      },
      {
        path: 'roadmap',
        component: ProductboardComponent
      },
      {
        path: 'cp',
        loadChildren: () => import('./client-portal/client-portal.module').then(mod => mod.ClientPortalModule)
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'no-permissions',
        component: NoPermissionsComponent
      },
      {
        path: 'jobs',
        loadChildren: () => import('./jobs/jobs.module').then(mod => mod.JobsModule)
      },
      {
        path: 'roadmap',
        component: ProductboardComponent
      },
    ],
    resolve: {
      firm: FirmResolver,
      jobs: JobsResolver,
      // entities: AccountsHouseholdsResolver,
      benchmarks: BenchmarksResolver,
      // feeStructures: FeeStructuresResolver,
      profiles: ProfilesResolver,
      wealthboxConfig: WealthboxIntegrationConfigResolver
    }
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
