import {Component, Input} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-data-grid-actions-bar',
  templateUrl: './data-grid-actions-bar.component.html',
})
export class DataGridActionsBarComponent {
  isFilterRowVisible: boolean;

  @Input() isGrouped?: boolean;
  @Input() dataGridInstance: DxDataGridComponent;
  @Input() hideExportBySelect?: boolean;
  @Input() hideColumnChooser?: boolean;

  constructor() { }

  expandAllGroups() {
    this.dataGridInstance.instance.expandAll();
  }

  collapseAllGroups() {
    this.dataGridInstance.instance.collapseAll();
  }

  toggleFilters() {
    this.isFilterRowVisible = !this.isFilterRowVisible;
    this.dataGridInstance.instance.option('filterRow', {visible: this.isFilterRowVisible, applyFilter: 'auto'});
  }

  showColumnChooser() {
    this.dataGridInstance.instance.showColumnChooser();
  }

  exportAllRows() {
    this.dataGridInstance.instance.exportToExcel(false);
  }

  exportSelectedRows() {
    this.dataGridInstance.instance.exportToExcel(true);
  }
}
