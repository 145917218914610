import { Component, OnInit } from '@angular/core';
import {MaterialModule} from '../material/material.module';
import {DevExtremeModule} from '../devextreme/dev-extreme.module';
import {IngestionStageHistory, IngestionStatus, StatusService} from './status.service';
import {CommonModule, JsonPipe, KeyValuePipe, NgForOf} from '@angular/common';
import {PipesModule} from '../shared/pipes/pipes.module';
import * as moment from 'moment';
import {RouterLinkWithHref} from '@angular/router';
import {Observable} from 'rxjs';
import {IngestionStageComponent} from './ingestion-stage/ingestion-stage.component';

@Component({
  standalone: true,
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
    imports: [
        CommonModule,
        MaterialModule,
        DevExtremeModule,
        KeyValuePipe,
        PipesModule,
        RouterLinkWithHref,
        IngestionStageComponent,
    ]
})
export class StatusComponent implements OnInit {

  ingestionStatuses: any;
  ingestionStatusesByCustodian = {};

  todayDate = moment();

  constructor(private statusService: StatusService) { }

  ngOnInit(): void {
    this.statusService.getLatestIngestionStatus()
      .toPromise().then((statuses) => {
      this.ingestionStatuses = statuses;
      statuses.forEach((s) => {
        if (!this.ingestionStatusesByCustodian[s.custodian]) {
          this.ingestionStatusesByCustodian[s.custodian] = [];
        }

        this.ingestionStatusesByCustodian[s.custodian].push(s)
      })
    })
  }
}
