<dx-popup #columnChooser
          title="Visible columns"
          [container]="container"
          [(visible)]="visible"
          [width]="350"
          [height]="500"
          [closeOnOutsideClick]="true"
          [resizeEnabled]="true"
          (onHiding)="cancel()"
          [shading]="false">

  <dxo-position at="right top" my="right top" [of]="container"></dxo-position>

  <dx-list [dataSource]="columns"
           [(selectedItemKeys)]="selectedItems"
           keyExpr="caption"
           displayExpr="caption"
           (onSelectionChanged)="onSelectionChanged($event)"
           [searchEnabled]="false"
           [showSelectionControls]="true"
           selectionMode="all">
  </dx-list>
</dx-popup>
