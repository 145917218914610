import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AlertConditionSet, TimeSeries} from './alert-condition.type';
import {map} from 'rxjs/operators';
import {getAlertConditions, State} from './state';
import {select, Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AlertConditionsService {
  endpoint = `${environment.apiV2Url}/insights/alert-condition-sets`;

  alertConditions$ = this.store.pipe(select(getAlertConditions));

  constructor(private http: HttpClient,
              private store: Store<State>) { }

  getAlertConditions(): Observable<AlertConditionSet[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: AlertConditionSet[]}) => {
        return resp.data;
      })
    );
  }

  getAlertCondition(id: number): Observable<AlertConditionSet> {
    const url = `${this.endpoint}/${id}`;

    return this.http.get<AlertConditionSet>(url);
  }

  updateAlertCondition(alertCondition: AlertConditionSet): Observable<AlertConditionSet> {
    const url = `${this.endpoint}/${alertCondition.id}`;

    return this.http.put<AlertConditionSet>(url, alertCondition);
  }

  updateAlertConditions(alertConditions: AlertConditionSet[]): Observable<AlertConditionSet[]> {
    return this.http.put<AlertConditionSet[]>(this.endpoint, alertConditions);
  }

  createAlertCondition(alertConditionSet: AlertConditionSet): Observable<AlertConditionSet> {
    return this.http.post<AlertConditionSet>(this.endpoint, alertConditionSet);
  }

  removeAlertCondition(alertCondition: AlertConditionSet): Observable<any> {
    const url = `${this.endpoint}/${alertCondition.id}`;

    return this.http.delete(url);
  }

  removeAlertConditions(alertConditions: AlertConditionSet[]): Observable<any> {
    const url = `${this.endpoint}`;

    const ids = alertConditions.map(c => c.id);

    const data = {
      ids
    };

    const options = { body: data };

    // @ts-ignore
    return this.http.delete(url, options);
  }

  // TimeSeries TODO: Create type
  getTimeSeries(): Observable<TimeSeries> {
    const url = `${environment.apiV2Url}/insights/time-series`;

    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp.data;
      })
    );
  }

}
