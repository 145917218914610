import { Component, OnInit } from '@angular/core';
import {UserProfileService} from '../../../shared/services/auth/user-profile.service';
import {BreadcrumbLink} from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import {FirmService} from '../../../firm/firm.service';
import {Firm} from '../../../firm/firm';
import {ActivatedRoute} from '@angular/router';
import {Invoice} from '../invoice';
import _get from 'lodash-es/get';
import _values from 'lodash-es/values';
import _pickBy from 'lodash-es/pickBy';
import _mapKeys from 'lodash-es/mapKeys';
import _pick from 'lodash-es/pick';
import {AccountService} from '../../../accounts/account.service';
import {Account} from '../../../accounts/account';
import {take} from 'rxjs/operators';
import {State, Store} from '@ngrx/store';
import * as fromAccounts from '../../../accounts/state';
import * as accountActions from '../../../accounts/state/account.actions';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent implements OnInit {
  isEndClientUser: () => boolean;
  isFirmUser: () => boolean;
  breadcrumbs: BreadcrumbLink[];
  isHelpWindowVisible: boolean;
  firm: Firm;
  invoice: Invoice;
  groupId: number;
  accountsByID: {[key: number]: Account};
  aum: number;
  totalAccountValuation = 0.0;
  totalAccountBalance = 0.0;
  totalAccountDebits = 0.0;
  totalAccountFees = 0.0;
  isRebateInvoice: boolean;
  isInvoiceByClient: boolean;
  clientName: string;

  loading = true;

  // custom datasource in order to use datagrid
  summaryDataSource: string[] = [
    'Assets Under Management',
    'Billable Balance',
    '',
    'Amount Due',
    'Effective Rate'
  ];

  constructor(
    private userProfileService: UserProfileService,
    firmService: FirmService,
    route: ActivatedRoute,
    private accountService: AccountService,
    private accountStore: Store<fromAccounts.State>,
  ) {
    this.breadcrumbs = [
      {
        url: '/billing/nav',
        name: 'Billing'
      },
      {
        url: '/invoices/list',
        name: 'Client Invoices'
      },
    ];

    this.firm = firmService.firm;

    this.invoice = route.snapshot.data.invoice;

    this.isRebateInvoice = this.invoice.is_rebate_invoice;

    this.isInvoiceByClient = this.invoice.invoice_by_client;

    this.clientName = this.invoice.client_name;

    this.groupId = _get(this.invoice, 'billing_group_serialized[0].id');

    this.accountsByID = {};

    let idstr;
    this.aum = 0.0;
    this.invoice.account_ids.forEach((id) => {
      idstr = id.toString();

      this.aum += this.invoice.account_value_on_snapshot_date[idstr];

      this.totalAccountValuation += this.invoice.account_value_on_snapshot_date[idstr];
      this.totalAccountBalance += this.invoice.account_df.billable_balance[idstr];

      this.totalAccountDebits += this.invoice.account_df.account_period_debit[idstr];
      this.totalAccountFees += this.invoice.account_df.account_period_fee[idstr];
    });
  }

  ngOnInit() {

    if (this.isRebateInvoice) {
      this.summaryDataSource[2] = 'Rebate Fee';
      this.totalAccountFees = -1 * this.totalAccountFees;
      this.totalAccountDebits = -1 * this.totalAccountDebits;
    } else {
      this.summaryDataSource[2] = 'Management Fee';
    }

    this.isEndClientUser = this.userProfileService.isEndClientUser.bind(this);
    this.isFirmUser = this.userProfileService.isFirmUser.bind(this);

    this.accountService.accounts$
      // unsubscribes after 3rd emitted value
      .pipe(take(3))
      .subscribe(accounts => {
        this.accountsByID = {};

        accounts.forEach(a => {
          this.accountsByID[a.id] = a;
        });

        this.loading = false;
      });
  }

  calculateSelectedRow = (options) => {
    if (options.name === 'Valuation') {
      options.totalValue = this.totalAccountValuation;
    } else if (options.name === 'BillableBalance') {
      options.totalValue = this.totalAccountBalance;
    } else if (options.name === 'Fees') {
      options.totalValue = this.totalAccountFees;
    } else if (options.name === 'Debits') {
      options.totalValue = this.totalAccountDebits;
    } else if (options.name === 'Rebate') {
      options.totalValue = this.totalAccountDebits;
    } else if (options.name === 'EffectiveRate') {
      options.totalValue = this.periodEffectiveRatePercentage();
    }
  }

  openHelpModal() {
    this.isHelpWindowVisible = true;
  }

  showHeader() {
    return this.firm && this.firm.invoice_from && this.firm.invoice_header.length > 0;
  }

  showCustomAddress() {
    return this.firm && this.firm.invoice_from && this.firm.invoice_from.length > 0;
  }

  showEffectiveRates() {
    return this.firm && this.firm.invoice_effective_rates;
  }

  periodEffectiveRatePercentage() {
    if (!this.invoice) {
      return 0.0;
    }

    if (this.isInvoiceByClient) {
      return this.invoice.period_effective_rate;
    }
    return this.invoice.group_df.period_effective_rate[this.groupId];
  }

  showAnnualEffectiveRates() {
    return this.firm && this.firm.invoice_annualized_effective_rates;
  }

  annualEffectiveRatePercentage() {
    if (!this.invoice) {
      return 0.0;
    }

    if (this.isInvoiceByClient) {
      return this.invoice.annual_effective_rate;
    }

    return this.invoice.group_df.annual_effective_rate[this.groupId].toFixed(2);
  }

  showFeeStructures() {
    return this.firm && this.firm.invoice_include_fee_structures;
  }

  getAccount(id: number): Account | {} {
    return _get(this.accountsByID, id, {});
  }

  assignedFeeStructures(aid) {
    if (!this.invoice.account_fs_df || Object.keys(this.invoice.account_fs_df).length === 0) {
      // must be an iteration 2 invoice; lookup fee structure ids from one-to-one relationship
      const fsid = _get(this.invoice.account_df.fee_structure_id, aid, 0);
      return _values(_pickBy(this.invoice.fee_structures_serialized, {id: fsid}));
    } else {
      // iteration 3+ invoice; use multiple fee struture id lookup
      const mapped = _mapKeys(this.invoice.fee_structures_serialized, 'id');
      const fsids = this.invoice.fee_structure_ids;

      return _values(_pick(mapped, fsids));
    }
  }

  getAccountNameByFeeLocation(invoice: Invoice, aid): string {
    return _get(this.getAccount(invoice.account_df.fee_location[aid]), 'display_name', '');
  }

  getAccountName(aid): string {
    return _get(this.accountsByID[aid], 'display_name');
  }

  getFee(accountId) {
    if (this.isRebateInvoice) {
      return -1 * this.invoice.account_df.account_period_debit[accountId];
    }
    return this.invoice.account_df.account_period_debit[accountId];
  }

  getDebit(accountId) {
    if (this.isRebateInvoice) {
      return -1 * this.invoice.account_df.account_period_fee[accountId];
    }
    return this.invoice.account_df.account_period_fee[accountId];
  }

  showAgreement() {
    return this.firm && this.firm.invoice_show_agreement;
  }

  nothingDueExplanation() {
    return this.firm && this.firm.invoice_not_a_bill_explanation;
  }

  /**
   * Returns true if the direct billed amount is positive
   */
  isPaymentOwed() {
    if (!this.invoice) {
      return false;
    }

    return parseFloat(this.invoice.direct_billed_period_debit) > 0.0;
  }

  showFooter() {
    return this.firm && this.firm.invoice_from && this.firm.invoice_footer.length > 0;
  }

  getClientName() {
    if (this.isInvoiceByClient) {
      return this.clientName;
    }
    return this.invoice.billing_group_serialized[0].name;
  }

  getAUM() {
    if (this.isInvoiceByClient) {
      return this.aum;
    }
    return this.invoice.group_value_on_snapshot_date[this.groupId];
  }

  getBillableBalance() {
    if (this.isInvoiceByClient) {
      return this.totalAccountBalance;
    }
    return this.invoice.group_df.balance[this.groupId];
  }
}
