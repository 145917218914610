import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingDisplayCollectionType'
})
export class BillingDisplayCollectionTypePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'R') {
      return 'Arrears';
    } else if (input === 'D') {
      return 'Advance';
    } else if (input === 'P') {
      return 'Advance with Proration';
    }

    return '';
  }

}
