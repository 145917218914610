import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Benchmark, Coefficient} from '../../../benchmark/benchmark';
import {BenchmarkService} from '../../../benchmark/benchmark.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Index} from '../../../idc';
import {IdcService} from '../../../idc/index.service';
import {NotificationService} from '../../../shared/services/ui/notification.service';
import {map, startWith, switchMap} from 'rxjs/operators';
import {NgxTippyProps} from 'ngx-tippy-wrapper';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-benchmarks-create-edit',
  templateUrl: './benchmarks-create-edit.component.html',
  styleUrls: ['./benchmarks-create-edit.component.scss']
})
export class BenchmarksCreateEditComponent implements OnInit {
  myIndexControl = new UntypedFormControl();
  filteredIndexOptions: Observable<any>;

  tooltipProps: NgxTippyProps = {
    placement: 'bottom'
  };

  benchmark: Benchmark;
  editing = false;

  benchmarkForm: UntypedFormGroup;

  indexes: Index[];
  indexesByID: any;

  saving = false;

  constructor(private dialogRef: MatDialogRef<BenchmarksCreateEditComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private benchmarkService: BenchmarkService,
              private indexService: IdcService,
              private notification: NotificationService) {

    this.benchmark = data.benchmark;
    this.editing = !!this.benchmark;

    if (!this.editing) {
      this.benchmark = benchmarkService.constructBenchmark();
    }
  }

  ngOnInit() {
    this.benchmarkForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.benchmark.name, Validators.required),
      index: new UntypedFormControl(Validators.required)
    });

    this.indexes = this.indexService.indexes;

    this.indexesByID = this.indexService.indexesByID;

    this.filteredIndexOptions = this.myIndexControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ?  this._filterIndexes(value) : this.indexes.slice())
      );
  }

  private _filterIndexes(value: string): any {
    const filterValue = value.toLowerCase();
    return this.indexes.filter(option => option.internal_name.toLowerCase().includes(filterValue));
  }

  canSave() {
    return this.totalWeight() === 100;
  }

  saveBenchmark() {
    if (!this.canSave()) {
      this.notification.showErrorNotification('Weights must add to 100% before saving');
      return;
    }

    this.saving = true;

    // Coefficients can't be added until the benchmark is created
    if (!this.editing) {
      const coefficients = this.benchmark.coefficients;

      this.benchmark.coefficients = [];

      this.benchmarkService.saveBenchmark(this.benchmark).pipe(
        switchMap((benchmark: Benchmark) => {
          this.benchmark = benchmark;
          this.benchmark.coefficients = coefficients;

          this.benchmark.coefficients.forEach(c => c.benchmark_id = this.benchmark.id);

          return this.benchmarkService.saveBenchmark(this.benchmark);
        })
      ).toPromise().then(
        (benchmark) => {
          this.notification.showSuccessNotification(`Weights updated for benchmark: ${benchmark.name}`);
          this.dialogRef.close(benchmark);
        }).catch((err) => {
        if (err.status === 403) {
          this.notification.showErrorNotification(`err.data.message`);
        } else {
          this.notification.showErrorNotification(`There was a problem saving this benchmark.
          Please contact support: support@bridgeft.com.`);
        }

      }).finally(() => {
          this.saving = false;
      });
    } else {
      this.benchmarkService.saveBenchmark(this.benchmark).toPromise()
        .then((benchmark) => {
          this.notification.showSuccessNotification(`Weights updated for benchmark: ${benchmark.name}`);
          this.dialogRef.close(benchmark);

        })
        .catch((err) => {
          if (err.status === 403) {
            this.notification.showErrorNotification(`err.data.message`);
          } else {
            this.notification.showErrorNotification(`There was a problem saving this benchmark.
          Please contact support: support@bridgeft.com.`);
          }

        })
        .finally(() => {
          this.saving = false;
        });
    }
  }

  getIndex(coeff: Coefficient): Index {
    return this.indexesByID[coeff.index_id];
  }

  totalWeight() {
    let sum = 0;
    this.benchmark.coefficients.forEach((coefficient) => {
      sum += coefficient.weight;
    });
    return sum;
  }

  removeCoefficient(item: Coefficient) {
    this.benchmark.coefficients = this.benchmark.coefficients.filter((c) => c.index_id !== item.index_id);
  }

  selectedIndexChanged(selection) {
    const selectedIndex = selection;

    if (!selectedIndex) { return; }

    const existingCoeff = this.benchmark.coefficients.find((item) => item.index_id === selectedIndex.id);

    if (existingCoeff) {
      const index = this.indexesByID[existingCoeff.index_id];
      this.notification.showInfoNotification(`${index.internal_name} is already part of this benchmark.`);
    } else {
      const coeff = this.benchmarkService.constructCoefficient(this.benchmark.id, selectedIndex.id);
      this.benchmark.coefficients.push(coeff);
    }

    this.benchmarkForm.controls['index'].setValue(null);
    this.myIndexControl.reset();
  }

}
