<mat-toolbar color="primary">

  <img class="logo" src="assets/images/BridgeFT_Logo_white_ART%20(1).png">

  <span class="span-flex"></span>

  <a mat-button
     routerLink="/dashboard">
    Dashboard
  </a>
</mat-toolbar>

<div class="status-container">
  <div style="max-width: 850px">
    <div style="display: flex">
      <h2>{{todayDate.format("MMM Do, YYYY")}}</h2>

      <span class="span-flex"></span>

      <h2>Ingestion Status</h2>
    </div>

    <mat-divider></mat-divider>

    <br>

    <p>
      Bridge’s WealthTech Platform receives and processes data from sources overnight, delayed by a day.
      We receive data drops Tuesday through Saturday and begin processing it when it’s made available by the source.
    </p>

    <br>

    <div *ngFor="let status of ingestionStatusesByCustodian | keyvalue" style="margin-bottom: 20px">
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>{{status.key | displayCustodian}}</mat-panel-title>
        </mat-expansion-panel-header>

        <dx-data-grid
          [dataSource]="status.value"
        >
          <dxo-master-detail [enabled]="true" template="detail">

          </dxo-master-detail>

          <div *dxTemplate="let row of 'detail'">
            <app-ingestion-stage [ingestion]="row.data"></app-ingestion-stage>
          </div>

          <dxi-column
            caption="Status"
            dataField="status"
            dataType="string"
            cellTemplate="statusTemplate"
          >
          </dxi-column>

          <div *dxTemplate="let row of 'statusTemplate'">
            <span *ngIf="row.value === 'Ready'">
              Complete
            </span>

            <span *ngIf="row.value === 'Stopped'">
              Error
            </span>

            <span *ngIf="row.value !== 'Stopped' && row.value !== 'Ready'">
              {{ row.value }}
            </span>
          </div>

          <dxi-column
            caption="Start Time"
            dataField="start_time"
            dataType="date"
            cellTemplate="time"
          >
          </dxi-column>

          <dxi-column
            caption="End Time"
            dataField="end_time"
            cellTemplate="time"
          >
          </dxi-column>

          <dxi-column
            caption="Duration"
            dataField="duration"
            dataType="string"
          >
          </dxi-column>

          <div *dxTemplate="let row of 'time'">
            {{ row.value | date:'short'}}
          </div>

          <dxo-selection mode="none"></dxo-selection>
        </dx-data-grid>
      </mat-expansion-panel>
    </div>
  </div>
</div>
