import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-data-grid-search',
  templateUrl: './data-grid-search.component.html',
  styleUrls: ['./data-grid-search.component.css']
})
export class DataGridSearchComponent implements OnInit, OnDestroy {
  @Input() dataGrid: DxDataGridComponent;

  searchCtrl = new UntypedFormControl('');
  searchSubscription: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.searchSubscription = this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
    ).subscribe((value) => {
      this.dataGrid.instance.searchByText(value);
    });
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

}
