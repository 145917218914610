<div class="login-margin">
  <div class="background-layer">
  </div>

  <div class="login-page">
    <div class="section login">

      <div class="back-btn" (click)="backToLogin()">
        <mat-icon>keyboard_backspace</mat-icon>
        Back to Login
      </div>


      <div class="login-container">
        <div  class="section-item logo-container">
          <img src="../../../assets/images/Bridge_Black.jpg" alt="Bridge Financial Technology Logo" class="firm-logo">
        </div>

        <div>
          <div class="section-item welcome-header">
            Enter hashkey:
          </div>

          <div class="msg-container error"
               *ngIf="message"
               (click)="closeMessage()">
            <div class="icon-container">
              <i class="fas fa-times-circle error-msg-close"></i>
            </div>

            {{message}}
          </div>


          <div class="section-item">
            <form>
              <mat-form-field appearance="outline" style="width: 100%;">
                <textarea matInput name="hashkey" id="textarea1" class="materialize-textarea"
                          placeholder="Hashkey" [(ngModel)]="user.hashkey"></textarea>
              </mat-form-field>
            </form>
          </div>


          <div class="section-item reset-btn-container">
            <app-atlas-submit-button [saving]="loggingIn"
                                     (click)="login()">
              Log In
            </app-atlas-submit-button>
          </div>
        </div>
      </div>


      <div class="footer">
        Powered by
        <div class="footer-img-container">
          <img src="../../../assets/images/Bridge_Black.jpg" alt="Bridge Financial Technology Logo" class="firm-logo">
        </div>
      </div>
    </div>


    <div class="section-two"></div>
  </div>
</div>
