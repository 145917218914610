import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSetActive]'
})
export class SetActiveDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) {
  }

  @HostListener('click') onClick() {
    const tabsContainer = document.querySelector('.tab-container');
    const tabs: any = tabsContainer.querySelectorAll('.tab');
    const selector = document.querySelector('.selector');

    tabs.forEach(tab => {
      tab.classList.remove('active');
    });

    this.el.nativeElement.classList.add('active');

    let activeWidth: any = window.getComputedStyle(this.el.nativeElement).width.split('');
    activeWidth.pop();
    activeWidth.pop();
    activeWidth = parseFloat(activeWidth.join(''));

    let totalWidth = 0;
    let counter = 0;

    for (let i = 0; i < tabs.length; i++) {
      counter = i;
      if (this.el.nativeElement === tabs[i]) {
        break;
      }
      let width: any = window.getComputedStyle(tabs[i]).width.split('');
      width.pop();
      width.pop();
      width = parseFloat(width.join(''));
      totalWidth += width;

      let paddingLeft: any = window.getComputedStyle(tabs[i]).paddingLeft.split('');
      paddingLeft = parseFloat(paddingLeft.slice(0, paddingLeft.length - 2).join(''));

      let paddingRight: any = window.getComputedStyle(tabs[i]).paddingRight.split('');
      paddingRight = parseFloat(paddingRight.slice(0, paddingRight.length - 2).join(''));

      totalWidth += (paddingLeft + paddingRight);
    }

    // conditionally render content based on selected tab
    const contentsContainer = document.querySelector('.content-outer-container');
    const contents = contentsContainer.querySelectorAll('.content');

    contents.forEach(content => {
      content.classList.remove('active');
    });
    contents[counter].classList.add('active');

    this.renderer.setStyle(selector, 'left', `${totalWidth}px`);
    this.renderer.setStyle(selector, 'width', `${activeWidth}px`);
  }
}
