import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from '../auth-sources/state';
import {AuthSource} from '../auth-sources/auth-source.type';
import {Observable} from 'rxjs';
import {AuthSourcesService} from '../auth-sources/auth-sources.service';
import {LoadAuthSources} from '../auth-sources/state/auth-sources.actions';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss']
})
export class IntegrationsListComponent implements OnInit {
  authSources$: Observable<AuthSource[]>;
  authSources: AuthSource[];
  loading = false;

  isHelpWindowVisible = false;
  // private hasPortformerApiKey = true;

  private stratifiCredentials: AuthSource;

  constructor(private router: Router,
              private authSourcesService: AuthSourcesService,
              private store: Store<State>) {
    this.authSources$ = this.authSourcesService.authSources$;
  }

  ngOnInit() {
    this.loading = true;
    this.store.dispatch(LoadAuthSources());
    this.authSources$.subscribe(as => {
      this.authSources = as;
      this.loading = false;
    });

  }

  // portformerRedirect() {
  //   if (this.hasPortformerApiKey) {
  //     this.router.navigate(['/settings/integrations/portformer']);
  //   } else {
  //     window.open(environment.portformerExternalUrl, '_blank');
  //   }
  // }

  // If this leaves a hanging browser window may need to rethink implementation
  stratifiRedirect() {
    this.stratifiCredentials = this.authSources.find(as => as.source === 'Stratifi');

    if (this.stratifiCredentials !== undefined) {
      this.router.navigate(['/settings/integrations/stratifi']);
    } else {
      const state = this.authSourcesService.registerOauthState('Stratifi');
      const clientId = environment.stratifiClientId;
      const authPath = `?response_type=code&state=${state}&client_id=${clientId}&scope=read%20write`;
      window.open(`${environment.stratifiExternalUrl}/${authPath}`, '_blank');
    }
  }

}
