import { Injectable } from '@angular/core';
import {combineLatest, Observable, pipe, throwError} from 'rxjs';
import {TopHoldings} from '../../securities/security';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CurrentBalanceService} from '../../balances/current-balance.service';
import {filter, map, mergeMap, shareReplay, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopHoldingsService {
  private lucaTopHoldingsEndpoint = `${environment.apiV2Url}/analytics/top-n-holdings`;

  constructor(
    private http: HttpClient,
    private currentBalanceService: CurrentBalanceService,
    ) { }

  top5HoldingsAll$ = this.currentBalanceService.lastDate$.pipe(
    tap(lastDate => {
      if (!lastDate) {
        return throwError('error');
      }
    }),
    mergeMap((lastDate) => {
        return this.getTopHoldings(50, lastDate);
      }),
    shareReplay(1)
  );

  top10HoldingsEtf$ = this.currentBalanceService.lastDate$.pipe(
    tap(lastDate => {
      if (!lastDate) {
        throw {err: 'err'};
      }
    }),
    mergeMap((lastDate) => {
      return this.getTopHoldings(10, lastDate, 'mu_etf');
    }),
    shareReplay(1)
  );

  getTopHoldings(n: number = 5, lastDate: string, group?): Observable<TopHoldings[]> {
    const data = {
      n,
      as_of_date: lastDate,
      ...(group && {group})
    };

    return combineLatest([
      this.http.post<TopHoldings[]>(this.lucaTopHoldingsEndpoint, data)
    ]).pipe(
      map(([ lucaTopHoldings]) => {
        const topHoldings = [];

        lucaTopHoldings.forEach((h: any) => topHoldings.push({security_id: h.security_id, sum_market_value: h.market_value}));

        // Sort top holdings
        topHoldings.sort((a, b) => {
          return b.sum_market_value - a.sum_market_value;
        });

        return topHoldings.slice(0, 5);
      })
    );
  }
}
