<div *ngIf="resolvingRoute">
  <div class="loading">
    Loading...

    <div style="height: 10px;"></div>

    <app-material-spinner></app-material-spinner>
  </div>
</div>

<router-outlet></router-outlet>
