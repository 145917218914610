<!--<div class="btn-group" *ngIf="isGrouped === true">-->
<!--  <button id="expandAllDataGridButton"-->
<!--          type="button" class="btn btn-default"-->
<!--          (click)="expandAllGroups()"-->
<!--          matTooltip="Expand All Groups">-->
<!--    <i class="fa fa-plus-square"></i>-->
<!--  </button>-->
<!--</div>-->

<!--<div class="btn-group" *ngIf="isGrouped === true">-->
<!--  <button id="collapseAllDataGridButton"-->
<!--          type="button" class="btn btn-default"-->
<!--          (click)="collapseAllGroups()"-->
<!--          matTooltip="Collapse All Groups">-->
<!--    <i class="fa fa-minus-square"></i>-->
<!--  </button>-->
<!--</div>-->

<!--<div class="btn-group">-->
<!--  <button id="toggleFiltersDataGridButton"-->
<!--          type="button" class="btn btn-block btn-default" matTooltip="Toggle Filters"-->
<!--          [ngClass]="{'active': isFilterRowVisible}"-->
<!--          (click)="toggleFilters()">-->
<!--    <i class="fa fa-filter"></i>-->
<!--  </button>-->
<!--</div>-->

<!--<div class="btn-group">-->
<!--  <button id="toggleColumnsDataGridButton"-->
<!--          type="button" class="btn btn-block btn-default"-->
<!--          matTooltip="Toggle Columns" (click)="showColumnChooser()">-->
<!--    <i class="fa fa-columns"></i>-->
<!--  </button>-->
<!--</div>-->

<!--<div class="btn-group">-->
<!--  <div class="dropdown">-->
<!--    <button id="exportAllButton"-->
<!--            type="button"-->
<!--            class="btn btn-block btn-default dropdown-toggle"-->
<!--            *ngIf="hideExportBySelect"-->
<!--            (click)="exportAllRows()"-->
<!--            matTooltip="Export to Excel">-->
<!--      <i class="fa fa-download"></i>-->
<!--    </button>-->

<!--    <button id="exportOptionsDropdown"-->
<!--            *ngIf="!hideExportBySelect" type="button" class="btn btn-block btn-default dropdown-toggle" data-toggle="dropdown"-->
<!--            matTooltip="Export to Excel">-->
<!--      <i class="fa fa-download"></i>-->
<!--    </button>-->
<!--    <ul class="dropdown-menu pull-right">-->
<!--      <li><a (click)="exportAllRows()">Export All Rows</a></li>-->
<!--      <li><a (click)="exportSelectedRows()">Export Selected Rows</a></li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</div>-->

<div class="flex-row-align-center">
  <mat-menu #excelOptionsMenu>
    <button mat-menu-item (click)="exportAllRows()">Export All Rows</button>
    <button mat-menu-item *ngIf="!hideExportBySelect" (click)="exportSelectedRows()">Export Selected</button>
  </mat-menu>

  <button mat-stroked-button
          color="primary"
          [mat-menu-trigger-for]="excelOptionsMenu">
    <i class="fas fa-file-excel prefix-icon"></i>
    Export
    <i class="fas fa-caret-down suffix-icon"></i>
  </button>

  <button mat-stroked-button
          color="primary"
          *ngIf="!hideColumnChooser"
          (click)="showColumnChooser()">
    <i class="fas fa-columns prefix-icon"></i>
    Columns
  </button>
</div>



