import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './firm.reducer';
import {EffectsModule} from '@ngrx/effects';
import {FirmEffects} from './firm.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('firms', reducer),
    EffectsModule.forFeature(
      [ FirmEffects ]
    ),
  ]
})
export class FirmStoreModule { }
