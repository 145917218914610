<div class="content-wrap">
  <mat-card>
    <mat-card-content>

      <div class="align-center">

        <img src="../../../assets/images/Bridge_Black.jpg" alt="Bridge Financial Technology Logo" style="height: 80px">

        <br>

        Successfully unsubscribed from receiving Insight notifications.

        <br>
        <br>

        <a mat-button color="primary" routerLink="/settings/insights">View your insight settings</a>
      </div>



    </mat-card-content>
  </mat-card>
</div>

