import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppLoadingService {
  // @ts-ignore
  isAppLoadingSubject = new BehaviorSubject<boolean>(false);
  isAppLoading = this.isAppLoadingSubject.asObservable();

  constructor() { }

  setAppLoading(loading: boolean) {
    this.isAppLoadingSubject.next(loading);
  }

  setAppFinishedLoading() {
    this.isAppLoadingSubject.next(false);
    this.isAppLoadingSubject.complete();
  }
}

