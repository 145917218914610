import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { CognitoService } from './auth/cognito.service';
import { environment } from '../../../environments/environment';
import {
  catchError,
  concatMap,
  delay,
  filter,
  finalize,
  first,
  map,
  retryWhen,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {NotificationService} from './ui/notification.service';
import {WealthboxSettingsService} from '../../settings/integrations/wealthbox/wealthbox-settings/wealthbox-settings.service';
import {WealthboxService} from '../../settings/integrations/wealthbox/wealthbox.service';
import {PortformerSettingsService} from '../../settings/integrations/portformer/portformer-settings/portformer-settings.service';
import {AuthSource} from '../../settings/integrations/auth-sources/auth-source.type';
import {LoginService} from './auth/login.service';
import {Router} from '@angular/router';

// Logic that needs to run before an http request is sent can go here.
// Examples: Setting the header token, checking refresh tokens, etc.

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    retryCount = 3;

    constructor(
      private cognitoService: CognitoService,
      private wealthboxSettingsService: WealthboxSettingsService,
      private notifyService: NotificationService,
      private wealthboxService: WealthboxService,
      private portformerSettingsService: PortformerSettingsService,
      private loginService: LoginService,
      private router: Router,
    ) {
      this.refreshingToken = false;
    }
    refreshingToken: boolean;
    tokenCheck: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private static addToken(req, authHeader) {
      return req.clone({
        setHeaders: {
          Authorization: authHeader
        }
      });
    }

  private static addWealthboxToken(req, authHeader) {
    return req.clone({
      setHeaders: {
        ACCESS_TOKEN: authHeader
      }
    });
  }


    // Set header for every http request
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const isV2Url = req.url.startsWith(environment.apiV2Url);
      const isWealthboxUrl = req.url.startsWith(environment.apiWealthbox);
      const isPortformerUrl = req.url.startsWith(environment.apiPortformer);
      const isPlaidUrl = req.url.startsWith(environment.plaidServiceUrl);
      const isInsightsUrl = req.url.startsWith(environment.insightsApiUrl);
      const isAlbUrl = req.url.startsWith(environment.apiV2Url);
      const isLocalUrl = req.url.includes('localhost');
      // const isAlbUrl = req.url.startsWith(environment.apiV2Url) || req.url.startsWith(environment.apiV2UrlLocal);

      if (isWealthboxUrl) {
        return this.handleWealthboxRequest(next, req);
      } else if (isPortformerUrl) {
        return this.handlePortformerRequest(next, req);
      } else if (isV2Url || isPlaidUrl || isInsightsUrl || isAlbUrl || isLocalUrl) {
        return this.handleV2Request(next, req);
      } else {
        return next.handle(req);
      }
    }

    handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler) {
      if (!this.refreshingToken) {
        this.refreshingToken = true;
        this.tokenCheck.next(null);

        return this.cognitoService.refreshToken().pipe(
          switchMap((newToken) => {
            if (newToken) {
              this.tokenCheck.next(newToken);

              req = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`
                }
              });

              return next.handle(req);
            }
          }),
          finalize(() => {
            this.refreshingToken = false;
          })
        );


      } else {
        return this.tokenCheck.pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            req = HttpInterceptorService.addToken(req, token);

            return next.handle(req);
          })
        );
      }
    }

  private handleWealthboxError() {
      this.notifyService.showErrorNotification('The error occurs while connecting to the Wealthbox server. ' +
        'Check of you have valid API key');
  }

  private handleWealthboxRequest(next, req): Observable<any> {
    return this.wealthboxService.getWealthboxIntegrationConfig()
      .pipe(
        map((config: AuthSource) => {
          return HttpInterceptorService.addWealthboxToken(req, config.key);
        }),
        concatMap((reqUpdated: any) => {
          return next.handle(reqUpdated).pipe(
            catchError(err => {
              if (err.status === 401) {
                this.handleWealthboxError();
              }

              this.handleErrorsDefault(err);

              return throwError(err);
            })
          );
        }),
      );
  }

  private handleV2Request(next, req): Observable<any> {
    if (!req.url.includes('version') && !req.url.includes('client-inbox/download')) {
      req = HttpInterceptorService.addToken(req, `Bearer ${this.cognitoService.authToken}`);
    }

    return next.handle(req).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((e, count) => {
            if (count < this.retryCount && e.status === 500) {
              return of(e);
            }

            return throwError(e);
          }),
          delay(1000),
        )
      ),
      catchError((err) => {
        if (err.status === 401 && req.url.includes('client-inbox/download')) {
          return throwError(err);
        }

        if (err.status === 401 || err.status === 0) {
          return this.handleUnauthorizedError(req, next);
        }

        if (err.status === 403 && err.error.message === 'user is no longer active') {
          alert('The user is no longer active. You will be logged out.');
          this.cognitoService.logout();
          (window as any).location = '';

          return throwError(err);
        }

        // TODO: Firm no longer active. Create a view for this
        if (err.status === 403 && err.error.message === 'firm is no longer active') {
          alert('The firm is no longer active. You will be logged out.');
          this.cognitoService.logout();
          (window as any).location = '';

          return throwError(err);
        }

        if (err.status === 403) {
          // TODO: Temporary for now. We need to rethink this strategy of handling permission errors.
          const emptyResponse = {
            has_next: false,
            has_previous: false,
            current_page: null,
            total_pages: null,
            data: []
          };

          return of<HttpEvent<any>>(new HttpResponse({body: emptyResponse}));
        } else {
          this.handleErrorsDefault(err);
        }

        return throwError(err);
      }));
  }

  private handleV1Request(next, req): Observable<any> {
    req = HttpInterceptorService.addToken(req, `Token ${this.cognitoService.authProfile.v1_api_key}`);

    return next.handle(req).pipe(
      catchError((err) => {
        this.handleErrorsDefault(err);
        return throwError(err);
      }));
  }

  private handleErrorsDefault(err) {
    if (err.status === 403) {
      // User doesn't have permission to view a resource, so this needs to be displayed
      this.notifyService.showErrorNotification(err.error.message);
    }

    if (err.status === 503) {
      this.router.navigateByUrl("maintenance-mode");
      return;
    }
  }

  private handlePortformerRequest(next, req): Observable<any> {

    return this.portformerSettingsService.config$.pipe(
      map((config) => {
        return HttpInterceptorService.addToken(req, `Bearer api|${environment.portformerAppKey}|${config.apiKey}`);
      }),
      concatMap((reqUpdated: any) => {
        return next.handle(reqUpdated).pipe(
          catchError((err) => {
            this.handleErrorsDefault(err);
            return throwError(err);
          })
        );
      })
      );
  }
}
