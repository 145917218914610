<div class="content-wrap">
  <mat-card>
    <div *ngIf="integrationPartnerName">
      <mat-card-title>{{integrationPartnerName}} Integration Received</mat-card-title>

      <mat-card-content style="padding: 20px;">
        <span>Verifying integration...</span>
      </mat-card-content>
    </div>

    <div *ngIf="!integrationPartnerName">
      <mat-card-title>Integration Error</mat-card-title>

      <mat-card-content style="padding: 20px;">
        <div class="alert orange white-text">
          We are not able to load this integration. Please ensure you have followed the necessary authentication steps.
          Feel free to contact <a href="mailto:support@bridgeft.com"></a> if you continue to have errors.
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
