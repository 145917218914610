import {SecurityExclusionState, selectAllSecurityExclusions} from './security-exclusions.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: SecurityExclusionState;
}

const getSecurityExclusionFeatureState = createFeatureSelector<SecurityExclusionState>('securityExclusions');

export const getSecurityExclusions = createSelector(
  getSecurityExclusionFeatureState,
  selectAllSecurityExclusions
);

export const getLoading = createSelector(
  getSecurityExclusionFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getSecurityExclusionFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getSecurityExclusionFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getSecurityExclusionFeatureState,
  state => state.updateCallState.loading
);
