import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromSecurities from './state/securities.reducer';
import {Security} from './security';
import {combineLatest, Observable, of} from 'rxjs';
import {
  getFetchByIdLoading,
  getLoaded,
  getLoading,
  getManagedIds,
  getManagedLoaded,
  getManagedLoading,
  getSecurities,
  getSecuritiesByIds,
} from './state';
import {concatMap, filter, map, switchMap, take, tap} from 'rxjs/operators';
import * as securityActions from './state/securities.actions';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import _map from 'lodash-es/map';
import * as pako from 'pako';
import {SecuritiesState} from './state/securities.reducer';

@Injectable({
  providedIn: 'root'
})
export class SecuritiesService {
  private endpoint = `${environment.apiV2Url}/data/custodian/securities`;
  usd: Security;
  cashSecurities: Security[];

  compressedSecuritiesCache: any;

  private searchedSecuritiesCache: any = {};

  constructor(
    private store: Store<fromSecurities.SecuritiesState>,
    private http: HttpClient
    ) { }

  securities$: Observable<Security[]> = combineLatest([
      this.store.pipe(select(getSecurities)),
      this.store.pipe(select(getLoaded)),
      this.store.pipe(select(getLoading)),
    ]).pipe(
      map(([securities, loaded, loading]) => {
        if (!loaded && !loading) {
          this.store.dispatch(securityActions.Load());
        }
        return securities;
      })
    );

  managedIds$: Observable<number[]> = combineLatest([
    this.store.pipe(select(getManagedIds)),
    this.store.pipe(select(getManagedLoaded)),
    this.store.pipe(select(getManagedLoading)),
  ]).pipe(
    map(([ids, loaded, loading]) => {
      if (!loaded && !loading) {
        this.store.dispatch(securityActions.LoadManagedIds());
      }
      return ids;
    })
  );


  getSecurities(): Observable<Security[]> {
    if (this.compressedSecuritiesCache) {
      return of(this.decodeData(this.compressedSecuritiesCache));
    }
    const url = `${this.endpoint}/get-compressed`;

    return this.http.get(url).pipe(
      map(data => {
        this.compressedSecuritiesCache = data;
        return this.decodeData(data);
      })
    );
  }

  // Temporary method, need to be removed when we will replace all promises with observables
  getSecuritiesPromise(): Promise<Security[]> {
    return combineLatest([this.securities$, this.store.pipe(select(getLoaded))]).pipe(
      filter(([securities, loaded]) => loaded),
      map(([securities, loaded]) => securities),
      take(1)
    ).toPromise();
  }

  fetchSecurities(securityIds: number[]): Observable<Security[]> {
    const url = `${this.endpoint}/fetch`;
    const data = {
      ids: securityIds
    };

    return this.http.post<Security[]>(url, data);
  }

  getSecuritiesByIds(securityIds: number[]): Observable<Security[]> {
    return this.fetchSecurities(securityIds);
  }

  getSecuritiesByIdsPromise(securityIds: number[]): Promise<Security[]> {
    return combineLatest([
      this.getSecuritiesByIds(securityIds),
      this.store.pipe(select(getFetchByIdLoading))])
      .pipe(
        filter(([securities, loading]) => !loading),
        map(([securities]) => securities),
        take(1)
      ).toPromise();
  }

  getUSD(): Promise<Security> {
    if (this.usd) {
      return Promise.resolve(this.usd);
    }

    const url = `${this.endpoint}/get-usd`;

    return this.http.get(url).toPromise().then((security: Security) => {
      this.usd = security;
      return this.usd;
    });
  }

  getCash() {
    if (this.cashSecurities && this.cashSecurities.length) {
      return Promise.resolve(this.cashSecurities);
    }

    const url = `${this.endpoint}?master_asset_class=CA`;

    return this.http.get(url).pipe(
      map((resp: any) => resp.data)
    ).toPromise();
  }

  searchSecurities(query: string = null): Observable<Security[]> {
    const url = `${this.endpoint}/search`;

    if (this.searchedSecuritiesCache[query]) {
      return of(this.searchedSecuritiesCache[query]);
    }

    return this.http.get(url, {params: {q: query}}).pipe(
      map((results: any) => {

        const securities: any = {};

        for (const [key] of Object.entries(results)) {
          securities[key] = results[key].slice(0, 50);
        }
        // Cache the return value
        if (securities.id) {
          const transformedSecurities = securities.id.map((id, index) => {
            return {
              id,
              symbol: securities.symbol[index],
              cusip: securities.cusip[index],
              description: securities.description[index]
            };
          });

          this.searchedSecuritiesCache[query] = transformedSecurities;

          return transformedSecurities;
        }
      })
    );

  }

  fetchManagedSecurityIDs(): Observable<number[]> {
    const url = `${this.endpoint}/managed`;

    return this.http.get<number[]>(url);
  }

  getManagedSecurities(): Observable<Security[]> {
    return this.fetchManagedSecurityIDs().pipe(
      switchMap((securityIDs: number[]) => this.fetchSecurities(securityIDs))
    );
  }

  getManagedIdsPromise(): Promise<number[]> {
    return combineLatest([this.managedIds$, this.store.pipe(select(getManagedLoaded))]).pipe(
      filter(([securities, loaded]) => loaded),
      map(([securityIds, loaded]) => securityIds),
      take(1)
    ).toPromise();
  }

  getSecurity(id: number): Observable<Security> {
    const url = `${this.endpoint}/${id}`

    return this.http.get<Security>(url);
  }
  // Decode compressed security data
  private decodeData(response) {
    // unpack the data
    // convert base64 -> binary
    const strData = (window as any).atob(response);

    // convert to characters
    const charData = strData.split('').map((x) => {
      return x.charCodeAt(0);
    });

    // convert to a byte array and decompress it
    const binData = new Uint8Array(charData);
    const data = pako.inflate(binData);

    // get the string-representation of data
    const convertedArr = _map(data, (i) => {
      return String.fromCharCode(i);
    });

    // build the original text and parse it on delimiters, building securities along the way
    const arr = convertedArr.join('');
    const ls = arr.split('\n');

    const securities = [];

    ls.forEach((line) => {
      const parts = line.split('|');
      const sec = {
        id: parseInt(parts[0], 10),
        symbol: parts[1],
        description: parts[2],
        cusip: parts[3],
        master_asset_class: parts[4],
        security_type: parts[5]
      };

      securities.push(sec);
    });

    return securities;
  }
}
