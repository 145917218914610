import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {InvoiceService} from './invoice.service';

@Injectable()
export class InvoicesResolver implements Resolve<any> {
  constructor(private invoice: InvoiceService) {
  }

  resolve(): Promise<any> {
    return this.invoice.getInvoices();
  }
}

@Injectable()
export class InvoiceResolver implements Resolve<any> {
  constructor(
      private invoice: InvoiceService,
    ) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const invoiceId = parseInt(route.params.id, 0);
    return this.invoice.getInvoice(invoiceId);
  }
}
