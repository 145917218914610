import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestedTickersComponent } from './suggested-tickers/suggested-tickers.component';
import { AddSpacePipe } from './add-space.pipe';
import { PortformerSettingsComponent } from './portformer-settings/portformer-settings.component';
import {SharedModule} from '../../../shared/shared.module';



@NgModule({
  declarations: [SuggestedTickersComponent, AddSpacePipe, PortformerSettingsComponent],
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [SuggestedTickersComponent]
})
export class PortformerModule { }
