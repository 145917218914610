import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayQuarterCycle'
})
export class DisplayQuarterCyclePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 3 || input === '3') {
      return 'Mar - Jun - Sep - Dec';
    } else if (input === 2 || input === '2') {
      return 'Feb - May - Aug - Nov;';
    } else if (input === 1 || input === '1') {
      return 'Jan - Apr - Jul - Oct';
    }

    return '';
  }

}
