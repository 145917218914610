import { Injectable } from '@angular/core';
import {UserProfile} from './auth/user-profile';
import {Firm} from '../../firm/firm';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChurnZeroService {

  initialized = false;

  constructor() { }

  init(profile: UserProfile, firm: Firm): void {
    if (environment.churnZeroAppKey === '') {
      return;
    }

    (window as any).ChurnZero.push(['setAppKey', environment.churnZeroAppKey]);
    (window as any).ChurnZero.push(['setContact', firm.id, profile.email]);
    this.initialized = true;
  }

  trackEvent(eventType: string, description: string): void {
    if (this.initialized)  {
      (window as any).ChurnZero.push(['trackEvent', eventType, description]);
    }
  }

  trackModule(moduleName: string) {
    if (this.initialized) {
      (window as any).ChurnZero.push(['setModule', moduleName]);
    }
  }
}
