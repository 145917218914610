import { Injectable } from '@angular/core';
import {CognitoService} from './cognito.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserProfileService} from './user-profile.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FirmService} from '../../../firm/firm.service';
import {NotificationService} from '../ui/notification.service';
import {ClientPortalAccessService} from '../../../client-portal/client-portal-access.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(public router: Router,
              private userProfileService: UserProfileService,
              private firmService: FirmService,
              private notificationService: NotificationService,
              private cognitoService: CognitoService,
              private clientPortalAccessService: ClientPortalAccessService
              ) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | Observable<boolean> {
    const firmUserRequired = route.data.firmUserRequired || null;
    const requiredPermissions = route.data.requiredPermissions || [];

    if (this.cognitoService.authProfile.is_client_user) {
      const url = state.url.toString();

      return this.firmService.clientHasPermission(url).pipe(
        map((authorized) => {
          if (!authorized) {
            this.clientPortalAccessService.reroute();
          }
          return authorized;
        })
      );
    } else {

      return this.userProfileService.hasPermission(requiredPermissions).pipe(
        map((hasPermission) => {
          if (firmUserRequired) {
            return this.userProfileService.isFirmUser();
          }

          // If user still doesn't have permission route to unauthorized
          if (!hasPermission) {
            this.notificationService.showErrorNotification('You are not permitted to view this page.');
            this.router.navigate(['unauthorized']);
          }
          return hasPermission;
        })
      );
    }
  }
}
