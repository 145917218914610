import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UserProfile} from './auth/user-profile';
import {UserProfileService} from './auth/user-profile.service';
import {UtilsService} from './utils.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {toLower} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  firmEndpoint: string;
  clientEndpoint: string;
  profiles: UserProfile[];
  profiles$: BehaviorSubject<UserProfile[]>;
  clientProfiles$: Observable<UserProfile[]>;
  profilesByID: any;
  profilesByUserID: any;

  constructor(
    private http: HttpClient,
    private userProfileService: UserProfileService,
    private utils: UtilsService
  ) {
    this.firmEndpoint = `${environment.apiV2Url}/user-management/firm-profiles`;
    this.clientEndpoint = `${environment.apiV2Url}/user-management/client-profiles`;

    /**
     * @deprecated use profiles$ observable instead
     */
    this.profiles = [];
    this.profiles$ = new BehaviorSubject([]);
    this.profilesByID = {};
    this.profilesByUserID = {};
    this.clientProfiles$ = this.profiles$.pipe(
      map(p => p.filter((profile) => profile.is_client_user))
    );
  }

  getProfiles(): Promise<any> {
    if (this.profiles.length > 0) {
      return Promise.resolve(this.profiles);
    }

    // Firm Profiles and Client Profiles
    const promises = [
      this.getFirmProfiles(),
      this.getClientProfiles()
    ];

    return Promise.all(promises).then((results) => {
      this.profiles = results[0].data;
      this.profiles.push(...results[1].data);

      this.profiles$.next(this.profiles);
      this.setupData();
      return this.profiles;
    });
  }

  get clientProfiles() {
    return this.profiles.filter((profile) => profile.is_client_user);
  }

  get firmUserProfiles() {
    return this.profiles.filter((profile) => profile.is_firm_user);
  }

  getClientProfiles(): Promise<any> {
    return this.http.get(this.clientEndpoint).toPromise<any>();
  }

  getFirmProfiles(): Promise<any> {
    return this.http.get(this.firmEndpoint).toPromise<any>();
  }

  setupData() {
    this.profiles.forEach((profile) => {
      this.profilesByUserID[profile.user_id] = profile;
      this.profilesByID[profile.id] = profile;
    });
  }

  getLoggedInUserProfile(): UserProfile {
    return this.userProfileService.userProfile;
  }

  deleteProfiles(type: string, ids: number[]): Observable<UserProfile[]> {
    let url = '';

    if (type === 'firm') {
      url = this.firmEndpoint;
    } else {
      url = this.clientEndpoint;
    }

    const data = {
      ids
    };

    const options = {
      body: data
    };

    // @ts-ignore
    return this.http.delete(url, options).pipe(
      map((resp) => {
        this.profiles = this.profiles.filter(userProfile => !ids.includes(userProfile.id));
        this.profiles$.next(this.profiles);
        this.setupData();

        return this.profiles;
      })
    );
  }

  deleteProfile(profile): Promise<any> {
    let url = '';

    if (profile.is_firm_user) {
      url = `${this.firmEndpoint}/${profile.id}`;
    }  else {
      url = `${this.clientEndpoint}/${profile.id}`;
    }

    return this.http.delete(url).toPromise<any>().then(() => {
      this.profiles = this.profiles.filter((userProfile) => userProfile.id !== profile.id);
      this.profiles$.next(this.profiles);
      this.utils.removeItem(this.profilesByID, profile);
      this.utils.removeItem(this.profilesByUserID, profile, 'user_id');
    });
  }

  saveProfile(profile: any): Promise<any> {
    let url = '';
    if (profile.id) {
      // create
      url = `${this.firmEndpoint}/${profile.id}`;

      return this.http.put(url, profile).toPromise<any>().then((userProfile) => {
        this.utils.updateDataByPath(this.profiles, userProfile);
        this.utils.updateDataByPath(this.profilesByID, userProfile);
        this.utils.updateDataByPath(this.profilesByUserID, userProfile, 'user_id');
        return userProfile;
      });
    } else {
      // put (edit)
      url = this.firmEndpoint;

      return this.http.post(url, profile).toPromise<any>().then((userProfile) => {
        this.profiles.push(...userProfile);
        this.profiles$.next(this.profiles);
        this.profilesByID[userProfile.id] = userProfile;
        this.profilesByUserID[userProfile.user_id] = userProfile;

        return userProfile;
      });
    }
  }

  createClientUser(firstName: string, lastName: string, email: string, householdId: number): Promise<UserProfile> {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: toLower(email),
      end_client_household_id: householdId,
      username: email,
      firm_id: this.userProfileService.userProfile.firm_id,
      account_access_level: 'limited',
    };

    return this.http.post(this.clientEndpoint, data).toPromise<any>().then((profile: UserProfile) => {
      this.profiles.push(profile);
      this.profiles$.next(this.profiles);
      this.setupData();

      return profile;
    });
  }

  createFirmUser(firstName: string, lastName: string, email: string, isOwner: boolean,
                 accessLevel: string, householdIds: number[], userRole: any): Promise<UserProfile> {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: toLower(email),
      username: toLower(email),
      permissions: (userRole) ? userRole.permissions : ['*'],
      is_owner: isOwner,
      accessible_household_ids: householdIds,
      account_access_level: accessLevel,
      role_id: (userRole) ? userRole.id : null,
      firm_id: this.userProfileService.userProfile.firm_id
    };

    return this.http.post(this.firmEndpoint, data).toPromise<any>().then((profile: UserProfile) => {
      this.profiles.push(profile);
      this.profiles$.next(this.profiles);
      this.setupData();

      return profile;
    });
  }

  resendInvite(profile: UserProfile) {
    let url = '';

    if (profile.is_client_user) {
      url = `${environment.apiV2Url}/user-management/client-profiles/${profile.id}/resend-invite`;
    } else {
      url = `${environment.apiV2Url}/user-management/firm-profiles/${profile.id}/resend-invite`;
    }

    return this.http.post(url, {}).toPromise<any>();
  }
}
