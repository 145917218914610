import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  isSearchVisible$ = new BehaviorSubject(false);

  constructor() {}

  displaySearch() {
    this.isSearchVisible$.next(true);
  }

  closeSearch() {
    this.isSearchVisible$.next(false);
  }
}
