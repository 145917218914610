<div class="content-wrap">
  <mat-card>
    <mat-card-title>Stratifi Integration</mat-card-title>

    <mat-card-content style="padding: 20px;">
      <dx-data-grid #stratifiAuthsGrid
                    [dataSource]="stratifiAuth$ | async"
                    [hidden]="loading">

        <dxi-column caption="Owning Atlas User"
                    [calculateCellValue]="getUserName"
                    [calculateDisplayValue]="getUserName"
                    [calculateSortValue]="getUserName"
        >
        </dxi-column>

        <dxi-column caption="Created On"
                    dataType="string"
                    dataField="created_at_utc">
        </dxi-column>

        <dxi-column type="buttons"
                    cellTemplate="actionsTemplate"
                    [fixed]="true"
                    fixedPosition="right"></dxi-column>

        <div *dxTemplate="let row of 'actionsTemplate'">
          <button mat-icon-button
                  color="primary"
                  matTooltip="Delete"
                  (click)="removeAuthSource(row.data)">
            <i class="fas fa-trash datagrid-icon"></i>
          </button>
        </div>

        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
      </dx-data-grid>
    </mat-card-content>
  </mat-card>
</div>
