import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ReportPackage} from '../report-package';
import {CallState} from '../../../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CreateReportPackage,
  CreateReportPackageFail,
  CreateReportPackageSuccess,
  LoadReportPackages,
  LoadReportPackagesFail,
  LoadReportPackagesSuccess,
  RemoveReportPackage,
  RemoveReportPackageFail, RemoveReportPackages, RemoveReportPackagesFail, RemoveReportPackagesSuccess,
  RemoveReportPackageSuccess,
  UpdateReportPackage,
  UpdateReportPackageFail,
  UpdateReportPackages,
  UpdateReportPackagesFail,
  UpdateReportPackagesSuccess,
  UpdateReportPackageSuccess,
} from './report-packages.actions';

export interface ReportPackageState extends EntityState<ReportPackage> {
  callState: CallState;
  createCallState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}

export const adapter: EntityAdapter<ReportPackage> = createEntityAdapter<ReportPackage>();

const initialState: ReportPackageState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
  removeMultipleCallState: {...new InitialCallState()},
});

export function reducer(state: ReportPackageState | undefined, action: Action) {
  const reportPackageReducer = createReducer(
    initialState,
    on(LoadReportPackages, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadReportPackagesSuccess, (s, {reportPackages}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(reportPackages, s), callState};
    }),

    on(LoadReportPackagesFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, ...callState};
    }),

    on(CreateReportPackage, (s) => {
      const createCallState = {...s.createCallState, loading: true};
      return {...s, createCallState};
    }),

    on(CreateReportPackageSuccess, (s, {reportPackage}) => {
      const createCallState = {loading: false};
      return {...adapter.addOne(reportPackage, s), ...createCallState};
    }),

    on(CreateReportPackageFail, (s) => {
      const createCallState = {loading: false};
      return {...s, ...createCallState};
    }),

    on(UpdateReportPackage, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateReportPackageSuccess, (s, {reportPackage}) => {
      const updateCallState = {loading: false};
      return {...adapter.upsertOne(reportPackage, s), ...updateCallState};
    }),

    on(UpdateReportPackageFail, (s) => {
      const updateCallState = {loading: false};
      return {...s, ...updateCallState};
    }),

    on(UpdateReportPackages, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateReportPackagesSuccess, (s, {reportPackages}) => {
      const updateManyCallState = {loading: false};
      const ids = reportPackages.map(f => f.id);
      return {...adapter.upsertMany(reportPackages, s), ...updateManyCallState};
    }),

    on(UpdateReportPackagesFail, (s) => {
      const updateManyCallState = {loading: false};
      return {...s, ...updateManyCallState};
    }),

    on(RemoveReportPackage, (s) => {
      const removeCallState = {...s.removeCallState, loading: true};
      return {...s, removeCallState};
    }),

    on(RemoveReportPackageSuccess, (s, {reportPackage}) => {
      const removeCallState = {loading: false};
      return {...adapter.removeOne(reportPackage.id, s), ...removeCallState};
    }),

    on(RemoveReportPackageFail, (s) => {
      const removeCallState = {loading: false};
      return {...s, ...removeCallState};
    }),

    on(RemoveReportPackages, (s) => {
      const removeMultipleCallState = {...s.removeMultipleCallState, loading: true};
      return {...s, removeMultipleCallState};
    }),

    on(RemoveReportPackagesSuccess, (s, {reportPackages}) => {
      const removeMultipleCallState = {loading: false};
      const ids = reportPackages.map(f => f.id);
      return {...adapter.removeMany(ids, s), ...removeMultipleCallState};
    }),

    on(RemoveReportPackageSuccess, (s) => {
      const removeMultipleCallState = {loading: false};
      return {...s, ...removeMultipleCallState};
    }),
  );

  return reportPackageReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllReportPackages = selectAll;
