import { Pipe, PipeTransform } from '@angular/core';
import _get from 'lodash-es/get';
import {AppConstants} from '../../app-constants';

@Pipe({
  name: 'displayRelationshipType'
})
export class DisplayRelationshipTypePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    return _get(AppConstants.HELDAWAY_RELATIONSHIP_TYPES, input.toString(), 'Unspecified');
  }

}
