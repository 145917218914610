import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {select, Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {State} from './state';
import {Observable} from 'rxjs';
import {AuthSource} from './auth-source.type';
import {map} from 'rxjs/operators';
import {getAuthSources} from './state';
import {v4 as uuid} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AuthSourcesService {
  endpoint = `${environment.apiV2Url}/integrations/auth-sources`;

  authSources$ = this.store.pipe(select(getAuthSources));

  constructor(private http: HttpClient,
              private store: Store<State>) { }

  getAuthSources(): Observable<AuthSource[]> {
    const url = `${this.endpoint}`;
    return this.http.get(url).pipe(
      map((resp: {data: AuthSource[]}) => {
        return resp.data;
      })
    );
  }

  createAuthSource(authSource: AuthSource): Observable<AuthSource> {
    return this.http.post<AuthSource>(this.endpoint, authSource);
  }

  updateAuthSource(authSource: AuthSource): Observable<AuthSource> {
    const url = `${this.endpoint}/${authSource.id}`;

    return this.http.put<AuthSource>(url, authSource);
  }

  removeAuthSource(authSource: AuthSource): Observable<any> {
    const url = `${this.endpoint}/${authSource.id}`;

    return this.http.delete(url);
  }

  registerOauthState(integrationPartnerName: string): string {
    const newState = uuid();
    localStorage.setItem(`oauth-${newState}`, integrationPartnerName);
    return newState;
  }

  verifyOauthState(redirectState: string): string {
    return localStorage.getItem(`oauth-${redirectState}`);
  }

  // potential for small memory leak over time via failure condition in oauth-landing.component.ts
  deregisterOauthState(redirectState: string) {
    localStorage.removeItem(`oauth-${redirectState}`);
  }
}
