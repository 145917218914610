import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Minimum} from './billing';
import {HttpClient} from '@angular/common/http';
import {FirmService} from '../firm/firm.service';

@Injectable({
  providedIn: 'root'
})
export class MinimumService {

  private endpoint = `${environment.apiV2Url}/billing/minimums`;
  public minimums: Minimum[] = [];
  constructor(private http: HttpClient,
              private firmService: FirmService) { }

  save(minimum: Minimum): Promise<Minimum[]> {
    const isCreate = !minimum.id;

    Object.assign(minimum, {object: 'billing.minimum', firm_id: this.firmService.firm.id});

    if (!minimum.name) {
      if (minimum.value_type === 'F') {
        const n = minimum.value;
        minimum.name = `$${n.toLocaleString()} / yr`;
      } else if (minimum.value_type === 'P') {
        minimum.name = `${minimum.value.toFixed(1)}% / yr`;
      }
    }


    const url = isCreate ? this.endpoint : `${this.endpoint}/${minimum.id}`;
    const method = isCreate ? 'post' : 'put';

    return this.http[method](url, minimum).toPromise()
      .then((resp: Minimum) => {
        this.minimums = isCreate ? [...this.minimums, resp] : this.minimums.map(m => m.id === minimum.id ? resp : m);

        return this.minimums;
      });
  }

  remove(minimum: Minimum): Promise<Minimum[]> {
    if (!minimum.id) {
      return;
    }

    const url = `${this.endpoint}/${minimum.id}`;
    return this.http.delete(url).toPromise()
      .then(() => {

        this.minimums = this.minimums.filter(m => m !== minimum);

        return this.minimums;
    });
  }

  removeMinimums(minimums: Minimum[]): Promise<Minimum[]> {
    const ids = minimums.map(m => m.id);
    const data = {
      ids
    };
    const url = this.endpoint;

    const options = {
      body: data
    };

    // Body on delete request to work with ALB API
    // @ts-ignore

    return this.http.delete(url, options).toPromise()
      .then(() => {
        this.minimums = this.minimums.filter(m => !minimums.includes(m));

        return this.minimums;
      });
  }

  fetchAll() {
    return this.http.get(this.endpoint).toPromise()
      .then((resp: {data: Minimum[]}) => {
        this.minimums = resp.data;

        return this.minimums;
    });
  }
}
