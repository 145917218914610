export class PermissionsObject {
  user_management = new UserManagementPermission();
  billing = new BillingPermission();
  reporting = new ReportingPermission();
  account_management = new AccountManagementPermission();
  data = new DataPermission();
  firm = new FirmPermissions();
  file_sharing = new FileSharingPermission();
  heldaway = new HeldawayPermission();
  '*': boolean;
}

export class BasePermission {
  read?: boolean = false;
  create?: boolean = false;
  delete?: boolean = false;
  update?: boolean = false;
  assign?: boolean = false;
  download?: boolean = false;
}

export class UserManagementPermission {
  client_user = new BasePermission();
  firm_user = new BasePermission();
  role = new BasePermission();
}

export class BillingPermission {
  fee_structure = new BasePermission();
  group = new BasePermission();
  asset_adjustment = new BasePermission();
  report = new BasePermission();
  split = new BasePermission();
  minimum = new BasePermission();
  invoice = new BasePermission();
}

export class ReportingPermission {
  benchmark = new BasePermission();
  printable = new BasePermission();
  asset_classification = new BasePermission();
  settings = new BasePermission();
  web = new BasePermission();
}

export class AccountManagementPermission {
  account = new BasePermission();
  heldaway = new BasePermission();
  household = new BasePermission();
}

export class DataPermission {
 custodian = new CustodianPermission();
}

export class CustodianPermission {
  position = new BasePermission();
  transaction = new BasePermission();
  gainloss = new BasePermission();
  security = new BasePermission();
}

export class FirmPermissions {
  settings = new BasePermission();
}

export class FileSharingPermission {
  link = new BasePermission();
  file = new BasePermission();
}

export class HeldawayPermission {
  balance = new BasePermission();
  position = new BasePermission();
}
