import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './balances.reducer';
import {EffectsModule} from '@ngrx/effects';
import {BalancesEffects} from './balances.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('balances', reducer),
    EffectsModule.forFeature(
      [ BalancesEffects ]
    ),
  ]
})
export class BalancesStoreModule { }
