import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingDisplayFrequency'
})
export class BillingDisplayFrequencyPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'M') {
      return 'Monthly';
    } else if (input === 'Q') {
      return 'Quarterly';
    }

    return '';
  }

}
