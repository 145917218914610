<div class="content-wrap">
  <mat-card>
    <mat-card-title>Integrations</mat-card-title>

    <mat-card-content style="padding: 20px;">
      <div
        fxLayout="row"
        fxLayout.lt-sm="column"
        gdGap="50px"
        fxLayoutAlign="space-evenly stretch"
        gdColumns="calc(48% - 25px) calc(48% - 25px)"
        gdColumns.lt-md="calc(100%)">

        <div class="card card-linkable" routerLink="/settings/integrations/wealthbox">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/wealthbox.png">
          </div>

          <div class="content">
            <h4>Wealthbox CRM</h4>

            <p class="info">
              If you have an active Wealthbox subscription, you can view Wealthbox contacts and associate them to households in Atlas. As well as view/create notes that automatically sync with Wealthbox.
            </p>
          </div>
        </div>

        <div class="card">
          <div class="title" style="height: 30px;">
            <img class="company-logo" src="../../../../assets/images/portformer-logo-with-icon.png">
          </div>

          <div class="content">
            <h4>PortformerTM</h4>

            <p class="info">
              Get special analytics on your fund holdings and see if there are more suitable alternatives for your clients.
            </p>
          </div>
        </div>

        <div class="card card-linkable" (click)="stratifiRedirect()">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/stratifi-logo.png">
          </div>

          <div class="content">
            <h4>Stratifi</h4>

            <p class="info">
              Get risk analysis on your accounts.
            </p>
          </div>
        </div>

        <div class="card">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/plaid-logo.png">
          </div>

          <div class="content">
            <h4>Plaid</h4>

            <p class="info">
              Pull in heldaway accounts, positions, and transactions into Atlas so you can see your clients’ full financial picture.
            </p>
          </div>
        </div>



        <div class="card">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/blaze-portfolio-logo.png">
          </div>

          <div class="content">
            <h4>BlazePortfolio</h4>

            <p class="info">
              Import reconciled positions from Atlas into Blaze's Atom Align trading software. Contact your client success agent for help getting set up.
            </p>
          </div>
        </div>


        <div class="card">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/thomson-reuters-eikon-logo.png">
          </div>

          <div class="content">
            <h4>Thompson Reuters Eikon</h4>

            <p class="info">
              Import models and accounts from Atlas into Eikon to run more advanced analytics. Contact your client success agent for help getting set up.
            </p>
          </div>
        </div>


        <div class="card">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/salesforce-logo.png">
          </div>

          <div class="content">
            <h4>Salesforce</h4>

            <p class="info">
              The BridgeFT app is available on Salesforce and can be downloaded and configured to import account and balance information!
            </p>
          </div>
        </div>


        <div class="card">
          <div class="title">
            <img class="company-logo" src="../../../../assets/images/advisor-peak-logo.png">
          </div>

          <div class="content">
            <h4>AdvisorPeak</h4>

            <p class="info">
              Reconciled positions and account information can be easily imported into AdvisorPeak. Contact your client success agent for additional information and partner pricing.
            </p>
          </div>
        </div>

        <div class="card">
          <div class="title" style="height: 75px;">
            <img class="company-logo" src="../../../../assets/images/money-guide.svg">
          </div>

          <div class="content">
            <h4>MoneyGuide</h4>

            <p class="info">
              Import account and household balance and position information to streamline your financial planning!
              Instructions on how to set up this integration can be found within MoneyGuide.
            </p>
          </div>
        </div>

        <div class="card">
          <div class="title" style="height: 75px;">
            <img class="company-logo" style="max-height: 50px;" src="../../../../assets/images/smartria-logo-new.png">
          </div>

          <div class="content">
            <h4>SmartRIA</h4>

            <p class="info">
              Streamline and automate your compliance oversight by enabling this integration. Atlas will send account, position,
              and transaction information to SmartRIA to power their alerts. This integration can be set up in SmartRIA.
            </p>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>
