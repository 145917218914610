import {Component, Inject, OnInit} from '@angular/core';
import {Account} from '../../accounts/account';
import {AccountService} from '../../accounts/account.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-billing-payment-source-dialog',
  templateUrl: './billing-payment-source-dialog.component.html',
  styleUrls: ['./billing-payment-source-dialog.component.scss']
})
export class BillingPaymentSourceDialogComponent implements OnInit {

  selectedAccounts: Account[];
  saving = false;
  paymentSource: string;

  constructor(private accountService: AccountService,
              private router: Router,
              private dialogRef: MatDialogRef<BillingPaymentSourceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.selectedAccounts = data.accounts;
  }

  ngOnInit(): void {

  }

  save = () => {
    this.saving = true;

    this.selectedAccounts.forEach(account => account.payment_source = this.paymentSource);

    this.accountService.dispatchAccounts(this.selectedAccounts);

    this.saving = false;

    this.dialogRef.close(true);
  }

}
