import {createAction, props} from '@ngrx/store';
import {SecurityExclusion} from '../security-exclusion.type';

export const LoadSecurityExclusions = createAction('[SecurityExclusions] Load security exclusions');
export const LoadSecurityExclusionsSuccess = createAction('[SecurityExclusions] Load securityExclusions success', props<{securityExclusions: SecurityExclusion[]}>());
export const LoadSecurityExclusionsFail = createAction('[SecurityExclusions] Load securityExclusions fail', props<{error: any}>());

export const CreateSecurityExclusion = createAction('[SecurityExclusions] Create clients', props<{securityExclusion: SecurityExclusion}>());
export const CreateSecurityExclusionSuccess = createAction('[SecurityExclusions] Create clients success', props<{securityExclusion: SecurityExclusion}>());
export const CreateSecurityExclusionFail = createAction('[SecurityExclusions] Create clients fail', props<{error: any}>());

export const UpdateSecurityExclusion = createAction('[SecurityExclusions] Update securityExclusion', props<{securityExclusion: SecurityExclusion}>());
export const UpdateSecurityExclusionSuccess = createAction('[SecurityExclusions] Update securityExclusion success', props<{securityExclusion: SecurityExclusion}>());
export const UpdateSecurityExclusionFail = createAction('[SecurityExclusions] Update securityExclusion fail', props<{error: any}>());

export const UpdateSecurityExclusions = createAction('[SecurityExclusions] Update securityExclusions', props<{securityExclusions: SecurityExclusion[]}>());
export const UpdateSecurityExclusionsSuccess = createAction('[SecurityExclusions] Update securityExclusions success', props<{securityExclusions: SecurityExclusion[]}>());
export const UpdateSecurityExclusionsFail = createAction('[SecurityExclusions] Update securityExclusions fail', props<{error: any}>());

export const RemoveSecurityExclusion = createAction('[SecurityExclusions] Remove securityExclusion', props<{securityExclusion: SecurityExclusion}>());
export const RemoveSecurityExclusionSuccess = createAction('[SecurityExclusions] Remove securityExclusion success', props<{securityExclusion: SecurityExclusion}>());
export const RemoveSecurityExclusionFail = createAction('[SecurityExclusions] Remove securityExclusion fail', props<{error: any}>());

export const RemoveSecurityExclusions = createAction('[SecurityExclusions] Remove securityExclusions', props<{securityExclusions: SecurityExclusion[]}>());
export const RemoveSecurityExclusionsSuccess = createAction('[SecurityExclusions] Remove securityExclusions success', props<{securityExclusions: SecurityExclusion[]}>());
export const RemoveSecurityExclusionsFail = createAction('[SecurityExclusions] Remove securityExclusions fail', props<{error: any}>());
