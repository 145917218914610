import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface IngestionStatus {
  created_at_utc: string;
  current_stage: string;
  custodian: string;
  delivery: string;
  duration: string;
  duration_seconds: number;
  end_time: string;
  id: number,
  object: string;
  start_time: string,
  status: string;
  updated_at_utc: string;
}

export interface IngestionStageHistory {
  id: number;
  ingestion_id: number;
  duration: string;
  duration_seconds: number;
  start_time: string;
  end_time: string;
  stage: string;
  status: string;
  object: string;
  updated_at_utc: string;
  created_at_utc: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private http: HttpClient) {
  }

  getIngestionStatuses(asOfDate: string): Observable<IngestionStatus[]> {
    const endpoint = `${environment.apiV2Url}/status/ingestion/filter`;

    const data = {
      start_time: {
        any_or_all: 'all',
        conditions: [{
          op: 'gte',
          value: asOfDate
        }]
      }
    }

    return this.http.post(endpoint, data).pipe(
      map((resp: {data: IngestionStatus[]}) => resp.data)
    );
  }

  getLatestIngestionStatus(): Observable<IngestionStatus[]> {
    const endpoint = `${environment.apiV2Url}/status/ingestion/latest`;

    return this.http.get<IngestionStatus[]>(endpoint);
  }

  getIngestionStageHistory(ingestionID: number): Observable<IngestionStageHistory[]> {
    const endpoint = `${environment.apiV2Url}/status/ingestion/stages/${ingestionID}`;

    return this.http.get<IngestionStageHistory[]>(endpoint);
  }
}
