<mat-form-field appearance="outline" [ngStyle]="style" *ngIf="control">
  <input matInput
         [matDatepicker]="picker"
         [max]="max"
         [min]="min"
         [placeholder]="placeholder ? placeholder : 'Select a date'"
         [value]="selectedDate"
         (dateChange)="emitDate($event)"
         [formControl]="control">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>

<mat-form-field appearance="outline" [ngStyle]="style" *ngIf="!control">
  <input matInput
         [matDatepicker]="picker"
         [max]="max"
         [min]="min"
         [placeholder]="placeholder ? placeholder : 'Select a date'"
         [value]="selectedDate"
         (dateChange)="emitDate($event)">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>

