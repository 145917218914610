import {Action, createReducer, on} from '@ngrx/store';
import {changeSidePanelState, closeSidePanel, toggleSidePanel} from './layout.actions';
import {LayoutState} from './index';

const initialState: LayoutState = {
  sidePanel: {
    opened: true,
    section: 'ATLAS_ACTIVITY',
    tab: '',
    mode: 'tabs',
  }
};

const layoutReducer = createReducer(
  initialState,
  on(changeSidePanelState, (state, {sidePanel}): LayoutState => {
    const sidePanelNew = (state.sidePanel.section === sidePanel.section)
      ? {...state.sidePanel, ...sidePanel}
      : {...state.sidePanel, tab: '', data: null, mode: 'tabs'};

    return {...state, sidePanel: sidePanelNew};
  }),
  on(toggleSidePanel, (state: LayoutState, {section}) => {
    let sidePanelNew = (state.sidePanel.section === section)
      ? {...state.sidePanel, section}
      : {...state.sidePanel, tab: '', data: null, mode: 'tabs', section};

    // commented out since toggle feature is no longer needed

    // if (state.sidePanel.opened && state.sidePanel.section === section) {
    //   sidePanelNew = {...sidePanelNew, opened: false};
    // } else if (!state.sidePanel.opened) {
    //   sidePanelNew = {...sidePanelNew, opened: true};
    // }

    return {...state, sidePanel: sidePanelNew};
  }),
  on(closeSidePanel, (state: LayoutState) => {
    const sidePanel = {...state.sidePanel, opened: false, data: null};
    return {...state, sidePanel};
  }),
);

export function reducer(state: LayoutState | undefined, action: Action) {
  return layoutReducer(state, action);
}
