import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ReportPackage} from './report-package';
import {combineLatest, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, tap, takeWhile} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {getLoaded, getLoading, getReportPackages, State} from './state';
import {LoadReportPackages} from './state/report-packages.actions';

@Injectable({
  providedIn: 'root'
})
export class ReportPackagesService {

  endpoint = `${environment.apiV2Url}/reporting/report-packages`;

  reportPackages$ = this.store.pipe(select(getReportPackages));

  constructor(private http: HttpClient,
              private store: Store<State>) {

  }

  getReportingPackages(): Observable<ReportPackage[]> {
    return this.http.get(this.endpoint).pipe(
      map((resp: {data: ReportPackage[]}) => {
        return resp.data;
      })
    );
  }

  getReportingPackage(id: number): Observable<ReportPackage> {
    const url = `${this.endpoint}/${id}`;

    return this.http.get<ReportPackage>(url);
  }

  createReportingPackage(reportPackage: ReportPackage): Observable<ReportPackage> {

    return this.http.post<ReportPackage>(this.endpoint, reportPackage);
  }

  updateReportingPackage(reportPackage: ReportPackage): Observable<ReportPackage> {
    const url = `${this.endpoint}/${reportPackage.id}`;

    return this.http.put<ReportPackage>(url, reportPackage);
  }

  updateReportingPackages(reportPackages: ReportPackage[]): Observable<ReportPackage[]> {
    return this.http.put<ReportPackage[]>(this.endpoint, reportPackages);
  }

  removeReportPackage(reportPackage: ReportPackage): Observable<any> {
    const url = `${this.endpoint}/${reportPackage.id}`;

    return this.http.delete(url);
  }

  removeReportPackages(reportPackages: ReportPackage[]): Observable<any> {
    const url = `${this.endpoint}`;

    const ids = reportPackages.map(c => c.id);

    const data = {
      ids
    };

    const options = { body: data };

    // @ts-ignore
    return this.http.delete(url, options);
  }
}
