import { Pipe, PipeTransform } from '@angular/core';
import {Account} from '../../accounts/account';

@Pipe({
  name: 'maskManualAccount'
})
export class MaskManualAccountPipe implements PipeTransform {

  transform(account: Account, ...args: unknown[]): string {
    if (account.custodian !== 'HDG') {
      return account.number;
    }
    const accountNumber = account.number;

    const parts = accountNumber.split('-');

    return parts.slice(1, parts.length).join('-');
  }
}
