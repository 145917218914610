import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cacDisplayClassification'
})
export class CacDisplayClassificationPipe implements PipeTransform {

  transform(security: any, args?: any): any {
    if (security.isUpdating) {
      return '(updating)';
    }

    if (security.cac && security.cac !== '') {
      return security.cac.tag_name;
    }

    if (security.master_asset_class === 'EQ') {
      return 'Equity';
    } else if (security.master_asset_class === 'DT') {
      return 'Fixed income';
    } else if (security.master_asset_class === 'CA') {
      return 'Cash';
    } else if (security.master_asset_class === 'MX') {
      return 'Mixed';
    } else if (security.master_asset_class === 'AL') {
      return 'Alternatives';
    } else if (security.master_asset_class === 'UC') {
      return 'Unclassified';
    } else if (security.master_asset_class === 'EF') {
      return 'ETF';
    } else if (security.master_asset_class === 'MF') {
      return 'Mutual Fund';
    }

    return 'Unknown';
  }

}
