import { Component, OnInit } from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import {
  CreateAuthSource,
  CreateAuthSourceFail,
  CreateAuthSourceSuccess
} from '../auth-sources/state/auth-sources.actions';
import {AuthSourcesService} from '../auth-sources/auth-sources.service';
import {Store} from '@ngrx/store';
import {State} from '../auth-sources/state';
import {ActivatedRoute, Router} from '@angular/router';
import {UserProfileService} from '../../../shared/services/auth/user-profile.service';
import {NotificationService} from '../../../shared/services/ui/notification.service';
import {AuthSource} from '../auth-sources/auth-source.type';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-oauth-landing',
  templateUrl: './oauth-landing.component.html',
  styleUrls: ['./oauth-landing.component.css']
})
export class OauthLandingComponent implements OnInit {
  loading = false;
  authReturn = false;
  redirectParams = {
    code: '',
    state: ''
  };
  newAuth: AuthSource;
  integrationPartnerName = '';

  successSubscription: Subscription;
  failureSubscription: Subscription;

  constructor(private authSourcesService: AuthSourcesService,
              private store: Store<State>,
              private route: ActivatedRoute,
              private router: Router,
              private userProfileService: UserProfileService,
              private actions$: Actions,
              private notificationsService: NotificationService) {
  }

  ngOnInit(): void {
    this.route.url.subscribe( route => {
      this.authReturn = route.some( r => r.path === 'oauth' );
    });
    if (this.authReturn) {
      this.successSubscription = this.actions$.pipe(
        ofType(CreateAuthSourceSuccess),
      ).subscribe(() => {
        const msg = `${this.integrationPartnerName} account successfully linked.`;
        this.notificationsService.showSuccessNotification(msg);
        this.authSourcesService.deregisterOauthState(this.redirectParams.state);
        setTimeout(() => {
          this.router.navigate([`/settings/integrations/${this.integrationPartnerName.toLowerCase()}`]);
        }, 3000);
      });

      this.failureSubscription = this.actions$.pipe(
        ofType(CreateAuthSourceFail),
      ).subscribe(() => {
        this.notificationsService.showErrorNotification('There was an error linking your account. Please try again.');
      });

      this.route.queryParams.subscribe(params => {
        this.redirectParams.code = params.code;
        this.redirectParams.state = params.state;
        this.integrationPartnerName = this.authSourcesService.verifyOauthState(this.redirectParams.state);
        if (this.integrationPartnerName !== null) {
          this.registerNewAuth();
        } else {
          this.notificationsService.showErrorNotification('There was an error linking your account. Please try again.');
          setTimeout(() => {
            this.router.navigate(['/settings/integrations']);
          }, 5000);
        }
      });
    }
  }

  getExpiration(min: number): string {
    const expr = new Date(Date.now() + min * 60000);
    return expr.toISOString();
  }

  // This may need to be refactored to be the concern of the specific integration component
  // in the future if we have a use case for expires_at, auth_credentials object, etc. with an OAuth integration
  registerNewAuth() {
    this.newAuth = {
      auth_credentials: {initial_key: this.redirectParams.code},
      expires_at_utc: null,
      firm_id: this.userProfileService.userProfile.firm_id,
      key: this.redirectParams.code,
      source: this.integrationPartnerName,
      user_id: this.userProfileService.userProfile.id
    };
    this.store.dispatch(CreateAuthSource({authSource: this.newAuth}));
  }
}
