import {Component, OnInit} from '@angular/core';
import {State} from '../auth-sources/state';
import {Store} from '@ngrx/store';
import {AuthSourcesService} from '../auth-sources/auth-sources.service';
import {AuthSource} from '../auth-sources/auth-source.type';
import {Router, ActivatedRoute} from '@angular/router';
import {LoadAuthSources, RemoveAuthSource} from '../auth-sources/state/auth-sources.actions';
import {UserProfileService} from '../../../shared/services/auth/user-profile.service';
import {NotificationService} from '../../../shared/services/ui/notification.service';
import {Observable} from 'rxjs';
import {Actions} from '@ngrx/effects';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-stratifi',
  templateUrl: './stratifi.component.html',
  styleUrls: ['./stratifi.component.css']
})
export class StratifiComponent implements OnInit {
  stratifiAuth$: Observable<AuthSource[]>;

  loading = false;

  constructor(private authSourcesService: AuthSourcesService,
              private store: Store<State>,
              private route: ActivatedRoute,
              private router: Router,
              private userProfileService: UserProfileService,
              private actions$: Actions,
              private notificationsService: NotificationService) {
      this.stratifiAuth$ = this.authSourcesService.authSources$.pipe(
        map(ass => ass.filter(as => as.source === 'Stratifi'))
      );
  }

  ngOnInit() {
  }

  removeAuthSource(authSource: AuthSource) {
    const msg = `You are deleting the ${authSource.source} credential. This cannot be undone.`;

    this.notificationsService.displayConfirmationNotification('Are you sure?', msg, 'delete')
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          this.store.dispatch(RemoveAuthSource({authSource}));
          this.store.dispatch(LoadAuthSources());
        }
      });
  }

  getUserName = (authSource: AuthSource): string => {
    // In future may need to handle different-than-current users
    // (ie all creds for firm) currently endpoint is ringfenced to user_id.
    return this.userProfileService.userProfile.full_name;
  }
}
