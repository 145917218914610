<div>
        <h2 mat-dialog-title>
          Assign Payment Source
        </h2>

        <div class="detail-label margin-bottom">Payment Source</div>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(value)]="paymentSource">
            <mat-option [value]="'C'">Custodian Billed</mat-option>
            <mat-option [value]="'D'">Directly Billed</mat-option>
          </mat-select>
        </mat-form-field>


        <div class="actions-container">
          <button mat-button
                  color="primary"
                  [mat-dialog-close]="false">
            Cancel
          </button>

          <app-atlas-submit-button [saving]="saving"
                                   [disabled]="saving"
                                   [onClick]="save">
            Save
          </app-atlas-submit-button>
        </div>

</div>

