<mat-card>
  <div class="card-header">
    <div class="subreport-header__title">
      <h2 class="title">Invoices</h2>
      <div class="subtitle">Invoices from the latest billing report created on {{ mostRecentBillingDate | date: "mediumDate":"UTC" }}</div>
    </div>

    <div class="flex-row-align-center">
      <div class="search-bar">
        <i matPrefix class="fas fa-search searchbar-icon"></i>
        <input placeholder="Search" (input)="dataGrid.instance.searchByText($event.target.value)">
      </div>

      <button mat-stroked-button
              color="primary"
              (click)="sendInvoices()">
        <i class="fas fa-envelope prefix-icon"></i>
        Send to Client
      </button>

      <button mat-stroked-button
              color="primary"
              [matMenuTriggerFor]="actionMenu">
        Actions
        <i class="fas fa-caret-down suffix-icon"></i>
      </button>
      <mat-menu #actionMenu="matMenu">
        <button mat-menu-item (click)="downloadSelectedInvoices()">Download Selected Invoices</button>
        <button mat-menu-item (click)="loadAllInvoices()">Load Historical Invoices</button>
      </mat-menu>

      <app-data-grid-actions-bar [isGrouped]="isGrouped" [dataGridInstance]="dataGrid" ></app-data-grid-actions-bar>
    </div>
  </div>


  <mat-card-content>
    <div *ngIf="!loading && invoices.length === 0" class="no-data">
      <div *ngIf="!showAll">
        No invoices to display from your last billing report.

        <a mat-button color="primary" (click)="loadAllInvoices()">Load Historical Invoices</a>
      </div>

      <div *ngIf="showAll">
        No invoices to display.
      </div>
    </div>

    <dx-data-grid #dataGrid
                  id="gridContainer"
                  [visible]="invoices.length > 0"
                  [dataSource]="invoices"
                  width="100%"
                  appHighlightRow
                  appFillHeight
                  [allowColumnResizing]="true"
                  [allowColumnReordering]="true"
                  [columnAutoWidth]="true"
                  (onSelectionChanged)="onSelection($event)"
                  (onContentReady)="onContentReady($event)">
      <dxi-column
        caption="Invoice Date"
        dataType="date"
        dataField="billing_date"
        cellTemplate="invoiceDetailTemplate">
      </dxi-column>

      <dxi-column
        caption="Created"
        dataType="date"
        dataField="created_at_utc"
        sortOrder="desc">
      </dxi-column>

      <dxi-column
        caption="Name"
        dataType="string"
        [allowFiltering]="true"
        [allowSearch]="true"
        [calculateGroupValue]="getGroupName"
        [calculateSortValue]="getGroupName"
        [calculateCellValue]="getGroupName"
        cellTemplate="billingGroupNameTemplate">
      </dxi-column>

      <dxi-column
        caption="Invoice Type"
        dataType="string"
        [allowFiltering]="true"
        [allowSearch]="true"
        [calculateGroupValue]="invoiceType"
        [calculateSortValue]="invoiceType"
        [calculateCellValue]="invoiceType">
      </dxi-column>

      <dxi-column
        caption="Valuation"
        dataType="number"
        dataField="total_balance">
        <dxo-format [precision]="0" type="currency"></dxo-format>
      </dxi-column>
      <dxi-column
        caption="Fees"
        dataType="number"
        dataField="period_debit"
        cellTemplate="feesTemplate">
      </dxi-column>
      <dxi-column
        caption="Custodian Billed"
        dataType="number"
        dataField="custodian_billed_period_debit"
        cellTemplate="custodianBilledTemplate">
      </dxi-column>
      <dxi-column
        caption="Directly Billed"
        dataType="number"
        dataField="direct_billed_period_debit">
        <dxo-format [precision]="2" type="currency"></dxo-format>
      </dxi-column>

      <dxi-column
        caption="Actions"
        dataType="string"
        dataField="id"
        [allowFiltering]="false"
        [allowSorting]="false"
        [allowGrouping]="false"
        cellTemplate="downloadLinkTemplate">
      </dxi-column>

      <dxo-pager [visible]="false"></dxo-pager>
      <dxo-scrolling mode="virtual"></dxo-scrolling>

      <dxo-export fileName="invoices_list"></dxo-export>
      <dxo-state-storing [enabled]="true" storageKey="invoices"></dxo-state-storing>
      <dxo-selection
        selectAllMode="allPages"
        showCheckBoxesMode="always"
        mode="multiple"
      ></dxo-selection>

      <div *dxTemplate="let row of 'feesTemplate'">
        <div [ngClass]="getFees(row.data) < 0 ? 'negative-number' : null">
          {{ getFees(row.data) | financialStandard:"currency"}}
        </div>
      </div>

      <div *dxTemplate="let row of 'custodianBilledTemplate'">
        <div [ngClass]="getCustodianBilled(row.data) < 0 ? 'negative-number' : null">
          {{ getCustodianBilled(row.data) | financialStandard:"currency"}}
        </div>
      </div>

      <div *dxTemplate="let row of 'invoiceDetailTemplate'">
        <a [routerLink]="['/revenue/invoices/details', row.data.id]">
          {{ row.data.billing_date | date:"shortDate":"UTC" }}
        </a>
      </div>

      <div *dxTemplate="let cell of 'downloadLinkTemplate'">
        <button mat-icon-button color="primary" *ngIf="!cell.row.data.error_message" [routerLink]="null" (click)="downloadPDFInvoice(cell.data)">
          <i class="fas fa-arrow-to-bottom datagrid-icon"></i>
        </button>
        <span *ngIf="cell.row.data.error_message" class="badge error" matTooltip="Contact client success support@bridgeft.com">Error</span>
      </div>

      <div *dxTemplate="let row of 'billingGroupNameTemplate'">
        <a mat-button color="primary" class="mat-button-xs" *ngIf="row.data.billing_group_id; else billingGroupName" [routerLink]="['/settings/billing/groups/details', row.data.billing_group_id || '']">
          <ng-container *ngTemplateOutlet="billingGroupName"></ng-container>
        </a>

        <ng-template #billingGroupName>{{ getGroupName(row.data) }}</ng-template>
      </div>
    </dx-data-grid>
  </mat-card-content>
</mat-card>
