import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxChartModule} from 'devextreme-angular/ui/chart';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import {DxDateBoxModule} from 'devextreme-angular/ui/date-box';
import {DxDropDownBoxModule} from 'devextreme-angular/ui/drop-down-box';
import {DxFormModule} from 'devextreme-angular/ui/form';
import {DxListModule} from 'devextreme-angular/ui/list';
import {DxLoadIndicatorModule} from 'devextreme-angular/ui/load-indicator';
import {DxLoadPanelModule} from 'devextreme-angular/ui/load-panel';
import {DxLookupModule} from 'devextreme-angular/ui/lookup';
import {DxNumberBoxModule} from 'devextreme-angular/ui/number-box';
import {DxPieChartModule} from 'devextreme-angular/ui/pie-chart';
import {DxPopoverModule} from 'devextreme-angular/ui/popover';
import {DxPopupModule} from 'devextreme-angular/ui/popup';
import {DxRadioGroupModule} from 'devextreme-angular/ui/radio-group';
import {DxRangeSelectorModule} from 'devextreme-angular/ui/range-selector';
import {DxSelectBoxModule} from 'devextreme-angular/ui/select-box';
import {DxTagBoxModule} from 'devextreme-angular/ui/tag-box';
import {DxTemplateModule} from 'devextreme-angular/core';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {DxToolbarModule} from 'devextreme-angular/ui/toolbar';
import {DxValidationGroupModule} from 'devextreme-angular/ui/validation-group';
import {DxValidatorModule} from 'devextreme-angular/ui/validator';
import {DxScrollViewModule} from 'devextreme-angular/ui/scroll-view';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxDropDownBoxModule,
    DxButtonModule,
    DxChartModule,
    DxPieChartModule,
    DxLoadIndicatorModule,
    DxPopupModule,
    DxFormModule,
    DxTagBoxModule,
    DxRangeSelectorModule,
    DxDateBoxModule,
    DxTextBoxModule,
    DxRadioGroupModule,
    DxToolbarModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxScrollViewModule,
    DxLookupModule,
    DxListModule,
    DxPopoverModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxNumberBoxModule
  ],
  exports: [
    DxDataGridModule,
    DxSelectBoxModule,
    DxDropDownBoxModule,
    DxButtonModule,
    DxChartModule,
    DxPieChartModule,
    DxLoadIndicatorModule,
    DxPopupModule,
    DxFormModule,
    DxTagBoxModule,
    DxRangeSelectorModule,
    DxDateBoxModule,
    DxTextBoxModule,
    DxRadioGroupModule,
    DxToolbarModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxScrollViewModule,
    DxLookupModule,
    DxListModule,
    DxPopoverModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxNumberBoxModule
  ]
})
export class DevExtremeModule { }
