import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CreateAlertSubscription,
  CreateAlertSubscriptionFail,
  CreateAlertSubscriptionSuccess,
  LoadAlertSubscriptions,
  LoadAlertSubscriptionsFail,
  LoadAlertSubscriptionsSuccess,
  RemoveAlertSubscription,
  RemoveAlertSubscriptionFail,
  RemoveAlertSubscriptionSuccess,
} from './alert-subscriptions.actions';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getAlertSubscriptions, State} from './index';
import {AlertSubscription} from '../alert-subscription.type';
import {AlertSubscriptionsService} from '../alert-subscriptions.service';

@Injectable()
export class AlertSubscriptionsEffects {
  constructor(
    private alertSubscriptionsService: AlertSubscriptionsService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  
  loadAlertSubscriptions$ = createEffect(() => this.actions$.pipe(
    ofType(LoadAlertSubscriptions),
    withLatestFrom(this.store.pipe(select(getAlertSubscriptions))),
    mergeMap(() => this.alertSubscriptionsService.getAlertSubscriptions().pipe(
      map((resp) => LoadAlertSubscriptionsSuccess({alertSubscriptions: resp})),
      catchError((err) => of(LoadAlertSubscriptionsFail({error: err.error})))
    )),
    take(1),
  ));

  
  createAlertSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAlertSubscription),
    map(action => action.alertSubscription),
    mergeMap((alertSubscription) => {
      return this.alertSubscriptionsService.createAlertSubscription(alertSubscription).pipe(
        map((createdAlertSubscription) => CreateAlertSubscriptionSuccess({alertSubscription: createdAlertSubscription})),
        catchError((err) => {
          return of(CreateAlertSubscriptionFail({error: err.error}));
        })
      );
    })
  ));

  
  removeAlertSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(RemoveAlertSubscription),
    map(action => action.alertSubscription),
    mergeMap((alertSubscription: AlertSubscription) => {
      return this.alertSubscriptionsService.removeAlertSubscription(alertSubscription).pipe(
        map(() => RemoveAlertSubscriptionSuccess({alertSubscription})),
        catchError((err) => {
          return of(RemoveAlertSubscriptionFail({error: err.error}));
        })
      );
    })
  ));
}
