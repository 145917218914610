import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-data-grid-link-template',
  template: `<a href="" [routerLink]="[link]" [attr.tooltip]="tooltip">{{ text }}</a>`,
})
export class DataGridLinkTemplateComponent implements OnInit {

  @Input() link: string;
  @Input() tooltip: string;
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
