import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss']
})
export class ColumnChooserComponent implements OnInit {

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter();
  @Input()  columns;
  @Input() container: string;
  @Input() grid: DxDataGridComponent;
  selectedItems = [];

  constructor() {}

  ngOnInit(): void {
    const storageKey = JSON.parse(localStorage.getItem(this.grid.stateStoring.storageKey));

    if (storageKey) {
      storageKey.columns.forEach((c, i) => {
        const column = this.columns[i];

        column.isVisible = c.visible;
        delete column.visible;

        if (column.isVisible) {
          this.selectedItems.push(column.caption);
        }
      });
    } else {
      this.columns.forEach(c => {
        // if the default visible property is false, it'll prevent the column from showing
        // up at all in the custom column chooser
        if (c.hasOwnProperty('visible')) {
          c.isVisible = false;
          delete c.visible;
        } else {
          c.isVisible = true;
        }

        if (c.isVisible) {
          this.selectedItems.push(c.caption);
        }
      });
    }
  }

  onSelectionChanged = (e) => {
    this.grid.instance.beginUpdate();
    if (e.addedItems.length) {
        e.addedItems.forEach(a => {
          this.selectedItems.push(a.caption);
          this.grid.instance.columnOption(a.caption, 'visible', true);
        });
    } else if (e.removedItems.length) {
      e.removedItems.forEach(a => {
        this.selectedItems = this.selectedItems.filter(i => i.caption !== a.caption);
        this.grid.instance.columnOption(a.caption, 'visible', false);
      });
    }
    this.grid.instance.endUpdate();
  }


  cancel() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
