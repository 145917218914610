// getFeeStructures
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {FeeStructuresService} from '../settings/billing/fee-structures/fee-structures.service';
import {MinimumService} from './minimum.service';
import {BillingSplitsService} from './billing-splits.service';
import {BillingGroupsService} from './billing-groups.service';
import {BillingAssetAdjustmentService} from './billing-asset-adjustment.service';

@Injectable()
export class SplitResolver implements Resolve<any> {
  constructor(private billing: BillingSplitsService) {
  }

  resolve(): Promise<any> {
    return this.billing.fetchAll();
  }
}

@Injectable()
export class GroupResolver implements Resolve<any> {
  constructor(private billing: BillingGroupsService) {
  }

  resolve(): Promise<any> {
    return this.billing.getAccountGroups();
  }
}

@Injectable()
export class MinimumsResolver implements Resolve<any> {
  constructor(private billing: MinimumService) {
  }

  resolve(): Promise<any> {
    return this.billing.fetchAll();
  }
}

@Injectable()
export class AssetAdjustmentsResolver implements Resolve<any> {
  constructor(private billing: BillingAssetAdjustmentService) {
  }

  resolve(): Promise<any> {
    return this.billing.getAssetAdjustments();
  }
}
