import {Component, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbLink} from '../../../../shared/components/breadcrumbs/breadcrumbs.component';
import {WealthboxContactsMappingService} from './wealthbox-contacts-mapping.service';
import CustomStore from 'devextreme/data/custom_store';
import {Household} from '../../../../households/household';
import {WealthboxContact} from '../type-classes/wealthbox-contact';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {DxLookupComponent} from 'devextreme-angular/ui/lookup';
import {NotificationService} from '../../../../shared/services/ui/notification.service';
import {WealthboxService} from '../wealthbox.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../../../accounts/account.service';
import {combineLatest, from, Observable} from 'rxjs';
import {filter, map, startWith, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-wealthbox-contacts-mapping',
  templateUrl: './wealthbox-contacts-mapping.component.html',
  styleUrls: ['./wealthbox-contacts-mapping.component.scss'],
  providers: [WealthboxContactsMappingService],
})
export class WealthboxContactsMappingComponent implements OnInit {
  breadcrumbs: BreadcrumbLink[];
  isHelpWindowVisible: boolean;
  relationsDataSource: { store: CustomStore };
  selectedIntegrations: string[] = [];

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('householdLookup') householdLookup: DxLookupComponent;
  @ViewChild('wealthboxContactsLookup') wealthboxContactsLookup: DxLookupComponent;
  wealthboxForm: UntypedFormGroup;
  householdControl: AbstractControl;
  contactControl: AbstractControl;
  loadingAddContact = false;
  loadingRemoveContact = false;

  households$: Observable<Household[]>;
  filteredHouseholds$: Observable<Household[]>;
  wealthboxContacts$: Observable<WealthboxContact[]>;
  filteredWealthboxContacts$: Observable<WealthboxContact[]>;

  contacts: WealthboxContact[] = [];

  constructor(
    private wealthboxContactsMappingService: WealthboxContactsMappingService,
    private notification: NotificationService,
    private wealthboxService: WealthboxService,
    private accountService: AccountService
  ) {
    this.breadcrumbs = [
      {
        url: '/households/list',
        name: 'Households'
      },
      {
        name: 'Wealthbox mapping'
      }
    ];
  }

  openHelpModal() {
    this.isHelpWindowVisible = true;
  }

  ngOnInit() {
    this.wealthboxForm = new UntypedFormGroup({
      household: new UntypedFormControl(null, Validators.required),
      contact: new UntypedFormControl(null, Validators.required)
    });

    this.householdControl = this.wealthboxForm.controls.household;
    this.contactControl = this.wealthboxForm.controls.contact;

    this.households$ = this.accountService.households$;

    this.wealthboxContacts$ = from(this.wealthboxService.getAllContacts());

    combineLatest([
      this.households$,
      from(this.wealthboxContacts$)
    ]).subscribe(([h, c]) => {
        this.relationsDataSource = this.wealthboxContactsMappingService.getRelationsDataSource(h, c);
      });

    this.filteredHouseholds$ = this.wealthboxForm.controls.household.valueChanges
      .pipe(
        startWith(''),
        filter(search => {
          return typeof search === 'string';
        }),
        switchMap(search => {
          search = search.toLowerCase();
          return this.households$.pipe(
            map((households: Household[]) => {
              return households.filter(household => household.name.toLowerCase().includes(search));
            })
          );
        })
      );

    this.filteredWealthboxContacts$ = this.wealthboxForm.controls.contact.valueChanges
      .pipe(
        startWith(''),
        filter(search => {
          return typeof search === 'string';
        }),
        switchMap(search => {
          search = search.toLowerCase();
          return this.wealthboxContacts$.pipe(
            map((contacts: WealthboxContact[]) => {
              return contacts.filter(contact => contact.name.toLowerCase().includes(search));
            })
          );
        })
      );
  }

  householdDisplay(household) {
    return household && household.name ? household.name : '';
  }

  contactDisplay(contact) {
    return contact && contact.name ? contact.name : '';
  }

  integrate() {
    this.loadingAddContact = true;
    this.wealthboxService.addIntegration(this.householdControl.value, this.contactControl.value)
      .then(() => {
        this.notification.showSuccessNotification('Contact was successfully integrated');
      })
      .catch(() => {
        this.notification.showErrorNotification('There is a problem occurs while removing your integrated contact.');
      })
      .finally(() => {
        this.wealthboxForm.reset();
        this.loadingAddContact = false;
      });

    this.dataGrid.instance.refresh();
  }

  removeRelation(relation) {
    this.notification.displayConfirmationNotification('Are you sure?', `You are deleting ${relation.data.wealthboxContact.name} contact`, 'delete')
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result) {
          this.loadingRemoveContact = true;
          this.wealthboxService.removeIntegrations(relation.data.id)
            .then(() => {
              this.dataGrid.instance.refresh();
            })
            .catch(() => {
              this.notification.showErrorNotification('There is a problem occurs while removing your integrated contact.');
            })
            .finally(() => {
              this.loadingRemoveContact = false;
            });
        }
      });
  }

  removeRelations() {
    if (this.selectedIntegrations.length === 0) {
      this.notification.showInfoNotification('There are no integrations.');
      return false;
    }

    this.notification.displayConfirmationNotification('Are you sure?', `You are deleting ${this.selectedIntegrations.length} contacts`, 'delete')
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result) {
          this.loadingRemoveContact = true;
          this.wealthboxService.removeIntegrations(this.selectedIntegrations)
            .then(() => {
              this.dataGrid.instance.refresh();
            })
            .catch(() => {
              this.notification.showErrorNotification('There is a problem occurs while removing your integrated contacts.');
            })
            .finally(() => {
              this.loadingRemoveContact = false;
            });
        }
      });
  }

  // Need to reflect correct disabled state for selectbox items
  private refreshDisabledState() {
    this.householdLookup.instance.getDataSource().load();
    this.wealthboxContactsLookup.instance.getDataSource().load();
  }

  getContactName = (id: number): string => {
    const contact = this.contacts.find(c => c.id === id);

    return contact ? contact.name : '';
  }
}
