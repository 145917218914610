import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromHouseholds from './household.reducer';

export interface State  {
  products: fromHouseholds.HouseholdState;
}

const getHouseholdFeatureState = createFeatureSelector<fromHouseholds.HouseholdState>('households');

export const getHouseholds = createSelector(
  getHouseholdFeatureState,
  fromHouseholds.selectAllHouseholds
);

export const getLoading = createSelector(
  getHouseholdFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getHouseholdFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getHouseholdFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getHouseholdFeatureState,
  state => state.updateCallState.loading
);
