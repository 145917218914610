<div style="text-align: center; padding: 150px;">
  <article>
    <img src="../../../assets/images/BridgeFT_LOGO_BLACK.png" alt="Bridge Financial Technology logo" style="width: 500px">
    <br>
    <br>
    <br>

    <h1 style="font-size: 50px;">We&rsquo;ll be back soon!</h1>

    <br>
    <br>

    <div style="display: block; text-align: left; width: 650px; margin: 0 auto;">
      <p class="text-lead text-left">
        We're making updates and/or fixing known issues that can't be handled while the dashboard is live.
      </p>

      <p class="text-lead text-left">
        This usually doesn't take long. Please try again later.
      </p>

      <p class="text-lead text-left">
        If you have a pressing issue,
        please email <a href="mailto:support@bridgeft.com">support@bridgeft.com</a>.
      </p>
    </div>
  </article>
</div>


