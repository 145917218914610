import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../shared/services/auth/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CognitoService, HashkeyResults} from '../../shared/services/auth/cognito.service';
import {NotificationService} from '../../shared/services/ui/notification.service';
import {AppLoadingService} from '../../layout/app-loading.service';

@Component({
  selector: 'app-hashkey-login',
  templateUrl: './hashkey-login.component.html',
  styleUrls: ['./hashkey-login.component.scss']
})
export class HashkeyLoginComponent implements OnInit {

  user = {
    hashkey: ''
  };

  errorMessage = false;
  message: string;

  loggingIn = false;

  constructor(private loginService: LoginService,
              private route: ActivatedRoute,
              private router: Router,
              private cognitoService: CognitoService,
              private notifyService: NotificationService,
              private appLoadingService: AppLoadingService) { }

  ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      this.router.navigateByUrl('/dashboard');
      return;
    }

    this.appLoadingService.setAppLoading(false);

    if (this.route.snapshot.params.id) {
      this.user.hashkey = this.route.snapshot.params.id;
      this.login();
    }
  }

  login() {
    this.loggingIn = true;

    this.loginService.loginWithHashkey(this.user.hashkey).subscribe((loginResult: HashkeyResults) => {

      this.cognitoService.setHashkeyCredentials(loginResult);
      this.notifyService.showSuccessNotification('Hashkey login successful, your session will expire in one hour');
      this.router.navigateByUrl('/dashboard');

      }, (err) => {
      this.loggingIn = false;
      this.displayError(err.error.Message);
    });
  }

  closeMessage() {
    this.errorMessage = false;
    this.loginService.setLoginDisplayMessage('');
    this.message = null;
  }

  displayError(message) {
    this.errorMessage = true;
    this.message = message;
  }

  backToLogin() {
    this.router.navigateByUrl('/login');
  }
}
