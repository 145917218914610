import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'describeAssetAdjustment'
})
export class DescribeAssetAdjustmentPipe implements PipeTransform {

  transform(adjustment: any, args?: any): any {
    if (adjustment.adjustment_type === 'e' && adjustment.level === 'f') {
      return 'Firm-wide exclusion';
    } else if (adjustment.adjustment_type === 'e' && adjustment.level === 'a') {
      return 'Exclusion on selected accounts';
    } else if (adjustment.adjustment_type === 'i' && adjustment.level === 'f') {
      return 'Firm-wide inclusion';
    } else if (adjustment.adjustment_type === 'i' && adjustment.level === 'a') {
      return 'Inclusion on selected accounts';
    } else {
      return '';
    }
  }

}
