import { Component, OnInit } from '@angular/core';
import {CognitoService} from '../../shared/services/auth/cognito.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent implements OnInit {

  resetObj = {
    password: '',
    confirmation: ''
  };

  errorMessage = false;
  message: string;
  loggingIn: boolean;

  constructor(private cognitoService: CognitoService,
              private router: Router) { }

  ngOnInit() {
  }

  isFormValid(): boolean {
    return this.resetObj.confirmation !== '' && this.resetObj.password !== '' && this.resetObj.confirmation !== '';
  }

  isPasswordValid(): boolean {
    if (this.resetObj.password !== this.resetObj.confirmation) {
      this.displayError('Passwords do not match.');
      return false;
    }

    if (this.resetObj.password.length < 8) {
      this.displayError('Passwords must be at least 8 characters.');
      return false;
    }

    return true;
  }

  setPassword() {
    if (this.isPasswordValid()) {
      this.loggingIn = true;
      this.cognitoService.setNewPassword(this.resetObj.password, this.onResetSuccess, this.onResetError);
    }
  }

  onResetSuccess = () => {
    if (this.cognitoService.authProfile.is_firm_user) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/cp/main');
    }
  }

  onResetError = (err) => {
    this.loggingIn = false;
    this.displayError(err.message);
  }

  displayError(message) {
    this.errorMessage = true;
    this.message = message;
  }

  closeMessage() {
    this.errorMessage = false;
    this.message = null;
  }
}
