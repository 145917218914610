import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CreateAuthSource,
  CreateAuthSourceFail,
  CreateAuthSourceSuccess,
  LoadAuthSources,
  LoadAuthSourcesFail,
  LoadAuthSourcesSuccess,
  RemoveAuthSource,
  RemoveAuthSourceFail,
  RemoveAuthSourceSuccess, UpdateAuthSource, UpdateAuthSourceFail, UpdateAuthSourceSuccess,
} from './auth-sources.actions';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getAuthSources, State} from './index';
import {AuthSource} from '../auth-source.type';
import {AuthSourcesService} from '../auth-sources.service';

@Injectable()
export class AuthSourcesEffects {
  constructor(
    private authSourceService: AuthSourcesService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  
  loadAuthSources$ = createEffect(() => this.actions$.pipe(
    ofType(LoadAuthSources),
    withLatestFrom(this.store.pipe(select(getAuthSources))),
    mergeMap(() => this.authSourceService.getAuthSources().pipe(
      map((resp) => LoadAuthSourcesSuccess({authSources: resp})),
      catchError((err) => of(LoadAuthSourcesFail({error: err.error})))
    )),
    take(1),
  ));

  
  createAuthSource$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAuthSource),
    map(action => action.authSource),
    mergeMap((authSource) => {
      return this.authSourceService.createAuthSource(authSource).pipe(
        map((createdAuthSource) => CreateAuthSourceSuccess({authSource: createdAuthSource})),
        catchError((err) => {
          return of(CreateAuthSourceFail({error: err.error}));
        })
      );
    })
  ));

  
  updateAuthSource$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateAuthSource),
    map(action => action.authSource),
    mergeMap((authSource) => {
      return this.authSourceService.updateAuthSource(authSource).pipe(
        map((updatedAuthSource) => UpdateAuthSourceSuccess({authSource: updatedAuthSource})),
        catchError((err) => {
          return of(UpdateAuthSourceFail({error: err.error}));
        })
      );
    })
  ));

  
  removeAuthSource$ = createEffect(() => this.actions$.pipe(
    ofType(RemoveAuthSource),
    map(action => action.authSource),
    mergeMap((authSource: AuthSource) => {
      return this.authSourceService.removeAuthSource(authSource).pipe(
        map(() => RemoveAuthSourceSuccess({authSource})),
        catchError((err) => {
          return of(RemoveAuthSourceFail({error: err.error}));
        })
      );
    })
  ));
}
