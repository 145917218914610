import { Component, OnInit } from '@angular/core';
import {CognitoService} from '../../shared/services/auth/cognito.service';
import {Router} from '@angular/router';
import {LoginService, LoginViewStates} from '../../shared/services/auth/login.service';

@Component({
  selector: 'app-enter-password',
  templateUrl: './enter-password.component.html',
  styleUrls: ['./enter-password.component.scss']
})
export class EnterPasswordComponent implements OnInit {

  resetObj = {
    verificationCode: '',
    password: '',
    confirmation: ''
  };

  errorMessage = false;
  message: string;

  constructor(private cognitoService: CognitoService,
              private router: Router,
              private loginService: LoginService) { }

  ngOnInit() {
  }

  onResetSuccess = () => {
    const loginPageMessage = 'Password has been reset. Login with your new password.';
    // this.router.navigateByUrl('/login');
    this.loginService.setLoginDisplayMessage(loginPageMessage);
    this.loginService.modeObservable.next(LoginViewStates.LOGIN);
  }

  onError = (err) => {
    this.errorMessage = true;
    this.message = err.message;
  }

  resetPassword() {
    if (this.isPasswordValid()) {
      this.cognitoService.confirmResetPassword(this.resetObj.verificationCode, this.resetObj.password, this.onResetSuccess, this.onError);
    }
  }

  isFormValid(): boolean {
    return this.resetObj.confirmation !== '' && this.resetObj.password !== '' && this.resetObj.confirmation !== '';
  }

  isPasswordValid(): boolean {
    if (this.resetObj.password !== this.resetObj.confirmation) {
      this.displayError('Passwords do not match.');
      return false;
    }

    if (this.resetObj.password.length < 8) {
      this.displayError('Passwords must be at least 8 characters.');
      return false;
    }

    return true;
  }

  displayError(message) {
    this.errorMessage = true;
    this.message = message;
  }

  closeMessage() {
    this.errorMessage = false;
    this.message = null;
  }
}
