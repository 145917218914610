export class Note {
  id?: number;
  creator: number;
  created_at?: string;
  updated_at?: string;
  content: string;
  linked_to?: ContactLink[];
  visible_to?: string;
  tags?: string[];

  constructor() {
    this.content = '';
    this.tags = [];
    this.linked_to = [];
  }
}

export class ContactLink {
  id: number;
  type = 'Contact';
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
