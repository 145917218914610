<div class="form-group mb-20">
  <label class="detail-label" style="text-align: right">
    <a (click)="isAllPermissionsHelpVisible = true">
      All Permissions
    </a>
  </label>

  <div class="col-sm-10 pl-35">
    <mat-checkbox
      [checked]="hasAllPermissions()"
      (change)="toggleAllPermissions()"
      [disabled]="!allPermissionsEnabled()"
    >
      All Permissions
    </mat-checkbox>
  </div>
</div>

<br/>
<br/>

<h3><u>Billing Permissions</u></h3>

<br/>



  <div fxLayout="column"
       fxLayoutAlign="center center"
       gdGap="10px"
       gdColumns="calc(20% - 10px) calc(50% - 10px)">

    <label class="detail-label" style="text-align: right" style="text-align: right">
        Fee Structures
    </label>

    <mat-chip-list [multiple]="true">
      <mat-chip [selectable]="true"
                [selected]="permissions.billing.fee_structure.read"
                [disabled]="permissionDisabled('billing.group.read')"
                (click)="permissions.billing.fee_structure.read = !permissions.billing.fee_structure.read; updatePermissionsList()">
        View
      </mat-chip>

      <mat-chip [selectable]="true"
                [selected]="permissions.billing.fee_structure.create"
                [disabled]="permissionDisabled('billing.group.create')"
                (click)="permissions.billing.fee_structure.create = !permissions.billing.fee_structure.create; updatePermissionsList()">
        Create
      </mat-chip>

      <mat-chip [selectable]="true"
                [selected]="permissions.billing.fee_structure.update"
                [disabled]="permissionDisabled('billing.group.update')"
                (click)="permissions.billing.fee_structure.update = !permissions.billing.fee_structure.update; updatePermissionsList()">
        Change
      </mat-chip>

      <mat-chip [selectable]="true"
                [selected]="permissions.billing.fee_structure.delete"
                [disabled]="permissionDisabled('billing.group.delete')"
                (click)="permissions.billing.fee_structure.delete = !permissions.billing.fee_structure.delete; updatePermissionsList()">
        Remove
      </mat-chip>

      <mat-chip [selectable]="true"
                [selected]="permissions.billing.fee_structure.assign"
                [disabled]="permissionDisabled('billing.group.assign')"
                (click)="permissions.billing.fee_structure.assign = !permissions.billing.fee_structure.assign; updatePermissionsList()">
        Assign
      </mat-chip>
    </mat-chip-list>

    <label class="detail-label" style="text-align: right" style="text-align: right">
      Billing Groups
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.billing.group.read"
        [disabled]="permissionDisabled('billing.group.read')"
        (click)="permissions.billing.group.read = !permissions.billing.group.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.group.create"
        [disabled]="permissionDisabled('billing.group.create')"
        (click)="permissions.billing.group.create = !permissions.billing.group.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.group.update"
        [disabled]="permissionDisabled('billing.group.update')"
        (click)="permissions.billing.group.update = !permissions.billing.group.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.group.delete"
        [disabled]="permissionDisabled('billing.group.delete')"
        (click)="permissions.billing.group.delete = !permissions.billing.group.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

    <label class="detail-label" style="text-align: right">
      Minimums
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.billing.minimum.read"
        [disabled]="permissionDisabled('billing.minimum.read')"
        (click)="permissions.billing.minimum.read = !permissions.billing.minimum.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.minimum.create"
        [disabled]="permissionDisabled('billing.minimum.create')"
        (click)="permissions.billing.minimum.create = !permissions.billing.minimum.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.minimum.update"
        [disabled]="permissionDisabled('billing.minimum.update')"
        (click)="permissions.billing.minimum.update = !permissions.billing.minimum.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.minimum.delete"
        [disabled]="permissionDisabled('billing.minimum.delete')"
        (click)="permissions.billing.minimum.delete = !permissions.billing.minimum.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

    <label class="detail-label" style="text-align: right">
      Splits
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.billing.split.read"
        [disabled]="permissionDisabled('billing.split.read')"
        (click)="permissions.billing.split.read = !permissions.billing.split.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.split.create"
        [disabled]="permissionDisabled('billing.split.create')"
        (click)="permissions.billing.split.create = !permissions.billing.split.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.split.update"
        [disabled]="permissionDisabled('billing.split.update')"
        (click)="permissions.billing.split.update = !permissions.billing.split.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.split.delete"
        [disabled]="permissionDisabled('billing.split.delete')"
        (click)="permissions.billing.split.delete = !permissions.billing.split.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>


    <label class="detail-label" style="text-align: right">
      Asset Adjustments
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.billing.asset_adjustment.read"
        [disabled]="permissionDisabled('billing.asset_adjustment.read')"
        (click)="permissions.billing.asset_adjustment.read = !permissions.billing.asset_adjustment.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.asset_adjustment.create"
        [disabled]="permissionDisabled('billing.asset_adjustment.create')"
        (click)="permissions.billing.asset_adjustment.create = !permissions.billing.asset_adjustment.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.asset_adjustment.update"
        [disabled]="permissionDisabled('billing.asset_adjustment.update')"
        (click)="permissions.billing.asset_adjustment.update = !permissions.billing.asset_adjustment.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.asset_adjustment.delete"
        [disabled]="permissionDisabled('billing.asset_adjustment.delete')"
        (click)="permissions.billing.asset_adjustment.delete = !permissions.billing.asset_adjustment.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

    <label class="detail-label" style="text-align: right">
      Billing Reports
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.billing.report.read"
        [disabled]="permissionDisabled('billing.report.read')"
        (click)="permissions.billing.report.read = !permissions.billing.report.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.report.create"
        [disabled]="permissionDisabled('billing.report.create')"
        (click)="permissions.billing.report.create = !permissions.billing.report.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.report.download"
        [disabled]="permissionDisabled('billing.report.download')"
        (click)="permissions.billing.report.download = !permissions.billing.report.download; updatePermissionsList()"
      >
        Download
      </mat-chip>

      <mat-chip
        [selected]="permissions.billing.report.delete"
        [disabled]="permissionDisabled('billing.report.delete')"
        (click)="permissions.billing.report.delete = !permissions.billing.report.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

    <label class="detail-label" style="text-align: right">
      Invoices
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.billing.invoice.read"
        [disabled]="permissionDisabled('billing.invoice.read')"
        (click)="permissions.billing.invoice.read = !permissions.billing.invoice.read; updatePermissionsList()"
      >
        View
      </mat-chip>

    </mat-chip-list>


<!--    <div class="col-sm-10">-->
<!--      <button type="button" class="btn btn-default" (click)="bulkChangeBillingPermissions(true)">Check all</button>-->
<!--      <button type="button" class="btn btn-default" (click)="bulkChangeBillingPermissions(false)">Uncheck all</button>-->
<!--    </div>-->



  </div>

<br/>
<br/>

<h3>
  <u>Reporting Permissions</u>
</h3>

  <div fxLayout="column"
       fxLayoutAlign="center center"
       gdGap="10px"
       gdColumns="calc(20% - 10px) calc(50% - 10px)">

    <label class="detail-label" style="text-align: right">
      Report Settings
    </label>

      <mat-chip-list [multiple]="true">

        <mat-chip
          [selected]="permissions.reporting.settings.read"
          [disabled]="permissionDisabled('reporting.settings.read')"
          (click)="permissions.reporting.settings.read = !permissions.reporting.settings.read; updatePermissionsList()"
        >
          View
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.settings.update"
          [disabled]="permissionDisabled('reporting.settings.update')"
          (click)="permissions.reporting.settings.update = !permissions.reporting.settings.update; updatePermissionsList()"
        >
          Change
        </mat-chip>

      </mat-chip-list>

    <label class="detail-label" style="text-align: right">
        Benchmarks
    </label>


      <mat-chip-list [multiple]="true">

        <mat-chip
          [selected]="permissions.reporting.benchmark.read"
          [disabled]="permissionDisabled('reporting.benchmark.read')"
          (click)="permissions.reporting.benchmark.read = !permissions.reporting.benchmark.read; updatePermissionsList()"
        >
          View
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.benchmark.create"
          [disabled]="permissionDisabled('reporting.benchmark.create')"
          (click)="permissions.reporting.benchmark.create = !permissions.reporting.benchmark.create; updatePermissionsList()"
        >
          Create
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.benchmark.update"
          [disabled]="permissionDisabled('reporting.benchmark.update')"
          (click)="permissions.reporting.benchmark.update = !permissions.reporting.benchmark.update; updatePermissionsList()"
        >
          Change
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.benchmark.delete"
          [disabled]="permissionDisabled('reporting.benchmark.delete')"
          (click)="permissions.reporting.benchmark.delete = !permissions.reporting.benchmark.delete; updatePermissionsList()"
        >
          Remove
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.benchmark.assign"
          [disabled]="permissionDisabled('reporting.benchmark.assign')"
          (click)="permissions.reporting.benchmark.assign = !permissions.reporting.benchmark.assign; updatePermissionsList()"
        >
          Assign
        </mat-chip>

      </mat-chip-list>


    <label class="detail-label" style="text-align: right">
        Asset Classifications
    </label>

      <mat-chip-list [multiple]="true">

        <mat-chip
          [selected]="permissions.reporting.asset_classification.read"
          [disabled]="permissionDisabled('reporting.asset_classification.read')"
          (click)="permissions.reporting.asset_classification.read = !permissions.reporting.asset_classification.read; updatePermissionsList()"
        >
          View
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.asset_classification.create"
          [disabled]="permissionDisabled('reporting.asset_classification.create')"
          (click)="permissions.reporting.asset_classification.create = !permissions.reporting.asset_classification.create; updatePermissionsList()"
        >
          Create
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.asset_classification.update"
          [disabled]="permissionDisabled('reporting.asset_classification.update')"
          (click)="permissions.reporting.asset_classification.update = !permissions.reporting.asset_classification.update; updatePermissionsList()"
        >
          Change
        </mat-chip>

        <mat-chip
          [selected]="permissions.reporting.asset_classification.delete"
          [disabled]="permissionDisabled('reporting.asset_classification.delete')"
          (click)="permissions.reporting.asset_classification.delete = !permissions.reporting.asset_classification.delete;  updatePermissionsList()"
        >
          Remove
        </mat-chip>

      </mat-chip-list>


    <label class="detail-label" style="text-align: right">
        Web reporting
    </label>

      <mat-chip-list [multiple]="true">

        <mat-chip
          [selected]="permissions.reporting.web.create"
          [disabled]="permissionDisabled('reporting.web.create')"
          (click)="permissions.reporting.web.create = !permissions.reporting.web.create; updatePermissionsList()"
        >
          Create / Run
        </mat-chip>

      </mat-chip-list>



    <label class="detail-label" style="text-align: right">
      Printable Reports
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.reporting.printable.read"
        [disabled]="permissionDisabled('reporting.printable.read')"
        (click)="permissions.reporting.printable.read = !permissions.reporting.printable.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.reporting.printable.create"
        [disabled]="permissionDisabled('reporting.printable.create')"
        (click)="permissions.reporting.printable.create = !permissions.reporting.printable.create; updatePermissionsList()"
      >
        Create / Run
      </mat-chip>

      <mat-chip
        [selected]="permissions.reporting.printable.download"
        [disabled]="permissionDisabled('reporting.printable.download')"
        (click)="permissions.reporting.printable.download = !permissions.reporting.printable.download; updatePermissionsList()"
      >
        Download
      </mat-chip>

      <mat-chip
        [selected]="permissions.reporting.printable.delete"
        [disabled]="permissionDisabled('reporting.printable.delete')"
        (click)="permissions.reporting.printable.delete = !permissions.reporting.printable.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

    <!-- TODO: Target Allocations, Security Exclusions, Report Packages-->

    <!--  <div class="col-sm-10">-->
    <!--    <button type="button" class="btn btn-default" (click)="bulkChangeReportingPermissions(true)">Check all</button>-->
    <!--    <button type="button" class="btn btn-default" (click)="bulkChangeReportingPermissions(false)">Uncheck all</button>-->
    <!--  </div>-->

  </div>

<br/>
<br/>

<h3>
  <u>Account Management Permissions</u>
</h3>

<div fxLayout="column"
     fxLayoutAlign="center center"
     gdGap="10px"
     gdColumns="calc(20% - 10px) calc(50% - 10px)">

  <label class="detail-label" style="text-align: right">
    Accounts
  </label>



  <mat-chip-list [multiple]="true">

    <mat-chip
      [selected]="permissions.account_management.account.read"
      [disabled]="permissionDisabled('account_management.account.read')"
      (click)="permissions.account_management.account.read = !permissions.account_management.account.read; updatePermissionsList()"
    >
      View
    </mat-chip>

    <mat-chip
      [selected]="permissions.account_management.account.update"
      [disabled]="permissionDisabled('account_management.account.update')"
      (click)="permissions.account_management.account.update = !permissions.account_management.account.update; updatePermissionsList()"
    >
      Change
    </mat-chip>

  </mat-chip-list>




  <label class="detail-label" style="text-align: right">
    Households
  </label>

  <mat-chip-list [multiple]="true">

    <mat-chip
      [selected]="permissions.account_management.household.read"
      [disabled]="permissionDisabled('account_management.household.read')"
      (click)="permissions.account_management.household.read = !permissions.account_management.household.read; updatePermissionsList()"
    >
      View
    </mat-chip>

    <mat-chip
      [selected]="permissions.account_management.household.update"
      [disabled]="permissionDisabled('account_management.household.update')"
      (click)="permissions.account_management.household.update = !permissions.account_management.household.update; updatePermissionsList()"
    >
      Change
    </mat-chip>

  </mat-chip-list>



  <label class="detail-label" style="text-align: right">
    Heldaways
  </label>


  <mat-chip-list [multiple]="true">

    <mat-chip
      [selected]="permissions.account_management.heldaway.read"
      [disabled]="permissionDisabled('account_management.heldaway.read')"
      (click)="permissions.account_management.heldaway.read = !permissions.account_management.heldaway.read; updatePermissionsList()"
    >
      View
    </mat-chip>

    <mat-chip
      [selected]="permissions.account_management.heldaway.create"
      [disabled]="permissionDisabled('account_management.heldaway.create')"
      (click)="permissions.account_management.heldaway.create = !permissions.account_management.heldaway.create; updatePermissionsList()"
    >
      Create
    </mat-chip>

    <mat-chip
      [selected]="permissions.account_management.heldaway.update"
      [disabled]="permissionDisabled('account_management.heldaway.update')"
      (click)="permissions.account_management.heldaway.update = !permissions.account_management.heldaway.update; updatePermissionsList()"
    >
      Change
    </mat-chip>

    <mat-chip
      [selected]="permissions.account_management.heldaway.delete"
      [disabled]="permissionDisabled('account_management.heldaway.delete')"
      (click)="permissions.account_management.heldaway.delete = !permissions.account_management.heldaway.delete; updatePermissionsList()"
    >
      Remove
    </mat-chip>

  </mat-chip-list>

  <!--  <div class="col-sm-10">-->
  <!--    <button type="button" class="btn btn-default" (click)="bulkChangeAccountManagementPermissions(true)">Check all</button>-->
  <!--    <button type="button" class="btn btn-default" (click)="bulkChangeAccountManagementPermissions(false)">Uncheck all</button>-->
  <!--  </div>-->

</div>

<br/>
<br/>

<h3>
  <u>File Sharing Permissions</u>
</h3>
<br/>

<div fxLayout="column"
     fxLayoutAlign="center center"
     gdGap="10px"
     gdColumns="calc(20% - 10px) calc(50% - 10px)">



  <label class="detail-label" style="text-align: right">
    File Sharing
  </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.file_sharing.file.read"
        [disabled]="permissionDisabled('file_sharing.file.read')"
        (click)="permissions.file_sharing.file.read = !permissions.file_sharing.file.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.file_sharing.file.create"
        [disabled]="permissionDisabled('file_sharing.file.create')"
        (click)="permissions.file_sharing.file.create = !permissions.file_sharing.file.create; updatePermissionsList()"
      >
        Upload
      </mat-chip>

      <mat-chip
        [selected]="permissions.file_sharing.file.delete"
        [disabled]="permissionDisabled('file_sharing.file.delete')"
        (click)="permissions.file_sharing.file.delete = !permissions.file_sharing.file.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>


<!--  <label class="detail-label" style="text-align: right">-->
<!--      Shared Links-->
<!--  </label>-->


<!--    <mat-chip-list [multiple]="true">-->

<!--      <mat-chip-->
<!--        [selected]="permissions.file_sharing.link.read"-->
<!--        [disabled]="permissionDisabled('file_sharing.link.read')"-->
<!--        (click)="permissions.file_sharing.link.read = !permissions.file_sharing.link.read; updatePermissionsList()"-->
<!--      >-->
<!--        View-->
<!--      </mat-chip>-->

<!--      <mat-chip-->
<!--        [selected]="permissions.file_sharing.link.create"-->
<!--        [disabled]="permissionDisabled('file_sharing.link.create')"-->
<!--        (click)="permissions.file_sharing.link.create = !permissions.file_sharing.link.create; updatePermissionsList()"-->
<!--      >-->
<!--        Create-->
<!--      </mat-chip>-->

<!--      <mat-chip-->
<!--        [selected]="permissions.file_sharing.link.update"-->
<!--        [disabled]="permissionDisabled('file_sharing.link.update')"-->
<!--        (click)="permissions.file_sharing.link.update = !permissions.file_sharing.link.update; updatePermissionsList()"-->
<!--      >-->
<!--        Change-->
<!--      </mat-chip>-->

<!--      <mat-chip-->
<!--        [selected]="permissions.file_sharing.link.delete"-->
<!--        [disabled]="permissionDisabled('file_sharing.link.delete')"-->
<!--        (click)="permissions.file_sharing.link.delete = !permissions.file_sharing.link.delete; updatePermissionsList()"-->
<!--      >-->
<!--        Remove-->
<!--      </mat-chip>-->

<!--    </mat-chip-list>-->


    <!--  <div class="col-sm-10">-->
    <!--    <button type="button" class="btn btn-default" (click)="bulkChangeFileSharingPermissions(true)">Check all</button>-->
    <!--    <button type="button" class="btn btn-default" (click)="bulkChangeFileSharingPermissions(false)">Uncheck all</button>-->
    <!--  </div>-->
</div>

<br/>
<br/>

<h3>
  <u>User Management Permissions</u>
</h3>


  <div fxLayout="column"
       fxLayoutAlign="center center"
       gdGap="10px"
       gdColumns="calc(20% - 10px) calc(50% - 10px)">

    <label class="detail-label" style="text-align: right">
      Client Users
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.user_management.client_user.read"
        [disabled]="permissionDisabled('user_management.client_user.read')"
        (click)="permissions.user_management.client_user.read = !permissions.user_management.client_user.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.client_user.create"
        [disabled]="permissionDisabled('user_management.client_user.create')"
        (click)="permissions.user_management.client_user.create = !permissions.user_management.client_user.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.client_user.update"
        [disabled]="permissionDisabled('user_management.client_user.update')"
        (click)="permissions.user_management.client_user.update = !permissions.user_management.client_user.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.client_user.delete"
        [disabled]="permissionDisabled('user_management.client_user.delete')"
        (click)="permissions.user_management.client_user.delete = !permissions.user_management.client_user.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

    <label class="detail-label" style="text-align: right">
      Firm Users
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.user_management.firm_user.read"
        [disabled]="permissionDisabled('user_management.firm_user.read')"
        (click)="permissions.user_management.firm_user.read = !permissions.user_management.firm_user.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.firm_user.create"
        [disabled]="permissionDisabled('user_management.firm_user.create')"
        (click)="permissions.user_management.firm_user.create = !permissions.user_management.firm_user.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.firm_user.update"
        [disabled]="permissionDisabled('user_management.firm_user.update')"
        (click)="permissions.user_management.firm_user.update = !permissions.user_management.firm_user.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.firm_user.delete"
        [disabled]="permissionDisabled('user_management.firm_user.delete')"
        (click)="permissions.user_management.firm_user.delete = !permissions.user_management.firm_user.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>


    <label class="detail-label" style="text-align: right">
      Roles
    </label>

    <mat-chip-list [multiple]="true">

      <mat-chip
        [selected]="permissions.user_management.role.read"
        [disabled]="permissionDisabled('user_management.role.read')"
        (click)="permissions.user_management.role.read = !permissions.user_management.role.read; updatePermissionsList()"
      >
        View
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.role.create"
        [disabled]="permissionDisabled('user_management.role.create')"
        (click)="permissions.user_management.role.create = !permissions.user_management.role.create; updatePermissionsList()"
      >
        Create
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.role.update"
        [disabled]="permissionDisabled('user_management.role.update')"
        (click)="permissions.user_management.role.update = !permissions.user_management.role.update; updatePermissionsList()"
      >
        Change
      </mat-chip>

      <mat-chip
        [selected]="permissions.user_management.role.delete"
        [disabled]="permissionDisabled('user_management.role.delete')"
        (click)="permissions.user_management.role.delete = !permissions.user_management.role.delete; updatePermissionsList()"
      >
        Remove
      </mat-chip>

    </mat-chip-list>

<!--    <div class="col-sm-10">-->
<!--      <button type="button" class="btn btn-default" (click)="bulkChangeUserManagementPermissions(true)">Check all</button>-->
<!--      <button type="button" class="btn btn-default" (click)="bulkChangeUserManagementPermissions(false)">Uncheck all</button>-->
<!--    </div>-->

  </div>

<br/>
<br/>


<h3>
  <u>Settings Permissions</u>
</h3>

<div fxLayout="column"
     fxLayoutAlign="center center"
     gdGap="10px"
     gdColumns="calc(20% - 10px) calc(50% - 10px)">


  <label class="detail-label" style="text-align: right">
    Firm Settings
  </label>

  <mat-chip-list [multiple]="true">

    <mat-chip
      [selected]="permissions.firm.settings.read"
      [disabled]="permissionDisabled('firm.settings.read')"
      (click)="permissions.firm.settings.read = !permissions.firm.settings.read; updatePermissionsList()"
    >
      View
    </mat-chip>

    <mat-chip
      [selected]="permissions.firm.settings.update"
      [disabled]="permissionDisabled('firm.settings.update')"
      (click)="permissions.firm.settings.update = !permissions.firm.settings.update; updatePermissionsList()"
    >
      Change
    </mat-chip>

  </mat-chip-list>

<!--  <br/>-->
<!--  <br/>-->

  <!--
    TODO:

    Investment Management:
    - Strategies, Models, Audit Log, Security Allocations

    Organization:
    - Firms, Clients,

    Luca: ?

    Insights:
    - general read

    Auth:
    - API Keys

    Client Inbox:
    - Send
  -->

</div>
