import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billingDisplayFeeLocation'
})
export class BillingDisplayFeeLocationPipe implements PipeTransform {

  transform(assignment: any, args?: any): any {
    if (assignment.fee_location_option === 'S') {
      return 'Charged';
    } else if (assignment.fee_location_option === 'G') {
      return 'Uncharged';
    } else if (assignment.fee_location_option === 'A') {
      return 'Relocated';
    }

    return '';
  }

}
