import { Component } from '@angular/core';
import {IdcService} from '../../idc/index.service';
import {Index} from '../../idc';
import {BenchmarkService} from '../benchmark.service';
import {Benchmark} from '../benchmark';
import {UserProfileService} from '../../shared/services/auth/user-profile.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../shared/services/ui/notification.service';
import { MatDialog } from '@angular/material/dialog';
import {BenchmarksCreateEditComponent} from '../../settings/reporting/benchmarks-create-edit/benchmarks-create-edit.component';

@Component({
  selector: 'app-benchmarks-list',
  templateUrl: './benchmarks-list.component.html',
})
export class BenchmarksListComponent {
  private indexesById: {[key: string]: Index};
  benchmarks: Benchmark[];
  isFirmUser: () => boolean;
  isEndClient: () => boolean;
  isHelpWindowVisible: boolean;
  isCreateWindowVisible: boolean;

  selectedBenchmarks: Benchmark[] = [];

  constructor(
    indexService: IdcService,
    private benchmarkService: BenchmarkService,
    userProfile: UserProfileService,
    private router: Router,
    private notification: NotificationService,
    private dialog: MatDialog
  ) {
    this.indexesById = indexService.indexesByID;
    this.benchmarks = benchmarkService.benchmarks;
    this.isFirmUser = userProfile.isFirmUser;
    this.isEndClient = userProfile.isEndClientUser;
  }

  static benchmarkHasIndexes(benchmark) {
    return benchmark.indexes.length > 0;
  }

  hasBenchmarks() {
    return this.benchmarks.length > 0;
  }

  addBenchmark() {
    this.isCreateWindowVisible = true;
  }

  removeBenchmark(benchmark) {
    const msg = `You're removing ${benchmark.name}. Are you sure? This action cannot be undone.`;

    this.notification.displayConfirm(msg)
      .toPromise().then((doRemove) => {
        if (doRemove) {
          this.benchmarkService.deleteBenchmark(benchmark)
            .catch( (err) => {
            if (err.status === 403) {
              this.notification.showErrorNotification(err.data.message);
            } else {
              this.notification.showErrorNotification('There was a problem removing this benchmark. ' +
                'Please contact support: support@bridgeft.com.');
            }
          });
        }
      });
  }

  openHelpModal() {
    this.isHelpWindowVisible = true;
  }

  createBenchmark() {
    this.dialog.open(BenchmarksCreateEditComponent, {data: {benchmark: null}});
  }

  editBenchmark(benchmark: Benchmark) {
    this.dialog.open(BenchmarksCreateEditComponent, {data: {benchmark: benchmark}}).afterClosed().toPromise().then(
      (b) => {
        this.benchmarks = this.benchmarks.map((a) => b.id === a.id ? b : a);
      }
    );
  }

  deleteBenchmarks() {
    if (!this.selectedBenchmarks.length) {
      this.notification.showInfoNotification('No Benchmark(s) selected');
    }

    this.benchmarkService.deleteBenchmarks(this.selectedBenchmarks).toPromise()
      .then((benchmarks) => {
        this.benchmarks = benchmarks;
      }).catch(err => {
        this.notification.showErrorNotification('Error deleting benchmarks.');
      });
  }
}
