import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {IdcService} from './index.service';

@Injectable()
export class IndexResolver implements Resolve<any> {
  constructor(private idcService: IdcService) {}
  resolve(): Promise<any> {
    return this.idcService.getIndexes();
  }
}
