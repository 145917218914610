import {Injectable} from '@angular/core';
import * as tinycolor from 'tinycolor2';
import {environment} from '../../../environments/environment';
import {registerPalette} from 'devextreme/viz/palette';
import {Chart} from 'chart.js';
import {ACCENT_COLOR, BRAND_COLORS, PRIMARY_COLOR} from '../../app-constants';
import {BehaviorSubject} from 'rxjs';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ColorsService {
  endpoint: string;

  primaryColor = PRIMARY_COLOR;
  accentColor = ACCENT_COLOR;
  brandColors = BRAND_COLORS;

  chartPrimaryColor: string;
  chartHoverPrimaryColor: string;
  chartAccentColor: string;
  chartHoverAccentColor: string;

  primaryColorPalette: Color[] = [];
  accentColorPalette: Color[] = [];

  firmUpdated = new BehaviorSubject(null);

  constructor() {
    this.endpoint = `${environment.apiV2Url}/org/firms`;

    // these methods first calculates the various shades associated with a selected color
    // then the color shades will be used to set the theme in the _theming.scss
    this.savePrimaryColor();
    this.saveAccentColor();

    // colors used in dashboard widget charts
    this.chartPrimaryColor = this.convertHexToRGB(this.primaryColor, 0.8);
    this.chartHoverPrimaryColor = this.convertHexToRGB(this.primaryColor, 1);
    this.chartAccentColor = this.convertHexToRGB(this.accentColor, 0.8);
    this.chartHoverAccentColor = this.convertHexToRGB(this.accentColor, 1);

    // this will register the palette needed to generate the reporting charts

    // adding a far lighter shade of the primary color in the beginning as the first color is used
    // often in reporting to indicate things like account performance relative to benchmarks in line charts
    // and if a dark color is used, the other lines may not be as visible
    registerPalette('Steel', {
      simpleSet: [
        this.convertHexToRGB(this.primaryColor, 0.4),
        ...this.brandColors
      ]
    });
  }

  setFirmColors(firm) {
    if (firm) {
      if (firm.primary_color) {
        this.primaryColor = firm.primary_color;
      }

      this.savePrimaryColor();

      if (firm.accent_color) {
        this.accentColor = firm.accent_color;
      }

      this.saveAccentColor();

      if (firm.brand_colors.length) {
        this.brandColors = firm.brand_colors;
      } else {
        this.brandColors = BRAND_COLORS;
      }

      this.chartPrimaryColor = this.convertHexToRGB(this.primaryColor, 0.8);
      this.chartHoverPrimaryColor = this.convertHexToRGB(this.primaryColor, 1);
      this.chartAccentColor = this.convertHexToRGB(this.accentColor, 0.8);
      this.chartHoverAccentColor = this.convertHexToRGB(this.accentColor, 1);

      registerPalette('Steel', {
        simpleSet: [
          this.convertHexToRGB(this.primaryColor, 0.4),
          ...this.brandColors
        ]
      });

      Chart.defaults.global.tooltips.titleFontColor = this.primaryColor;

      localStorage.setItem('primary-color', this.primaryColor);

      this.firmUpdated.next(firm);
    }
  }

  resetFirmColors(firm) {
    firm.primary_color = '#5A2ED1';
    firm.accent_color = ACCENT_COLOR;
    firm.brand_colors = BRAND_COLORS;

    this.setFirmColors(firm);
  }

  savePrimaryColor() {
    this.primaryColorPalette = this.computeColors(this.primaryColor);
    this.updateTheme(this.primaryColorPalette, 'primary');
  }

  saveAccentColor() {
    this.accentColorPalette = this.computeColors(this.accentColor);
    this.updateTheme(this.accentColorPalette, 'accent');
  }

  updateTheme(colors: Color[], theme: string) {
    colors.forEach(color => {
      document.documentElement.style.setProperty(
        `--theme-${theme}-${color.name}`,
        color.hex
      );
      document.documentElement.style.setProperty(
        `--theme-${theme}-contrast-${color.name}`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    });
  }

  computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
    ];
  }

  convertHexToRGB(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }
}

