import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayAssetClass'
})
export class DisplayAssetClassPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'EQ') {
      return 'Equity';
    } else if (input === 'DT') {
      return 'Debt';
    } else if (input === 'CA') {
      return 'Cash';
    } else if (input === 'MX') {
      return 'Mixed';
    } else if (input === 'AL') {
      return 'Alternative';
    } else if (input === 'UC') {
      return 'Unclassified';
    } else if (input === 'EF') {
      return 'ETF';
    } else if (input === 'MF') {
      return 'Mutual Fund';
    } else if (input === 'P') {
      return 'Portfolio';
    } else if (input === 'CE') {
      return 'Money Markets';
    } else if (input === 'ETF') {
      return 'ETF';
    } else if (input === 'Stock') {
      return 'Stock';
    }
  }
}
