import {AlertConditionState, selectAllAlertConditions} from './alert-conditions.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: AlertConditionState;
}

const getAlertConditionFeatureState = createFeatureSelector<AlertConditionState>('alertConditions');

export const getAlertConditions = createSelector(
  getAlertConditionFeatureState,
  selectAllAlertConditions
);

export const getLoading = createSelector(
  getAlertConditionFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getAlertConditionFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getAlertConditionFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getAlertConditionFeatureState,
  state => state.updateCallState.loading
);
