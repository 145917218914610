<div>
  <div class="section-item welcome-header">
    Please enter a new password.
  </div>

  <div [ngClass]="errorMessage ? 'msg-container error' : 'msg-container success'"
       *ngIf="message"
       (click)="closeMessage()">
    <div class="icon-container">
      <i *ngIf="errorMessage" class="fas fa-times-circle error-msg-close"></i>

      <i *ngIf="!errorMessage" class="fas fa-check-circle success-msg-close"></i>
    </div>

    {{message}}
  </div>

  <div class="section-item">
    <form>
      <div class="help">
        Passwords must be at least eight characters.
      </div>

      <mat-form-field appearance="outline" style="width: 100%;">
        <input type="password"
               matInput
               class="form-control"
               name="password"
               placeholder="Password"
               (keydown.enter)="setPassword()"
               [(ngModel)]="resetObj.password"
               [ngModelOptions]="{standalone: true}"
               required>
        <mat-icon matPrefix class="login-icon">lock</mat-icon>
      </mat-form-field>
      <br>

      <mat-form-field appearance="outline" style="width: 100%;">
        <input type="password"
               matInput
               class="form-control"
               name="password_confirmation"
               placeholder="Confirm Password"
               (keydown.enter)="setPassword()"
               [(ngModel)]="resetObj.confirmation"
               [ngModelOptions]="{standalone: true}"
               required>
        <mat-icon matPrefix class="login-icon">lock</mat-icon>
      </mat-form-field>
    </form>
  </div>

  <div class="section-item reset-btn-container">
    <app-atlas-submit-button [disabled]="!isFormValid()"
                             [saving]="loggingIn"
                             (click)="setPassword()">
      Reset Password
    </app-atlas-submit-button>
  </div>
</div>
