import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FeeStructure} from '../../../../billing/billing';
import {FeeStructuresService} from '../fee-structures.service';
import {NotificationService} from '../../../../shared/services/ui/notification.service';
import {BreadcrumbLink} from '../../../../shared/components/breadcrumbs/breadcrumbs.component';
import {
  BALANCE_TYPES,
  CALCULATION_TYPES,
  COLLECTION_TYPES,
  FREQUENCYS,
  QUARTER_CYCLES,
  SEMI_ANNUAL_CYCLES,
  ANNUAL_CYCLES
} from '../../../../billing/billing.constants';
import * as fromFeeStructure from '../state';
import {select, Store} from '@ngrx/store';
import * as feeStructureActions from '../state/fee-structure.actions';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take, takeWhile} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

interface DropdownOption {
  name: string;
  id: string | number;
}
@Component({
  selector: 'app-billing-create-edit-fee-id',
  templateUrl: './fee-structures-create-edit.component.html',
  styleUrls: ['./fee-structures-create-edit.component.scss']
})
export class FeeStructuresCreateEditComponent implements OnInit, OnDestroy {
  structureValue: string = null;
  feeStructure: FeeStructure;
  private feeStructureId: number;
  editing: boolean;
  public breadcrumbs: BreadcrumbLink[];
  isHelpWindowVisible: boolean;
  public collectionTypes: DropdownOption[];
  calculationTypes: DropdownOption[];
  balanceTypes: DropdownOption[];
  frequencys: DropdownOption[];
  quarterCycles: DropdownOption[];
  semiAnnualCycles: DropdownOption[];
  annualCycles: DropdownOption[];

  // @ViewChild('validationGroupComponent', { static: true }) validationGroup: DxValidationGroupComponent;
  private feeStructureSubscription: Subscription;
  isUpdateLoading$: Observable<boolean>;
  private componentActive = true;

  feeStructureForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private billingFeeStructuresService: FeeStructuresService,
    private notification: NotificationService,
    private store: Store<fromFeeStructure.State>,
    private actions$: Actions,
    private dialogRef: MatDialogRef<FeeStructuresCreateEditComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {

    this.feeStructureId = data.id;

    this.breadcrumbs = [
      {
        url: '/billing/nav',
        name: 'Billing'
      },
      {
        name: 'Fee Structures',
        url: '/billing/fee-id/list',
      }
    ];

    this.collectionTypes = COLLECTION_TYPES;

    this.calculationTypes = CALCULATION_TYPES;

    this.balanceTypes = BALANCE_TYPES;

    this.frequencys = FREQUENCYS;

    this.quarterCycles = QUARTER_CYCLES;

    this.semiAnnualCycles = SEMI_ANNUAL_CYCLES;

    this.annualCycles = ANNUAL_CYCLES;
  }

  ngOnInit() {


    this.editing = !!this.feeStructureId;

    if (this.editing) {
      this.feeStructureSubscription = this.store.pipe(
        select(fromFeeStructure.getFeeStructures),
        map((feeStructures) => feeStructures.find(f => f.id === this.feeStructureId)),
        take(1)
      )
        .subscribe(feeStructure => {
          this.feeStructure = {...feeStructure};
          this.canActivate();
        });
    } else {
      this.feeStructure = this.billingFeeStructuresService.constructChargeUnit();
    }

    this.actions$.pipe(
      ofType(feeStructureActions.UpdateFeeStructureSuccess),
      takeWhile(() => this.componentActive)
    )
      .subscribe(() => {
        const msg = `Fee Structure ${this.feeStructure.name} was saved`;
        this.notification.showSuccessNotification(msg);
        this.dialogRef.close();
      });

    this.actions$.pipe(
      ofType(feeStructureActions.UpdateFeeStructureFail),
      takeWhile(() => this.componentActive)
    )
      .subscribe(({error}) => {
        this.notification.showErrorNotification(error);
      });

    this.actions$.pipe(
      ofType(feeStructureActions.RemoveFeeStructureSuccess),
      takeWhile(() => this.componentActive)
    ).subscribe(() => {
      this.notification.showSuccessNotification(`Fee structure ${this.feeStructure.name} was removed`);
      this.router.navigate(['billing/fee-id/list']);
    });

    this.actions$.pipe(
      ofType(feeStructureActions.RemoveMultipleFeeStructureFail),
      takeWhile(() => this.componentActive)
    ).subscribe((err: any) => {
        if (err.status === 403) {
          this.notification.showErrorNotification(err.data.message);
        } else {
          this.notification.showErrorNotification(`Failed to remove fee structure ${name}`);
        }
      }
    );

    this.feeStructureForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.feeStructure.name, Validators.required),
      collectionType: new UntypedFormControl(this.feeStructure.collection_type, Validators.required),
      structure: new UntypedFormControl(this.feeStructure.calculation_type, Validators.required),
      valuationMethod: new UntypedFormControl(this.feeStructure.balance_type, Validators.required),
      frequency: new UntypedFormControl(this.feeStructure.frequency, Validators.required)
    });

    this.feeStructure.tiers = this.feeStructure.tiers.sort((a, b) => a.min_balance - b.min_balance);


    //  The FromGroup will add and remove formControls based on the selection of structure. Before adding any control, the form group
    //  should be reverted back to the origin. Every time the page loads, any form control other than the original are cleared out.
    this.clearFormControl();
    this.addFromControl(this.feeStructure.calculation_type);
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  openHelpModal() {
    this.isHelpWindowVisible = true;
  }

  save() {
    this.feeStructure.tiers = this.feeStructure.tiers.sort((a, b) => a.min_balance - b.min_balance);

    this.feeStructure.name = this.feeStructureForm.controls['name'].value;

    this.store.dispatch(feeStructureActions.UpdateFeeStructure({feeStructure: this.feeStructure}));
    this.isUpdateLoading$ = this.store.pipe(select(fromFeeStructure.getUpdateLoading));
  }

  addTierLevel(): void {
    const tier = this.billingFeeStructuresService.constructTier(this.feeStructure.id);
    if (this.feeStructure.tiers.length > 0) {
      tier.min_balance =  Number(this.feeStructure.tiers[this.feeStructure.tiers.length - 1].max_balance) + 0.01;
    }
    this.feeStructure.tiers.push(tier);
  }


  removeTier(tier) {
    this.feeStructure.tiers = this.feeStructure.tiers.filter(t => t !== tier);
  }

  submitForm() {
    const isValid = this.feeStructureForm.valid;
    if (isValid) {
      this.save();
    }
  }

  remove(chargeUnit) {
    const name = chargeUnit.name;

    this.notification.displayConfirmationNotification(
      `You're removing fee structure: ${name}`,
      'This action cannot be undone, and all existing accounts assigned to this fee structure will be unassigned.',
      'delete'
    ).afterClosed()
      .toPromise()
      .then(result => {
        if (result) {
          this.store.dispatch(feeStructureActions.RemoveFeeStructure({feeStructure: chargeUnit}));
        }
      });
  }

  private canActivate() {
    const canActivate = this.feeStructureId && this.feeStructure || !this.editing;

    if (!canActivate) {
      this.notification.showErrorNotification('This fee structure was not found, or you do not have permissions to view it');


      return;
    }
  }

  selectStructure(e) {
    this.clearFormControl();
    this.addFromControl(e.value);
  }

  // adds form control based on the calculation type
  addFromControl(calculationType) {
    if (calculationType === 'R') {
      this.feeStructureForm.addControl('flatRate', new UntypedFormControl(this.feeStructure.flat_rate, Validators.required));
    } else if (calculationType === 'A' || calculationType === 'G') {
      this.feeStructureForm.addControl('flatAmount', new UntypedFormControl(this.feeStructure.flat_rate, Validators.required));
    }
  }

  clearFormControl() {
    if (this.feeStructureForm.contains('flatRate')) {
      this.feeStructureForm.removeControl('flatRate');
    }
    if (this.feeStructureForm.contains('flatAmount')) {
      this.feeStructureForm.removeControl('flatAmount');
    }
  }

}


