import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {WebsocketService} from './websocket.service';
import {UserProfileService} from './auth/user-profile.service';
import _keyBy from 'lodash-es/keyBy';
import {Job} from '../type_classes/job';
import * as moment from 'moment';
import {JobType, WebSocket} from '../type_classes/websocket';
import {AppConstants} from '../../app-constants';
import {BehaviorSubject, Subject} from 'rxjs';
import {NotificationService} from './ui/notification.service';
import {ActivityService} from '../../settings/integrations/atlas/activity.service';
import {ChurnZeroService} from './churn-zero.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  pdfJobEndpoint: string;
  billingJobEndpoint: string;
  backgroundJobEndpoint: string;
  billingBulkDownloadEndpoint: string;
  pdfBulkDownloadEndpoint: string;

  jobs: Job[];

  webReportLink$ = new BehaviorSubject(undefined);

  constructor(
    private http: HttpClient,
    private wsService: WebsocketService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationService,
    private activityService: ActivityService,
    private churnZeroService: ChurnZeroService,
  ) {
    this.pdfJobEndpoint = `${environment.apiV2Url}/reporting/printable-reports`;
    this.billingJobEndpoint = `${environment.apiV2Url}/billing/reports`;
    this.backgroundJobEndpoint = `${environment.apiV2Url}/job/background-job`;
    this.pdfBulkDownloadEndpoint = `${environment.apiV2Url}/reporting/printable-reports/download`;
    this.billingBulkDownloadEndpoint = `${environment.apiV2Url}/billing/invoices/download`;

    this.jobs = [];
  }

  get jobsByID() {
    return _keyBy(this.jobs, 'id');
  }

  // TODO: Create an input type
  queuePdfReportingJob(accountIds: number[], householdIds: number[], month: string, year: string, frequency: string, startDate: string,
                       endDate: string, selectedReports: any, emailNotification: boolean,
                       clientAccessible: boolean, tag: any, useIRRPerformance: boolean, useNonInceptionPerformanceChart: boolean,
                       usePortrait: boolean, exclude_portfolio_performance_summary: boolean): Promise<any> {
    const data = {
      account_ids: accountIds,
      household_ids: householdIds,
      month: parseInt(month, 10),
      year: parseInt(year, 10),
      standard_period_frequency: frequency,
      start_date: startDate,
      end_date: endDate,
      email_notification: emailNotification,
      client_accessible: clientAccessible,
      sub_reports: selectedReports,
      web_report: false,
      irr_performance: useIRRPerformance,
      non_inception_performance_chart: useNonInceptionPerformanceChart,
      portrait_pdf_orientation: usePortrait,
      exclude_portfolio_performance_summary: exclude_portfolio_performance_summary,
    };

    return this.http.post(this.pdfJobEndpoint, data).toPromise<any>().then((job: Job) => {
      this.churnZeroService.trackEvent('Printable Reports', `User generated printable reports`);
      this.addJob(job);
    });
  }

  queueWebReport(entityId: string, startDate: string, endDate: string, subReports: string[]) {
    const data = {
      entity_ids: [entityId],
      standard_period_month: null,
      standard_period_year: null,
      is_custom_period: true,
      start_date: startDate,
      end_date: endDate,
      sub_reports: subReports,
      tags: [],
      web_report: true,
      client_accessible: false,
      email_notification: false
    };

    return this.http.post(this.pdfJobEndpoint, data).toPromise<any>().then((job: Job) => {
      this.addJob(job);
    });
  }

  queueBillingJob(input) {
    const data = {
      billing_date: input.billingDate,
      create_invoices: input.createInvoices,
      period_type: input.periodType,
      email_notification: input.emailNotification,
      exact_start_date: input.exactStartDate,
      exact_end_date: input.exactEndDate,
      group_ids: input.selectedGroupIds,
      one_off_billing: input.isOneOffBilling,
      one_off_billing_type: input.oneOffBillingType,
      is_invoice_by_client: input.invoiceByClient
    };

    return this.http.post(this.billingJobEndpoint, data).toPromise<any>().then((job: Job) => {
      this.churnZeroService.trackEvent(data.one_off_billing ? 'One-Off Billing' : 'Firm Wide Billing', 'User initiated billing');

      this.addJob(job);
    });
  }

  fetchJobs(): Promise<Job[]> {
    return this.http.get(`${this.backgroundJobEndpoint}?pager.limit=50`).toPromise().then((resp: any) => {
      this.jobs = resp.data as Job[];
      this._addMoments(this.jobs);
      return this.jobs;
    });
  }

  // Callback to update job status from websocket

  initJobService() {
    this.fetchJobs();
    this.wsService.openWebSocketConnection(this.handleJobProcess);
  }

  initWebsocketForClient() {
    // This function is only called by a client user, they are not allowed to fetch jobs, but need the websocket for adding heldaways
    this.wsService.openWebSocketConnection(this.handleJobProcess);
  }

  _addMoments(jobs) {
    jobs.forEach((job) => {
        job.moment_created_at_utc = moment(job.created_at_utc);
    });
  }

  clearNonRunningJobs() {
    const url = `${this.backgroundJobEndpoint}/clear`;
    const nonRunningJobIDs = this.jobs.map((job) => {
      if (job.is_finished || job.is_failed || job.is_revoked) {
        return job.id;
      }
    });

    return this.http.post(url, {}).toPromise().then(() => {
      this.jobs = this.jobs.filter((job) => !nonRunningJobIDs.includes(job.id));
    });
  }

  startBulkDownload(downloadType: number, downloadIDs: number[]): Promise<any> {
    let url = '';

    if (downloadType === AppConstants.DOWNLOAD_TYPE.DOWNLOAD_TYPE_REPORT) {
      url = this.pdfBulkDownloadEndpoint;
    } else if (downloadType === AppConstants.DOWNLOAD_TYPE.DOWNLOAD_TYPE_INVOICE) {
      url = this.billingBulkDownloadEndpoint;
    }

    const data = {
      ids: downloadIDs
    };

    return this.http.post(url, data).toPromise().then((job: Job) => {
      this.addJob(job);
    });
  }

  getJobs() {
    // Filter only pdf and billing
    return this.jobs.filter((job) => {
      return (job.job_type === JobType.PDF_REPORT || job.job_type === JobType.BILLING_REPORT) && job.moment_created_at_utc.isSame(new Date(), 'day');
    });
  }

  private addJob(job: Job) {
    this.jobs.push(job);
    this._addMoments(this.jobs);

  }

  handleJobProcess = (msg: WebSocket): void => {
    const job = this.jobs.find((j: Job) => j.id === msg.job_id);

    if (!job) {
      return;
    }

    if (msg.total) {
      job.num_steps = msg.total;
      job.is_queued = false;
      job.is_running = true;
    }
    job.current_step = msg.total - msg.remaining;
    job.is_finished = msg.state === 'complete';

    if (job.is_finished) {
      this.handleCompletedJob(msg);
    }

    if (msg.state === 'error') {
      this.handleErrors(msg);
    }
  }

  handleErrors(msg: WebSocket): void {
    switch (msg.job_type) {
      case JobType.WEB_REPORT:
        this.notificationService.showErrorNotification('There was an error running your web report. Please try again or contact support@bridgeft.com for assistance');
        this.webReportLink$.next('');
        break;
      default:
        break;
    }
  }

  handleCompletedJob(msg: WebSocket): void {
    switch (msg.job_type) {
      case JobType.WEB_REPORT:
        this.webReportLink$.next(msg.download_link);
        break;
      case JobType.PDF_REPORT:
        if (this.userProfileService.isFirmUser()) {
          this.activityService.getMostRecentReports()
            .subscribe(() => {
              this.notificationService.showJobCompleteNotification(msg.job_type);
            });
        }
        break;
      case JobType.BILLING_REPORT:
        if (this.userProfileService.isFirmUser()) {
          this.activityService.getBillingReportActivity()
            .subscribe(resp => {
              this.notificationService.showJobCompleteNotification(msg.job_type, resp.id);
            });
        }
        break;
      case JobType.BULK_DOWNLOAD:
        if (this.userProfileService.isFirmUser()) {
          this.handleBulkDownload(msg);
        }
        break;
    }
  }

  handleBulkDownload(msg: WebSocket): void {
    msg.download_link = msg.download_link.replace('http://', 'https://');
    // @ts-ignore
    window.location = msg.download_link;
  }
}
