import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-help-popup',
  template: `<dx-popup
    [title]="header"
    [(visible)]="show"
    [shading]="true"
    shadingColor="rgba(0, 0, 0, 0.5)"
    [width]="600"
    height="auto"
    [closeOnOutsideClick]="true"
    contentTemplate="popupContent">
    <div *dxTemplate="let data of 'popupContent'">
      <div class="modal-body">
        <ng-content select="popup-body"></ng-content>
      </div>
      <dxi-toolbar-item
        toolbar="bottom"
        location="after">
        <div class="modal-footer">
          <ng-content select="popup-footer"></ng-content>
          <button class="btn btn-default" type="button" (click)="close()">Close</button>
        </div>
      </dxi-toolbar-item>
    </div>
  </dx-popup>`,
})
export class HelpPopupComponent {
  private showPopup: boolean;
  @Output() showChange = new EventEmitter<boolean>();
  @Input() header: string;
  @Input()
  get show() {
    return this.showPopup;
  }
  set show(value) {
    this.showPopup = value;
    this.showChange.emit(this.showPopup);
  }

  close() {
    this.show = false;
  }
}
