import { Injectable } from '@angular/core';
import {UserProfile} from './user-profile';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CognitoService} from './cognito.service';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  userProfile: UserProfile;
  endpoint: string;
  constructor(private http: HttpClient,
              private cognitoService: CognitoService) {
    this.endpoint = `${environment.apiV2Url}/user-management`;
  }

  fetchUserProfile(): Observable<UserProfile> {
    if (this.userProfile) {
      return of(this.userProfile);
    }

    let url = this.endpoint;
    if (this.cognitoService.authProfile.is_firm_user) {
       url = `${url}/firm-profiles/${this.cognitoService.authProfile.user_id}`;
    } else {
      url = `${url}/client-profiles/${this.cognitoService.authProfile.user_id}`;
    }

    return this.http.get<UserProfile>(url).pipe(
      tap((profile) => this.userProfile = profile)
    );
  }

  hasPermission(permissions: string[]): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.fetchUserProfile().subscribe((profile) => {
        if (profile.granted_permissions.includes('*')) {
          observer.next(true);
          observer.complete();
          return;
        }

        // Check permissions
        let hasPermission = true;
        permissions.forEach((permission) => {
          // If permission has been set to false don't set it again.
          if (hasPermission) {
            hasPermission = profile.granted_permissions.includes(permission);
          }
        });

        observer.next(hasPermission);
        observer.complete();
      });
    });
  }

  isEndClientUser = (): boolean => {
    return this.userProfile && this.userProfile.is_client_user;
  }

  isFirmUser = (): boolean => {
    return this.userProfile && this.userProfile.is_firm_user;
  }
}
