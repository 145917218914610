import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DxTextBoxComponent} from 'devextreme-angular/ui/text-box';
import {NotificationService} from '../../services/ui/notification.service';

@Component({
  selector: 'app-api-key-editor',
  templateUrl: './api-key-editor.component.html',
  styleUrls: ['./api-key-editor.component.scss']
})
export class ApiKeyEditorComponent implements OnInit {
  formGroup: UntypedFormGroup;
  formSubmitAttempt: boolean;

  @Input() enabled: boolean;
  @Input() keyString: string;
  @Input() minLength?: number;
  @Input() maxLength?: number;
  @Input() placeholder?: string;
  @Output() addKey: EventEmitter<any> = new EventEmitter();
  @Output() removeKey: EventEmitter<any> = new EventEmitter();
  @Output() toggleEnabled: EventEmitter<any> = new EventEmitter();

  @ViewChild('apiKeyInput') apiKeyInput: DxTextBoxComponent | any;
  constructor(
    private notification: NotificationService,
  ) {}

  get keyStringControl(): AbstractControl {
    return this.formGroup.get('apiKey');
  }

  get enabledControl(): AbstractControl {
    return this.formGroup.get('enabled');
  }

  get hasKey() {
    return !!this.enabled && !!this.keyString;
  }

  ngOnInit() {
    const minLength = this.minLength || 0;
    const maxLength = this.maxLength || 500;
    this.formGroup = new UntypedFormGroup({
      apiKey: new UntypedFormControl(
        this.keyString,
        Validators.compose([Validators.required, Validators.maxLength(maxLength), Validators.minLength(minLength)])
      ),
      enabled: new UntypedFormControl(this.enabled),
    });
  }

  onAddKey() {
    this.formSubmitAttempt = true;
    if (this.formGroup.status === 'INVALID') {
      return;
    }

    this.addKey.emit(this.keyStringControl.value);
  }

  copyToClipboard() {
    this.apiKeyInput.element.nativeElement.querySelector('input').select();
    document.execCommand('copy');
    this.notification.showSuccessNotification('The API key was copied to the clipboard');
  }

  removeWealthboxApiKey() {
    this.formSubmitAttempt = false;
    this.keyStringControl.setValue('');
    this.removeKey.emit();
  }

  onToggleEnabled() {
    if (this.hasKey) {
      this.formSubmitAttempt = true;
    }
    this.toggleEnabled.emit(this.enabledControl.value);
  }
}
