import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromFirms from './firm.reducer';

export interface State  {
  products: fromFirms.FirmState;
}

const getFirmFeatureState = createFeatureSelector<fromFirms.FirmState>('firms');

export const getFirms = createSelector(
  getFirmFeatureState,
  fromFirms.selectAllFirms
);

export const getLoading = createSelector(
  getFirmFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getFirmFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getFirmFeatureState,
  (state) => state.callState.error
);

export const getUpdateLoading = createSelector(
  getFirmFeatureState,
  state => state.updateCallState.loading
);
