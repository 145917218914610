import { Injectable } from '@angular/core';
import _get from 'lodash-es/get';
import {FirmService} from '../firm/firm.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ClientPortalAccessService {

  constructor(
    private firmService: FirmService,
    private router: Router
  ) { }

  canAccessWebReports() {
    return _get(this.firmService.firm, 'cp_web_reports_enabled');
  }

  canAccessPrintableReports() {
    return _get(this.firmService.firm, 'cp_printable_reports_enabled');
  }

  canAccessInvoices() {
    return _get(this.firmService.firm, 'cp_invoices_enabled');
  }

  canAccessSharedFiles() {
    return _get(this.firmService.firm, 'cp_shared_files_enabled');
  }

  canAccessHeldaways() {
    return _get(this.firmService.firm, 'cp_heldaways_enabled');
  }

  anyAccess() {
    return _get(this.firmService.firm, 'cp_enabled');
  }

  reroute() {
    this.router.navigateByUrl('/cp/main');
  }
}

