import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

// This directive is used for ux on the sidebar. When tabs are nested, the top <li> element will need to stay active
// Also, this adds animation to closing accordian with sub-routes
@Directive({
  selector: '[appRouteActive]'
})
export class RouteActiveDirective implements AfterViewInit {

  @Input() subRoutes: string[];
  @Input() activeClass: string;
  @Input() collapsible: boolean;

  constructor(private element: ElementRef,
              private router: Router) {}

  ngAfterViewInit() {
    this.activeClass = this.activeClass || 'active';
    this.update();

    // After a successful route change
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  update() {
    const isActive = this.subRoutes.some(subRoute => window.location.pathname.includes(subRoute));

    if (isActive) {
      this.element.nativeElement.classList.add(this.activeClass);
    } else {
      this.element.nativeElement.classList.remove(this.activeClass);
    }

    // if (this.collapsible) {
    //   if (!isActive) {
    //     $(this.element.nativeElement.getElementsByTagName('ul')).slideUp(380, 'swing');
    //   } else {
    //     $(this.element.nativeElement.getElementsByTagName('ul')).slideDown(380, 'swing');
    //   }
    // }

  }

}
