import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullMonthName'
})
export class FullMonthNamePipe implements PipeTransform {

  transform(input: any, args?: any): any {

    if (input === 1) {
      return 'January';
    } else if (input === 2) {
      return 'February';
    } else if (input === 3) {
      return 'March';
    } else if (input === 4) {
      return 'April';
    } else if (input === 5) {
      return 'May';
    } else if (input === 6) {
      return 'June';
    } else if (input === 7) {
      return 'July';
    } else if (input === 8) {
      return 'August';
    } else if (input === 9) {
      return 'September';
    } else if (input === 10) {
      return 'October';
    } else if (input === 11) {
      return 'November';
    } else if (input === 12) {
      return 'December';
    }

    return '';
  }

}
