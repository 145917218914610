import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer} from './security-exclusions.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SecurityExclusionsEffects} from './security-exclusions.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('securityExclusions', reducer),
    EffectsModule.forFeature(
      [SecurityExclusionsEffects]
    ),
  ]
})
export class SecurityExclusionsStoreModule {}
