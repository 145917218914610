import { Pipe, PipeTransform } from '@angular/core';
import _startCase from 'lodash-es/startCase';

@Pipe({
  name: 'displaySplitterSlug'
})
export class DisplaySplitterSlugPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    return _startCase(input.split('-'));
  }

}
