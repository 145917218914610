import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-permissions',
  template: `
      <div style="height:50px;"></div>


      <div class="row">
          <div class="col-md-12 p-l-30 p-r-30">
              <div class="card">
                  <div class="title">
                      <h2>
                          No Permissions
                      </h2>    
                  </div>
                  
                  <div class="content">
                      <p class="text-center">
                          Your account doesn't have any permissions associated with it.
                      </p>

                      <p class="text-center">
                          Please contact your system administrator or support@bridgeft.com for support.
                      </p>

                      <br/>
                      <br/>
                      <br/>

                  </div>
              </div>
          </div>
      </div>
  `,
})
export class NoPermissionsComponent{
  constructor() { }
}
