import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import _mapKeys from 'lodash-es/mapKeys';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {AccountService} from '../accounts/account.service';
import * as moment from 'moment';
import {select, Store} from '@ngrx/store';
import {getLoaded, getLoading, State} from './state';
import {getBalances} from './state';
import {Load} from './state/balances.actions';
import {v4 as uuid} from 'uuid';
import {FirmWideReportingService} from '../dashboard/firm-wide-reporting/firm-wide-reporting.service';
import _cloneDeep from 'lodash-es/cloneDeep';

@Injectable({
  providedIn: 'root'
})
export class CurrentBalanceService {
  endpoint: string;

  state: any;

  currentBalances: any;
  currentBalanceByAccountID: any;
  currentBalanceByHouseholdID: any;

  manualBalancesByHouseholdID = {};

  balancesLoaded$ = this.store.pipe(select(getLoaded));

  balances$ = combineLatest([
    this.store.pipe(select(getLoaded)),
    this.store.pipe(select(getLoading))
  ]).pipe(
    tap(([loaded, loading]) => {
      if (!loaded && !loading) {
        this.store.dispatch(Load());
      }
    }),
    filter(([loaded, loading]) => {
      return loaded && !loading;
    }),
    switchMap(([loaded, loading]) => {
      return this.store.pipe(select(getBalances));
    }
  )
  );

  loadedBalances$ = this.balancesLoaded$.pipe(filter(loaded => loaded), switchMap(loaded => this.balances$));

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private store: Store<State>,
    private firmWideReportService: FirmWideReportingService // temporarily use to fetch DST and Manual Balances
  ) {
    this.endpoint = `${environment.apiV2Url}/data/source`;

    this.currentBalanceByAccountID = {};
    this.currentBalanceByHouseholdID = {};
  }

  // Last day available in the balance table (last time data was ingested)
  get lastDate$(): Observable<string> {
    return this.balances$.pipe(
        map(balances => {
          const dates = balances.map(b => moment(b.reported_date).utc());

          return moment.max(dates).utc().format('YYYY-MM-DD');
        })
      );
  }

  // TODO: This will be refactored after manual accounts have moved off of the luca endpoints
  getBalances(): Observable<any> {
    const accountsUrl = `${this.endpoint}/account-balances/current`;
    const householdsUrl = `${this.endpoint}/household-balances/current`;

    return combineLatest([
      this.http.get<any>(accountsUrl),
      this.http.get<any>(householdsUrl)
    ]).pipe(
      switchMap(([accountBalances, householdBalances]) => {
        const dates = accountBalances.map(b => moment(b.reported_date).utc());
        const asOfDate = moment.max(dates).utc().format('YYYY-MM-DD');

        return this.firmWideReportService.fetchLucaAccountBalancesReport(asOfDate, asOfDate).pipe(
          map((manualBalances) => {

            return [accountBalances, householdBalances, manualBalances]
          })
        )
      }),
      map(([accountBalances, householdBalances, manualBalances]) => {
        this.currentBalances = [];

        manualBalances.forEach((balance) => {
          balance.id = uuid();
          balance.reported_date = balance.as_of_date;
          balance.total_value = balance.cash_value + balance.security_holdings_value;

          this.currentBalances.push(balance);
        });

        accountBalances.forEach(balance => {
          this.currentBalances.push(balance);
        });

        householdBalances.forEach(balance => {
          balance.id = uuid(); // needed for ngrx selectId compatibility
          this.currentBalances.push(balance)
        })

        this.setupData(accountBalances, householdBalances, manualBalances);

        return this.currentBalances;
      }),
    );
  }

  setupData(accountBalances, householdBalances, manualBalances) {
    accountBalances.forEach((balance) => {
      if (balance.cash_value_reported) {
        balance.cash_value = balance.cash_value_reported;
      }

      if (balance.securities_value_reported) {
        balance.securities_value = balance.securities_value_reported;
      }

      if (balance.total_value_reported) {
        balance.total_value = balance.total_value_reported;
      }

      this.currentBalanceByAccountID[balance.account_id] = balance;
    });

    Object.assign(this.currentBalanceByHouseholdID, _mapKeys(householdBalances, 'household_id'));

    manualBalances.forEach((balance) => {
      if (balance.household_id) {
        if (this.currentBalanceByHouseholdID[balance.household_id]) {
          this.currentBalanceByHouseholdID[balance.household_id].cash_value += balance.cash_value;
          this.currentBalanceByHouseholdID[balance.household_id].securities_value += balance.securities_value;
          this.currentBalanceByHouseholdID[balance.household_id].total_value += balance.total_value;
        } else {
          this.currentBalanceByHouseholdID[balance.household_id] = _cloneDeep(balance);
        }
      }
      this.currentBalanceByAccountID[balance.account_id] = balance;
    });
  }

  getTotalAUMByAccount() {
    let sum = 0;

    this.currentBalances.forEach((balance) => {
      if (balance.account_id) {
        sum += balance.total_value
      }
    });

    return sum;
  }
}
