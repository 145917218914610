import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productboard',
  templateUrl: './productboard.component.html',
  styleUrls: ['./productboard.component.scss']
})
export class ProductboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
