import {Component, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'atlas-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements OnInit {
  @Input() textAlign: string;

  constructor(private renderer: Renderer2) {
    this.textAlign = 'left';
  }

  ngOnInit() { }

}
