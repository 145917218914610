<div class="content-wrap">

  <button mat-button routerLink="/settings/reporting" style="margin-bottom: 10px"><mat-icon>arrow_back</mat-icon> Back to Reporting Setup</button>

  <mat-card>
    <div class="card-header">
      <div class="card-header__title">
        <h2>Manage Benchmarks</h2>
<!--        <p>Benchmarks define a standard against which account performance is compared</p>-->
      </div>

      <div class="card-options">
        <div class="search-bar">
          <i matPrefix class="fas fa-search searchbar-icon"></i>
          <input placeholder="Search" (input)="benchmarkList.instance.searchByText($event.target.value)">
        </div>

        <button mat-stroked-button color="primary" (click)="createBenchmark()">
          <i class="fas fa-plus prefix-icon"></i>
          Create
        </button>

        <button class="btn delete-btn no-margin-right" (click)="deleteBenchmarks()">
          <i class="fas fa-trash prefix-icon"></i>
          Delete
        </button>

<!--        <button mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu">-->
<!--          Actions-->
<!--          <span><i class="fas fa-caret-down"></i></span>-->
<!--        </button>-->
<!--        <mat-menu #actionMenu="matMenu">-->
<!--          <button mat-menu-item (click)="deleteBenchmarks()">Delete Selected Benchmarks</button>-->
<!--        </mat-menu>-->
      </div>
    </div>

    <mat-card-content>
      <dx-data-grid #benchmarkList
                    appFillHeight
                    [dataSource]="benchmarks"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [(selectedRowKeys)]="selectedBenchmarks"
                    columnResizingMode="widget"
      >
        <dxi-column caption="Name"
                    dataField="name"
                    cellTemplate="benchmarkLink"
        >
        </dxi-column>

        <dxi-column caption="No. Components"
                    cellTemplate="componentTemplate"
        >
        </dxi-column>

        <div *dxTemplate="let row of 'benchmarkLink'">
          <button mat-button color="primary" class="mat-button-xs" (click)="editBenchmark(row.data)">{{row.data.name}}</button>
        </div>

        <div *dxTemplate="let row of 'componentTemplate'">
          {{row.data.coefficients.length}}
        </div>

        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-pager [visible]="false"></dxo-pager>
        <dxo-paging [enabled]="false"></dxo-paging>
      </dx-data-grid>
    </mat-card-content>
  </mat-card>
</div>
