import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayMinimumValuetype'
})
export class DisplayMinimumValuetypePipe implements PipeTransform {

  transform(input: any, args?: any): any {
    if (input === 'F') {
      return 'Flat dollar amount';
    } else if (input === 'P') {
      return 'Percentage of assets';
    }

    return '';
  }

}
