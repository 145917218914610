import { Component, OnInit } from '@angular/core';
import {Invoice} from '../invoice';
import {InvoiceService} from '../invoice.service';

@Component({
  selector: 'app-client-invoice-list',
  templateUrl: './client-invoice-list.component.html',
  styleUrls: ['./client-invoice-list.component.scss']
})
export class ClientInvoiceListComponent implements OnInit {

  loading = false;
  invoices: Invoice[];

  constructor(private invoiceService: InvoiceService) { }

  ngOnInit() {
    this.loading = true;

    this.invoiceService.getInvoices().then((invoices) => {
      this.invoices = invoices;
    }).finally(() => {
      this.loading = false;
    });
  }


  downloadPDFInvoice(invoice: Invoice) {
    this.invoiceService.downloadPDFInvoice(invoice);
  }
}
