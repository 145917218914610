import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayRiskCategory'
})
export class DisplayRiskCategoryPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === 'CP') {
      return 'Capital Preservation';
    } else if (value === 'CO') {
      return 'Conservative';
    } else if (value === 'MC') {
      return 'Moderate Conservative';
    } else if (value === 'MO') {
      return 'Moderate';
    } else if (value === 'MG') {
      return 'Moderate Growth';
    } else if (value === 'GR') {
      return 'Growth';
    } else if (value === 'AG') {
      return 'Aggressive Growth';
    } else if (value === 'B') {
      return 'Balanced Risk';
    } else if (value === 'BG') {
      return 'Balanced Growth';
    } else if (value === 'A') {
      return 'Aggressive Risk';
    } else if (value === 'DG') {
      return 'Dividend Growth';
    } else if (value === 'D') {
      return 'Diversifier Risk';
    } else if (value === 'HG') {
      return 'Hedged Growth';
    } else if (value === 'N') {
      return 'None';
    }
  }

}
