<div>
  <h2 mat-dialog-title>
    {{ title }}
  </h2>

  <div class="dialog-content">

    <div style="display: flex; justify-content:center">
      <button mat-stroked-button
              color="primary"
              style="justify-content:center; width: 180px"
              (click)="exportTemplateFile()">
        <i class="fas fa-file-excel prefix-icon"></i>
        Download Template
      </button>
    </div>

    <br>

    <h4> Upload File </h4>


    <mat-form-field>

      <mat-toolbar>

        <!-- Readonly Input to show File names -->
        <input matInput style="font-size: 16px" [(ngModel)]="fileName" readonly />

        <!-- Browse Button -->

        <div class="parent" style="position: relative;">
          <button mat-flat-button
                  color="primary"
                  style="display: flex; justify-content: center; width: 70px">
            Browse
          </button>
          <div class="child">
            <input type="file"
                   id="fileUpload"
                   (change)="uploadFile($event)"
                   (click)="$event.target.value=null"
                   name="fileUpload"/>
          </div>
        </div>

      </mat-toolbar>

    </mat-form-field>



    <div class="actions-container">
      <button mat-button
              color="primary"
              [mat-dialog-close]="false">
        Cancel
      </button>

      <app-atlas-submit-button [disabled]="disable"
                               [onClick]="onClick">
        Next
      </app-atlas-submit-button>
    </div>

  </div>
</div>
