import { Injectable } from '@angular/core';
import { FirmService } from '../firm/firm.service';
import { AccountService } from '../accounts/account.service';
import { UtilsService } from '../shared/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import _keyBy from 'lodash-es/keyBy';
import {BillingGroup, BillingGroupAssignment, Group} from './billing';
import {ChurnZeroService} from '../shared/services/churn-zero.service';

@Injectable({
  providedIn: 'root'
})
export class BillingGroupsService {
  constructor(
    private firmService: FirmService,
    private accountService: AccountService,
    private util: UtilsService,
    private http: HttpClient,
    private utilService: UtilsService,
    private churnZeroService: ChurnZeroService
  ) {
    this.endpoint = `${environment.apiV2Url}/billing/groups`;
  }

  accountGroups: Group[] = [];
  /**
   * Billing Groups keyed by their account IDs
   */
  accountGroupByAccountID: any = {};
  /**
   * Each group by its associated household ID, which is optional (but recommended and typically used)
   */
  accountGroupByHouseholdID: any = {};
  accountGroupsByID: any = {};
  endpoint: string;

  public constructAssignment(accountID, groupID) {
    accountID = accountID || null;
    groupID = groupID || null;

    return new BillingGroupAssignment(accountID, groupID);
  }

  /**
   * Populates the accountGroupByAccountID object
   */
  private _populateAccountGroupsByAccountID() {
    this.util.clearObject(this.accountGroupByAccountID);
    this.util.clearObject(this.accountGroupByHouseholdID);

    this.accountGroups.forEach((group) => {
      if (group.assignments) {
        group.assignments.forEach((item) => {
          const acctID = item.account_id;
          this.accountGroupByAccountID[acctID] = group;
        });
      }
      if (this.util.isDefinedAndNotNull(group.household_id)) {
        this.accountGroupByHouseholdID[group.household_id] = group;
      }
    });
  }

  getAccountGroups(): Promise<BillingGroup[]> {
    if (this.accountGroups.length > 0) {
      return Promise.resolve(this.accountGroups);
    }

    return this.utilService.stepByStepLoading(this.endpoint, 5000, ).then((resp: any) => {
      const groups = resp;

      this.accountGroups = groups;
      this.accountGroupsByID = _keyBy(groups, 'id');

      this._populateAccountGroupsByAccountID();

      return this.accountGroups;
    });
  }

  constructGroup() {
    return new BillingGroup(this.firmService.firm.id);
  }

  saveAccountGroup(group) {
    const url = group.id ? `${this.endpoint}/${group.id}` : this.endpoint;
    const method = group.id ? 'put' : 'post';

    if (method === 'put') {
      this.churnZeroService.trackEvent('Billing Groups', `User created a new billing group`);
    }

    return this.http[method](url, group).toPromise().then((groupObj: any) => {
      if (group.id) {
        this.accountGroups = this.accountGroups.map((a) => groupObj.id === a.id ? groupObj : a);
      } else {
        this.accountGroups.push(groupObj);
      }

      this.syncAssignments(group, groupObj);

      this.accountGroupsByID[groupObj.id] = groupObj;

      this._populateAccountGroupsByAccountID();

      return groupObj;
    });
  }

  deleteGroup(group) {
    const url = `${this.endpoint}${group.id}`;

    return this.http.delete(url).toPromise().then((resp: any) => {
      this.util.removeItem(this.accountGroups, group);
      this._populateAccountGroupsByAccountID();
    });
  }

  deleteGroups(groups) {
    const url = this.endpoint;

    const ids = groups.map(g => g.id);

    const data = {
      ids
    };

    const options = {
      body: data
    };

    // Body on delete request to work with ALB API
    // @ts-ignore
    return this.http.delete(url, options).toPromise().then(() => {
      groups.forEach((grp) => {
        this.util.removeItem(this.accountGroups, grp);
        this._populateAccountGroupsByAccountID();
      });
    });
  }


  /**
   * Remove the given account from the given group
   */
  deleteAssignment(assignment: any) {
    const url = `${this.endpoint}/remove-assignment`;

    const data = {
      group_id: assignment.group_id,
      id: assignment.id
    };

    // TODO: update the group from the returned instance

    // @ts-ignore
    return this.http.post(url, data).toPromise<any>().then(() => {
      this.accountGroups.forEach((accountGroup) => {
        if (accountGroup.id === assignment.group_id) {
          accountGroup.assignments = accountGroup.assignments.filter((a) => a.id !== assignment.id);
        }
      });
      this._populateAccountGroupsByAccountID();
    });
  }


  /**
   * Re-creates all Billing Groups from households
   */
  createGroupsFromHouseholds() {
    const url = `${this.endpoint}/create-from-households`;

    return this.http.post(url, {}).toPromise().then((resp: any) => {
      this.churnZeroService.trackEvent('Billing Groups', `User created billing groups from households`);
      // clear out all existing data; this method will delete/replace on the server
      this.util.clearArray(this.accountGroups);
      this.util.clearObject(this.accountGroupsByID);
      this.util.clearObject(this.accountGroupByAccountID);

      Object.assign(this.accountGroups, resp);
      this.accountGroupsByID = _keyBy(resp, 'id');

      this._populateAccountGroupsByAccountID();

      return this.accountService.initialize()
        .toPromise()
        .then(() => this.accountGroups);
    });
  }

  private syncAssignments(oldGroupAssignmentData, newGroupObject) {
    // Removes old group assignment
    // New Group
    if (!oldGroupAssignmentData.id) {
      oldGroupAssignmentData.assignments.forEach((assignment) => {
        if (assignment.group_id && assignment.group_id !== newGroupObject.group_id) {
          this.accountGroups.forEach((group) => {
            if (group.id === assignment.group_id) {
              group.assignments = group.assignments.filter((a) => a.account_id !== assignment.account_id);
            }
          });
        }
      });
    } else { // Group that already exists
      oldGroupAssignmentData.assignments.forEach((assignment) => {
        if (assignment.group_id !== oldGroupAssignmentData.id) {
          this.accountGroups.forEach((group) => {
            if (group.id === assignment.group_id) {
              group.assignments = group.assignments.filter((a) => a.account_id !== assignment.account_id);
            }
          });
        }
      });
    }
  }
}
