import {createAction, props} from '@ngrx/store';
import {Client} from '../clients.types';

export const LoadClients = createAction('[Clients] Load clients');
export const LoadClientsSuccess = createAction('[Clients] Load clients success', props<{clients: Client[]}>());
export const LoadClientsFail = createAction('[Clients] Load clients fail', props<{error: any}>());

export const CreateClient = createAction('[Clients] Create clients', props<{client: Client}>());
export const CreateClientSuccess = createAction('[Clients] Create clients success', props<{client: Client}>());
export const CreateClientFail = createAction('[Clients] Create clients fail', props<{error: any}>());

export const UpdateClient = createAction('[Clients] Update client', props<{client: Client}>());
export const UpdateClientSuccess = createAction('[Clients] Update client success', props<{client: Client}>());
export const UpdateClientFail = createAction('[Clients] Update client fail', props<{error: any}>());

export const UpdateClients = createAction('[Clients] Update clients', props<{clients: Client[]}>());
export const UpdateClientsSuccess = createAction('[Clients] Update clients success', props<{clients: Client[]}>());
export const UpdateClientsFail = createAction('[Clients] Update clients fail', props<{error: any}>());

export const RemoveClient = createAction('[Clients] Remove client', props<{client: Client}>());
export const RemoveClientSuccess = createAction('[Clients] Remove client success', props<{client: Client}>());
export const RemoveClientFail = createAction('[Clients] Remove client fail', props<{error: any}>());

export const RemoveClients = createAction('[Clients] Remove clients', props<{clients: Client[]}>());
export const RemoveClientsSuccess = createAction('[Clients] Remove clients success', props<{clients: Client[]}>());
export const RemoveClientsFail = createAction('[Clients] Remove clients fail', props<{error: any}>());
