<form [formGroup]="formGroup" (ngSubmit)="onAddKey()">
  <div class="form-group">
    <div class="row">
      <div class="col s8">

        <div class="api-key-container">
          <div class="input-container" *ngIf="enabledControl.value">
            <mat-form-field appearance="outline" style="width: 100%;">
              <input matInput
                     placeholder="{{ placeholder || 'API key' }}"
                     formControlName="apiKey"
                     [readonly]="hasKey"
                     class="fs-block">
              <mat-error *ngIf="keyStringControl.invalid || !formSubmitAttempt" style="font-size: 13px;">API key is invalid.</mat-error>
            </mat-form-field>

            <div class="action-btns">
              <button type="submit"
                      mat-stroked-button color="primary"
                      *ngIf="!hasKey">
                <i class="fas fa-plus prefix-icon"></i>
                Add
              </button>

              <button mat-stroked-button color="primary"
                      *ngIf="hasKey"
                      (click)="copyToClipboard()">
                <i class="fas fa-copy prefix-icon"></i>
                Copy
              </button>

              <button class="btn delete-btn"
                      *ngIf="hasKey"
                      (click)="removeWealthboxApiKey()">
                <i class="fas fa-trash prefix-icon"></i>
                Delete
              </button>
            </div>
          </div>

          <div class="checkbox-container">
            <mat-checkbox formControlName="enabled"
                          (ngModelChange)="onToggleEnabled()">
              Enabled
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
