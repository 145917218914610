import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {WealthboxToolbarService} from '../wealthbox-toolbar/wealthbox-toolbar.service';
import {WealthboxContact} from '../type-classes/wealthbox-contact';
import {SidePanelService} from '../../../../layout/side-panel/side-panel.service';

@Component({
  selector: 'app-wealthbox-contact-detail-view',
  templateUrl: './wealthbox-contact-detail-view.component.html',
  styleUrls: ['./wealthbox-contact-detail-view.component.scss']
})
export class WealthboxContactDetailViewComponent implements OnInit {

  @Input() contact: WealthboxContact;

  activityDatasource: DataSource;

  constructor(private wealthboxToolbarService: WealthboxToolbarService,
              public sidePanelState: SidePanelService) {}

  ngOnInit() {
    this.activityDatasource = this.wealthboxToolbarService.getActivityDataSource(this.contact.id);
  }

  get phoneNumber(): string {
    let phone = null;

    this.contact.phone_numbers.forEach((phoneObject) => {
      if (phoneObject.principal) {
        phone = phoneObject.address;
      }
    });

    // Default to the first number
    if (!phone && this.contact.phone_numbers[0]) {
      phone = this.contact.phone_numbers[0].address;
    }

    if (!phone) {
      return 'n/a';
    }

    return phone;
  }

  get email(): string {
    let address = null;

    this.contact.email_addresses.forEach(email => {
      if (email.principal) {
        address = email.address;
      }
    });

    if (!address && this.contact.email_addresses[0]) {
      address = this.contact.email_addresses[0].address;
    }

    if (!address) {
      address = 'n/a';
    }

    return address;
  }
}
