<!--<mat-toolbar>-->
<!--  <mat-toolbar-row>-->
<!--    <span>Wealthbox</span>-->
<!--    <span style="display: flex; flex: 1;"></span>-->
<!--    <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>-->
<!--  </mat-toolbar-row>-->
<!--</mat-toolbar>-->


<div *ngIf="(sidePanelState.currentMode$ | async) === 'tabs'">
  <mat-tab-group class="wealthbox-mat-tab-group" (selectedIndexChange)="changeTabsState($event)" [selectedIndex]="tabs[sidePanelState.currentTab$ | async]">
    <mat-tab class="mat-tab-label" label="Activity">
      <ng-template matTabContent>
        <dx-list
          class="toolbar-dx-full-list dx-disable-selection"
          [dataSource]="activityDatasource"
          [activeStateEnabled]="false"
          [focusStateEnabled]="false"
          [hoverStateEnabled]="false"
          selectionMode="none"
          [searchEnabled]="false">
          >
          <div *dxTemplate="let data of 'item'">
            <app-atlas-activity-card>
              <app-atlas-activity-card-header>
                <img mat-card-avatar [src]="data.icon" style="height: calc(100% - 2px); width: auto">
              </app-atlas-activity-card-header>
              <app-atlas-activity-card-content *ngIf="data.body">

                <div [innerHTML]="data.header"></div>
                <app-atlas-activity-card-details>
                  <div [innerHTML]="data.body"></div>
                </app-atlas-activity-card-details>
              </app-atlas-activity-card-content>
            </app-atlas-activity-card>
          </div>
        </dx-list>
      </ng-template>
    </mat-tab>


    <mat-tab class="mat-tab-label" label="Notes">
      <ng-template matTabContent>
        <app-wealthbox-notes-list></app-wealthbox-notes-list>
      </ng-template>
    </mat-tab>

    <mat-tab class="mat-tab-label" label="Contacts">
      <ng-template matTabContent>
        <dx-list
          class="toolbar-dx-full-list dx-disable-selection"
          [dataSource]="contactsDxListDataSource"
          selectionMode="none"
          [activeStateEnabled]="false"
          [focusStateEnabled]="false"
          [hoverStateEnabled]="false"
          [searchEnabled]="true"
          searchTimeout="1000"
          (onDisposing)="resetContactsListDxStorage()">
          <div *dxTemplate="let data of 'item'">
            <app-wealthbox-contact-card [contact]="data"></app-wealthbox-contact-card>
          </div>
        </dx-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="(sidePanelState.currentTab$ | async) === 'contactDetails'">
  <app-wealthbox-contact-detail-view id="wealthboxContactDetails" *ngIf="activeContact$ | async as activeContact else loading" [contact]="activeContact"></app-wealthbox-contact-detail-view>
</div>

<ng-template #loading>
  <div class="text-center pt-40">
    <dx-load-indicator height="40" width="40"></dx-load-indicator>
  </div>
</ng-template>
