import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-atlas-multi-date-picker',
  templateUrl: './atlas-multi-date-picker.component.html',
  styleUrls: ['./atlas-multi-date-picker.component.css']
})
export class AtlasMultiDatePickerComponent implements OnInit {

  @Input() placeholder: string;

  selectedStartDate: string;
  selectedEndDate: string;

  @Output() startDateChange = new EventEmitter<string>();
  @Output() endDateChange = new EventEmitter<string>();

  @Input()
  get startDate() {
    return this.selectedStartDate;
  }

  set startDate(date: string) {
    this.selectedStartDate = date;
    this.startDateChange.emit(this.selectedStartDate);
  }

  @Input()
  get endDate() {
    return this.selectedEndDate;
  }

  set endDate(date: string) {
    this.selectedEndDate = date;
    this.endDateChange.emit(this.selectedEndDate);
  }

  constructor() { }

  ngOnInit(): void {
  }

  emitStartDate(event) {
    const momentDate = event.value;
    this.selectedStartDate = momentDate.format('YYYY-MM-DD');
    this.startDateChange.emit(this.selectedStartDate);
  }

  emitEndDate(event) {
    const momentDate = event.value;
    if (momentDate) {
      this.selectedEndDate = momentDate.format('YYYY-MM-DD');
      this.endDateChange.emit(this.selectedEndDate);
    } else {
      this.selectedEndDate = null;
      this.endDateChange.emit(this.selectedEndDate);
    }

  }

}
