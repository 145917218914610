import {AlertSubscriptionState, selectAllAlertSubscriptions} from './alert-subscriptions.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  products: AlertSubscriptionState;
}

const getAlertSubscriptionFeatureState = createFeatureSelector<AlertSubscriptionState>('alertSubscriptions');

export const getAlertSubscriptions = createSelector(
  getAlertSubscriptionFeatureState,
  selectAllAlertSubscriptions
);

export const getLoading = createSelector(
  getAlertSubscriptionFeatureState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getAlertSubscriptionFeatureState,
  (state) => state.callState.loaded
);

export const getError = createSelector(
  getAlertSubscriptionFeatureState,
  (state) => state.callState.error
);
