import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WealthboxContactDetailsComponent } from './wealthbox-contact-details/wealthbox-contact-details.component';
import { WealthboxContactsMappingComponent } from './wealthbox-contacts-mapping/wealthbox-contacts-mapping.component';
import {SharedModule} from '../../../shared/shared.module';
import { WealthboxIntegrationConfigResolver} from './wealthbox.resolve';
import {WealthboxSettingsComponent} from './wealthbox-settings/wealthbox-settings.component';
import { WealthboxToolbarComponent } from './wealthbox-toolbar/wealthbox-toolbar.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import { WealthboxNoteComponent } from './wealthbox-note/wealthbox-note.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { WealthboxNotesListComponent } from './wealthbox-notes-list/wealthbox-notes-list.component';
import { WealthboxContactCardComponent } from './wealthbox-contact-card/wealthbox-contact-card.component';
import { WealthboxContactDetailViewComponent } from './wealthbox-contact-detail-view/wealthbox-contact-detail-view.component';

@NgModule({
  declarations: [
    WealthboxContactDetailsComponent,
    WealthboxContactsMappingComponent,
    WealthboxSettingsComponent,
    WealthboxToolbarComponent,
    WealthboxNoteComponent,
    WealthboxNotesListComponent,
    WealthboxContactCardComponent,
    WealthboxContactDetailViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EditorModule,
    CKEditorModule
  ],
  exports: [
    WealthboxContactDetailsComponent,
    WealthboxToolbarComponent
  ],
  providers: [
    WealthboxIntegrationConfigResolver
  ]
})
export class WealthboxModule { }
