import {Injectable} from '@angular/core';
import {Account} from '../account';
import {AccountService} from '../account.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import * as accountActions from './account.actions';
import * as fromAccount from '../state';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import * as householdActions from "../../households/state/household.actions";
import {Household} from "../../households/household";
import {ManualHoldingsService} from "../../manual-holdings/manual-holdings.service";

@Injectable()
export class AccountEffects {
  constructor(
    private accountService: AccountService,
    private actions$: Actions,
    private store: Store<fromAccount.State>,
    private manualHoldings: ManualHoldingsService
  ) {}


  loadAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.Load),
    withLatestFrom(this.store.pipe(select(fromAccount.getAccounts))),
    mergeMap(() => {
      return this.accountService.getAccounts().pipe(
        map(accounts => (accountActions.LoadSuccess({accounts: accounts}))),
        catchError(error => of(accountActions.LoadFail({error})))
      );
    })
  ));


  updateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.UpdateAccount),
    map(action => action.account),
    mergeMap((account: Account) => {
      return this.accountService.saveAccount(account).pipe(
        map((updatedAccount: Account) => accountActions.UpdateAccountSuccess({account: updatedAccount})),
        catchError((err) => {
          return of(accountActions.UpdateAccountFail({error: err.error}));
        })
      );
    })
  ));


  updateAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.UpdateAccounts),
    map(action => action.accounts),
    mergeMap((accounts: Account[]) => {
      return this.accountService.saveAccounts(accounts)
        .pipe(
          map((updatedAccounts: Account[]) => accountActions.UpdateAccountsSuccess({accounts: updatedAccounts})),
          catchError((err) => {
          return of(accountActions.UpdateAccountsFail({error: err.error}));
        })
      );
    })
  ));


  remapAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.RemapAccounts),
    mergeMap(resp => {
      return this.accountService.remapAccountsToHousehold(resp.accounts, resp.household)
        .pipe(
          map(() => {
            return accountActions.RemapAccountsSuccess({accounts: resp.accounts, household: resp.household});
          }),
          catchError(err => {
            return of(accountActions.RemapAccountsFail({error: err.error}));
          })
        );
    })
  ));

  removeManualAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.RemoveManualAccounts),
    map(action => action.accounts),
    mergeMap((accounts: Account[]) => {
      return this.manualHoldings.deleteManualHoldings(accounts)
        .pipe(
          map(() => (accountActions.RemoveManualAccountsSuccess({accounts}))),
          catchError((err: any) => {
            return of(accountActions.RemoveManualAccountsFail({error: err.error}));
          })
        );
    })
  ));
}
