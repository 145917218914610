import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromSecurities from './securities.reducer';

const getSecuritiesState = createFeatureSelector<fromSecurities.SecuritiesState>('securities');

export const getSecurities = createSelector(
  getSecuritiesState,
  fromSecurities.selectAllSecurities
);

const getSecuritiesDictionary = createSelector(
  getSecuritiesState,
  fromSecurities.selectSecurities
);

export const getSecuritiesByIds = createSelector(
  getSecuritiesDictionary,
  (state, props) => {
    return props.securityIds.map(id => state[id]).filter(s => !!s);
  }
);

export const getLoading = createSelector(
  getSecuritiesState,
  (state) => state.callState.loading
);


export const getLoaded = createSelector(
  getSecuritiesState,
  (state) => state.callState.loaded
);

export const getFetchByIdLoading = createSelector(
  getSecuritiesState,
  (state) => state.fetchByIdCallState.loading
);

export const getManagedIds = createSelector(
  getSecuritiesState,
  state => state.managedIds
);

export const getManagedLoading = createSelector(
  getSecuritiesState,
  (state) => state.fetchManagedCallState.loading
);


export const getManagedLoaded = createSelector(
  getSecuritiesState,
  (state) => state.fetchManagedCallState.loaded
);
