import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as balanceActions from './balances.actions';
import * as fromBalance from '../state';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {CurrentBalanceService} from '../current-balance.service';

@Injectable()
export class BalancesEffects {

  constructor(
    private balanceService: CurrentBalanceService,
    private actions$: Actions,
    private store: Store<fromBalance.State>
    ) {
  }

  loadBalances$ = createEffect(() => this.actions$.pipe(
    ofType(balanceActions.Load),
    withLatestFrom(this.store.pipe(select(fromBalance.getBalances))),
    mergeMap(() => {
      return this.balanceService.getBalances()
        .pipe(
          map(balances => (balanceActions.LoadSuccess({balances}))),
          catchError(error => of(balanceActions.LoadFail({error})))
        );
    })
  ));
}
