<div mat-dialog-title>
  <h4 *ngIf="editing === true">Edit Fee Structure: {{ feeStructure.name }}</h4>
  <h4 *ngIf="editing === false">Create New Fee Structure</h4>
</div>

<mat-dialog-content>


  <form [formGroup]="feeStructureForm" (ngSubmit)="submitForm()">

        <label class="detail-label">Name</label>

        <mat-form-field appearance="outline" style="width: 100%;">
          <input matInput placeholder="e.g., Standard Fee" required formControlName="name">

          <mat-hint>Name of this fee structure</mat-hint>

          <mat-error *ngIf="feeStructureForm.controls['name'].invalid">Enter a name</mat-error>
        </mat-form-field>


        <label class="detail-label">Collection Type</label>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(value)]="feeStructure.collection_type" placeholder="Select One" formControlName="collectionType" required>
            <mat-option *ngFor="let type of collectionTypes" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>

          <mat-hint>
            Collection type determines the period relative to the billing statement date to which fees apply.
          </mat-hint>

          <mat-error *ngIf="feeStructureForm.controls['collectionType'].invalid">Select a collection type</mat-error>
        </mat-form-field>

        <label class="detail-label">Structure</label>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(value)]="feeStructure.calculation_type" placeholder="Select One" formControlName="structure" required
          (selectionChange)="selectStructure($event)">
            <mat-option *ngFor="let type of calculationTypes" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>

<!--          <mat-hint>-->
<!--            Type of calculation.-->
<!--          </mat-hint>-->

          <mat-error *ngIf="feeStructureForm.controls['structure'].invalid">Select a structure</mat-error>

          <mat-hint style="height: auto">
              <p class="help-block" *ngIf="feeStructure.calculation_type === 'R'">
                <b>Flat Rate</b> is a fixed percentage applied to all assets in the group.
<!--                This is fee structure applied at the group level, not the account level.-->
<!--                <br/>-->
<!--                <br/>-->
<!--                For example, a group with $100,000 in assets and a 1% fee will be charged $1,000-->
<!--                annually across all accounts. If the group has two accounts with the exact-->
<!--                same level of assets, each would be charged $500 per year.-->
              </p>


              <p class="help-block" *ngIf="feeStructure.calculation_type === 'G'">
                <b>Flat Group Fee</b> is a flat amount that's applied to the group.
<!--                <br/>-->
<!--                <br/>-->

<!--                For example, a flat account fee of $100 applied to a group with 5 accounts-->
<!--                will be charged $100 per year.-->
<!--              </p>-->

<!--              <p class="help-block" *ngIf="feeStructure.calculation_type === 'A'">-->
<!--                <b>Flat Account Fee</b> is a flat amount that's applied to each account.-->
<!--                <br/>-->
<!--                <br/>-->

<!--                For example, a flat account fee of $100 applied to a group with 5 accounts-->
<!--                will be charged $500 per year.-->
              </p>


              <p class="help-block" *ngIf="feeStructure.calculation_type === 'D'">
                <b>Drop Through</b> is a laddered fee structure whereby the asset level
                is applied to the highest asset tier.
<!--                <br/>-->
<!--                <br/>-->
<!--                For example, if you define a drop-through fee with 1% applied to assets under $100,000-->
<!--                and 0.50% applied to assets above $100,000, a billing group with $500,000 in total assets-->
<!--                would be charged 0.50% X $500,000 = $2,500 per year.-->
              </p>

              <p class="help-block" *ngIf="feeStructure.calculation_type === 'T'">
                <b>Tiered</b> is a laddered fee structure whereby the asset level
                is applied to each tier in the ladder.
<!--                <br/>-->
<!--                <br/>-->
<!--                For example, if you define a drop-through fee with 1% applied to assets under $100,000-->
<!--                and 0.50% applied to assets above $100,000, a billing group with $500,000 in total assets-->
<!--                would be charged 1% X $100,000 + 0.50% X $400,000 = $3,000 per year.-->
              </p>

              <p class="help-block" *ngIf="feeStructure.calculation_type === 'F'">
                <b>Favored</b> exempts accounts from being charged a fee.
<!--                The balance of favored accounts are ignored when creating the group billable balance.-->

<!--                <br/>-->
<!--                <br/>-->
<!--                For example suppose you have a group with two accounts: one with $250,000 in assets and a favored fee structure,-->
<!--                and another with $750,000 in assets and a 1% fee structure.-->
<!--                The billable balance for the group will be $750,000, charged $7,500 per year.-->

<!--                <br/>-->
<!--                <br/>-->
<!--                If you'd like a balance for an account to be included in the group but uncharged-->
<!--                you'll need to relocate the fee for the account you don't want charged.-->
<!--              </p>-->
          </mat-hint>
        </mat-form-field>

        <label class="detail-label">Valuation Type</label>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(value)]="feeStructure.balance_type" placeholder="Select One" formControlName="valuationMethod" required>
            <mat-option *ngFor="let type of balanceTypes" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>
          <mat-hint>
            The methodology for deriving an account's billable balance.
          </mat-hint>

          <mat-error *ngIf="feeStructureForm.controls['valuationMethod'].invalid">Select a valuation method</mat-error>
        </mat-form-field>

        <label class="detail-label">Billing Frequency</label>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(value)]="feeStructure.frequency" placeholder="Select One" formControlName="frequency" required>
            <mat-option *ngFor="let type of frequencys" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>

          <mat-error *ngIf="feeStructureForm.controls['frequency'].invalid">Select a billing frequency</mat-error>

          <mat-hint>The amount of time for which apply fees are applied.</mat-hint>
        </mat-form-field>


<!--        <div class="col-sm-9">-->
<!--          <dx-text-box [(value)]="feeStructure.name" placeholder="e.g., Standard Fee">-->
<!--            <dx-validator>-->
<!--              <dxi-validation-rule type="required" message="Name is required."></dxi-validation-rule>-->
<!--              <dxi-validation-rule type="stringLength" [max]="200" message="Cannot be longer than 200 characters."></dxi-validation-rule>-->
<!--            </dx-validator>-->
<!--          </dx-text-box>-->

<!--          <p class="help-block">-->
<!--            Name of this fee structure-->
<!--          </p>-->
<!--        </div>-->



<!--      <div class="form-group">-->
<!--        <label class="col-sm-3 control-label">Collection Type</label>-->
<!--        <div class="col-sm-9">-->
<!--          <dx-select-box [dataSource]="collectionTypes"-->
<!--                         displayExpr="name"-->
<!--                         valueExpr="id"-->
<!--                         placeholder="Select one"-->
<!--                         [(value)]="feeStructure.collection_type">-->
<!--            <dx-validator>-->
<!--              <dxi-validation-rule type="required" message="Collection is required."></dxi-validation-rule>-->
<!--            </dx-validator>-->
<!--          </dx-select-box>-->

<!--          <p class="help-block">-->
<!--            Collection type determines the period relative to the billing statement date to which fees apply.-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label class="col-sm-3 control-label">Structure</label>-->
<!--        <div class="col-sm-9">-->

<!--          <dx-select-box [dataSource]="calculationTypes"-->
<!--                         displayExpr="name"-->
<!--                         valueExpr="id"-->
<!--                         placeholder="Select one"-->
<!--                         [(value)]="feeStructure.calculation_type">-->
<!--            <dx-validator>-->
<!--              <dxi-validation-rule type="required" message="Structure is required."></dxi-validation-rule>-->
<!--            </dx-validator>-->
<!--          </dx-select-box>-->

<!--          <p class="help-block">-->
<!--            Type of calculation.-->
<!--          </p>-->


<!--          <p class="help-block" *ngIf="feeStructure.calculation_type === 'R'">-->
<!--            <b>Flat Rate</b> is a fixed percentage applied to all assets in the group.-->
<!--            This is fee structure applied at the group level, not the account level.-->
<!--            <br/>-->
<!--            <br/>-->
<!--            For example, a group with $100,000 in assets and a 1% fee will be charged $1,000-->
<!--            annually across all accounts. If the group has two accounts with the exact-->
<!--            same level of assets, each would be charged $500 per year.-->
<!--          </p>-->


<!--          <p class="help-block" *ngIf="feeStructure.calculation_type === 'G'">-->
<!--            <b>Flat Group Fee</b> is a flat amount that's applied to the group.-->
<!--            <br/>-->
<!--            <br/>-->

<!--            For example, a flat account fee of $100 applied to a group with 5 accounts-->
<!--            will be charged $100 per year.-->
<!--          </p>-->

<!--          <p class="help-block" *ngIf="feeStructure.calculation_type === 'A'">-->
<!--            <b>Flat Account Fee</b> is a flat amount that's applied to each account.-->
<!--            <br/>-->
<!--            <br/>-->

<!--            For example, a flat account fee of $100 applied to a group with 5 accounts-->
<!--            will be charged $500 per year.-->
<!--          </p>-->


<!--          <p class="help-block" *ngIf="feeStructure.calculation_type === 'D'">-->
<!--            <b>Drop Through</b> is a laddered fee structure whereby the asset level-->
<!--            is applied to the highest asset tier.-->
<!--            <br/>-->
<!--            <br/>-->
<!--            For example, if you define a drop-through fee with 1% applied to assets under $100,000-->
<!--            and 0.50% applied to assets above $100,000, a billing group with $500,000 in total assets-->
<!--            would be charged 0.50% X $500,000 = $2,500 per year.-->
<!--          </p>-->

<!--          <p class="help-block" *ngIf="feeStructure.calculation_type === 'T'">-->
<!--            <b>Tiered</b> is a laddered fee structure whereby the asset level-->
<!--            is applied to each tier in the ladder.-->
<!--            <br/>-->
<!--            <br/>-->
<!--            For example, if you define a drop-through fee with 1% applied to assets under $100,000-->
<!--            and 0.50% applied to assets above $100,000, a billing group with $500,000 in total assets-->
<!--            would be charged 1% X $100,000 + 0.50% X $400,000 = $3,000 per year.-->
<!--          </p>-->

<!--          <p class="help-block" *ngIf="feeStructure.calculation_type === 'F'">-->
<!--            <b>Favored</b> exempts accounts from being charged a fee.-->
<!--            The balance of favored accounts are ignored when creating the group billable balance.-->

<!--            <br/>-->
<!--            <br/>-->
<!--            For example suppose you have a group with two accounts: one with $250,000 in assets and a favored fee structure,-->
<!--            and another with $750,000 in assets and a 1% fee structure.-->
<!--            The billable balance for the group will be $750,000, charged $7,500 per year.-->

<!--            <br/>-->
<!--            <br/>-->
<!--            If you'd like a balance for an account to be included in the group but uncharged-->
<!--            you'll need to relocate the fee for the account you don't want charged.-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div class="form-group">-->
<!--        <label class="col-sm-3 control-label">Valuation Method</label>-->
<!--        <div class="col-sm-9">-->
<!--          <dx-select-box [dataSource]="balanceTypes"-->
<!--                         displayExpr="name"-->
<!--                         valueExpr="id"-->
<!--                         placeholder="Select one"-->
<!--                         [(value)]="feeStructure.balance_type">-->
<!--            <dx-validator>-->
<!--              <dxi-validation-rule type="required" message="Valuation method is required."></dxi-validation-rule>-->
<!--            </dx-validator>-->
<!--          </dx-select-box>-->

<!--          <p class="help-block">-->
<!--            The methodology for deriving an account's billable balance.-->
<!--            <br/>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div class="form-group">-->
<!--        <label class="col-sm-3 control-label">Billing Frequency</label>-->
<!--        <div class="col-sm-9">-->
<!--          <dx-select-box [dataSource]="frequencys"-->
<!--                         displayExpr="name"-->
<!--                         valueExpr="id"-->
<!--                         placeholder="Select one"-->
<!--                         [(value)]="feeStructure.frequency">-->
<!--            <dx-validator>-->
<!--              <dxi-validation-rule type="required" message="Billing frequency is required."></dxi-validation-rule>-->
<!--            </dx-validator>-->
<!--          </dx-select-box>-->
<!--          <p class="help-block">-->
<!--            The amount of time for which apply fees are applied.-->
<!--            <br/>-->
<!--            This is also the frequency we recommend sending billing invoices and uploading fee files.-->
<!--            <br/>-->
<!--            Your billing will automatically run at this frequency.-->
<!--            <br/>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->


      <div class="form-group" *ngIf="feeStructure.frequency === 'Q'">

        <label class="detail-label">Quarterly Cycle</label>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(value)]="feeStructure.quarter_cycle" placeholder="Select One">
            <mat-option *ngFor="let type of quarterCycles" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>

          <mat-hint>
            The last date in these months define the end of the quarter.
          </mat-hint>
        </mat-form-field>


<!--        <div class="col-sm-9">-->
<!--          <dx-select-box [dataSource]="quarterCycles"-->
<!--                         displayExpr="name"-->
<!--                         valueExpr="id"-->
<!--                         placeholder="Select one"-->
<!--                         [(value)]="feeStructure.quarter_cycle">-->
<!--            <dx-validator>-->
<!--              <dxi-validation-rule type="required" message="Quarter cycle is required for quarterly billing."></dxi-validation-rule>-->
<!--            </dx-validator>-->
<!--          </dx-select-box>-->

<!--          <p class="help-block">-->
<!--            The last date in these months define the end of the quarter.-->
<!--          </p>-->
<!--        </div>-->
      </div>
    <div class="form-group" *ngIf="feeStructure.frequency === 'S'">

      <label class="detail-label">Semi-Annual Cycle</label>

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(value)]="feeStructure.semi_annual_cycle" placeholder="Select One">
          <mat-option *ngFor="let type of semiAnnualCycles" [value]="type.id">{{type.name}}</mat-option>
        </mat-select>

        <mat-hint>
          The last date in these months define the end of the semi annual.
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="feeStructure.frequency === 'A'">

      <label class="detail-label">Annual Cycle</label>

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(value)]="feeStructure.annual_cycle" placeholder="Select One">
          <mat-option *ngFor="let type of annualCycles" [value]="type.id">{{type.name}}</mat-option>
        </mat-select>

        <mat-hint>
          The last date in these months define the end of the annual.
        </mat-hint>
      </mat-form-field>
    </div>

      <div class="form-group" *ngIf="feeStructure.calculation_type !== 'F'">
        <label class="detail-label">Fee Schedule</label>

        <div class="col-sm-9">
          <br>
          <p class="pt-5" *ngIf="!feeStructure.calculation_type">
            Select a structure to specify a fee schedule.
          </p>

          <!-- tiered -->
          <table class="mat-table"
                 *ngIf="feeStructure.calculation_type === 'T' || feeStructure.calculation_type === 'D'" style="width: 100%; text-align: left;">

            <tr class="mat-header-row">
              <th class="mat-header-cell">Min balance ($)</th>
              <th class="mat-header-cell">Max balance ($)</th>
              <th class="mat-header-cell">Fee Rate (%)</th>
              <th class="mat-header-cell"></th>
            </tr>

            <tr class="mat-row" *ngFor="let tier of feeStructure.tiers">
              <td class="mat-cell">

                  <mat-form-field appearance="outline" class="tiered-form">
                    <input matInput currencyMask placeholder="Min (required)" [(ngModel)]="tier.min_balance" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>

<!--                  <dx-number-box [(value)]="tier.min_balance"-->
<!--                                 placeholder="Min (required)"-->
<!--                                 format="$#,##0.##">-->
<!--                    <dx-validator>-->
<!--                      <dxi-validation-rule type="required" message="Min is required."></dxi-validation-rule>-->
<!--                    </dx-validator>-->
<!--                  </dx-number-box>-->
              </td>

              <td class="mat-cell">

                  <mat-form-field appearance="outline" class="tiered-form">
                    <input matInput currencyMask placeholder="Max (blank for unlimited)" [(ngModel)]="tier.max_balance" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>


<!--                  <dx-number-box [(value)]="tier.max_balance"-->
<!--                                 placeholder="Max (blank for unlimited)"-->
<!--                                 format="$#,##0.##">-->
<!--                  </dx-number-box>-->

              </td>

              <td class="mat-cell">
                  <mat-form-field appearance="outline" class="tiered-form">
                    <input matInput type="number" placeholder="Rate (percentage)" [(ngModel)]="tier.rate" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>


<!--                  <dx-number-box [(value)]="tier.rate"-->
<!--                                 placeholder="Rate (percentage)"-->
<!--                                 format="##0.##">-->
<!--                    <dx-validator>-->
<!--                      <dxi-validation-rule type="required" message="Rate is required."></dxi-validation-rule>-->
<!--                    </dx-validator>-->
<!--                  </dx-number-box>-->
              </td>

              <td class="mat-cell">
                <button type="button" mat-icon-button color="primary" (click)="removeTier(tier)"
                        matTooltip="Remove this tier">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>

          </table>

          <!-- flat rate -->
          <table class="mat-table" *ngIf="feeStructure.calculation_type === 'R'" style="width: 100%; text-align: left;">

            <tr class="mat-header-row">
              <th class="mat-header-cell">Fee Rate (%)</th>
            </tr>

            <tr class="mat-row">
              <td class="mat-cell">

                  <mat-form-field appearance="outline">
                    <input matInput type="number" placeholder="e.g., 0.5" [(ngModel)]="feeStructure.flat_rate" [ngModelOptions]="{standalone: true}" formControlName="flatRate" required>
                    <mat-error *ngIf="feeStructureForm.controls['flatRate'].invalid">Enter Flat Rate</mat-error>

                  </mat-form-field>


<!--                  <dx-number-box [(value)]="feeStructure.flat_rate"-->
<!--                                 placeholder="e.g., 0.5"-->
<!--                                 format="##0.##">-->
<!--                    <dx-validator>-->
<!--                      <dxi-validation-rule type="required" message="Flat is required."></dxi-validation-rule>-->
<!--                    </dx-validator>-->
<!--                  </dx-number-box>-->

              </td>
            </tr>

          </table>

          <!-- flat fee -->
          <table class="mat-table" *ngIf="feeStructure.calculation_type === 'A' || feeStructure.calculation_type === 'G'"
                 style="width: 100%; text-align: left;"
          >
            <tr class="mat-header-row">
              <th class="mat-header-cell">Annual Amount ($)</th>
            </tr>

            <tr class="mat-row">
              <td class="mat-cell">


                  <mat-form-field appearance="outline">
                    <input matInput currencyMask placeholder="e.g., $250.00" [(ngModel)]="feeStructure.flat_dollar_fee" [ngModelOptions]="{standalone: true}" formControlName="flatAmount" required>
                    <mat-error *ngIf="feeStructureForm.controls['flatAmount'].invalid">Enter Annual Amount</mat-error>
                  </mat-form-field>
<!--

<dx-number-box [(value)]="feeStructure.flat_dollar_fee"-->
<!--                                 placeholder="e.g., $250.00"-->
<!--                                 format="$ #,##0.##">-->
<!--                    <dx-validator>-->
<!--                      <dxi-validation-rule type="required" message="Annual Amount is required."></dxi-validation-rule>-->
<!--                    </dx-validator>-->
<!--                  </dx-number-box>-->

              </td>
            </tr>

          </table>

        </div>
      </div>


  </form>

  <div *ngIf="feeStructure.calculation_type === 'T' || feeStructure.calculation_type === 'D'">
    <button mat-button color="primary"
            matTooltip="Add a tier level"
            (click)="addTierLevel()">
      Add Level
    </button>
  </div>


</mat-dialog-content>

<mat-dialog-actions>

    <app-atlas-submit-button (click)="submitForm()" [saving]="isUpdateLoading$ | async" [disabled]="!feeStructureForm.valid">Save</app-atlas-submit-button>

    <button mat-stroked-button color="primary" (click)="remove(feeStructure)"
            *ngIf="editing"
            matTooltip="Delete Fee Structure">
      Remove
    </button>

    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>

</mat-dialog-actions>


