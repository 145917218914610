import {Injectable, NgZone} from '@angular/core';
import {LoginService} from './login.service';
import {CognitoService} from './cognito.service';

const MINUTES_UNTIL_LOGOUT = 90;
const CHECK_INTERVAL = 30000;
const STORAGE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  constructor(private loginService: LoginService,
              private ngZone: NgZone,
              private cognitoService: CognitoService) {
    this.listen();
    this.initInterval();
  }

  get lastAction(): number {
    // Get last action from local storage
    return parseInt(localStorage.getItem(STORAGE_KEY), 10);
  }

  set lastAction(timestamp: number) {
    // Set the last action in local storage
    localStorage.setItem(STORAGE_KEY, timestamp.toString());
  }

  // Listen for clicks, scrolls, key presses
  listen() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.resetTimer());
      document.body.addEventListener('scroll', () => this.resetTimer());
      document.body.addEventListener('keydown', () => this.resetTimer());
    });
  }

  // Checks every minute for inactivity
  initInterval() {
    this.lastAction = Date.now();

    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVAL);
    });
  }

  resetTimer() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeLeft = this.lastAction + MINUTES_UNTIL_LOGOUT * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        console.log('logout');
        this.cognitoService.logout();
        (window as any).location = '/login';
      }
    });
  }
}
