<div class="add-note-wrapper">
  <app-atlas-activity-card>
    <app-atlas-activity-card-header>
      New Note
    </app-atlas-activity-card-header>

    <app-atlas-activity-card-content>
      <span *ngIf="!creating" (click)="toggleAddNoteForm(true)">
        Add Note
      </span>
      <ckeditor class="wealthbox-note-editable" *ngIf="creating" [config]="editorConfig" [editor]="editor" [(ngModel)]="newNote.content" [ngModelOptions]="{standalone: true}" ></ckeditor>

      <app-atlas-activity-card-details *ngIf="creating">
        <button color="primary" mat-button class="mat-button-xs" (click)="addNote()">Create</button>
        <button mat-button class="mat-button-xs" (click)="toggleAddNoteForm(false)">Cancel</button>
      </app-atlas-activity-card-details>
    </app-atlas-activity-card-content>
  </app-atlas-activity-card>

</div>

<dx-list
  class="toolbar-dx-full-list dx-disable-selection"
  [dataSource]="notesDxListDataSource"
  [recalculateEvent]="recalculateListHeightSubject$"
  [viewportOffset]="0"
  minHeight="0"
  appFillHeight
  [activeStateEnabled]="false"
  [focusStateEnabled]="false"
  [hoverStateEnabled]="false"
  selectionMode="none"
  [searchEnabled]="false">
  <div *dxTemplate="let data of 'item'">
    <app-wealthbox-note [note]="data"></app-wealthbox-note>
  </div>
</dx-list>
