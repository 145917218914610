import {CurrentBalance} from '../current-balance';
import * as balanceActions from './balances.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState} from '../../state/types';
import {InitialCallState} from '../../state/helpings/initial-call-state';

export interface BalanceState  extends EntityState<CurrentBalance> {
  callState: CallState;
  updateCallState: CallState;
}
export const adapter: EntityAdapter<CurrentBalance> = createEntityAdapter<CurrentBalance>();

const initialState: BalanceState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()}
});

const balancesReducer = createReducer(
  initialState,
  on(balanceActions.Load, (state) => {
    const callState = {...state.callState, loading: true};
    return {...state, callState};
  }),
  on(balanceActions.LoadSuccess, (state, {balances: balances}) => {
    const callState = {loading: false, loaded: true, error: null};
    return {...adapter.setAll(balances, state), callState};
  }),
  on(balanceActions.LoadFail, (state, {error}) => {
    const callState = {loading: false, loaded: false, error};
    return {...state, callState};
  }),
);

export function reducer(state: BalanceState | undefined, action: Action) {
  return balancesReducer(state, action);
}

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllBalances = selectAll;
